<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/messaging";
export default {
  data() {
    return {
      installingNotification: null,
    };
  },

  methods: {
    async listenForPushNotifications() {
      // Let's check if the browser supports notifications
      if (!("Notification" in window)) {
        return this.$alert(
          "The Browser you are using does not support Desktop Notifications. For Better Experience, please use upgrade your Browser.",
          "Outdated Browser",
          {
            confirmButtonText: "OK",
            type: "warning",
            callback: () => {},
          }
        );
        // return this.showWarningMessage();
      } else if (Notification.permission === "denied") {
        return this.$alert(
          "Notifications are turned Off on your Browser. Please go to Site Settings and enable Notifications for better experience",
          "Notifications Turned Off",
          {
            confirmButtonText: "OK",
            type: "warning",
            callback: () => {},
          }
        );
      }

      try {
        const messaging = firebase.messaging();
        // messaging.
        let token = await messaging.getToken({
          vapidKey:
          "BPmT1lBjD07dWnmtZozTSwNKsZ71St1ZXk3-yaWoIM3xBbtuFN1MKJlEc6Bt2qSxvTKrKwX32d41URjQDmv8CZA",
        });
        if (token) {
          // messaging.onMessage((payload) => {
          //   if (
          //     payload.data.title === "Update Available" &&
          //     payload.data.target &&
          //     payload.data.target.includes("Admin")
          //   ) {
          //     return navigator.serviceWorker.ready.then((registration) => {
          //       registration.update();
          //     });
          //   }
          // });

          try {
            //Send Token to DB to add it if it doesnt exist already
            await this.$http.post("push-notifications-device-tokens/save", {
              deviceToken: token,
            });
          } catch {
            //Ignore such errors for now
          }
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unable to Get Device Token from Firebase yet user gave permissions"
          );
        }
      } catch (error) {
        //
        if (Notification.permission === "denied") {
          return this.$alert(
            "You have Turned Off Notifications. For Better User Experience, please go to Site Settings and enable Notifications.",
            "Notifications Turned Off",
            {
              confirmButtonText: "OK",
              type: "warning",
              callback: () => {},
            }
          );
        }
      }
    },
  },

  created() {
    
    document.addEventListener(
      "visibilitychange",
      function () {
        document.dispatchEvent(
          new CustomEvent("appVisibilityChange", { detail: document.hidden })
        );
      },
      false
    );

    this.listenForPushNotifications();
    navigator.serviceWorker.addEventListener("message", (event) => {
      //If it is an update request, then update the app
      if (
        (event.data && event.data.title == "Check Update") ||
        (event.data.data && event.data.data.title == "Check Update")
      ) {
        navigator.serviceWorker
          .getRegistrations()
          .then(function (registrations) {
            let serviceWorker = registrations.find(
              (worker) =>
                worker.active &&
                worker.active.scriptURL.includes("service-worker.js")
            );
            //If we found it, attempt to fetch updates
            if (serviceWorker) {
              serviceWorker.update();
            }
          });
      }
    });

    document.addEventListener(
      "swUpdateFound",
      () => {
        this.$notify({
          title: "We are Updating",
          // type: "info",
          iconClass: "el-icon-loading mt-2",
          showClose: false,
          message: "Fetching new update now...",
        });
      },
      { once: true }
    );

    document.addEventListener(
      "swUpdated",
      () => {
        this.$notify({
          title: "Update Available",
          dangerouslyUseHTMLString: true,
          // iconClass: "fas fa-download",
          type: "info",
          showClose: false,
          onClick: () => {
            location.reload();
            return false;
          },
          message: `There is an update available. Please Click below to refresh
                      <button
                          v-on:click="loginUser"
                          v-if="!isBusy"
                          :disabled="$v.emailOrPhone.$invalid || $v.password.$invalid"
                          type="button"
                          class="btn z-depth-0 mt-2"
                          v-ripple="'rgba(255, 255, 255, 0.35)'"
                          style="
                            width: 100px;
                            text-transform: capitalize;
                            font-size: 13px;
                            height: 35px;
                            border-radius: 40px;
                            padding: 10px 10px;
                            background-color: var(--el-app-primary);
                          "
                        >
                          <span style="position: relative; bottom: 3px">Reload</span>
                        </button>
                      `,
          duration: 0,
        });
      },
      { once: true }
    );
  },

  destroyed() {},
};
</script>

<style>
.el-message-box__title {
  font-size: 17px;
  font-weight: 600;
  font-family: "Euclid Circular B";
}

.el-popper {
  font-family: "Euclid Circular B" !important;
}

.el-button--primary {
  color: #fff;
  background-color: var(--el-app-primary) !important;
  border-color: var(--el-app-primary) !important;
}

.el-message-box__content {
  color: #4d4e52;
  font-weight: 400;
  font-size: 13px;
  font-family: "Euclid Circular B";
}

.el-notification__content {
  text-align: left !important;
}

.el-notification.right {
  font-family: "Euclid Circular B" !important;
}
.bread-crumb-item {
  font-size: 0.8em;
  font-weight: 500;
  color: #919191;
  cursor: pointer;
}

.bread-crumb-item:hover,
.bread-crumb-home:hover {
  color: #000 !important;
  transition: 1s;
}

#app {
  font-family: "Euclid Circular B";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
input.legacy-input {
  height: calc(1.55rem) !important;
  padding: 5px;
  font-size: 0.8rem !important;
  font-weight: 400 !important;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}
input.legacy-input:disabled {
  background-color: rgb(248, 246, 246) !important;
}
input.legacy-input.input-error {
  border: 1px solid red !important;
  box-shadow: none !important;
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;
  /*  animation-iteration-count:  infinite;  */
}
input.legacy-input:focus:not(.input-error) {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #80bdff !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #eeeeee;
}

/* Handle */
::-webkit-scrollbar-thumb {
  width: 10px;
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  width: 10px;
  background: #164b70;
  border-radius: 10px;
}

.card {
  box-shadow: 0 2px 5px 2px rgb(0 0 0 / 0%), 0 2px 10px 0 rgb(0 0 0 / 5%) !important;
}

button:disabled {
  cursor: not-allowed !important;
  pointer-events: all !important;
}
</style>
