






































// @ts-ignore
import carousel  from 'vue-owl-carousel';

export default {
     components: { carousel },
    
    data() {
        return {
            
        }
    },
    props: {
        course_count : {
            type: Number,
            required: true
        },
        student_count : {
            type: Number,
            required: true
        },
        staff_count : {
            type: Number,
            required: true
        }
    },

    methods: {
        format(percentage:any) {
            return percentage === 100 ? 'Full' : `${percentage}%`;
        }
    }
}
