<template>
  <section class="container">
    <div class="content">
      <div class="row w-100 mx-0">
        <div class="col-12 px-0">

          <div class="row w-100">
            <div class="col-12 text-left">
              <div style=" font-size: 20px; font-weight: 400; color: var(--el-app-primary); margin-bottom: 15px; ">
                <strong>Search results</strong>
              </div>
            </div>
          </div>

          <div class="row w-100">

            <div class="col-12 text-left" v-if="$store.state.search_results.faculties.content.length">
                <span style="color: grey; font-weight: 600;"><strong>Faculties</strong></span><br/>
                <ul style="display: flex;  flex-wrap: wrap; margin:0px; list-style:none; padding:0px">
                    <li class="lecturer" v-for="faculty in $store.state.search_results.faculties.content" :key="faculty.faculty_id" style="margin:5px; padding: 0px 10px; border-radius: 5px; list-style:none; background-color: #FFFFFF; position: relative;">
                      <div @click="facultyDetail(faculty.faculty_id )" style="cursor: pointer;">
                        <small>{{ faculty.title }}</small>
                      </div>
                    </li>
                </ul>
            </div>

            
            <div class="col-12 text-left" v-if="$store.state.search_results.courses.content.length" style="margin-top: 30px;">
                <span style="color: grey; font-weight: 500;"><strong>Programmes</strong></span><br/>
                <ul style="display: flex;  flex-wrap: wrap; margin:0px; list-style:none; padding:0px">
                    <li v-for="course in $store.state.search_results.courses.content" :key="course.course_id" style="margin:5px; padding: 0px 10px; border-radius: 5px; list-style:none; background-color: #ffffff; position: relative;">
                      <div @click="goToCourseDetails(course.course_id)" style="cursor: pointer;">
                        <small>{{ course.title }}</small>
                      </div>
                    </li>
                </ul>
            </div>

            
            <div class="col-12 text-left" v-if="$store.state.search_results.course_units.content.length" style="margin-top: 30px;">
                <span style="color: grey; font-weight: 500;"><strong>Modules</strong></span><br/>
                <ul style="display: flex;  flex-wrap: wrap; margin:0px; list-style:none; padding:0px">
                    <li v-for="course_unit in $store.state.search_results.course_units.content" :key="course_unit.course_unit_id" style="margin:5px; padding: 0px 10px; border-radius: 5px; list-style:none; background-color: #ffffff; position: relative;">
                        <div @click="goToCourseUnitResources(course_unit.course_unit_id)" style="cursor: pointer;">
                          <small>{{ course_unit.title }}</small>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="col-12 text-left" v-if="$store.state.search_results.staff.content.length" style="margin-top: 30px;">
                <span style="color: grey; font-weight: 500;"><strong>Staff</strong></span><br/>
                <ul style="display: flex;  flex-wrap: wrap; margin:0px; list-style:none; padding:0px">
                    <li v-for="st in $store.state.search_results.staff.content" :key="st.staff_id" style="margin:5px; padding: 0px 10px 0px 0px; border-radius: 50px 20px  20px 50px; list-style:none; background-color: #ffffff; position: relative;">
                      <div @click="staffProfile(st.staff_id)" style="display: flex; cursor: pointer; border-radius: 50px 20px  20px 50px;">
                        <div class="profile_img">
                          <img :src="st.profile_picture" v-if="st.profile_picture"  alt="" class="img-fluid" />
                          <div v-if="st.profile_picture == null" style="display: flex; align-items:center; justify-content: center; margin-right:5px; background-color: var(--el-app-primary); color: white; width:30px; height:30px; object-fit:cover; border-radius: 100%;">
                            <strong>{{ getInitials(`${st.first_name} ${st.last_name}`) }}</strong>
                          </div>
                        </div>
                        <div style="display: flex; align-items: center;margin-right:5px;">
                          <small>{{ st.first_name }} {{ st.last_name }}</small>
                        </div>
                      </div>
                    </li>
                </ul>
            </div>


            <div class="col-12 text-left" v-if="$store.state.search_results.students.content.length" style="margin-top: 30px;">
                <span style="color: grey; font-weight: 500;"><strong>Students</strong></span><br/>
                <ul style="display: flex;  flex-wrap: wrap; margin:0px; list-style:none; padding:0px">
                    <li v-for="student in $store.state.search_results.students.content" :key="student.student_id" style="margin:5px; padding: 0px 10px 0px 0px; border-radius: 50px 20px  20px 50px; list-style:none; background-color: #ffffff; position: relative;">
                      <div @click="studentProfile(student.student_id)" style="display: flex; cursor: pointer; border-radius: 50px 20px  20px 50px;">
                        <div class="profile_img">
                          <img :src="student.profile_picture" v-if="student.profile_picture"  alt="" class="img-fluid" />
                          <div v-if="student.profile_picture == null || student.profile_picture == '' " style="display: flex; align-items:center; justify-content: center;margin-right:5px; background-color: var(--el-app-primary); color: white; width:30px; height:30px; object-fit:cover; border-radius: 100%;">
                            <strong>{{ getInitials(`${student.first_name} ${student.last_name}`) }}</strong>
                          </div>
                        </div>
                        <div style="display: flex; align-items: center;margin-right:5px;">
                          <small>{{ student.first_name }} {{ student.last_name }}</small>
                        </div>
                      </div>
                    </li>
                </ul>
            </div>

          </div>

        </div>

      </div>
    </div>
  </section>
</template>

<script>
  // import { ScaleOut } from "vue-loading-spinner";
    
  export default {
    // components: { ScaleOut },

    data() {
      return {
        loading: false,
        loadingError: false,
        submiting: false,

        search_results: this.$store.state.search_results,
      };
    },

    computed: {

      getInitials() {
        return (nameString) => {
          //  format(parseISO(dateString), "do MMMM yyyy");
          var names = nameString.split(" "),
            initials = names[0].substring(0, 1).toUpperCase();
          if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
          }
          return initials;
        };
      },

    },

    mounted() { 
      if(Object.keys(this.$store.state.search_results).length === 0) {
        return this.$router.replace({name: '/dashboard/home',});
      }
    },
    
    methods: {

       
          facultyDetail(fId) {
              let facultyName = this.faculties.find((faculty) => {
                return faculty.faculty_id == fId
              }).title;

              this.$router.push({name: 'FacultyDetail', params: { fId, facultyName }});
          },

          goToCourseDetails(cId) {
            this.$router.push({ path: `course/${cId}/course-units` });
          },

          studentProfile(sId) {
            this.$router.push({ path: `student-profile/${sId}` });
          },

          staffProfile(sId) {
            this.$router.push({ path: `staff-profile/${sId}` });
          },

          goToCourseUnitResources(cuId) {
            let course_unit_title = this.$store.state.search_results.course_units.content.find((course_unit) => {
              return course_unit.course_unit_id == cuId
            }).title;
              
            this.$router.push({name: 'CourseUnitResources', params: {
              cuId,
              course_unit_title
            }});
          },
        


    },
  };
</script>

<style scoped>
    .content {
        margin-top: 45px !important;
    }

    @media (max-width: 768px) {
        .content {
            margin-top: 15px !important;
        }
    }
    .profile_img img{
        border-radius:50%; width:30px; height:30px; object-fit:cover;
        margin-right:5px;
    }
</style>
