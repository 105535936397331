<template>
  <div class="container-fluid px-0 h-100" style="text-align: left">
    <article>
      <section style="padding-top: 50px">
        <div class="container">
          <div class="row w-100 mb-4">
            <div class="col-12 pl-3 px-0 mb-0 text-left">
              <BreadCrumbComponent :pageTitles="['Intakes']" />
            </div>
          </div>

          <div class="row w-100">
            <div class="col-12 text-left">
              <div
                style="
                  font-size: 20px;
                  font-weight: 400;
                  color: var(--el-app-primary);
                  margin-bottom: 15px;
                "
              >
                <strong>Intakes</strong><br/>
                <span>
                  <small> Please add all Intakes here. </small>
                </span>
              </div>
            </div>
          </div>

          <div class="search_add_section">
            <div class="" style="display: flex;">
              <input type="text" v-model="filters[0].value" :placeholder="searchInputFieldPlaceHolder"  class="search_by_input" spellcheck="off"/>

              <div style="margin-left: 20px;">
                  <el-select v-model="searchBy" placeholder="Select" class="d-block" style="height: 40px !important;">
                      <el-option v-for="item in searchByOptions"
                          :key="item"
                          :label="item"
                          :value="item">
                      </el-option>
                  </el-select>
              </div>

            </div>

            <div :class="findCommonElements3(create_batch, $store.state.theRole)?'show_list':'hide_list'">
              <el-button style="background-color: var(--el-app-primary); color: white;" class="z-depth-0 addbtn" v-ripple="'rgba(255, 255, 255, 0.35)'" @click="openBatchModal">Add Intake</el-button>
            </div>

          </div>
          <hr />

          <div class="table-responsive">
            <data-tables
              :data="batches"
              :table-props="tableProps"
              :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }"
              type="expand"
              :filters="filters"
            >
              <div slot="empty">
                <ScaleOut
                  v-if="loading && !loadingError"
                  class="mt-3"
                  :background="'#164B70'"
                />
                <div
                  @click="getBatches"
                  v-else-if="!loading && loadingError"
                  style="cursor: pointer"
                >
                  Unable to Load Intakes Now. Please click here to retry
                </div>
                <div v-if="!loading && !loadingError">No Intakes</div>
              </div>

              <el-table-column
                label="#"
                :show-overflow-tooltip="true"
                label-class-name="text-center"
                class-name="text-center"
                width="80px"
              >
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Programme"
                :show-overflow-tooltip="true"
                label-class-name="text-left"
                width="auto"
              >
                <template slot-scope="scope">
                  <span>
                    {{ scope.row.course.title }}
                  </span>
                </template>
              </el-table-column>

              <el-table-column
                label="Intake"
                :show-overflow-tooltip="true"
                label-class-name="text-left">
                <template slot-scope="scope">
                  <span>{{ toMonthName(scope.row.month) }} {{ scope.row.year }}</span>
                </template>
              </el-table-column>

              <el-table-column label="Number of students"
                :show-overflow-tooltip="true" label-class-name="text-center" class-name="text-center">
                <template slot-scope="scope">
                  <div @click="showStudents(scope.row.course_intake_batch_id)" style="cursor: pointer; width:100%; height:100%;">
                    <!-- <span style="font-weight: 700;">{{scope.row.batch_students.length}},  {{ scope.row.batch_students.length == 1 ? 'Student' : 'Students' }} </span> -->
                    <el-button type="primary" round>
                          <span style="font-weight: 700;">{{scope.row.batch_students}} {{ scope.row.batch_students == 1 ? 'Student' : 'Students' }} </span>
                    </el-button>
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                label="Assign Intake"
                :show-overflow-tooltip="true"
                label-class-name="text-center"
                class-name="text-center"
              >
                <template slot-scope="scope">
                  <el-row style="display: flex; justify-content:center;">
                    <el-tooltip
                      content="Add Student to this Intake"
                      placement="top"
                    >
                      <el-button
                        :disabled="!findCommonElements3(student_crud, $store.state.theRole)"
                        @click="
                          showStudentModal(scope.row.course_intake_batch_id)
                        "
                        circle
                        style="
                          background-color: var(--el-app-primary);
                          color: white;
                        "
                        ><i class="fas fa-users"></i
                      ></el-button>
                    </el-tooltip>
                    <el-tooltip
                      content="Assign Lecturer to this Intake"
                      placement="top"
                    >
                      <el-button
                        type="success"
                        @click="batchDetail(scope.row.course_intake_batch_id)"
                        circle
                        ><i class="fas fa-user-tie"></i
                      ></el-button>
                    </el-tooltip>
                  </el-row>
                </template>
              </el-table-column>

              <el-table-column
                label="Actions"
                :show-overflow-tooltip="true"
                label-class-name="text-center"
                class-name="text-center"
              >
                <template slot-scope="scope">
                  <el-row style="display: flex; justify-content:center;">
                    <el-tooltip content="Edit" 
                    placement="top"
                    >
                      <el-button
                        type="primary"
                         :disabled="!findCommonElements3(create_batch, $store.state.theRole)"
                        @click="
                          batchEditData(
                            scope.row.course_intake_batch_id,
                            scope.row.year,
                            scope.row.month,
                            scope.row.course.course_id
                          )
                        "
                        icon="el-icon-edit"
                        circle
                      ></el-button>
                    </el-tooltip>
                    <el-tooltip content="Lectures Schedule" 
                      placement="top"
                    >
                      <el-button
                         :disabled="!findCommonElements3(live_lectures_create, $store.state.theRole)"
                        style="background-color: #9933cc; color: white"
                        @click="
                          $router.push({
                            name: 'IntakeBatchCalendar',
                            params: {
                              batchId: scope.row.course_intake_batch_id,
                            },
                          })
                        "
                        icon="el-icon-date"
                        circle
                      ></el-button>
                    </el-tooltip>
                    <el-tooltip content="Delete" 
                    placement="top" 
                    :disabled="!findCommonElements3(create_batch, $store.state.theRole)"
                    >
                      <el-button
                        type="danger" :disabled= "true"
                        @click="deleteBatch(scope.row.course_intake_batch_id)"
                        icon="el-icon-delete"
                        circle
                      ></el-button>
                    </el-tooltip>

                  </el-row>
                </template>
              </el-table-column>
            </data-tables>
          </div>

        </div>
      </section>

       <!--  -->
      <section>
        <div class="container">
          <div class="batch_dialogs">
            <el-dialog title="Add Intake" :visible.sync="addBatchModal" :destroy-on-close="true" :before-close="handleClose">
              <div v-loading="submiting" class="batch_request_form">
                <el-form
                  :model="batchForm"
                  :rules="rules"
                  ref="batchForm"
                  class="demo-batchForm2"
                >
                  <div class="row">
                    <div class="col-md-6">
                      <label>Year of Intake</label>
                      <el-form-item prop="year">
                        <el-date-picker style="width: 100%"
                          type="year"
                          :value-format="'yyyy'"
                          @keyup.enter="addBatch"
                          placeholder="Pick a year"
                          v-model="batchForm.year"
                        ></el-date-picker>
                      </el-form-item>
                    </div>
                    <div class="col-md-6">
                      <label>Month of Intake</label>
                      <el-form-item prop="month">
                        <el-date-picker style="width: 100%"
                          type="month"
                          :format="'MMMM'"
                          :value-format="'M'"
                          @keyup.enter="addBatch"
                          placeholder="Pick a month"
                          v-model="batchForm.month"
                        ></el-date-picker>
                      </el-form-item>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <label>Choose programme</label>
                      <el-form-item prop="course_id">
                        <el-select filterable v-model="batchForm.course_id"
                          placeholder="Choose programme" style="width: 100%">
                          <el-option
                            v-for="c in courses"
                            :key="c.course_id"
                            :value="c.course_id"
                            :label="c.title"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div class="col-md-6">
                      <label>Cohort</label>
                      <el-form-item prop="cohort">
                        <el-input v-model="batchForm.cohort" placeholder="Cohort"></el-input>
                      </el-form-item>
                    </div>
                  </div>

                  <el-form-item class="text-center">
                    <el-button 
                      style="background-color: var(--el-app-primary); color: white;"
                      v-on:click="addBatch('batchForm')"
                    >
                      Add Intake
                    </el-button>
                  </el-form-item>

                  <hr/>

                  <div class="text-center">
                    <div class="text-center">
                      <small>© 2021 Pesamoni, All rights reserved.</small>
                    </div>
                    <div class="text-center">
                      <!-- <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                    </div>
                  </div>
                </el-form>
              </div>
            </el-dialog>

            <el-dialog title="Edit Intake" :visible.sync="editBatchModal" :destroy-on-close="true" :before-close="handleClose">
              <div v-loading="submiting" class="batch_request_form">
                <el-form
                  :model="batchForm"
                  :rules="rules"
                  ref="batchForm"
                  class="demo-batchForm2"
                >
                  <div class="row">
                    <div class="col-md-6">
                      <label>Year of Intake</label>
                      <el-form-item prop="year">
                        <el-date-picker style="width: 100%"
                          type="year" :value-format="'yyyy'"
                          @keyup.enter="editBatch"
                          placeholder="Pick a year"
                          v-model="batchForm.year"></el-date-picker>
                      </el-form-item>
                    </div>
                    <div class="col-md-6">
                      <label>Month of Intake</label>
                      <el-form-item prop="month">
                        <el-date-picker style="width: 100%"
                          type="month" :format="'MMMM'"
                          :value-format="'M'" @keyup.enter="editBatch"
                          placeholder="Pick a month"
                          v-model="batchForm.month"></el-date-picker>
                      </el-form-item>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <label>Choose Programme</label>
                      <el-form-item prop="course_id">
                        <el-select filterable
                          v-model="batchForm.course_id"
                          placeholder="Choose Programme"
                          style="width: 100%">
                          <el-option
                            v-for="c in courses"
                            :key="c.courses"
                            :value="c.course_id"
                            :label="c.title" @keyup.enter="editBatch"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div class="col-md-6">
                      <label>Cohort</label>
                      <el-form-item prop="cohort">
                        <el-input v-model="batchForm.cohort" placeholder="Cohort"></el-input>
                      </el-form-item>
                    </div>
                  </div>

                  <el-form-item class="text-center">
                    <el-button
                      style="background-color: var(--el-app-primary); color: white;"
                      v-on:click="editBatch('batchForm')">
                      Edit Intake
                    </el-button>
                  </el-form-item>

                  <hr />

                  <div class="text-center">
                    <div class="text-center">
                      <small>© 2021 Pesamoni, All rights reserved.</small>
                    </div>
                    <div class="text-center">
                      <!-- <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                    </div>
                  </div>
                </el-form>
              </div>
            </el-dialog>

            <el-dialog title="Add Student" :visible.sync="addStudentsModal" :destroy-on-close="true" :before-close="handleClose">
              <div v-loading="submiting">
                <el-form :model="studentForm"
                  :rules="studentRules" ref="studentForm" class="demo-studentForm2">

                  <div class="row">
                    <div class="col-md-6">
                      <label>First Name</label>
                      <el-form-item prop="first_name">
                        <el-input v-model="studentForm.first_name"></el-input>
                      </el-form-item>
                    </div>
                    <div class="col-md-6">
                      <label>Last Name</label>
                      <el-form-item prop="last_name">
                        <el-input v-model="studentForm.last_name"></el-input>
                      </el-form-item>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <label>Email</label>
                      <el-form-item prop="email">
                        <el-input v-model="studentForm.email"></el-input>
                      </el-form-item>
                    </div>
                    <div class="col-md-6">
                      <label>Phone Number</label>
                      <el-form-item prop="phone_number">
                        <el-input v-model="studentForm.phone_number"></el-input>
                      </el-form-item>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <label>Registration Number</label>
                      <el-form-item prop="registration_no">
                        <el-input v-model="studentForm.registration_no"></el-input>
                      </el-form-item>
                    </div>
                    <div class="col-md-6">
                      <label>Choose Session</label>
                      <el-form-item prop="session">
                        <el-select v-model="studentForm.session" filterable placeholder="Choose Session" class="d-block" style="height: 40px !important;">
                          <el-option v-for="session in sessions"
                              :key="session"
                              :label="session"
                              :value="session">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <label>Choose Nationality</label>
                      <el-form-item prop="nationality">
                        <el-select v-model="studentForm.nationality" filterable placeholder="Choose Nationality" class="d-block" style="height: 40px !important;">
                          <el-option v-for="nationality in nationalities"
                              :key="nationality"
                              :label="nationality"
                              :value="nationality">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div class="col-md-6">
                      <label>Gender</label>
                      <el-form-item prop="gender">
                        <el-radio-group v-model="studentForm.gender">
                          <el-radio label="MALE"></el-radio>
                          <el-radio label="FEMALE"></el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </div>
                  </div>

                  <hr />
                  <el-form-item class="text-center">
                    <el-button type="primary" @click="submitForm('studentForm')">Create Student</el-button>
                  </el-form-item>
                </el-form>
              </div>
            </el-dialog>

            <el-dialog title="Students in the Intake" :visible.sync="dialogVisible" width="80%">
              <div v-loading="isBusy">

                <div style="margin-bottom: 20px; display: flex; align-items: center; justify-content: space-between;">
                  <div class="row w-100 d-flex">
                    <div class="col-md-5 mb-3">
                      <input type="text" class="legacy-input" v-model="filters2[0].value"
                        placeholder="Search by name" style="height: calc(1.8rem) !important" spellcheck="off"/>
                    </div>
                  </div>
                </div>

              <div style=" width: 100%">
                <data-tables :data="batch_students" :table-props="tableProps2" :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }" type="expand" :filters="filters2">
                      
                                <el-table-column label="#" :show-overflow-tooltip="true" label-class-name="text-left" width="30px">
                                    <template slot-scope="scope">
                                        <span>{{ scope.$index + 1 }}</span>
                                    </template>
                                </el-table-column>

                                <el-table-column label="Name" :show-overflow-tooltip="true" label-class-name="text-left" width="auto">
                                    <template slot-scope="scope">
                                        <div style="display:flex; align-items: center;">
                                            <div  style="width:40px; height:40px; border-radius: 50%;">
                                                <div v-if="scope.row.profile_picture" style="display: flex; align-items:center; justify-content: center; background-color: var(--el-app-primary); color: white; width:40px; height:40px; object-fit:cover; border-radius: 100%;">
                                                    <img :src="scope.row.profile_picture" class="img-fluid" style="width:40px; height:40px; object-fit:cover; border-radius: 100%;" />
                                                </div>
                                                <div v-if="scope.row.profile_picture == null" style="display: flex; align-items:center; justify-content: center; background-color: var(--el-app-primary); color: white; width:40px; height:40px; object-fit:cover; border-radius: 100%;">
                                                    <strong>{{ getInitials(`${scope.row.first_name} ${scope.row.last_name}` ) }}</strong>
                                                </div>
                                            </div>
                                            <div style="margin-left: 10px;">
                                                <span>{{ scope.row.first_name }} {{ scope.row.last_name }}</span><br/>
                                                <!-- <span showing years on edit style="font-weight: 600;">Email: </span><span>{{ scope.row.email }}</span> -->
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>

                                <el-table-column label="Registration Number" :show-overflow-tooltip="true" label-class-name="text-left" width="auto">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.registration_no }}</span>
                                    </template>
                                </el-table-column>

                                <el-table-column label="Phone Number">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.phone_number }}</span>
                                    </template>
                                </el-table-column>
                                            
                            </data-tables>
              </div>
              </div>

                <span slot="footer" class="dialog-footer">
                  <el-button @click="dialogVisible = false">Close</el-button>
                </span>
            </el-dialog>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
import { ScaleOut } from "vue-loading-spinner";
import BreadCrumbComponent from "../components/bread-crumb-component";
import getMonthName from "../helpers/getMonthName";

export default {
  components: { ScaleOut, BreadCrumbComponent },

  data() {
    return {
      addStudentsModal: false,
      addBatchModal: false,
      editBatchModal: false,
      dialogVisible: false,

      loading: false,
      loadingError: false,
      submiting: false,
      isBusy: false,
      deleting: false,
      batches: [],
      courses: [],
      batch_students: [],
      

      tableProps: {
        border: true,
        stripe: true,
        defaultSort: {
          prop: "course_intake_batch_id",
          order: "descending",
        },
      },

      filters: [
        {
          prop: "Course",
          value: "",
          filterFn: (row, filterItem) => {
            if (row.course == null ) return false;
            if (this.searchBy == "Search by Programme") {
              return `${ row.course.title }`
                .toLowerCase()
                .includes(filterItem.value.toLowerCase().trim());
            } else if (this.searchBy == "Search by Month of Intake") {
              return `${ this.toMonthName(row.month) }`
                .toLowerCase()
                .includes(filterItem.value.toLowerCase().trim());
            } else if (this.searchBy == "Search by Year of Intake") {
              return `${ row.year }`
                .toLowerCase()
                .includes(filterItem.value.toLowerCase().trim());
            }

            return false;
          },
        },
        {
          prop: "year",
          value: "",
        },
        {
          prop: "month",
          value: "",
        },
      ],

      tableProps2: {
        border: true,
        stripe: true,
      },

      filters2: [
        {
          prop: "first_name",
          value: "",
        },
      ],

      value: '',

      id_edit: "",

      batchForm: {
        year: "",
        month: "",
        course_id: "",
        cohort: "",
      },

      rules: {
        year: [
          { required: true, message: "Please input year", trigger: "blur" },
        ],
        month: [
          { required: true, message: "Please input Month", trigger: "blur" },
        ],
        course_id: [
          { required: true, message: "Please add Course", trigger: "change" },
        ],
        cohort: [
          { required: true, message: "Please enter Cohort", trigger: "blur" },
        ],
      },

      studentForm: {
        course_intake_batch_id: '',
        registration_no: '',
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        gender: '',
        nationality: '',
        session: ''
      },

      studentRules: {
        registration_no: [
            { required: true, message: 'Please input Registration Number', trigger: 'blur' }
        ],
        first_name: [
            { required: true, message: 'Please input First Name', trigger: 'blur' }
        ],
        last_name: [
            { required: true, message: 'Please input Last Name', trigger: 'blur' }
        ],
        email: [
            { required: true, message: 'Please add Email', trigger: 'blur' }
        ],
        phone_number: [
            { required: true, message: 'Please Add your phone number', trigger: 'blur' },
            { min: 10, max: 13, message: 'Length should be 10 to 13', trigger: 'blur' }
        ],
        gender: [
            { required: true, message: 'Please select your gender', trigger: 'change' }
        ],
        nationality: [
            { required: true, message: 'Please choose Nationality', trigger: 'change' }
        ],
        session: [
            { required: true, message: 'Please choose Session', trigger: 'change' }
        ],
      },

      searchBy: "Search by Programme",
      searchByOptions: [
        "Search by Programme",
        "Search by Month of Intake",
        "Search by Year of Intake",
      ],

      sessions: [
        "DAY", "EVENING", "WEEKEND", "DISTANCE", "ONLINE", "IN-SERVICE", "MODULAR"
      ],

      nationalities: [
        "Afghan", "Albanian", "Algerian", "American", "Andorran", "Angolan", "Antiguans", "Argentinean",
        "Armenian", "Australian", "Austrian", "Azerbaijani", "Bahamian", "Bahraini", "Bangladeshi",
        "Barbadian", "Barbudans", "Batswana", "Belarusian", "Belgian", "Belizean", "Beninese", "Bhutanese", 
        "Bolivian", "Bosnian", "Brazilian", "British", "Bruneian", "Bulgarian", "Burkinabe", "Burmese", 
        "Burundian", "Cambodian", "Cameroonian", "Canadian", "Cape Verdean", "Central African","Chadian", 
        "Chilean", "Chinese", "Colombian", "Comoran", "Congolese", "Costa Rican", "Croatian","Cuban", 
        "Cypriot", "Czech", "Danish", "Djibouti", "Dominican", "Dutch", "East Timorese", "Ecuadorean",
        "Egyptian", "Emirian", "Equatorial Guinean", "Eritrean", "Estonian", "Ethiopian", "Fijian", 
        "Filipino", "Finnish", "French", "Gabonese", "Gambian", "Georgian", "German","Ghanaian", "Greek",
        "Grenadian", "Guatemalan", "Guinea-Bissauan", "Guinean", "Guyanese", "Haitian", "Herzegovinian",
        "Honduran", "Hungarian", "I-Kiribati", "Icelander", "Indian", "Indonesian", "Iranian", "Iraqi", 
        "Irish", "Israeli", "Italian", "Ivorian", "Jamaican", "Japanese", "Jordanian", "Kazakhstani", 
        "Kenyan", "Kittian and Nevisian", "Kuwaiti", "Kyrgyz", "Laotian", "Latvian", "Lebanese", "Liberian",
        "Libyan", "Liechtensteiner", "Lithuanian", "Luxembourger", "Macedonian", "Malagasy", "Malawian", 
        "Malaysian", "Maldivan", "Malian", "Maltese", "Marshallese", "Mauritanian", "Mauritian", "Mexican",
        "Micronesian", "Moldovan", "Monacan", "Mongolian", "Moroccan", "Mosotho", "Motswana", "Mozambican",
        "Namibian", "Nauruan", "Nepalese", "New Zealander", "Nicaraguan", "Nigerian", "Nigerien", 
        "North Korean", "Northern Irish", "Norwegian", "Omani", "Pakistani", "Palauan", "Panamanian",
        "Papua New Guinean", "Paraguayan", "Peruvian", "Polish", "Portuguese", "Qatari", "Romanian", 
        "Russian", "Rwandan", "Saint Lucian", "Salvadoran", "Samoan", "San Marinese", "Sao Tomean", "Saudi",
        "Scottish", "Senegalese", "Serbian", "Seychellois", "Sierra Leonean", "Singaporean", "Slovakian",
        "Slovenian", "Solomon Islander", "Somali", "South African", "South Korean", "Spanish", "Sri Lankan",
        "Sudanese", "Surinamer", "Swazi", "Swedish", "Swiss", "Syrian", "Taiwanese", "Tajik", "Tanzanian",
        "Thai", "Togolese", "Tongan", "Trinidadian or Tobagonian", "Tunisian", "Turkish", "Tuvaluan", 
        "Ugandan", "Ukrainian", "Uruguayan", "Uzbekistani", "Venezuelan", "Vietnamese", "Welsh", "Yemenite",
        "Zambian", "Zimbabwean"
      ],

      
      student_crud: ['AR', 'VC', 'SUPER_ADMIN'],
      create_batch: ['AR', 'VC', 'SUPER_ADMIN'],
      live_lectures_create: ['DEAN', 'HOD','VC', 'SUPER_ADMIN'],

    };
  },

  computed: {

      getInitials() {
        return (nameString) => {
          //  format(parseISO(dateString), "do MMMM yyyy");
          var names = nameString.split(" "),
            initials = names[0].substring(0, 1).toUpperCase();
          if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
          }
          return initials;
        };
      },

      searchInputFieldPlaceHolder() {
        if (this.searchBy === "Search by Programme") {
          return "Search by Programme";
        } else if (this.searchBy === "Search by Month of Intake") {
          return "Search by Month of Intake";
        } else if (this.searchBy === "Search by Year of Intake") {
          return "Search by Year of Intake";
        } else return "";
      },

  },

  mounted() {
    this.getBatches();
  },

  methods: {

    findCommonElements3(arr1, arr2) {
      return arr1.some(item => arr2.includes(item))
    },

    handleClose(value) {
      value();
    },
    
    toMonthName(monthNum) {
      return getMonthName(monthNum, true);
    },

    openBatchModal() {
      this.getCourses();
      this.addBatchModal = true;
      this.batchForm.year = "";
      this.batchForm.month = "";
      this.batchForm.course_id = "";
      this.batchForm.cohort = "";
    },

    async addBatch(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submiting = true;
          this.$http.post(`course-intake-batches/add`, this.batchForm).then(
            (response) => {
              if (
                response.data.success &&
                response.data.message ==
                  "COURSE_INTAKE_BATCH_CREATED_SUCCESSFULLY"
              ) {
                this.$refs[formName].resetFields();
                this.submiting = false;
                this.getCourses();
                this.addBatchModal = false;
                this.getBatches();
                this.showSuccessMessage("Success", "Intake added successfully");
              } else if (
                response.data.message == "COURSE_INTAKE_BATCH_EXISTS"
              ) {
                this.showWarningMessage("Duplicate Intake", "Intake aleady exists");
                this.submiting = false;
              }
            },
            (error) => { // eslint-disable-line no-unused-vars
              this.submiting = false;
              if (error.data == "Network Error") {
                return this.showFailedMessage(
                  "Connection failed",
                  "A network error occured, please try again."
                );
              }
              this.showFailedMessage(
                "Unable to add Intakes",
                "An unexpected Error occured, please try again"
              );
            }
          );
        } else {
          return false;
        }
      });
    },

    async getBatches() {
      try {
        this.loading = true;
        this.loadingError = false;
        let request = await this.$http.get(`course-intake-batches/fetch-many`);
        if (request.data.success) {
          this.batches = request.data.batches.filter(batch => batch.course);
        } else {
          throw "ERROR OCCURED";
        }
      } catch (error) {
        if (error.data == "Network Error") {
          this.loadingError = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occured, please try again."
          );
        }

        this.loadingError = true;
        this.showFailedMessage(
          "Unable to load Intakes",
          "An unexpected Error occured, please try again"
        );
      } finally {
        this.loading = false;
      }
    },

    async getCourses() {
      try {
        this.loading = true;
        this.loadingError = false;
        let request = await this.$http.get(
          `courses/fetch-many`
        );
        // if (!request.data.success) throw "SUCCESS RETURNED FALSE";
        if (request.data.success && request.data.message == "COURSES_FETCHED_SUCCESSFULLY") {
          this.courses = request.data.courses;
        } else {
          throw "ERROR OCCURED";
        }
      } catch (error) {
        if (error.data == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occured, please try again."
          );
        }
        this.showFailedMessage(
          "Unable to load Programmes",
          "An unexpected Error occured, please try again"
        );
      } finally {
        this.loading = false;
      }
    },

    batchEditData(id, year, month, course_id, cohort) {
      this.editBatchModal = true;
      this.getCourses();
      this.id_edit = id;

      //Converting the variable year to a string
      this.batchForm.year = year + "";
      //Converting the variable month to a string
      this.batchForm.month = month + "";
      this.batchForm.course_id = course_id;
      this.batchForm.cohort = cohort;
    },

    async editBatch(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submiting = true;
          this.$http .patch( `/course-intake-batches/update-one/${this.id_edit}`, this.batchForm)
            .then(
              (response) => {
                if (
                  response.data.success &&
                  response.data.message == "COURSE_INTAKE_BATCH_EDITED_SUCCESSFULLY"
                ) {
                  this.$refs[formName].resetFields();
                  this.submiting = false;
                  this.editBatchModal = false;
                  this.getBatches();
                  this.showSuccessMessage(
                    "Success",
                    "Intake edited successfully"
                  );
                } else if (
                  response.data.message == "COURSE_ID_YEAR_MONTH_COMBINATION_MUST_BE_UNIQUE"
                ) {
                  this.showWarningMessage("Duplicate Entry", "Intake aleady exists");
                  this.submiting = false;
                }
              },
              (error) => { /*eslint no-unused-vars: "error"*/
                this.submiting = false;
                if (error.data == "Network Error") {
                  return this.showFailedMessage(
                    "Connection failed",
                    "A network error occured, please try again."
                  );
                }
                this.showFailedMessage(
                  "Unable to edit Intake",
                  "An unexpected Error occured, please try again"
                );
              }
            );
        } else {
          return false;
        }
      });
    },

    deleteBatch(id) {
      return; // eslint-disable-line no-unreachable
      this.$confirm( // eslint-disable-line no-unreachable
        "This will permanently delete the file. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          this.axios.delete(`course-intake-batches/delete-one/${id}`).then(
            (response) => {
              if (response.data.success == true) {
                this.getBatches();
                this.showSuccessMessage(
                  "Success",
                  "Intake deleted successfully!"
                );
              } else if (response.data.success == false) {
                this.showWarningMessage("Warning", "Unable to delete Intake");
              }
            },
            (error) => { // eslint-disable-line no-unused-vars
              this.showFailedMessage("Error", "Internal server error");
              this.loading = false;
            }
          );
        })
        .catch(() => {
          // this.showWarningMessage("Warning", "Delete canceled!");
        });
    },

    batchDetail(cbId) {
      this.$router.push(`/dashboard/${cbId}/course-batch-detail`);
    },

    showStudentModal(bId) {
      this.studentForm.course_intake_batch_id = bId;
      this.addStudentsModal = true;
    },

    async submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submiting = true;
          this.$http.post(`students/add`, this.studentForm).then(
            (response) => {
              if (
                response.data.success &&
                response.data.message == "STUDENT_CREATED_SUCCESSFULLY"
              ) {
                this.submiting = false;
                this.$refs[formName].resetFields();
                this.showSuccessMessage(
                  "Success",
                  "Student created successfully"
                );
                this.addStudentsModal = false;
                this.getBatches();
              } else if (response.data.message == "EMAIL_ALREADY_IN_USE") {
                this.submiting = false;
                this.showWarningMessage("Duplicate email", "Email aleady exists");
              } else if(response.data.message == "PHONE_NUMBER_EXISTS") {
                this.submiting = false;
                this.showWarningMessage("Duplicate Phone Number", "Phone aleady exists");
              } else if( response.data.message == "INVALID_EMAIL" ) {
                this.submiting = false;
                this.showWarningMessage("Invalid email","Invalid email address included.");
              } else if( response.data.message == "INVALID PHONE NUMBER" ) {
                this.submiting = false;
                this.showWarningMessage("Invalid phone number","Invalid Phone Number included.");
              }
            },
            (error) => { // eslint-disable-line no-unused-vars
              this.submiting = false;
              if (error.data == "Network Error") {
                return this.showFailedMessage(
                  "Connection failed",
                  "A network error occured, please try again."
                );
              }
              this.showFailedMessage(
                "Unable to add Student",
                "An unexpected Error occured, please try again"
              );
            }
          );
        } else {
          return false;
        }
      });
    },

    async showStudents(batchId) {
      this.dialogVisible = true;
      this.isBusy = true;
      try {
        this.loading = true;
        this.loadingError = false;
        let request = await this.$http.get(`course-intake-batches/fetch-students/${batchId}`);
        if (request.data.success) {
          this.batch_students = request.data.batch.batch_students;
          this.dialogVisible = true;
        } else {
          throw "ERROR OCCURED";
        }
      } catch (error) {
          if (error.data == "Network Error") {
            return this.showFailedMessage("Connection failed", "A network error occured, please try again.");
          }
          this.showFailedMessage("Unable to load students in this Intake", "An unexpected Error occured, please try again");
      } finally {
      this.isBusy = false;
      }
    },

    studentProfile(sId) {
        this.$router.push({ path: `student-profile/${sId}` });
    }

    },
  };
</script>

<style scoped>
  * {
    box-sizing: border-box;
  }

  .profile_img img{
    border-radius:50%; width:30px; height:30px; object-fit:cover;
    margin-right:5px;
  }
  
  input[type=text], select, textarea {
    width: 100%;
    padding: 9px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    background-color: #FFFFFF;
    font-size: .9em;
  }

  .search_add_section {
    display: flex; align-items: center; justify-content: space-between;
  }

  .search_by_input {
    width: 400px !important;
  }

  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
  }

  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  }

  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .search_add_section {
      display: block; 
    }
    .addbtn {
      width: 100%;
      margin-top: 10px;
    }
      .search_by_input {
        width: 100% !important;
      }
  }

  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .search_add_section {
      display: block;
    }
    .addbtn {
      width: 100%;
      margin-top: 10px;
    }
      .search_by_input {
        width: 100% !important;
      }
  } 

</style>

<style>
  .batch_request_form .el-select .el-input__inner {
    cursor: pointer;
    height: 36px !important;
    width: 100% !important;
    padding-left: 10px !important;
    text-transform: capitalize;
  }
  .batch_request_form .el-button--primary {
    color: #fff;
    background-color: #409eff !important;
    border-color: #409eff !important;
  }

  el-table th > .cell {
    color: black !important;
    font-weight: 500;
  }
  .el-table .cell {
    font-size: 0.9em;
    line-height: 23px;
    font-weight: 300;
  }

  .batch_request_form .el-input input {
    font-size: 0.8rem !important;
    font-weight: 400 !important;
    color: #495057 !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  }

  .batch_request_form .el-input input:focus {
    color: #495057 !important;
    background-color: #fff !important;
    border-color: #80bdff !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
  }

  .batch_dialogs .el-dialog {
    width: 50%;
  }
  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
    .batch_dialogs .el-dialog {
      width: 50%;
    }
  }

  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .batch_dialogs .el-dialog {
      width: 70%;
    }
  }

  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .batch_dialogs .el-dialog {
      width: 90%;
    }
  }

  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .batch_dialogs .el-dialog {
      width: 90%;
    }

  }
</style>
