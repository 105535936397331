<template>
  <div class="text-left schedule-live-lesson">
    <el-dialog
      title="Upload Content"
      :visible.sync="dialogVisible"
      width="60%"
      :before-close="handleClose"
    >
      <div v-loading="isUploadingFile">
        <div class="row w-100">
          <div
            class="col-md-6 pl-0 pl-md-3 pr-2 pr-md-4 pb-3"
            style="font-weight: 500; color: rgba(0, 0, 0, 0.8)"
          >
            Resource Type
            <div class="mt-1">
              <el-select
                v-model="$v.selectedResourceType.$model"
                placeholder="Select"
                class="d-block"
              >
                <el-option
                  v-for="item in resourceTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value.toLowerCase()"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        
          <div
            class="col-md-6 pl-0 pl-md-3 pr-4 pr-md-5 pb-3"
            style="font-weight: 500; color: rgba(0, 0, 0, 0.8)"
          >
            Resource Title
            <span
              v-show="$v.title.$error && !$v.title.required"
              class="pl-2"
              style="color: red; font-size: 0.8em"
              >Required</span
            >
            <span
              v-show="$v.title.$error && !$v.title.maxLength"
              class="pl-2"
              style="color: red; font-size: 0.8em"
              >Too Long</span
            >
            <div class="mt-1">
              <input
                type="text"
                placeholder="Resource Title"
                :class="{ 'input-error': $v.title.$error }"
                v-model.trim="$v.title.$model"
                class="legacy-input bg-white"
                style="width: 100%; height: calc(2.55rem) !important"
              />
            </div>
          </div>
          <div class="col-12 text-left mt-3">
            <div
              class="mb-3"
              style="font-weight: 500; color: rgba(0, 0, 0, 0.8)"
            >
              Choose File
            </div>
            <el-upload
              class="upload-demo"
              drag
              :action="''"
              :file-list="fileLists"
              :on-remove="handleRemove"
              :on-change="handlePreview"
              :auto-upload="false"
              :multiple="false"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                Drop file here or <em>click to upload</em>
              </div>
              <div class="el-upload__tip" slot="tip">
                <div>
                  * Accepted File Types for Notes, Course Work and Past Papers
                  are DOC, DOCX, XLS, XLSX, PPT, PPTX, ODT, ODS, ODP, PDF and
                  should not be more than <nobr>100Mbs</nobr>
                </div>
                <div>
                  * Accepted File Types for Videos are MP4, TS, WEBMV, AVI,
                  MPEG, FLV, AV and should not be more than 1Gb
                </div>
              </div>
            </el-upload>
          </div>
          <div class="row w-100 mt-5">
            <div class="col-12 text-center px-3">
              <button
                type="button"
                :disabled="$v.$invalid || !selectedFile"
                :class="{ 'not-allowed': $v.$invalid }"
                @click="addResource"
                class="btn z-depth-0 w-100 mx-0"
                v-ripple="'rgba(255, 255, 255, 0.35)'"
                style="
                  text-transform: capitalize;
                  height: 45px;
                  width: 170px !important;
                  border-radius: 4px;
                  background-color: var(--el-app-primary);
                "
              >
                <span style="position: relative; bottom: 1px"
                  >Upload Content</span
                >
              </button>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <div class="text-left">
      <button
        type="button"
        :disabled="findCommonElements3(faculties_cUnits_crud, $store.state.theRole)"
        @click="dialogVisible = true"
        class="btn z-depth-0"
        v-ripple="'rgba(255, 255, 255, 0.35)'"
        style="
          width: auto;
          text-transform: capitalize;
          height: 40px;
          border-radius: 4px;
          background-color: var(--el-app-primary);
          position: relative;
          bottom: 10px;
        "
      >
        <span style="position: relative; bottom: 4px">Upload Content </span>
      </button>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  components: {},
  props: {
    courseUnit: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isUploadingFile: false,
      dialogVisible: false,
      selectedFile: null,
      isDurationError: false,
      selectedResourceType: "",
      title: "",
      fileLists: [],

      resourceTypes: [
        { value: "notes", label: "Notes" },
        { value: "videos", label: "Videos" },
        { value: "course-work", label: "Course Work" },
        { value: "past-papers", label: "Past Papers" },
      ],

      grant_access: ['AR', 'BURSAR'],
      student_crud: ['AR'],
      student_read: ['AR', 'VC', 'BURSAR', 'DEAN', 'HOD', 'IT', ],
      staff_crud: ['HR'],
      staff_read: ['VC', 'AR', 'HR', 'DEAN', 'HOD', 'IT'],
      faculties_cUnits_crud: ['AR'],
      faculties_cUnits_read: ['AR', 'VC'],
      create_batch: ['AR'],
      live_lectures_create: ['DEAN', 'HOD'],
      live_lectures_read: ['AR', 'QA', 'VC'],
      help_messages: ['AR', 'HR', 'DEAN', 'HOD', 'QA', 'IT'],
      notice_board: ['AR', 'HR', 'DEAN', 'HOD', 'QA'],
      notice_board_read: ['VC'],
      staff_request: ['HR', 'VC'],
      // staff_request: ['AR'],
      assign_staff: ['DEAN', 'HOD']

    };
  },
  validations: {
    selectedResourceType: { required },
    title: { required, maxLength: maxLength(100) },
  },
  methods: {

    // Iterate through each element in the
    // first array and if some of them 
    // include the elements in the second
    // array then return true.
    findCommonElements3(arr1, arr2) {
      return arr1.some(item => arr2.includes(item))
    },

    handleClose(done) {
      done();
    },
    handlePreview(file) {
      this.fileLists = [file];
      this.selectedFile = file;
    },
    handleRemove() {
      this.selectedFile = null;
    },

    async addResource() {
      //Ensure user hasnt manipulated the DOM
      if (this.$v.$invalid || !this.selectedFile) return;

      let last_dot_index = this.selectedFile.name.lastIndexOf(".");
      const fileExtension = this.selectedFile.name.substr(last_dot_index + 1);
      //If its a notes they are trying to upload, ensure its the accepted file type and size
      if (
        this.selectedResourceType == "notes" ||
        this.selectedResourceType == "course-work" ||
        this.selectedResourceType == "past-papers"
      ) {
        if (
          ![
            "DOC",
            "DOCX",
            "XLS",
            "XLSX",
            "PPT",
            "PPTX",
            "ODT",
            "ODS",
            "ODP",
            "PDF",
            // "TXT",
          ].includes(fileExtension.toUpperCase())
        ) {
          return this.showWarningMessage(
            "Unsupported File Type",
            "The File you are trying to upload is not one of the accepted file types for the selected Resource Type"
          );
        }
        //Ensure it is not more than 100Mbs
        else if (this.selectedFile.size > 100 * 1024 * 1024) {
          return this.showWarningMessage(
            "Too Big",
            "The File you are trying to upload is too Big for the selected Resource Type"
          );
        }
      } else {
        //Means it is a vid
        if (
          !["mp4", "ts", "webmv", "avi", "mpeg", "flv", "av"].includes(
            fileExtension.trim().toLowerCase()
          )
        ) {
          return this.showWarningMessage(
            "Unsupported File Type",
            "The File you are trying to upload is not one of the accepted file types for the selected Resource Type"
          );
        }
        //Ensure its nt more than 1GB
        else if (this.selectedFile.size > 1024 * 1024 * 1024) {
          return this.showWarningMessage(
            "Too Big",
            "The File you are trying to upload is too Big for the selected Resource Type"
          );
        }
      }

      try {
        const formData = new FormData();
        formData.append("title", this.title);
        // formData.append("description", this.description);
        formData.append("file", this.selectedFile.raw);
        formData.append("course_unit_id", this.courseUnit);
        formData.append("resource_type", this.selectedResourceType);

        this.isUploadingFile = true;
        let request = await this.$http.post("resources/add", formData, {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => console.log(progressEvent),
        });

        if (
          request.data.success &&
          request.data.message == "RESOURCE_CREATED_SUCCESSFULLY" &&
          request.data.resource
        ) {
          this.$emit("on-resource-added", request.data.resource);
          this.dialogVisible = false;
          this.showSuccessMessage("Success", "Resource added successfully");
        } else throw "UNEXPECTED_RESPONSE";
      } catch (error) {
        this.showFailedMessage(
          "Upload Failed",
          "Unable to Upload Resource Now, please try again"
        );
      } finally {
        this.isUploadingFile = false;
      }
    },

    clearInputs() {
      this.selectedResourceType = "";
      this.title = " ";
    },
    doResourceTypeValidation(request, fileType) {
      let result = true;
      if (
        ["notes", "course-works", "past-papers"].includes(
          request.body.resource_type.toLowerCase().trim()
        )
      ) {
        if (
          ![
            "DOC",
            "DOCX",
            "XLS",
            "XLSX",
            "PPT",
            "PPTX",
            "ODT",
            "ODS",
            "ODP",
            "PDF",
            // "TXT",
          ].includes(fileType.toUpperCase())
        ) {
          result = false;
        }
      } else if (request.body.resource_type.trim() === "video") {
        if (
          !["mp4", "ts", "webmv", "avi", "mpeg", "flv", "av"].includes(
            request.body.resource_type.trim().toLowerCase()
          )
        ) {
          result = false;
        }
      }
      return result;
    },
  },
};
</script>

<style>
.input-error {
  border: 1px solid red !important;
  box-shadow: none !important;
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;
  /*  animation-iteration-count:  infinite;  */
}
.not-allowed {
  cursor: not-allowed !important;
  pointer-events: all !important;
}
select.browser-default {
  display: block !important;
  height: 30px !important;
  width: 105%;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  cursor: pointer;
}
.schedule-live-lesson .el-dialog__title {
  font-weight: 500;
}

.schedule-live-lesson .el-select .el-input__inner {
  cursor: pointer;
  height: 40px !important;
  width: 100% !important;
  padding-left: 10px !important;
}

.el-date-editor--date .el-input__inner,
.el-range-editor .el-input__inner {
  cursor: pointer;
  height: 40px !important;
  width: 100% !important;
  padding-left: 45px !important;
}
.el-date-editor .el-range-separator {
  display: contents;
  font-size: 0.8em;
  width: 20px !important;
  white-space: nowrap;
}

.el-date-editor.el-range-editor.el-input__inner.el-date-editor--timerange {
  height: 45px !important;
  width: 100%;
}
</style>