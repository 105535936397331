    <template>
  <div
    v-if="lectures.length != 0"
    class="row w-100 mt-3 mx-0"
    style="
      border-top: 1px solid rgba(0, 0, 0, 0.185);
      border-right: 1px solid rgba(0, 0, 0, 0.185);
      border-left: 1px solid rgba(0, 0, 0, 0.185);
    "
  >
    <!-- {{ formattedLectures }} -->
    <div class="calendar-item-column" style="border-right: none !important">
      <div class="caledar-header" style="border-bottom: none !important">
        Time
        <div class="mt-2" style="color: transparent">?</div>
      </div>

      <div
        v-for="i in lecturesTime"
        :key="i"
        class="w-100 pl-3"
        style="
          height: 70px;
          position: relative;
          top: -10px;
          font-size: 0.8em;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.4);
        "
      >
        {{ i }}
      </div>
    </div>
    <div class="calendar-item-column">
      <div
        class="caledar-header"
        :class="{ 'is-active-header': dateToday == daysBetween[0] }"
      >
        <div :class="{ 'is-active': dateToday == daysBetween[0] }">
          Sun
          <div class="mt-2">{{ daysBetween[0].substr(8, 2) }}</div>
        </div>
      </div>
      <div v-for="time in lecturesTime" :key="time" class="w-100 content">
        <div
          v-for="lecture in groupedLectures[daysBetween[0]]"
          :key="lecture.live_lecture_id"
        >
          <EventItemComponent
            v-if="shouldDisplayEvent(time, lecture)"
            :dateToday="dateToday"
            :event="{
              type: 'Lecture',
              dateToday: dateToday,
              data: lecture,
            }"
          />
        </div>
      </div>
    </div>
    <div class="calendar-item-column">
      <div
        class="caledar-header"
        :class="{ 'is-active-header': dateToday == daysBetween[1] }"
      >
        <div :class="{ 'is-active': dateToday == daysBetween[1] }">
          Mon
          <div class="mt-2">{{ daysBetween[1].substr(8, 2) }}</div>
        </div>
      </div>
      <div v-for="time in lecturesTime" :key="time" class="w-100 content">
        <div
          v-for="lecture in groupedLectures[daysBetween[1]]"
          :key="lecture.live_lecture_id"
        >
          <EventItemComponent
            v-if="shouldDisplayEvent(time, lecture)"
            :dateToday="dateToday"
            :event="{
              type: 'Lecture',
              dateToday: dateToday,
              data: lecture,
            }"
          />
        </div>
      </div>
    </div>
    <div class="calendar-item-column">
      <div
        class="caledar-header"
        :class="{ 'is-active-header': dateToday == daysBetween[2] }"
      >
        <div :class="{ 'is-active': dateToday == daysBetween[2] }">
          Tue
          <div class="mt-2">{{ daysBetween[2].substr(8, 2) }}</div>
        </div>
      </div>
      <div v-for="time in lecturesTime" :key="time" class="w-100 content">
        <div
          v-for="lecture in groupedLectures[daysBetween[2]]"
          :key="lecture.live_lecture_id"
        >
          <EventItemComponent
            v-if="shouldDisplayEvent(time, lecture)"
            :dateToday="dateToday"
            :event="{
              type: 'Lecture',
              dateToday: dateToday,
              data: lecture,
            }"
          />
        </div>
      </div>
    </div>
    <div class="calendar-item-column">
      <div
        class="caledar-header"
        :class="{ 'is-active-header': dateToday == daysBetween[3] }"
      >
        <div :class="{ 'is-active': dateToday == daysBetween[3] }">
          Wed
          <div class="mt-2">{{ daysBetween[3].substr(8, 2) }}</div>
        </div>
      </div>
      <div v-for="time in lecturesTime" :key="time" class="w-100 content">
        <div
          v-for="lecture in groupedLectures[daysBetween[3]]"
          :key="lecture.live_lecture_id"
        >
          <EventItemComponent
            v-if="shouldDisplayEvent(time, lecture)"
            :dateToday="dateToday"
            :event="{
              type: 'Lecture',
              dateToday: dateToday,
              eventData: lecture.course_unit_code,
              data: lecture,
            }"
          />
        </div>
      </div>
    </div>
    <div class="calendar-item-column">
      <div
        class="caledar-header"
        :class="{ 'is-active-header': dateToday == daysBetween[4] }"
      >
        <div :class="{ 'is-active': dateToday == daysBetween[4] }">
          Thu
          <div class="mt-2">{{ daysBetween[4].substr(8, 2) }}</div>
        </div>
      </div>
      <div v-for="time in lecturesTime" :key="time" class="w-100 content">
        <div
          v-for="lecture in groupedLectures[daysBetween[4]]"
          :key="lecture.live_lecture_id"
        >
          <EventItemComponent
            v-if="shouldDisplayEvent(time, lecture)"
            :dateToday="dateToday"
            :event="{
              type: 'Lecture',
              dateToday: dateToday,
              data: lecture,
            }"
          />
        </div>
      </div>
    </div>
    <div class="calendar-item-column">
      <div
        class="caledar-header"
        :class="{ 'is-active-header': dateToday == daysBetween[5] }"
      >
        <div :class="{ 'is-active': dateToday == daysBetween[5] }">
          Fri
          <div class="mt-2">{{ daysBetween[5].substr(8, 2) }}</div>
        </div>
      </div>
      <div v-for="time in lecturesTime" :key="time" class="w-100 content">
        <div
          v-for="lecture in groupedLectures[daysBetween[5]]"
          :key="lecture.live_lecture_id"
        >
          <EventItemComponent
            v-if="shouldDisplayEvent(time, lecture)"
            :dateToday="dateToday"
            :event="{
              type: 'Lecture',
              data: lecture,
            }"
          />
        </div>
      </div>
    </div>
    <div class="calendar-item-column" style="border-right: none !important">
      <div
        class="caledar-header"
        :class="{ 'is-active-header': dateToday == daysBetween[6] }"
      >
        <div :class="{ 'is-active': dateToday == daysBetween[6] }">
          Sat
          <div class="mt-2">{{ daysBetween[6].substr(8, 2) }}</div>
        </div>
      </div>
      <div v-for="time in lecturesTime" :key="time" class="w-100 content">
        <div
          v-for="lecture in groupedLectures[daysBetween[6]]"
          :key="lecture.live_lecture_id"
        >
          <EventItemComponent
            v-if="shouldDisplayEvent(time, lecture)"
            :dateToday="dateToday"
            :event="{
              type: 'Lecture',
              dateToday: dateToday,
              data: lecture,
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>
    
    <script>
import EventItemComponent from "../components/event-item-component";
import nestedGroupby from "nested-groupby";
import { format } from "date-fns";

export default {
  components: { EventItemComponent },
  data() {
    return {};
  },
  props: {
    lectures: {
      required: true,
      type: Array,
    },
    weekStartDate: { required: true, type: String },
    weekEndDate: { required: true, type: String },
    dateToday: { required: true, type: String },
  },

  computed: {
    daysBetween() {
      for (
        var arr = [], dt = new Date(this.weekStartDate);
        dt <= new Date(this.weekEndDate);
        dt.setDate(dt.getDate() + 1)
      ) {
        arr.push(format(new Date(dt), "yyyy-MM-dd"));
      }
      return arr;
    },
    formattedLectures() {
      return this.lectures
        .map((lecture) => {
          const startTime24hr = this.convertTime12to24(lecture.start_time);
          const endTime24hr = this.convertTime12to24(lecture.end_time);
          return { ...lecture, startTime24hr, endTime24hr };
        })
        .sort((a, b) =>
          a.startTime24hr > b.startTime24hr
            ? 1
            : b.startTime24hr > a.startTime24hr
            ? -1
            : 0
        )
        .sort((a, b) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0));
    },

    groupedLectures() {
      return nestedGroupby(this.formattedLectures, ["date"]);
    },

    firstLectureStartTime() {
      return this.formattedLectures.reduce(function (p, v) {
        return p.startTime24hr < v.startTime24hr ? p : v;
      }).startTime24hr;
    },

    lastLectureEndTime() {
      return this.formattedLectures.reduce(function (p, v) {
        return p.endTime24hr > v.endTime24hr ? p : v;
      }).endTime24hr;
    },

    lecturesTime() {
      let firstLectureStartTimeHrs = +this.firstLectureStartTime.substr(0, 2);
      let lastLectureEndTimeHrs = +this.lastLectureEndTime.substr(0, 2);
      let hrs = [];

      for (
        let i = firstLectureStartTimeHrs;
        i <= lastLectureEndTimeHrs + 1;
        i++
      ) {
        //Convert back to 12 Hour Clock for displaying
        var timeString = `${i < 10 ? "0" + i : i}:00`;
        var H = +timeString.substr(0, 2);
        var h = H % 12 || 12;
        var ampm = H < 12 || H === 24 ? " AM" : " PM";
        timeString = (h < 10 ? "0" + h : h) + timeString.substr(2, 3) + ampm;
        hrs.push(timeString);
      }

      return hrs;
    },
    // dateToday() {
    //   return this.dateToday.split("-")[2];
    // },
  },
  methods: {
    shouldDisplayEvent(time, lecture) {
      return (
        time.substr(0, 2) == lecture.start_time.substr(0, 2) &&
        time.substr(6, 2) == lecture.start_time.substr(5, 2)
      );
    },
    convertTime12to24(time12h) {
      // const [time, modifier] = time12h.split(" ");

      // let [hours, minutes] = time.split(":");
      let hours = time12h.substr(0, 2);
      let minutes = time12h.substr(3, 2);
      let modifier = time12h.substr(5, 2);

      if (hours === "12") {
        hours = "00";
      }

      if (modifier === "PM") {
        hours = parseInt(hours, 10) + 12;
      }

      return `${hours}:${minutes}`;
    },
  },
};
</script>
    
<style scoped>
.calendar-item-column {
  -ms-flex: 0 0 12.5%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 12.5%;
  flex: 0 0 12.5%;
  max-width: 12.5%;
  border-right: 1px solid rgba(0, 0, 0, 0.185);
}

.caledar-header {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid rgba(0, 0, 0, 0.185);
  padding-bottom: 30px;
  padding-top: 15px;
  text-align: center;
}

.content {
  height: 70px;
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.185);
}

.is-active {
  background-color: var(--el-app-primary);
  display: inline-block;
  padding: 10px;
  color: white;
  border-radius: 35px;
  font-weight: 400;
  font-size: 0.9em;
}

.is-active-header {
  padding-top: 15px;
  padding-bottom: 15px;
}
</style>