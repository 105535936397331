<template>
  <div class="container-fluid">
    <div class="row w-100">
      <div
        class="col-12 ml-5 mx-auto pt-5 pb-3"
        style="
          width: 100%;
          position: fixed;
          background-color: #eeeeee;
          z-index: 100;
          top: 60px;
        "
      >
        <el-tabs
          class="mx-auto"
          style="width: 525px; position: relative; right: 13%"
          :stretch="true"
          v-model="activeName"
          @tab-click="onTabClick"
        >
          <el-tab-pane name="notes">
            <span slot="label"
              ><i class="fas fa-book-open pr-2"></i> Notes</span
            >
          </el-tab-pane>
          <el-tab-pane name="videos">
            <span slot="label"><i class="fas fa-video pr-2"></i> Videos</span>
          </el-tab-pane>
          <el-tab-pane name="course-work">
            <span slot="label"
              ><i class="fas fa-graduation-cap pr-2"></i> Course Work</span
            >
          </el-tab-pane>
          <el-tab-pane name="past-papers">
            <span slot="label"
              ><i class="fas fa-sticky-note pr-2"></i> Past Papers</span
            >
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="row w-100" style="margin: 130px 27px 0px 27px">
        <div class="col-12 text-left pb-3">
          <BreadCrumbNavigation
            :pageTitles="[
              'Faculties',
              'Departments',
              'Programmes',
              'Modules',
              `Resources ${courseUnitName ? '(' + courseUnitName + ')' : ''}`,
            ]"
          />
          <!-- <BreadCrumbNavigation
            :pageTitles="[
              courseUnitName ? courseUnitName : '...',
            ]"
          /> -->
        </div>
        <div class="row w-100 my-2">
          <div class="col-12 text-left pl-5">
            
            <UploadContentComponent
              @on-resource-added="onResourceAdded"
              :courseUnit="`${$route.params.cuId}`"
            />
          </div>
        </div>
        <!-- RESOURCES PRELOADER -->
        <div v-if="isLoading && !isLoadingError" class="col-12">
          <ResourcesPreloader />
        </div>

        <!-- TABS SECTION -->
        <div
          v-if="!isLoading && !isLoadingError && !currentResource"
          class="row w-100 mx-0"
        >
          <div v-if="activeName == 'notes'" class="col-12">
            <DocumentResources
              @on-select-resource="onSelectResource"
              :resources="notesResources"
              @resource-deleted="onResourceDeleted"
              :title="'Notes'"
            />
          </div>

          <div v-else-if="activeName == 'videos'" class="col-12">
            <VideoResources
              @on-select-resource="onSelectResource"
              @resource-deleted="onResourceDeleted"
              :resources="videoResources"
              :title="'Video Resources'"
            />
          </div>
          <div v-else-if="activeName == 'course-work'" class="col-12">
            <DocumentResources
              :resources="courseWorkResources"
              @on-select-resource="onSelectResource"
              @resource-deleted="onResourceDeleted"
              :title="'Course Work Resources'"
            />
          </div>
          <div v-else-if="activeName == 'past-papers'" class="col-12">
            <DocumentResources
              :resources="pastPaperResources"
              @on-select-resource="onSelectResource"
              @resource-deleted="onResourceDeleted"
              :title="'Past Papers'"
            />
          </div>
        </div>

        <!-- ERROR SECTION FOR LOADING ERRORS -->
        <div v-if="!isLoading && isLoadingError" class="col-12 mt-5">
          <span style="font-weight: 600">Unable to get Resources</span>
          <div class="pt-2" style="font-size: 0.9em">
            Failed to get Resources right now. Click below to retry
          </div>
          <div class="col-12 mt-1">
            <button
              type="button"
              class="btn z-depth-0"
              @click="loadCourseUnitResources"
              v-ripple="'rgba(255, 255, 255, 0.35)'"
              style="
                width: 250px;
                text-transform: capitalize;
                height: 45px;
                border-radius: 4px;
                background-color: red;
                font-weight: 500;
              "
            >
              <span style="position: relative; bottom: 1px">Try Again</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Tabs, TabPane } from "element-ui";
  import DocumentResources from "../components/course-unit-resources/document-resources.vue";
  import VideoResources from "../components/course-unit-resources/video-resources.vue";
  import UploadContentComponent from "../components/upload-content-component.vue";
  import BreadCrumbNavigation from "../components/bread-crumb-component.vue";
  import ResourcesPreloader from "../components/course-unit-resources/resources-preloader.vue";
  export default {

    components: {
      "el-tabs": Tabs,
      "el-tab-pane": TabPane,
      DocumentResources,
      BreadCrumbNavigation,
      ResourcesPreloader,
      VideoResources,
      UploadContentComponent
    },

    data() {
      return {
        activeName: "notes",
        currentResource: "",
        activePage: 1,
        resources: [],
        isLoading: true,
        isLoadingError: false,
        courseName: "",
        courseUnitName: "",
      };
    },

    computed: {
      notesResources() {
        if (this.resources && this.resources.length == 0) return [];
        return this.resources.filter(
          (resource) => resource.resource_type === "notes"
        );
      },
      videoResources() {
        if (this.resources && this.resources.length == 0) return [];
        return this.resources.filter(
          (resource) => resource.resource_type === "videos"
        );
      },
      courseWorkResources() {
        if (this.resources && this.resources.length == 0) return [];
        return this.resources.filter(
          (resource) => resource.resource_type === "course-work"
        );
      },
      pastPaperResources() {
        if (this.resources && this.resources.length == 0) return [];
        return this.resources.filter(
          (resource) => resource.resource_type === "past-papers"
        );
      },
    },

    mounted() {
      this.courseName = this.$route.params.courseName;
      this.courseUnitName = this.$route.params.courseUnitName;
      this.loadCourseUnitResources();
    },

    methods: {

      onResourceAdded() {
        // this.resources.unshift(resource);
        this.loadCourseUnitResources();
      },

      onSelectResource(resource) {
        this.$router.push({ name: "ResourceDetails", params: { resource } });
        this.currentResource = resource;
      },

      onResourceDeleted(resourceID) {
        this.resources = this.resources.filter(
          (resource) => resource.resource_id != resourceID
        );
      },
      
      async loadCourseUnitResources() {
        this.isLoading = true;
        this.isLoadingError = false;
        try {
          let request = await this.$http.get(
            `resources/fetch-by-course-unit/${this.$route.params.cuId}`
          );
          if (
            request.data.success &&
            request.data.message === "RESOURCES_FETCHED_SUCCESSFULLY"
          ) {
            this.resources = request.data.resources;
            this.courseUnitName = request.data.course_unit_title;
            this.courseName = request.data.course_title;
          } else throw "UNEXPECTED_RESPONSE_RECEIVED";
        } catch (error) {
          this.isLoadingError = true;
          this.showFailedMessage(
            "Failed",
            "Unable to fetch Resources now, please try again"
          );
        } finally {
          this.isLoading = false;
        }
      },

      onTabClick() {},
    },
  };
</script>

<style scoped>
  .direction_arrows {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: rgb(214, 226, 238);
    color: var(--el-app-primary);
    cursor: pointer;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .direction_arrows i {
    font-size: 15px;
  }
</style>
