<template>
    <section class="container">
        <div class="content">
            <div class="row w-100 mx-0">
                <div class="row w-100 mx-0 mt-3 align-items-center">
                    <div class="col-md-1 px-0">
                        <div  style="width:100px; height:100px; border-radius: 50%;">
                            <img :src="profile_picture" v-if="profile_picture" alt="" class="img-fluid" style="width:100px; height:100px; object-fit:cover; border-radius: 100%;" />
                            <div v-if="profile_picture == null" style="display: flex; align-items:center; justify-content: center; background-color: var(--el-app-primary); color: white; width:100px; height:100px; object-fit:cover; border-radius: 100%;">
                                <strong>{{ getInitials(`${firstName} ${lastName}`) }}</strong>
                            </div>
                        </div>
                    </div>
                    <div class="col-11 pl-5 text-left">
                        <strong><span style="font-size: 20px;">{{firstName}} {{lastName}}</span></strong><br/>
                        <span><small>Adminstrator</small></span>
                        <div>
                            <p style="font-size: 15px; cursor:pointer;"><strong><i class="fas fa-envelope"></i> Send Message</strong></p>
                        </div>
                    </div>

                    <div class="col-12 px-0 pt-2">
                        <hr/>
                    </div>

                    <div class="col-12 text-left px-0" style="font-weight: 600">
                        Basic Info
                    </div>

                    <div class="col-12-px-0 pt-2 text-left" style="font-weight: 400; font-size: 0.9em">
                        <i class="fas fa-info-circle pr-2" style="color: rgba(0, 0, 0, 0.3)"></i>
                        Contact information: <br/>
                        <ul>
                            <li><strong>Phone: </strong> {{phoneNumber}}</li>
                            <li><strong>Email: </strong> {{ email }}</li>
                        </ul>
                    </div>

                    <div class="col-12 px-0 pt-2">
                        <hr/>
                    </div>

                    <div class="col-12-px-0 pt-2 text-left" style="font-weight: 400; font-size: 0.9em">
                        <i class="fas fa-info-circle pr-2" style="color: rgba(0, 0, 0, 0.3)"></i>
                        Resources by : {{`${firstName} ${lastName}`}}<br/>
                        
                        
                    </div>


                </div>
            </div>
        </div>
    </section>
</template>

<script>
// import BreadCrumbComponent from "../components/bread-crumb-component"; 0701480416
// import { ScaleOut } from "vue-loading-spinner";

export default {
//   components: { ScaleOut },

  data() {
    return {
      profilePictureModal: false,
      userRequestsModal: false,
      basicInfoModal: false,
      submiting: false,
      accessCode: "SLAU0X89",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      faculty: "Faculty Of Social Sciences",
      profile_picture: "",
      events: [],

      full_names: "",

      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    };
  },

  computed: {
      getInitials() {
        return (nameString) => {
          //  format(parseISO(dateString), "do MMMM yyyy");
          var names = nameString.split(" "),
            initials = names[0].substring(0, 1).toUpperCase();
          if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
          }
          return initials;
        };
      },
  },

  mounted() {
    this.getOneStaff();
  },
  methods: {

        // getOneStaff() {
        //       this.loading = true;
        //       this.loadingError = false;
        //       this.$http.get(`staff/fetch-one/${this.$route.params.sId}`).then(
        //         (response) => {
        //           if (
        //             response.data.success && (response.data.message == "Staff Retrieved successfully")
        //           ) {
        //             this.staff = response.data.staff;
        //             this.full_names = request.data.staff.full_names;
        //             this.firstName = this.staff.first_name;
        //             this.lastName = this.staff.last_name;
        //             this.email = this.staff.email;
        //             this.profile_picture = this.staff.profile_picture;
        //             this.phoneNumber = this.staff.phone_number;
        //           } else {
        //             this.loadingError = true;
        //             this.showFailedMessage(
        //               "Fetch Failed",
        //               "An unexpected Error Occured. Please try again"
        //             );
        //           }
        //         },
        //       () => {
        //         this.loadingError = true;
        //         this.showFailedMessage(
        //           "Fetch Failed",
        //           "An unexpected Error Occured. Please try again"
        //         );
        //       }
        //     )
        //     .finally(() => (this.loading = false)); Years
        // },
        

        async getOneStaff() {
        try {
          this.loading = true;
          this.loadingError = false;
          let request = await this.$http.get(`staff/fetch-one/${this.$route.params.sId}`);
          if ( request.data.success && request.data.message == "STAFF_RETRIEVED_SUCCESSFULLY" ) { 
            this.full_names = request.data.staff.full_names;
            this.firstName = request.data.staff.first_name;
            this.lastName = request.data.staff.last_name;
            this.email = request.data.staff.email;
            this.profile_picture = request.data.staff.profile_picture;
            this.phoneNumber = request.data.staff.phone_number;
          } 
          else {throw "ERROR OCCURED"; }  
        } catch (error) {
            if ( error.data == "Network Error" ) {
              this.loadingError = true;
              return this.showFailedMessage("Connection failed","A network error occured, please try again.");
            }
            this.loadingError = true; 
            this.showFailedMessage("Unable to load staffs", "An unexpected Error occured, please try again");
        } finally {
            this.loading = false; 
        }
      },
  }

};
</script>

<style scoped>
.content {
  margin-top: 45px !important;
}

.remove-picture-btn {
  width: auto;
  padding: 0.85rem 1.8rem;
  text-transform: capitalize;
  box-shadow: -1px 0px 9px 0px rgb(0 0 0 / 11%), 0 2px 10px 0 rgb(0 0 0 / 0%) !important;
  height: 40px;
  border-radius: 4px;
  color: red !important;
  background-color: transparent;
  font-weight: 500;
}

input.legacy-input:focus:not(.input-error) {
  box-shadow: none !important;
}

@media (max-width: 768px) {
  .content {
    margin-top: 15px !important;
  }
}
</style>