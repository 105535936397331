<template>
  <div class="container-fluid">
    <div class="row w-100">
      <div class="row w-100" style="margin: 30px 27px 0px 27px">
        <div class="col-12 text-left pb-3">
          <BreadCrumbNavigation
            v-if="resource"
            :pageTitles="[
             
              resource.title,
            ]"
          />
        </div>

        <!-- VIDEO RESOURCES -->
        <div v-if="resource.resource_type == 'videos'" class="row w-100 mx-0">
          <span
            class="pb-4 float-left"
            style="
              font-weight: 600;
              text-transform: capitalize;
              font-size: 1.2em;
            "
          >
            {{ resource.title.toLowerCase() }}
          </span>
          <div class="col-12">
            <vue-plyr :options="options">
              <video controls playsinline>
                <source :src="resource.path_url" type="video/mp4" />
                <track
                  default
                  kind="captions"
                  label="English captions"
                  srclang="en"
                />
              </video>
            </vue-plyr>
          </div>
        </div>

        <!-- DOCUMENT RESOURCES -->
        <div v-else-if="resource.file_type === 'pdf'" class="row w-100 mx-0">
          <div v-show="!isLoading" class="row w-100 mx-0">
            <div class="col-12">
              <span
                class="pb-4 float-left"
                style="
                  font-weight: 600;
                  text-transform: capitalize;
                  font-size: 1.2em;
                "
              >
                {{ resource.title.toLowerCase() }}
              </span>
              <span class="float-right">
                <span class="pr-5" style="font-weight: 400"
                  >Page <strong>{{ activePage }}</strong> of
                  {{ totalPages ? totalPages : "N/A" }}</span
                >
                <div style="display: inline-flex">
                  <div @click="onPreviousPage" class="direction_arrows">
                    <i class="fas fa-long-arrow-alt-left"></i>
                  </div>
                  <div @click="onNextPage" class="direction_arrows">
                    <i class="fas fa-long-arrow-alt-right"></i>
                  </div>
                </div>
              </span>
            </div>
            <div class="col-12">
              <pdf
                :src="resource.path_url"
                :page="activePage"
                @num-pages="onPageNumberObtained"
                @progress="onProgress"
                @loaded="onLoaded"
                @error="onPDfError"
              ></pdf>
            </div>
            <div class="col-12 text-right">
              <span class="pr-5" style="font-weight: 400"
                >Page <strong>{{ activePage }}</strong> of
                {{ totalPages ? totalPages : "N/A" }}</span
              >
              <div class="pt-3" style="display: inline-flex">
                <div @click="onPreviousPage" class="direction_arrows">
                  <i class="fas fa-long-arrow-alt-left"></i>
                </div>
                <div @click="onNextPage" class="direction_arrows">
                  <i class="fas fa-long-arrow-alt-right"></i>
                </div>
              </div>
            </div>
          </div>
          <div
            v-show="isLoading"
            class="col-12 text-center"
            style="margin-top: 130px"
          >
            <ScaleOut :background="'#1F4EA1'" class="mx-auto" />
            <div style="font-size: 0.9em; font-weight: 500">
              {{ downloadProgress }}% Loading...
            </div>
          </div>
        </div>

        <!-- OTHER SUPPORTED DOCUMENT VIEWER LONG AS IT'S ONE OF .DOC, .DOCX, .XLS, .XLSX, .PPT, .PPTX, .ODT, .ODS, .ODP, .PDF, .TXT -->
        <div v-else class="row w-100">
          <div
            class="col-12 pb-4 text-left"
            style="
              font-weight: 600;
              text-transform: capitalize;
              font-size: 1.2em;
            "
          >
            {{ resource.title.toLowerCase() }}
          </div>
          <div class="col-12 text-left">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe
                id="document_viewer_live"
                :src="`https://view.officeapps.live.com/op/embed.aspx?src=${resource.path_url}`"
                class="embed-responsive-item"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { Tabs, TabPane } from "element-ui";

import { ScaleOut } from "vue-loading-spinner";
import BreadCrumbNavigation from "../components/bread-crumb-component.vue";
import pdf from "vue-pdf";

export default {
  components: {
    pdf,
    ScaleOut,
    // "el-tabs": Tabs,
    // "el-tab-pane": TabPane,
    BreadCrumbNavigation,
  },
  data() {
    return {
      activeName: "notes",
      activePage: 1,
      progress: 0,
      isLoading: true,
      totalPages: "N/A",
      resource: this.$route.params.resource,
    };
  },

  computed: {
    downloadProgress() {
      return Math.round(this.progress * 100);
    },
  },

  mounted() {
    //If there's No resource, e.g page has reloaded, go back to previous page
    if (!this.$route.params.resource) {
      this.$router.back();
    }
  },
  methods: {
    onPDfError() {
      this.showFailedMessage(
        "loading Failed",
        "Unable to load the selected Document, please try again"
      );
      this.$router.back();
    },
    onLoaded() {
      this.isLoading = false;
    },
    onProgress(progress) {
      if (!progress) return;
      this.progress = progress;
    },
    onNextPage() {
      if (this.activePage == this.totalPages) return;
      ++this.activePage;
      window.scrollTo(0, 0);
    },
    onPreviousPage() {
      if (this.activePage == 1) return;
      --this.activePage;
      window.scrollTo(0, 0);
    },
    onPageNumberObtained(pageNumber) {
      this.totalPages = pageNumber;
    },

    onTabClick() {},
  },
};
</script>

<style scoped>
.direction_arrows {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: rgb(214, 226, 238);
  color: var(--el-app-primary);
  cursor: pointer;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.direction_arrows i {
  font-size: 15px;
}
</style>