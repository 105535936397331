<template>
    <div class="container-fluid px-0 h-100" style="text-align: left;">
        <article>
            <section style="padding-top: 50px;">
                <div class="container">
                    <div class="row w-100">
                        <div class="col-12 text-left">
                            <div style="font-size: 20px; font-weight: 400; color: var(--el-app-primary); margin-bottom: 15px; ">
                                <strong>Students</strong><br>
                            </div>
                        </div>
                    </div>

                    <div class="search_add_section">
                        <div class="" style="display: flex;">
                            <input type="text" v-model.trim="filters[0].value" placeholder="Search by name"  class="search_by_input" spellcheck="off"/>
                        
                            <div style="margin-left: 20px;">
                                <el-select v-model="selectedFilter" placeholder="Select" class="d-block" style="height: 40px !important;">
                                    <el-option v-for="item in filterOptions"
                                        :key="item"
                                        :label="item"
                                        :value="item">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div :class="findCommonElements3(student_crud, $store.state.theRole)?'show_list':'hide_list'">
                            <el-button style="background-color: var(--vu-red); color: white;" class="z-depth-0 redbtn" v-ripple="'rgba(255, 255, 255, 0.35)'" @click="addMultipleStudentsModal = true">Import File</el-button>
                        </div>
                    </div>
                    <hr/>

                    <div v-loading="deleting" class="table-responsive">
                        <data-tables :data="studentsData" :table-props="tableProps" :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }" type="expand" :filters="filters">
                            
                            <div slot="empty">
                                <ScaleOut v-if="loading && !loadingError" class="mt-3" :background="'#164B70'"/>
                                <div @click="getAllStudents" v-else-if="!loading && loadingError" style="cursor: pointer">
                                Unable to Load Students Now. Please click here to retry
                                </div>
                                <div v-if="!loading && !loadingError">No Students</div>
                            </div>

                            <el-table-column label="#" :show-overflow-tooltip="true" label-class-name="text-left" width="30px">
                                <template slot-scope="scope">
                                    <span>{{ scope.$index + 1 }}</span>
                                </template>
                            </el-table-column>

                            <el-table-column label="Name" :show-overflow-tooltip="true" label-class-name="text-left" width="auto">
                                <template slot-scope="scope">
                                    <div style="display:flex; align-items: center;">
                                        <div  style="width:40px; height:40px; border-radius: 50%;">
                                            <div v-if="scope.row.profile_picture" style="display: flex; align-items:center; justify-content: center; background-color: var(--vu-red); color: white; width:40px; height:40px; object-fit:cover; border-radius: 100%;">
                                                <img :src="scope.row.profile_picture" class="img-fluid" style="width:40px; height:40px; object-fit:cover; border-radius: 100%;" />
                                            </div>
                                            <div v-if="scope.row.profile_picture == null" style="display: flex; align-items:center; justify-content: center; background-color: var(--vu-red); color: white; width:40px; height:40px; object-fit:cover; border-radius: 100%;">
                                                <strong>{{ getInitials(`${scope.row.first_name} ${scope.row.last_name}` ) }}</strong>
                                            </div>
                                        </div>
                                        <div style="margin-left: 10px;">
                                            <span>{{ scope.row.first_name }} {{ scope.row.last_name }}</span><br/>
                                            <!-- <span showing years on edit style="font-weight: 600;">Email: </span><span>{{ scope.row.email }}</span> -->
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>

                            <el-table-column label="Registration Number" :show-overflow-tooltip="true" label-class-name="text-left" width="auto">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.registration_no }}</span>
                                </template>
                            </el-table-column>

                            <el-table-column label="Phone Number">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.phone_number }}</span>
                                </template>
                            </el-table-column>

                            <el-table-column label="Access Expires In">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.expiresIn }}</span>
                                </template>
                            </el-table-column>

                            <el-table-column label="Actions" label-class-name="text-center" 
                                class-name="text-center" style="display: none;">
                                <template slot-scope="scope">
                                    <el-row style="display: flex; justify-content:center;">
                                        <el-tooltip content="Show Course Intake Batch Intake" 
                                        placement="top"
                                        >
                                            <el-button type=""
                                            :class="findCommonElements3(student_crud, $store.state.theRole)?'show_list':'hide_list'"
                                            
                                             style="background-color: blue; color: white;" @click="showIntakeBatch(scope.row.student_id)" circle><i class="fas fa-swatchbook"></i></el-button>
                                        </el-tooltip>
                                        <el-tooltip content="Edit Student information" 
                                        placement="top"
                                        >
                                            <el-button
                                            @click="showStudentModal(scope.row.student_id)"
                                            :class="findCommonElements3(student_crud, $store.state.theRole)?'show_list':'hide_list'"
                                            
                                             type="primary" icon="el-icon-edit" circle></el-button>
                                        </el-tooltip>
                                        <div v-if="$store.state.theRole.includes('BURSAR')">
                                            <el-button type="primary"
                                            :disabled="!findCommonElements3(grant_access, $store.state.theRole)"
                                             @click="grantAccess(scope.row.student_id)" icon="el-icon-zoom-in" round>Grant Access</el-button>
                                        </div>
                                        <el-tooltip content="Grant Student Access" 
                                        placement="top" v-else>
                                            <el-button type="success"
                                            :disabled="!findCommonElements3(grant_access, $store.state.theRole)"
                                             @click="grantAccess(scope.row.student_id)" icon="el-icon-zoom-in" circle></el-button>
                                        </el-tooltip>
                                    </el-row>
                                </template>
                            </el-table-column>
                                        
                        </data-tables>
                    </div>

                    <div class="students_dialogs">
                        <el-dialog title="Upload Student Excel file" 
                        :visible.sync="addMultipleStudentsModal" :destroy-on-close="true" :before-close="handleClose">
                            <div v-loading="submiting">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <input type="file" id="file" ref="file" name="file" class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group text-center" style="padding-top:10px; display: flex; justify-content:flex-end; ">
                                    <el-button style="width: 150px; background-color:  var(--el-app-primary); color:white;" class="z-depth-0"  v-on:click="uploadStudents()" >
                                        Upload Students
                                    </el-button>
                                </div>

                                <div class="text-center" style="display: flex; align-items: center; justify-content: space-between;">
                                    <div class="text-center">
                                        <small>© 2021 Pesamoni, All rights reserved.</small>
                                    </div>
                                    <div class="text-center" @click="downloadFile" style="font-weight: 600; cursor: pointer;">
                                        <span>Download sample excel file <i class="fas fa-cloud-download-alt"></i></span>
                                    </div>
                                </div>

                            </div>
                        </el-dialog>

                        <el-dialog title="Edit Student Data" 
                        :visible.sync="editStudentsModal" :destroy-on-close="true" :before-close="handleClose">
                            <div v-loading="submiting">
                                <div v-if="!studentEditStatus">
                                        <div>
                                            <p><span style="font-weight: 700;">Name:</span> {{ studentForm.first_name }} {{ studentForm.last_name }}</p>
                                            <p><span style="font-weight: 700;">Email:</span> {{ studentForm.email }}</p>
                                            <p><span style="font-weight: 700;">Phone Number:</span> {{ studentForm.phone_number }}</p>
                                            <p><span style="font-weight: 700;">Registration Number:</span> {{ studentForm.registration_no }}</p>
                                            <p><span style="font-weight: 700;">Gender:</span> {{ studentForm.gender }}</p>

                                            <!-- <div style="cursor: pointer; color: var(--el-app-primary);">
                                                <p @click="showPassword()" v-if="!showPasswordBool"><span style="font-weight: 700;">Show Password</span></p>
                                                <p v-if="showPasswordBool"><span style="font-weight: 700;">Password: </span><span>{{StudentPassword}}</span></p>
                                            </div> -->

                                            
                                            <div style="color: var(--el-app-primary); display: flex; align-items: center; justify-content: space-between;">
                                                <div style="cursor: pointer;">
                                                    <div @click="showPassword()" v-if="!showPasswordBool">
                                                    <span style="font-weight: 700;">Show Password<i class="el-icon-key"></i></span>
                                                    </div>
                                                    <div v-if="showPasswordBool">
                                                    <span style="font-weight: 700;">Password: </span>{{StudentPassword}}
                                                    </div>
                                                </div>
                                                
                                                <div @click="resetPassword" class="btn-success" style="cursor: pointer; font-weight: 600; border-radius: 5px; padding:3px 10px;">
                                                    Reset Password
                                                </div>
                                            </div>

                                        </div>
                                        <hr/>
                                        <label><span style="font-weight: 700;">{{ is_active == true ? "Deactivate Student" : "Activate Student" }}</span></label><br/>
                                        <div style="display: flex; align-items:center; justify-content: space-between;">
                                            <el-switch v-model="is_active" @change="swicthStatus" active-color="#13ce66" inactive-color="">
                                            </el-switch>
                                            <span><small>{{ is_active == true ? "Activated" : "Deactivated" }}</small></span>
                                        </div>
                                        <hr/>
                                </div>

                                <div v-if="studentEditStatus">
                                        <el-form :model="studentForm" :rules="rules" ref="studentForm"  class="demo-studentForm2">
                                            
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <label>First Name</label>
                                                    <el-form-item prop="first_name">
                                                        <el-input v-model="studentForm.first_name"></el-input>
                                                    </el-form-item>
                                                </div>
                                                <div class="col-md-6">
                                                    <label>Last Name</label>
                                                    <el-form-item prop="last_name">
                                                        <el-input v-model="studentForm.last_name"></el-input>
                                                    </el-form-item>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-md-6">
                                                    <label>Email</label>
                                                    <el-form-item prop="email">
                                                        <el-input v-model="studentForm.email"></el-input>
                                                    </el-form-item>
                                                </div>
                                                <div class="col-md-6">
                                                    <label>Phone Number</label>
                                                    <el-form-item prop="phone_number">
                                                        <el-input v-model="studentForm.phone_number"></el-input>
                                                    </el-form-item>
                                                </div>
                                            </div>

                                             <div class="row">
                    <div class="col-md-6">
                      <label>Registration Number</label>
                      <el-form-item prop="registration_no">
                        <el-input v-model="studentForm.registration_no"></el-input>
                      </el-form-item>
                    </div>
                    <div class="col-md-6">
                      <label>Choose Session</label>
                      <el-form-item prop="session">
                        <el-select v-model="studentForm.session" filterable placeholder="Choose Session" class="d-block" style="height: 40px !important;">
                          <el-option v-for="session in sessions"
                              :key="session"
                              :label="session"
                              :value="session">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <label>Choose Nationality</label>
                      <el-form-item prop="nationality">
                        <el-select v-model="studentForm.nationality" filterable placeholder="Choose Nationality" class="d-block" style="height: 40px !important;">
                          <el-option v-for="nationality in nationalities"
                              :key="nationality"
                              :label="nationality"
                              :value="nationality">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div class="col-md-6">
                      <label>Gender</label>
                      <el-form-item prop="gender">
                        <el-radio-group v-model="studentForm.gender">
                          <el-radio label="MALE"></el-radio>
                          <el-radio label="FEMALE"></el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </div>
                  </div>

                                            <!-- <div class="row">
                                                <div class="col-md-12">
                                                    <label>Gender</label>
                                                    <el-form-item prop="gender">
                                                        <el-radio-group v-model="studentForm.gender">
                                                            <el-radio label="MALE"></el-radio>
                                                            <el-radio label="FEMALE"></el-radio>
                                                        </el-radio-group> 
                                                    </el-form-item>
                                                </div>
                                            </div> -->

                                            

                                                
                                            <hr/>
                                            <el-form-item class="text-center">
                                                <el-button type="primary" @click="editStudent('studentForm')">Edit Student</el-button>
                                            </el-form-item>
                                            <hr/>
                                        </el-form>
                                </div>

                                <div>
                                    <el-tooltip content="Edit Student" placement="top" v-if="!studentEditStatus">
                                        <el-button type="primary"  @click="showEditSection" icon="el-icon-edit">Edit</el-button>
                                    </el-tooltip>
                                    <el-button v-if="studentEditStatus" type="success" @click="showStatusSection" icon="el-icon-warning-outline">{{ is_active == true ? "Activate" : "Deactivate" }}</el-button>
                                    
                                    <el-tooltip content="Delete Student" placement="top">
                                        <el-button type="danger" :disabled= "true" @click="deleteStudent" icon="el-icon-delete">Delete</el-button>
                                    </el-tooltip>
                                </div>
                            </div>
                        </el-dialog>

                        <el-dialog title="Grant Student Access" 
                        :visible.sync="grantAccessModal" :destroy-on-close="true" :before-close="handleClose">
                            <div v-loading="submiting">
                                <el-form :model="grantAccessForm" :rules="grant_rules" ref="grantAccessForm"  class="demo-grantAccessForm2">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label>Access duration in (<small><i>years, months, or weeks</i></small>)</label>
                                            <el-form-item prop="access_duration">
                                                <el-input-number v-model="grantAccessForm.access_duration" controls-position="right" @change="handleChange" :min="1" :max="12" style="width: 100%;"></el-input-number>
                                            </el-form-item>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Duration type</label>
                                            <el-form-item prop="duration_type">
                                                <el-select v-model="grantAccessForm.duration_type" placeholder="Duration type" style="width: 100%;">
                                                    <el-option label="Years" value="YEAR"></el-option>
                                                    <el-option label="Months" value="MONTH"></el-option>
                                                    <el-option label="Weeks" value="WEEK"></el-option>
                                                    <el-option label="Days" value="DAY"></el-option>
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                    </div>           
                                    <hr/>
                                    <div style="display: flex; justify-content:center">
                                        <el-form-item class="text-center" style="margin: 0px 5px;">
                                            <el-button type="primary" @click="grantAccesAction('grantAccessForm')">Grant Access</el-button>
                                        </el-form-item>
                                        <el-form-item class="text-center" style="margin: 0px 5px;">
                                            <el-button type="danger" @click="removeAccesAction(studentId_grant)">Remove Access</el-button>
                                        </el-form-item>
                                    </div>
                                </el-form>
                            </div>
                        </el-dialog>

                        <el-dialog title="Error message" :visible.sync="dialogVisible">
                            <span>{{fileErrorMessage}}</span>
                            <span slot="footer" class="dialog-footer">
                                <el-button type="primary" @click="dialogVisible = false">Cancel</el-button>
                            </span>
                        </el-dialog>

                        <el-dialog title="Student Batch Intake" :visible.sync="intakeBatchModal">
                            <div v-loading="submiting">
                                <div v-loading="submiting">
                                <div v-if="!studentEditStatus">
                                    <div>
                                        <p><span style="font-weight: 700;">Course:</span> {{course_title}} </p>
                                        <p><span style="font-weight: 700;">Year of Intake:</span> {{course_intake_batch.year}}</p>
                                        <p><span style="font-weight: 700;">Month of Intake:</span> {{ toMonthName(course_intake_batch.month) }}</p>
                                    </div>
                                </div>

                                <div v-if="studentEditStatus">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="gender">Choose Batch Intake</label>
                                                <el-select filterable v-model="course_intake_batch_choice" placeholder="Choose Batch Intake" style="width: 100%; height: 100px;">
                                                    <el-option v-for="batch in course_intake_batches" :key="batch.course_intake_batch_id" :label="batch.name" :value="batch.course_intake_batch_id"></el-option>
                                                </el-select>

                                                <el-button type="primary"  @click="submitBatchchanges">Save Changes</el-button>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <el-button v-if="!studentEditStatus" type="primary"  @click="showEditSection" icon="el-icon-edit">Change Batch Intake</el-button>
                                    <el-button v-if="studentEditStatus" type="success" @click="showStatusSection" icon="el-icon-warning-outline">Batch Intake info</el-button>
                                </div>
                            </div>
                            </div>
                        </el-dialog>
                    </div>
                </div>
            </section>
        </article>
        
    </div>
</template>

<script>

    import { ScaleOut } from "vue-loading-spinner"; 
    import getMonthName from "../helpers/getMonthName";

    export default {

        components: { ScaleOut },

        data() {
            return {
                grantAccessModal: false,
                addMultipleStudentsModal: false,
                editStudentsModal: false,
                dialogVisible: false,
                intakeBatchModal: false,
                fileErrorMessage: '',

                loading: false,
                loadingError: false,
                submiting: false,
                deleting: false,
                responseData: "",
                file: "",
                students: [],
                studentEditStatus: false,
                StudentPassword: "",
                showPasswordBool: false,
                course_intake_batch_choice: "",

                grant_access: ['AR', 'BURSAR', 'VC', 'SUPER_ADMIN'],
                student_crud: ['AR', 'VC', 'SUPER_ADMIN'],
                student_read: ['AR', 'VC', 'BURSAR', 'DEAN', 'HOD', 'IT', 'SUPER_ADMIN' ],

                tableProps: {
                    border: true,
                    stripe: true,
                },

                filters: [
                    {
                    prop: "first_name",
                    value: "",
                    },
                ],

                course_intake_batch: '',
                course_intake_batches: '',
                course_title: '',
                studentId: '',
                is_active: false,
                studentForm: {
                    registration_no: '',
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone_number: '',
                    gender: '',
                    nationality: '',
                    session: ''
                },
                rules: {
                    registration_no: [
                        { required: true, message: 'Please input Registration Number', trigger: 'blur' }
                    ],
                    first_name: [
                        { required: true, message: 'Please input First name', trigger: 'blur' }
                    ],
                    last_name: [
                        { required: true, message: 'Please input Last name', trigger: 'blur' }
                    ],
                    email: [
                        { required: true, message: 'Please add email', trigger: 'blur' }
                    ],
                    phone_number: [
                        { required: true, message: 'Please Add your phone number', trigger: 'blur' },
                        { min: 10, max: 13, message: 'Length should be 10 to 13', trigger: 'blur' }
                    ],
                    gender: [
                        { required: true, message: 'Please select your gender', trigger: 'blur' }
                    ],
                    nationality: [
                        { required: true, message: 'Please choose Nationality', trigger: 'change' }
                    ],
                    session: [
                        { required: true, message: 'Please choose Session', trigger: 'change' }
                    ],
                },

                studentId_grant: '',
                grantAccessForm: {
                    access_duration: 1,
                    duration_type: '',
                },
                grant_rules: {
                    access_duration: [
                        { required: true, message: 'Please input access dutation', trigger: 'change' }
                    ],
                    duration_type: [
                        { required: true, message: 'Please choose duration type', trigger: 'blur' }
                    ]
                },

                selectedFilter: "Show All",
                filterOptions: [
                    "Show All",
                    "Show Expired",
                ],

                sessions: [
                    "DAY", "EVENING", "WEEKEND", "DISTANCE", "ONLINE", "IN-SERVICE", "MODULAR"
                ],

                nationalities: [
                    "Afghan", "Albanian", "Algerian", "American", "Andorran", "Angolan", "Antiguans", "Argentinean",
                    "Armenian", "Australian", "Austrian", "Azerbaijani", "Bahamian", "Bahraini", "Bangladeshi",
                    "Barbadian", "Barbudans", "Batswana", "Belarusian", "Belgian", "Belizean", "Beninese", "Bhutanese", 
                    "Bolivian", "Bosnian", "Brazilian", "British", "Bruneian", "Bulgarian", "Burkinabe", "Burmese", 
                    "Burundian", "Cambodian", "Cameroonian", "Canadian", "Cape Verdean", "Central African","Chadian", 
                    "Chilean", "Chinese", "Colombian", "Comoran", "Congolese", "Costa Rican", "Croatian","Cuban", 
                    "Cypriot", "Czech", "Danish", "Djibouti", "Dominican", "Dutch", "East Timorese", "Ecuadorean",
                    "Egyptian", "Emirian", "Equatorial Guinean", "Eritrean", "Estonian", "Ethiopian", "Fijian", 
                    "Filipino", "Finnish", "French", "Gabonese", "Gambian", "Georgian", "German","Ghanaian", "Greek",
                    "Grenadian", "Guatemalan", "Guinea-Bissauan", "Guinean", "Guyanese", "Haitian", "Herzegovinian",
                    "Honduran", "Hungarian", "I-Kiribati", "Icelander", "Indian", "Indonesian", "Iranian", "Iraqi", 
                    "Irish", "Israeli", "Italian", "Ivorian", "Jamaican", "Japanese", "Jordanian", "Kazakhstani", 
                    "Kenyan", "Kittian and Nevisian", "Kuwaiti", "Kyrgyz", "Laotian", "Latvian", "Lebanese", "Liberian",
                    "Libyan", "Liechtensteiner", "Lithuanian", "Luxembourger", "Macedonian", "Malagasy", "Malawian", 
                    "Malaysian", "Maldivan", "Malian", "Maltese", "Marshallese", "Mauritanian", "Mauritian", "Mexican",
                    "Micronesian", "Moldovan", "Monacan", "Mongolian", "Moroccan", "Mosotho", "Motswana", "Mozambican",
                    "Namibian", "Nauruan", "Nepalese", "New Zealander", "Nicaraguan", "Nigerian", "Nigerien", 
                    "North Korean", "Northern Irish", "Norwegian", "Omani", "Pakistani", "Palauan", "Panamanian",
                    "Papua New Guinean", "Paraguayan", "Peruvian", "Polish", "Portuguese", "Qatari", "Romanian", 
                    "Russian", "Rwandan", "Saint Lucian", "Salvadoran", "Samoan", "San Marinese", "Sao Tomean", "Saudi",
                    "Scottish", "Senegalese", "Serbian", "Seychellois", "Sierra Leonean", "Singaporean", "Slovakian",
                    "Slovenian", "Solomon Islander", "Somali", "South African", "South Korean", "Spanish", "Sri Lankan",
                    "Sudanese", "Surinamer", "Swazi", "Swedish", "Swiss", "Syrian", "Taiwanese", "Tajik", "Tanzanian",
                    "Thai", "Togolese", "Tongan", "Trinidadian or Tobagonian", "Tunisian", "Turkish", "Tuvaluan", 
                    "Ugandan", "Ukrainian", "Uruguayan", "Uzbekistani", "Venezuelan", "Vietnamese", "Welsh", "Yemenite",
                    "Zambian", "Zimbabwean"
                ]

            }
        },

        computed: {
            createdBy() {
                return (createdBy)=> {
                    if(!createdBy) return "N/A";
                    return `${createdBy.first_name} ${createdBy.last_name}`;
                }
            },

            getInitials() {
                return (nameString) => {
                //  format(parseISO(dateString), "do MMMM yyyy");
                var names = nameString.split(" "),
                    initials = names[0].substring(0, 1).toUpperCase();
                if (names.length > 1) {
                    initials += names[names.length - 1].substring(0, 1).toUpperCase();
                }
                return initials;
                };
            },

            studentsData() {
                return this.students.filter((student) => {
                    if (this.selectedFilter === "Show Expired")
                    return student.expiresIn === "Expired";
                    return true;
                });
            },
        },

        mounted() {
            this.getAllStudents();
        },

        methods: {

            findCommonElements3(arr1, arr2) {
                return arr1.some(item => arr2.includes(item))
            },
            
            handleChange(value) {
                value();
            },

            handleClose(done) {
                done();
            },

            async getAllStudents() {
                try {
                    this.loading = true;
                    this.loadingError = false;
                    let request = await this.$http.get(`students/fetch-many`);
                    if ( request.data.success && request.data.message == "STUDENTS_FETCHED_SUCCESSFULLY" ) { 
                    this.students = request.data.students;
                } 
                else { throw "ERROR OCCURED"; }
                } catch (error) {
                    if (error.data == "Network Error") {

                    this.loadingError = true;
                    return this.showFailedMessage("Connection failed","A network error occured, please try again.");
                    
                    }
                    this.loadingError = true;
                    this.showFailedMessage("Unable to load Students","An unexpected Error occured, please try again");
                } finally {
                    this.loading = false;
                }
            },

            onFileChange(e) {
                this.file = e.target.files[0];
            },
            
            async uploadStudents() {
                const formData = new FormData();
                formData.append('studentFile', this.$refs.file.files[0]);
                this.submiting = true;

                try {
                let request = await this.$http.post(`students/upload-file`, formData, { headers: {'Content-Type': 'multipart/form-data' } } );
                if ( request.data.success && request.data.message == "STUDENT_UPLOAD_SUCCESS" ) {
                    this.getAllStudents();
                    this.showSuccessMessage("Success","Students added successfully");
                    this.addMultipleStudentsModal = false;
                } else if( request.data.success == false && request.data.message == "CONSTRAINT_VIOLATION" ) {

                    this.fileErrorMessage = request.data.errorMessage;
                    this.addMultipleStudentsModal = false;
                    this.dialogVisible = true;

                } else if( request.data.success == false && request.data.message == "EMPTY_FILE_SENT" ) {

                    this.showWarningMessage("Warning","The excel file you uploaded is empty");

                } else if( request.data.success == false && request.data.message == "UNSUPPORTED_FILE_TYPE" ) {

                    return this.showWarningMessage("Unsupported", "The file you uploaded is unsupported.");

                } else if( request.data.success == false && request.data.message == "INVALID_EMAIL" ) {

                    this.showWarningMessage("Warning","Invalid email address included.");
                    this.fileErrorMessage = request.data.errorMessage;
                    this.addMultipleStudentsModal = false;
                    this.dialogVisible = true;

                } else if( request.data.success == false && request.data.message == "INVALID PHONE NUMBER" ) {

                    this.showWarningMessage("Warning","Invalid Phone Number included.");
                    this.fileErrorMessage = request.data.errorMessage;
                    this.addMultipleStudentsModal = false;
                    this.dialogVisible = true;

                } else if( request.data.success == false && request.data.message == "REGISTRATION_NUMBER_IS_ALREADY_IN_USE" ) {

                    this.showWarningMessage("Warning","Detected Registration already in use");
                    this.fileErrorMessage = request.data.errorMessage;
                    this.addMultipleStudentsModal = false;
                    this.dialogVisible = true;
                }
                else {
                    throw "ERROR OCCURED";
                }
                } catch (error) { // eslint-disable-line no-unused-vars
                             
                    if (!error.response) {
                        this.submiting = false;
                        this.showFailedMessage("Error", "Unable to upload students excel file");
                    } else {
                            
                        if (error.response.data.message == "UNSUPPORTED_FILE_TYPE") {
                        this.submiting = false;
                        return this.showWarningMessage("Unsupported", "The file you uploaded is unsupported.");
                        } else {
                        this.submiting = false;
                        return this.showFailedMessage("Error", "Unable to upload students excel file");
                        }

                    }

                } finally {
                    this.file = "";
                    this.submiting = false;
                }
            },

            downloadFile() {
                this.submiting = true;
                this.$http.get(`students/sample-file`, { responseType: 'blob', }).then((response) => {
                const url = URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute(
                    'download',
                    `Student-sample-${new Date().toLocaleDateString()}.xlsx`
                )
                document.body.appendChild(link)
                link.click()
                })
                .finally(() => {
                this.submiting = false;
                });

            },

            showStudentModal(sId) {
                this.studentId = sId;
                this.studentForm.first_name = '';
                this.studentForm.last_name = '';
                this.studentForm.email = '';
                this.studentForm.phone_number = '';
                this.studentForm.registration_no = '';
                this.studentForm.gender = '';
                this.studentForm.nationality = '';
                this.studentForm.session = '';
                this.editStudentsModal = true;
                this.studentEditStatus = false;
                this.showPasswordBool = false;
                this.getStudent(sId);
            },

            async getStudent(studentId) {
                try {
                    this.loading = true;
                    let request = await this.$http.get(`students/get-student-for-edit/${studentId}`);
                    if ( request.data.success && request.data.message == "STUDENT_RETRIEVED_SUCCESSFULLY" ) { 
                    
                    this.studentForm.first_name = request.data.student.first_name;
                    this.studentForm.last_name = request.data.student.last_name;
                    this.studentForm.email = request.data.student.email;
                    this.studentForm.phone_number = request.data.student.phone_number;
                    this.studentForm.registration_no = request.data.student.registration_no;
                    this.studentForm.gender = request.data.student.gender;
                    this.studentForm.nationality =  request.data.student.nationality;
                    this.studentForm.session =  request.data.student.session;
                    this.is_active = request.data.student.is_active;

                    } 
                    else { throw "ERROR OCCURED"; }
                } catch (error) {
                    if (error.data == "Network Error") {

                        this.loadingError = true;
                        return this.showFailedMessage("Connection failed","A network error occured, please try again.");
                    
                    }
                    this.loadingError = true;
                    this.showFailedMessage("Unable to load Student","An unexpected Error occured, please try again");
                } finally {
                    this.loading = false;
                }
            }, 

            async swicthStatus() {
                let studentStatus = {
                    is_active: this.is_active
                }
                try {
                    this.submiting = true;
                    this.loading = true;
                    this.loadingError = false;
                    let request = await this.$http.patch(`students/change_active/${this.studentId}`, studentStatus );
                    if ( request.data.success && request.data.message == "STUDENT_ACTIVE_STATUS_CHANGED_SUCCESSFULLY" ) { 
                        this.getAllStudents();
                        this.showSuccessMessage("Success","Student activation status changed successfully");
                    }
                    else { throw "ERROR OCCURED"; }
                } catch (error) {
                    if (error.data == "Network Error") {

                        this.loadingError = true;
                        return this.showFailedMessage("Connection failed","A network error occured, please try again.");
                        
                    }
                    this.loadingError = true;
                    this.showFailedMessage("Unable to load Students","An unexpected Error occured, please try again");
                } finally {
                    this.loading = false;
                    this.submiting = false;
                }
            },

            async editStudent(formName) {
                this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.submiting = true;
                    this.$http.patch(`students/update-one/${this.studentId}`, this.studentForm ).then(
                    (response) => {
                        if(response.data.success && response.data.message == "STUDENT_EDITED_SUCCESS_FULLY"){
                            this.submiting = false;
                            this.$refs[formName].resetFields();
                            this.getAllStudents();
                            this.showSuccessMessage("Success","Student edited successfully");
                            this.editStudentsModal = false;
                        } else if (response.data.message == "EMAIL_ALREADY_IN_USE") {
                            this.submiting = false;
                            this.showWarningMessage("Warning", "Email aleady exists");
                        } else if( response.data.message == "INVALID_EMAIL" ) {
                            this.submiting = false;
                            this.showWarningMessage("Warning","Invalid email address included.");
                        } else if( response.data.message == "INVALID PHONE NUMBER" ) {
                            this.submiting = false;
                            this.showWarningMessage("Warning","Invalid Phone Number included.");
                        }
                    },
                    error => { // eslint-disable-line no-unused-vars
                        this.submiting = false;
                        if (error.data == "Network Error") {
                            return this.showFailedMessage("Connection failed","A network error occured, please try again.");
                        }
                        this.showFailedMessage("Unable to edit Student","An unexpected Error occured, please try again");
                        
                    });

                } else {
                    return false;
                }
                });
            },

            deleteStudent() {
                return; // eslint-disable-line no-unreachable
                this.$confirm('This will permanently delete this student. Do you want to continue?', 'Confirm delete', { // eslint-disable-line no-unreachable
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.submiting = true;
                    this.deleting = true;
                    this.$http.delete(`students/delete-one/${this.studentId}`).then((response) => {
                        if ( response.data.success && response.data.message == "STUDENT_DELETED_SUCCESSFULLY") {
                            this.getAllStudents();
                            this.showSuccessMessage("Success","Student deleted successfully!");
                            this.editStudentsModal = false;
                            this.submiting = false;
                            this.deleting = false;
                        }
                    }, 
                    error => { // eslint-disable-line no-unused-vars
                        this.showFailedMessage("Error", "Unable to delete Student");
                        this.submiting = false;
                            this.deleting = false;
                    });
                        
                }).catch(() => {
                    
                });
            },

            grantAccess(sId) {
                this.studentId_grant = sId;
                this.grantAccessModal = true;
            },

            grantAccesAction(formName) {
                let grantData = {
                    "access_granted_upto": `${this.grantAccessForm.access_duration} ${this.grantAccessForm.duration_type}`
                }
                this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.submiting = true;
                    this.$http.patch(`students/grant_access/${this.studentId_grant}`, grantData ).then(
                    (response) => {
                        if(response.data.success && response.data.message == "STUDENT_ACCESS_GRANTED_UPTO_CHANGED_SUCCESSFULLY"){
                            this.submiting = false;
                            this.$refs[formName].resetFields();
                            this.getAllStudents();
                            this.showSuccessMessage("Success","Student grant success changed successfully");
                            this.grantAccessModal = false;
                        }
                    },
                    error => { // eslint-disable-line no-unused-vars
                        this.submiting = false;
                        if (error.data == "Network Error") {
                            return this.showFailedMessage("Connection failed","A network error occured, please try again.");
                        }
                        this.showFailedMessage("Unable to edit Student","An unexpected Error occured, please try again");
                        
                    });

                } else {
                    return false;
                }
                });
            },

            removeAccesAction(studentId) {
                this.submiting = true;
                    this.$http.patch(`students/revoke_access/${studentId}` ).then(
                    (response) => {
                        if(response.data.success && response.data.message == "STUDENT_ACCESS_GRANTED_UPTO_CHANGED_SUCCESSFULLY" ){
                            this.submiting = false;
                            this.getAllStudents();
                            this.showSuccessMessage("Success","Student Access Removed successfully");
                            this.grantAccessModal = false;
                        }
                    },
                    error => { // eslint-disable-line no-unused-vars
                        this.submiting = false;
                        if (error.data == "Network Error") {
                            return this.showFailedMessage("Connection failed","A network error occured, please try again.");
                        }
                        this.showFailedMessage("Unable to remove Student Access","An unexpected Error occured, please try again");
                        
                    });
            },

            showEditSection() {
                this.studentEditStatus = true;
            },

            showStatusSection() {
                this.studentEditStatus = false;
            },

            async showPassword() {
                this.StaffPassword = "";
                try {
                    this.submiting = true;
                    let request = await this.$http.get(`students/default_password/${this.studentId} `);
                    if ( request.data.message == "STUDENT_PASSWORD_RETRIEVED_SUCCESFULLY" ) { 
                    this.showPasswordBool = true;
                    this.StudentPassword = request.data.default_pass;
                    } else if(request.data.message == "STUDENT_HAS_NO_DEFAULT_PASSWORD") {
                        this.showWarningMessage("Warning","Student has no default password.");
                    }
                    else { throw "ERROR OCCURED"; }
                } 
                catch (error) {
                    if (error.data == "Network Error") {
                        return this.showFailedMessage("Connection failed","A network error occured, please try again.");
                    }
                    this.showFailedMessage("Unable to load Password","An unexpected Error occured, please try again");
                } finally {
                    this.submiting = false;
                }
                
            },

            async resetPassword() {
        
                this.StaffPassword = "";

                try {
                    this.submiting = true;
                    let request = await this.$http.patch(`students/default_password/${this.studentId}`);
                    if ( request.data.message == "STUDENT_DEFAULT_PASSWORD_CHANGED_SUCCESSFULLY" ) { 
                        this.showPasswordBool = true;
                        this.StudentPassword = request.data.default_password;
                        return this.showSuccessMessage("Success","Password reset successfully");
                    }
                    else { throw "ERROR OCCURED"; }
                } 
                        catch (error) {
                    if (error.data == "Network Error") {
                        return this.showFailedMessage("Connection failed","A network error occured, please try again.");
                    }
                    this.showFailedMessage("Unable to load Password","An unexpected Error occured, please try again");
                } finally {
                    this.submiting = false;
                }
                        
            },  

            async showIntakeBatch(studentId) {
                this.studentId = studentId;
                this.course_intake_batch = "";
                this.course_title = "";
                this.intakeBatchModal = true;
                this.getCourseIntakeBatches();

                try {
                    this.submiting = true;
                    let request = await this.$http.get(`students/course-intake-batch/${studentId}`);
                    if ( request.data.success && request.data.message == "STUDENT_BATCH_RETREIVED_SUCCESSFULLY" ) { 
                        if(request.data.student.course_intake_batch == null) {
                            this.studentEditStatus = true;
                            return this.showWarningMessage("Student has no Batch Intake","Please assign student a Batch");
                        }else {
                            this.studentEditStatus = false;
                            this.course_intake_batch = request.data.student.course_intake_batch;
                            this.course_title = this.course_intake_batch.course.title;
                        }
                    } 
                    else { throw "ERROR OCCURED"; }     
                } catch (error) {
                    if (error.data == "Network Error") {

                        this.loadingError = true;
                        return this.showFailedMessage("Connection failed","A network error occured, please try again.");
                        
                    }
                    this.loadingError = true;
                    this.showFailedMessage("Unable to retrieve Student Batch Intake","An unexpected Error occured, please try again");
                } finally {
                    this.submiting = false;
                }
            },

            toMonthName(monthNum) {
                return getMonthName(monthNum, true);
            },

            async getCourseIntakeBatches() {
                try {
                    this.submiting = true;
                    let request = await this.$http.get(`course-intake-batches/structured`);
                    if ( request.data.success && request.data.message ==  "COURSE_BATCHES_FETCHED_SUCCESSFULLY" ) { 
                        this.course_intake_batches = request.data.batches;
                    } 
                    else { throw "ERROR OCCURED"; }
                } catch (error) {
                    if (error.data == "Network Error") {

                        this.loadingError = true;
                        return this.showFailedMessage("Connection failed","A network error occured, please try again.");
                        
                    }
                    this.loadingError = true;
                    this.showFailedMessage("Unable to  Batch Intakes","An unexpected Error occured, please try again");
                } finally {
                    this.submiting = false;
                }
            },

            async submitBatchchanges() {
                let studentBatch = {
                    course_intake_batch_id: this.course_intake_batch_choice
                }
                try {
                    this.submiting = true;
                    let request = await this.$http.patch(`students/update-one/${this.studentId}`, studentBatch );
                    if ( request.data.success && request.data.message == "STUDENT_EDITED_SUCCESS_FULLY" ) { 
                        this.showSuccessMessage("Success","Student  Batch Intake changed successfully");
                        this.intakeBatchModal = false;
                        this.studentEditStatus = false;
                        this.submiting = false;
                    } 
                    else { throw "ERROR OCCURED"; }
                } catch (error) {
                    if (error.data == "Network Error") {

                        this.submiting = false;
                        return this.showFailedMessage("Connection failed","A network error occured, please try again.");
                        
                    }
                    this.showFailedMessage("Unable to change Batch Intake","An unexpected Error occured, please try again");
                } finally {
                    this.submiting = false;
                }
            },
           
        }
    }

</script>

<style scoped>
  * {
    box-sizing: border-box;
  }
  
  input[type=text], select, textarea {
    width: 100%;
    padding: 9px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    background-color: #FFFFFF;
    font-size: .9em;
  }

  .search_add_section {
    display: flex; align-items: center; justify-content: space-between;
  }

  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
  }

  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  }

  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .search_add_section {
      display: block; 
    }
    .addbtn {
      width: 100%;
      margin-top: 10px;
    }
      .search_by_input {
        width: 100% !important;
      }
  }

  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .search_add_section {
      display: block;
    }
    .addbtn {
      width: 100%;
      margin-top: 10px;
    }
      .search_by_input {
        width: 100% !important;
      }
  }
</style>

<style>
  
  .students_dialogs .el-dialog {
    width: 60%;
  }

  .search_by_input {
    width: 400px !important;
  }

  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
    .students_dialogs .el-dialog {
      width: 60%;
    }
  }

  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .students_dialogs .el-dialog {
      width: 70%;
    }
  }

  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .students_dialogs .el-dialog {
      width: 90%;
    }
  }

  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .students_dialogs .el-dialog {
      width: 90%;
    }
  }
</style>