<template>
  <section>
    <div id="sidebar-article">
      <div style="cursor: pointer">
        <div style="
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: center;">

          <img :src="$store.state.logoImageUrl"
            style="height: 65px" alt="" class="img-fluid" />

        </div>
        <div>
          <!-- {{ $store.state.theRole }}
          {{ findCommonElements3(resource_content, $store.state.theRole) }} -->
        </div>
      </div>

      <div class="theLists" style="">
        <ul style="">

          <li v-on:click="closeNav()">
            <router-link to="/dashboard/home">
              <div class="side-link" :class="{ active: $route.name == 'Home' }">
                <span><i class="fas fa-home"></i> Home</span>
              </div>
            </router-link>
          </li>

          <li v-on:click="closeNav()"
          :class="findCommonElements3(faculties_cUnits_read, $store.state.theRole)?'show_list':'hide_list'">
            <router-link to="/dashboard/faculties">
              <div
                class="side-link"
                :class="{
                  active:
                    $route.name == 'Faculties' ||
                    $route.name == 'FacultyDetail' ||
                    $route.name == 'DepartmentCourses' ||
                    $route.name == 'MyCourseUnits',
                }"
              >
                <span><i class="fas fa-th"></i> Faculties</span>
              </div>
            </router-link>
          </li>

          <li v-on:click="closeNav()" 
          :class="findCommonElements3(staff_read, $store.state.theRole)?'show_list':'hide_list'">
            <router-link to="/dashboard/staff">
              <div
                class="side-link"
                :class="{ active: $route.name == 'Staff' }"
              >
                <span><i class="fas fa-users"></i> Staff Members</span>
              </div>
            </router-link>
          </li>

          <li v-on:click="closeNav()">
            <router-link to="/dashboard/course-batches">
              <div
                class="side-link"
                :class="{ active: $route.name == 'CourseBatches' }"
              >
                <span><i class="fas fa-window-restore"></i> Intakes</span>
              </div>
            </router-link>
          </li>

          <li v-on:click="closeNav()"
          :class="findCommonElements3(live_lectures_read, $store.state.theRole)?'show_list':'hide_list'">
            <router-link to="/dashboard/live-lectures">
              <div
                class="side-link"
                :class="{
                  active:
                    $route.name == 'LiveLecture' ||
                    $route.name == 'LiveLectures',
                }"
              >
                <span><i class="fas fa-video"></i> Live Lectures</span>
              </div>
            </router-link>
          </li>

          <li v-on:click="closeNav()">
            <router-link to="/dashboard/students">
              <div
                class="side-link"
                :class="{ active: $route.name == 'Students' }"
              >
                <span><i class="fas fa-user-friends"></i> Students</span>
              </div>
            </router-link>
          </li>

          <li v-on:click="closeNav()" 
          :class="findCommonElements3(resource_content, $store.state.theRole)?'show_list':'hide_list'">
            <router-link to="/dashboard/resource-contents">
              <div
                class="side-link"
                :class="{ active: $route.name == 'ResourceContents' }"
              >
                <span><i class="fas fa-user-friends"></i> Resources</span>
              </div>
            </router-link>
          </li>

          <li v-on:click="closeNav()" 
          :class="findCommonElements3(help_messages, $store.state.theRole)?'show_list':'hide_list'">
            <router-link to="/dashboard/help-messages">
              <div
                class="side-link"
                :class="{ active: $route.name == 'HelpMessages' }"
              >
                <span><i class="fas fa-hands-helping"></i> Help Messages</span>
              </div>
            </router-link>
          </li>

          <li v-on:click="closeNav()"
          :class="findCommonElements3(help_messages, $store.state.theRole)?'show_list':'hide_list'">
            <router-link to="/dashboard/user-requests">
              <div
                class="side-link"
                :class="{ active: $route.name == 'UserRequests' }"
              >
                <span
                  ><i class="fas fa-question-circle"></i> User Requests</span
                >
              </div>
            </router-link>
          </li>

          <li v-on:click="closeNav()"
          :class="findCommonElements3(notice_board, $store.state.theRole)?'show_list':'hide_list'">
            <router-link to="/dashboard/notice-board">
              <div class="side-link" :class="{ active: $route.name == 'NoticeBoard' }">
                <span><i class="fas fa-flag-checkered"></i> Notice Board</span>
              </div>
            </router-link>
          </li>

        </ul>
      </div>

      <div style="">
        <ul style="">
          <li v-on:click="closeNav()">
            <router-link to="/dashboard/settings">
              <div
                class="side-link"
                :class="{ active: $route.name == 'Settings' }"
              >
                <span><i class="fas fa-cog"></i> Settings</span>
              </div>
            </router-link>
          </li>
          <li v-on:click="closeNav()">
            <router-link to="/dashboard/help">
              <div class="side-link" :class="{ active: $route.name == 'Help' }">
                <span><i class="far fa-question-circle"></i> Help</span>
              </div>
            </router-link>
          </li>
          <li @click="onLogoutIntent">
            <div class="side-link">
              <span><i class="fas fa-sign-out-alt"></i> Logout</span>
            </div>
          </li>
        </ul>
      </div>

      <!--  -->

    </div>
  </section>
</template>

<script>
  export default {
    data() {
      return {

        student_read: ['AR', 'VC', 'BURSAR', 'DEAN', 'HOD', 'IT', 'SUPER_ADMIN' ],
        staff_read: ['VC', 'AR', 'HR', 'DEAN', 'HOD', 'IT', 'SUPER_ADMIN'],
        faculties_cUnits_read: ['AR', 'VC', 'SUPER_ADMIN'],
        create_batch_read: ['AR', 'VC', 'DEAN', 'HOD', 'SUPER_ADMIN'],
        live_lectures_read: ['AR', 'QA', 'VC','DEAN', 'HOD', 'IT', 'SUPER_ADMIN'],
        help_messages: ['AR', 'HR', 'DEAN', 'HOD', 'QA', 'IT', 'VC', 'SUPER_ADMIN'],
        notice_board: ['AR', 'HR', 'DEAN', 'HOD', 'QA', 'VC', 'SUPER_ADMIN'],
        notice_board_read: ['VC', 'SUPER_ADMIN'],
        staff_request: ['HR', 'VC', 'SUPER_ADMIN'],
        resource_content: ['QA', 'VC'],

      };
    },

    mounted() {
    },

    methods: {

      // Iterate through each element in the
      // first array and if some of them 
      // include the elements in the second
      // array then return true.
      findCommonElements3(arr1, arr2) {
          return arr1.some(item => arr2.includes(item))
      },
      
      closeNav() {
        document.getElementById("mySidenav").style.width = "0";
      },

      async onLogoutIntent() {
        this.closeNav();
        try {
          let logOut = await this.$confirm(
            "You will be immediately logged out of your account, Continue?",
            "Confirm Log out",
            {
              confirmButtonText: "Logout",
              cancelButtonText: "Cancel",
              type: "warning",
            }
          );

          if (logOut) {
            this.logOut();
          }
        } catch {
          //no-empty
        }
      },

      async logOut() {
        const loading = this.$loading({
          lock: true,
          text: "Logging you out...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });

        try {
          let request = await this.$http.post("/staff/logout");
          if (
            request.data &&
            request.data.success &&
            request.data.message == "LOGOUT_SUCCESSFUL"
          ) {
            loading.close();
            this.$store.commit("LOGOUT_USER");
            this.$router.replace({ path: "/login" });
          }
        } catch (error) {
          this.showFailedMessage(
            "Logout failed",
            "Unable to log you out now, please try again, please try again"
          );
        } finally {
          loading.close();
        }
      },

    },
  };
</script>

<style scoped>
  .theLists {
    margin: 50px 0px;
    padding: 0px;
  }
  ul {
    padding: 0px;
  }
  ul li {
    list-style: none;
    text-align: left;
    font-weight: 400;
  }
  ul li i {
    margin-right: 5px;
  }
  .side-link {
    color: var(--el-app-primary);
    padding: 10px 30px;
    cursor: pointer;
    transition: 0.5s;
  }
  .side-link.active {
    border-left: 6px solid var(--vu-red);
    color: var(--el-app-primary) !important;
    font-weight: 600;
  }
  .side-link:hover {
    background-color: #eeeeee;
    padding: 10px 40px;
    transition: 0.5s;
    color: #74a4c5;
  }
  .bg-white {
    background-color: white;
  }

</style>
