<template>
  <div class="container-fluid px-0 h-100" style="text-align: left;">
    <article>
      <section style="padding-top: 50px;">
        <div class="container">

          <div class="row w-100 mb-4">
            <div class="col-12 pl-3 px-0 mb-0 text-left">
              <BreadCrumbComponent :pageTitles="['Notice Board']" />
            </div>
          </div>

              <div class="row w-100">
                <div class="col-12 text-left">
                  <div style="font-size: 20px; font-weight: 400; color: var(--el-app-primary); margin-bottom: 15px; ">
                    <strong>Notice Board</strong>
                  </div>
                </div>
              </div>

              <div class="search_add_section">
                <div class="">
                  <input type="text" v-model.trim="filters[0].value" placeholder="Search by Title"  class="search_by_input" spellcheck="off"/>
                </div>
                <el-button style="background-color: var(--el-app-primary); color: white;" class="z-depth-0 addbtn" v-ripple="'rgba(255, 255, 255, 0.35)'" @click="showNoticeModal">Add Announcement</el-button>
              </div>
                <hr/>

          <div v-loading="deleting" style=" width: 100%">
            <data-tables :data="notices" :table-props="tableProps" :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }" type="expand" :filters="filters">
              
              <div slot="empty">
                <ScaleOut v-if="loading && !loadingError" class="mt-3" :background="'#164B70'"/>
                <div @click="getNotices" v-else-if="!loading && loadingError" style="cursor: pointer">
                  Unable to Load Announcements Now. Please click here to retry
                </div>
                <div v-if="!loading && !loadingError">No Announcements</div>
              </div>

              <el-table-column label="#" :show-overflow-tooltip="true" label-class-name="text-center" class-name="text-center" width="80px">
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <el-table-column label="Title" :show-overflow-tooltip="true" label-class-name="text-left" width="auto">
                <template slot-scope="scope">
                  <span>{{ scope.row.title }}</span>
                </template>
              </el-table-column>

              <el-table-column label="Audience" :show-overflow-tooltip="true" label-class-name="text-left" class-name="text-left">
                <template slot-scope="scope">
                  <span>{{ scope.row.audience}}</span>
                </template>
              </el-table-column>

              <el-table-column label="Created by" :show-overflow-tooltip="true" label-class-name="text-left" class-name="text-left">
                <template slot-scope="scope">
                  <span>{{ createdBy(scope.row.staff)}}</span>
                </template>
              </el-table-column>

              <el-table-column label="Actions" :show-overflow-tooltip="true" label-class-name="text-center" class-name="text-center">
                <template slot-scope="scope">
                  <el-row>
                    <el-tooltip content="Edit" placement="top">
                      <el-button type="primary" @click="noticeEditData(scope.row.notice_board_id, scope.row.title, scope.row.message, scope.row.audience, scope.row.valid_upto, scope.row.course_intake_batch_id )" icon="el-icon-edit" circle></el-button>
                    </el-tooltip>
                    <el-tooltip content="Delete" placement="top">
                      <el-button type="danger" :disabled= "false" @click="deleteAnnouncement(scope.row.notice_board_id)" icon="el-icon-delete" circle></el-button>
                    </el-tooltip>
                  </el-row>
                </template>
              </el-table-column>

            </data-tables>
          </div>
          
            </div>
      </section>

      <section>
            <div class="container">
              <div class="announcement_dialogs">
                <el-dialog title="Add Announcement" :visible.sync="addNoticeModal" :destroy-on-close="true" :before-close="handleClose">
                  <div v-loading="submiting">
                      <div class="row">
                          <div class="col-md-12">
                            <label for="title">Announcement Title</label>
                            <input type="text" :class="{ 'input-error': $v.title.$error }"  @keyup.enter="addNotice" v-model.trim="$v.title.$model" placeholder="Title"><br/>
                            <span v-if="$v.title.$error" style="font-size: 0.7em; color: red">{{ titleError }}</span>
                          </div>
                      </div>

                      <div class="row">
                        <div class="col-md-12">
                          <br/>
                          <label for="message"><strong>Message</strong></label><br/>
                          <el-input type="textarea" id="message" :class="{ 'input-error': $v.message.$error }" @keyup.enter="addNotice" v-model="$v.message.$model" :autosize="{ minRows: 10, maxRows: 10}" placeholder="Type message here ..."> </el-input>
                          <br/>
                          <span v-if="$v.message.$error" style="font-size: 0.7em; color: red">{{ messageError }}</span>
                          <br/>
                        </div>
                      </div>

                      <div class="row">
                          <div class="col-md-6">
                              <label for="gender">Target Audience</label><br/>
                              <el-select filterable :class="{ 'input-error': $v.audience.$error }" @keyup.enter="addNotice" v-model="$v.audience.$model" placeholder="Audience" style="width: 98%;">
                                  <el-option label="All" value="ALL"></el-option>
                                  <el-option label="Staff" value="STAFF"></el-option>
                                  <el-option label="Students" value="STUDENT"></el-option>
                              </el-select><br/><br/>
                              <span v-if="$v.audience.$error" style="font-size: 0.7em; color: red">{{ audienceError }}</span>
                          </div>

                          <div class="col-md-6">
                              <label for="gender">Target Batch Audience (Optional)</label>
                              <el-select multiple @keyup.enter="addNotice" v-model="course_intake_batch_ids" placeholder="Batch Audience" style="width: 98%;">
                                  <el-option v-for="batch in batches" :key="batch.course_intake_batch_id" :label="batch.name" :value="batch.course_intake_batch_id"></el-option>
                              </el-select><br/><br/>
                          </div>
                      </div>

                      <div class="row">
                          <div class="col-md-12">
                            <span class="demonstration">Expiry Date</span><br/>
                            <el-date-picker :class="{ 'input-error': $v.valid_upto.$error }" @keyup.enter="addNotice" v-model="$v.valid_upto.$model"
                                  type="date"
                                  :value-format="'dd MMMM yyyy'"
                                  placeholder="Pick a day"  style="width: 100%;">
                            </el-date-picker>
                            <br/>
                            <span v-if="$v.valid_upto.$error" style="font-size: 0.7em; color: red">{{ valid_uptoError }}</span>
                          </div>
                      </div>
                        
                      <div class="form-group text-center" style="padding-top:10px;">
                          <br/><br/>
                          <el-button style="background-color: var(--el-app-primary); position: relative; bottom: 9px; color: white;" class="z-depth-0" v-ripple="'rgba(255, 255, 255, 0.35)'" @click="addNotice" :disabled="!$v.title.required">Add Announcement</el-button>
                      </div>

                    <div class="text-center">
                        <div class="text-center">
                            <small>© 2021 Pesamoni, All rights reserved.</small>
                        </div>
                        <div class="text-center">
                            <!-- <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                        </div>
                    </div>
                  </div>
                </el-dialog>

                <el-dialog title="Edit Announcement" :visible.sync="editFacultyModal" :destroy-on-close="true" :before-close="handleClose">
                  <div v-loading="submiting">

                    <div class="row">
                      <div class="col-md-12">
                          <label for="title">Announcement Title</label><br/>
                          <input type="text" :class="{ 'input-error': $v.title_edit.$error }" @keyup.enter="editNotice_final" v-model.trim="$v.title_edit.$model" placeholder="Title"><br/>
                          <span v-if="$v.title_edit.$error" style="font-size: 0.7em; color: red">{{ titleEditError }}</span>
                        <br/>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                          <label for="message"><strong>Message</strong></label>
                          <el-input type="textarea" id="message" :class="{ 'input-error': $v.message_edit.$error }" @keyup.enter="editNotice_final" v-model="$v.message_edit.$model" :autosize="{ minRows: 10, maxRows: 10}" placeholder="Type message here ..."> </el-input>
                          <br/>
                          <span v-if="$v.message_edit.$error" style="font-size: 0.7em; color: red">{{ messageEditError }}</span>
                      <br/>
                      </div>
                    </div>

                      <div class="row">
                        <div class="col-md-6">
                          <label for="gender">Target Audience</label><br/>
                          <el-select filterable  :class="{ 'input-error': $v.audience_edit.$error }" @keyup.enter="editNotice_final" v-model="$v.audience_edit.$model" placeholder="Audience" style="width: 100%;">
                              <el-option label="All" value="ALL"></el-option>
                              <el-option label="Staff" value="STAFF"></el-option>
                              <el-option label="Students" value="STUDENT"></el-option>
                          </el-select><br/><br/>
                          <span v-if="$v.audience.$error" style="font-size: 0.7em; color: red">{{ audienceError }}</span>
                        </div>
                        
                        <div class="col-md-6">
                          <label for="gender">Target Batch Audience (Optional)</label><br/>
                          <el-select multiple @keyup.enter="addNotice" v-model="course_intake_batch_ids_edit" placeholder="Batch Audience" style="width: 100%;">
                              <el-option v-for="batch in batches" :key="batch.course_intake_batch_id" :label="batch.name" :value="batch.course_intake_batch_id"></el-option>
                          </el-select><br/><br/>
                        </div>
                      </div>


                    <div class="row">
                        <div class="col-md-12">
                          <span class="demonstration">Expiry Date</span><br/>
                          <el-date-picker :value-format="'dd MMMM yyyy'" :class="{ 'input-error': $v.valid_upto_edit.$error }" @keyup.enter="editNotice_final" v-model="$v.valid_upto_edit.$model"
                          type="date" placeholder="Pick a day" style="width: 100%;">
                          </el-date-picker>
                          <br/>
                          <span v-if="$v.valid_upto_edit.$error" style="font-size: 0.7em; color: red">{{ valid_uptoEditError }}</span>
                        </div>
                    </div>

                    <div class="form-group text-center" style="padding-top:10px;">
                      <br/><br/>
                      <el-button style="background-color: var(--el-app-primary); position: relative; bottom: 9px; color: white;" class="z-depth-0" v-ripple="'rgba(255, 255, 255, 0.35)'" @click="editNotice_final" :disabled="!$v.title_edit.required">Edit Announcement</el-button>
                    </div>

                    <div class="text-center">
                        <div class="text-center">
                            <small>© 2021 Pesamoni, All rights reserved.</small>
                        </div>
                        <div class="text-center">
                            <!-- <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong></strong></a></small> -->
                        </div>
                    </div>

                  </div>
                </el-dialog>
              </div>
            </div>
      </section>
    </article>
  </div>
</template>

<script>

  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  import { ScaleOut } from "vue-loading-spinner";
  import BreadCrumbComponent from "../components/bread-crumb-component";

  export default {

    components: { ScaleOut, BreadCrumbComponent },
    mixins: [validationMixin],

      data() {
          return {
            addNoticeModal: false,
            editFacultyModal: false,
            loading: false,
            loadingError: false,
            submiting: false,
            deleting: false,
            responseData: "",
            title: "",
            message: "",
            message_edit: "",
            audience: "",
            course_intake_batch_ids: [],
            valid_upto: "",
            audience_edit: "",
            course_intake_batch_ids_edit: [],
            valid_upto_edit: "",
            title_edit: "",
            notices: [],
            batches: [],

            tableData: [],
            tableProps: {
            border: true,
            stripe: true,
            },

            filters: [
                {
                    prop: "title",
                    value: "",
                },
            ],

        };
      },

      computed: {
        titleError() {
          if (!this.$v.title.required) {
            return "*Title is required";
          }
          return "";
        },

        titleEditError() {
          if (!this.$v.title_edit.required) {
            return "*Title is required";
          }
          return "";
        },

        messageError() {
          if (!this.$v.title.required) {
            return "*Message is required";
          }
          return "";
        },

        messageEditError() {
          if (!this.$v.title_edit.required) {
            return "*Message is required";
          }
          return "";
        },

        audienceError() {
          if (!this.$v.audience.required) {
            return "*Audience is required";
          }
          return "";
        },

        audienceEditError() {
          if (!this.$v.audience_edit.required) {
            return "*Audience is required";
          }
          return "";
        },

        valid_uptoError() {
          if (!this.$v.valid_upto.required) {
            return "*Valid end date is required";
          }
          return "";
        },

        valid_uptoEditError() {
          if (!this.$v.valid_upto_edit.required) {
            return "*Valid end date is required";
          }
          return "";
        },

        createdBy() {
          return (creator)=> {
            if(!creator) return "N/A";
            return `${creator.first_name} ${creator.last_name}`;
          }
        }
      },

      validations: {
        title: {
            required
        },
        title_edit: {
            required
        },
        message: {
            required
        },
        message_edit: {
            required
        },
        audience: {
            required
        },
        valid_upto: {
            required
        },
        audience_edit: {
            required
        },
        valid_upto_edit: {
            required
        },
      },

      mounted() {
          this.getNotices();
      },

      methods: {

        handleClose(done) {
          this.clearInputs();
          this.$v.$reset();
          done();
        },

        clearInputs() {
          this.title = "";
          this.message = "";
          this.audience = "";
          this.course_intake_batch_ids = [];
          this.valid_upto = "";
        },

        showNoticeModal() {
          this.addNoticeModal = true;
          this.getBatches();
        },

        async addNotice() {
            //If the function was triggered elsewhere e.g from pressing enter
            if (!this.$v.title.required) return;

            let noticeBoardData = {
                title: this.title,
                message: this.message,
                audience: this.audience,
                course_intake_batch_ids: this.course_intake_batch_ids,
                valid_upto: this.valid_upto,
            }
            this.submiting = true;

            try {
                let request = await this.$http.post(`notice-board/add`, noticeBoardData );
                if (
                    request.data.success && request.data.message == "NOTICE_ADDED_SUCCESSFULLY"
                ) {
                    this.getNotices();
                    this.showSuccessMessage("Success","Announcement added successfully");
                    this.addNoticeModal = false;
                    this.clearInputs();
                    this.$v.$reset();

                } else if (request.data.message == "INVALID_DATE") {
                  
                  this.showWarningMessage("Invalid date","The end date is invalid");

                } else if (request.data.message == "DATE_SUPPLIED_IS_IN_THE_PAST") {

                  this.showWarningMessage("Invalid date","The date you have chosen is already past.");

                } else {
                    throw "ERROR OCCURED";
                }
            } catch (error) {
                if (error.data == "Network Error") {

                    return this.showFailedMessage("Connection failed","A network error occured, please try again.");
                
                }
                this.showFailedMessage("Unable to add Announcement","An unexpected Error occured, please try again");
            } finally {
                this.submiting = false;
            }
        },

        async getNotices() {
            try {
                this.loading = true;
                this.loadingError = false;
                let request = await this.$http.get(`notice-board/get`);
                if ( request.data.success && request.data.message == "NOTICES_RETRIEVED_SUCEESSFULLY" ) { 
                    this.notices = request.data.notices;
                } 
                else {throw "ERROR OCCURED"; }  
            } catch (error) {
                if (error.data == "Network Error") {

                    this.loadingError = true;
                    return this.showFailedMessage("Connection failed","A network error occured, please try again.");
                
                }

                this.loadingError = true; 
                this.showFailedMessage("Unable to load Program categories", "An unexpected Error occured, please try again");
            } finally {
                this.loading = false; 
            }
        },

        noticeEditData(id, title, message, audience, valid_upto, batches) {
          this.editFacultyModal = true;
          this.id_edit = id;
          this.title_edit = title;
          this.message_edit = message;
          this.audience_edit = audience;
          this.valid_upto_edit = valid_upto;
          this.course_intake_batch_ids_edit = batches;
          this.getBatches();
        },
        
        async editNotice_final() {
          //If the function was triggered elsewhere e.g from pressing enter
          if (!this.$v.title_edit.required) return;
          let noticeEditData = {
            title: this.title_edit,
            message: this.message_edit,
            audience: this.audience_edit,
            valid_upto: this.valid_upto_edit,
            course_intake_batch_ids: this.course_intake_batch_ids_edit
          }
          this.submiting = true;

          try {
            let request = await this.$http.patch(`notice-board/edit/${this.id_edit}`, noticeEditData );
            if ( request.data.success && request.data.message == "NOTICE_EDITED_SUCCESSFULLY" ) {

              this.getNotices();
              this.editFacultyModal = false;
              this.showSuccessMessage("Success", "Announcement edited successfully!");

            } else if (request.data.message == "TITLE_IS_ALREADY_IN_USE") {

              this.showWarningMessage("Warning","Announcement Title aleady in use");

            } else {
              throw "ERROR OCCURED";
            }
          } 
          catch (error) {
            if (error.data == "Network Error") {

              return this.showFailedMessage("Connection failed","A network error occured, please try again.");
            
            }
            this.showFailedMessage("Unable to edit Program category","An unexpected Error occured, please try again");
          } 
          finally {
            this.submiting = false;
          }
        },

        deleteAnnouncement(id) {
          return; // eslint-disable-line no-unreachable
          this.$confirm('This will permanently delete this Announcement. Do you want to Continue?', 'Confirm delete', { // eslint-disable-line no-unreachable
              confirmButtonText: 'OK',
              cancelButtonText: 'Cancel',
              type: 'warning'
          }).then(() => {
            this.deleting = true;
            this.$http.delete(`notice-board/delete/${id}`).then((response) => {
              if ( response.data.success && response.data.message == "NOTICE_DELETED_SUCCESSFULLY") {
                this.getNotices();
                this.showSuccessMessage("Success","Announcement deleted successfully!");
                this.deleting = false;
              }
            }, 
            error => { // eslint-disable-line no-unused-vars
              this.showFailedMessage("Error", "Unable to delete Announcement");
              this.deleting = false;
            });
                
            }).catch(() => {
                  
            }).finally(() => {

          });
        },

        async getBatches() {
          try {
            this.submiting = true;
            let request = await this.$http.get(`staff/fetch-all-batches`);
            if (request.data.success && request.data.message == "COURSE_BATCHES_FETCHED_SUCCESSFULLY") {
              this.batches = request.data.batches;
            } else {
              throw "ERROR OCCURED";
            }
          } catch (error) {
            if (error.data == "Network Error") {
              return this.showFailedMessage(
                "Connection failed", "A network error occured, please try again.");
            }

            this.showFailedMessage(
              "Unable to load Batch", "An unexpected Error occured, please try again");
          } finally {
            this.submiting = false;
          }
        },
        
      }

  }

</script>

<style scoped>
  * {
    box-sizing: border-box;
  }
  
  input[type=text], select, textarea {
    width: 100%;
    padding: 10px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    background-color: #FFFFFF;
    font-size: .9em;
  }

  .search_add_section {
    display: flex; align-items: center; justify-content: space-between;
  }

  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
  }

  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  }

  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .search_add_section {
      display: block; 
    }
    .addbtn {
      width: 100%;
      margin-top: 10px;
    }
      .search_by_input {
        width: 100% !important;
      }
  }

  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .search_add_section {
      display: block;
    }
    .addbtn {
      width: 100%;
      margin-top: 10px;
    }
      .search_by_input {
        width: 100% !important;
      }
  }
</style>

<style>
  
  .announcement_dialogs .el-dialog {
    width: 60%;
  }

  .search_by_input {
    width: 400px !important;
  }


  .announcement_dialogs .el-select .el-input__inner {
    cursor: pointer;
    height: 36px !important;
    width: 100% !important;
    padding-left: 10px !important;
    text-transform: capitalize;
  }
  .announcement_dialogs .el-button--primary {
    color: #fff;
    background-color: #409eff !important;
    border-color: #409eff !important;
  }

  el-table th > .cell {
    color: black !important;
    font-weight: 500;
  }
  .el-table .cell {
    font-size: 0.9em;
    line-height: 23px;
    font-weight: 300;
  }

  .announcement_dialogs .el-input input {
    font-size: 0.8rem !important;
    font-weight: 400 !important;
    color: #495057 !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  }

  .announcement_dialogs .el-input input:focus {
    color: #495057 !important;
    background-color: #fff !important;
    border-color: #80bdff !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
  }

  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
    .announcement_dialogs .el-dialog {
      width: 50%;
    }
  }

  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .announcement_dialogs .el-dialog {
      width: 70%;
    }
  }

  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .announcement_dialogs .el-dialog {
      width: 90%;
    }
  }

  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .announcement_dialogs .el-dialog {
      width: 90%;
    }

  }
</style>
