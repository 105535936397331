<template>
  <div class="container-fluid px-0 h-100" style="text-align: left">
    <article>
      <section style="padding-top: 50px">
        <div class="container">

          <div class="row w-100 mb-4">
            <div class="col-12 pl-3 px-0 mb-0 text-left">
              <BreadCrumbComponent :pageTitles="['Faculties','Departments','Programmes','Modules' ]" />
            </div>
          </div>

          <div class="row w-100">
            <div class="col-12 text-left">
              <div style="font-size: 20px; font-weight: 400; color: var(--el-app-primary); margin-bottom: 15px;">
                <strong>Modules in "<span style="">{{ courseName ? courseName : '...'}}</span>"</strong><br/>
              </div>
            </div>
          </div>

          <div class="search_add_section">
            <div class="">
              <input type="text" v-model.trim="filters[0].value" placeholder="Search by Title" class="search_by_input" spellcheck="off"/>
            </div>
            <el-button class="z-depth-0 redBtn" v-ripple="'rgba(255, 255, 255, 0.35)'" @click="showAddCourseUnitModal">Add A Module</el-button>
          </div>
            <br/>

            <div class="table-responsive">
                <div v-loading="deleting">
                    <data-tables :data="course_units" :table-props="tableProps" :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }" type="expand" :filters="filters">
                        
                        <div slot="empty">
                          <ScaleOut v-if="loading && !loadingError" class="mt-3" :background="'#164B70'"/>
                          <div @click="getCourseUnits" v-else-if="!loading && loadingError" style="cursor: pointer">
                            Unable to Load Modules Now. Please click here to retry
                          </div>
                          <div v-if="!loading && !loadingError">No Modules</div>
                        </div>
                        <el-table-column label="#" :show-overflow-tooltip="true" label-class-name="text-center" class-name="text-center" width="80px">
                            <template slot-scope="scope">
                                <span>{{ scope.$index + 1 }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Module Code" :show-overflow-tooltip="true" label-class-name="text-left" width="auto">
                            <template slot-scope="scope">
                                <span>{{ scope.row.course_unit_code }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Module Title" :show-overflow-tooltip="true" label-class-name="text-left" width="auto">
                            <template slot-scope="scope">
                                <span>{{ scope.row.title }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Module Resources" :show-overflow-tooltip="true" label-class-name="text-left">
                          <template slot-scope="scope">
                            <el-button type="primary" @click="goToCourseUnitResources(scope.row.course_unit_id)" round>See Resources</el-button>
                          </template>
                        </el-table-column>
                        <el-table-column label="Action">
                          <template slot-scope="scope">
                            <el-row>
                              <el-tooltip content="Click to edit this Module" placement="top">
                                <el-button type="primary" :disabled="!findCommonElements3(faculties_cUnits_crud, $store.state.theRole)" @click="courseUnitEditData( scope.row.course_unit_id, scope.row.title, scope.row.course_unit_code, scope.row.year_of_study, scope.row.semester_of_study )" icon="el-icon-edit" circle></el-button>
                              </el-tooltip>
                              <el-tooltip content="Click to delete this Module" placement="top">
                                <el-button type="danger" :disabled= "true" @click="deleteCourseUnit(scope.row.course_unit_id)" icon="el-icon-delete" circle></el-button>
                              </el-tooltip>
                            </el-row>
                          </template>
                        </el-table-column>
                    </data-tables>
                </div>
            </div>
    
          <div class="courseUnits_dialogs">
            <el-dialog title="Add A Module" :visible.sync="addCourseUnitModal" :destroy-on-close="true" :before-close="handleClose" style="text-align: left;">
              <div v-loading="submiting">
                <el-form :model="courseUnitForm" :rules="rules" ref="courseUnitForm"  class="demo-courseUnitForm2">
                  <div class="row">
                    <div class="col-md-6">
                      <label>Module Title</label>
                      <el-form-item prop="title">
                        <el-input v-model="courseUnitForm.title"></el-input>
                      </el-form-item>
                    </div>
                    <div class="col-md-6">
                      <label>Module code</label>
                      <el-form-item prop="course_unit_code">
                        <el-input v-model="courseUnitForm.course_unit_code"></el-input>
                      </el-form-item>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <label>Year of study</label>
                      <el-form-item prop="year_of_study">
                        <el-select v-model="courseUnitForm.year_of_study" placeholder="Duration type" style="width: 100%;">
                          <el-option label="Year 1" value="1"></el-option>
                          <el-option label="Year 2" value="2"></el-option>
                          <el-option label="Year 3" value="3"></el-option>
                          <el-option label="Year 4" value="4"></el-option>
                          <el-option label="Year 5" value="5"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>

                    <div class="col-md-6">
                      <label>Semester</label>
                      <el-form-item prop="semester_of_study">
                        <el-select v-model="courseUnitForm.semester_of_study" placeholder="Duration type" style="width: 100%;">
                          <el-option label="Semester 1" value="1"></el-option>
                          <el-option label="Semester 2" value="2"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
                                        
                  <hr/>
                  <el-form-item class="text-center">
                      <el-button type="primary" @click="addCourseUnit('courseUnitForm')">Add Module</el-button>
                  </el-form-item>
                </el-form>

                <div class="text-center">
                  <div class="text-center">
                    <small>© 2021 Pesamoni, All rights reserved.</small>
                  </div>
                  <div class="text-center">
                    <!--v-model="courseUnitForm.Course" <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                  </div>
                </div>
              </div>
            </el-dialog>

            <el-dialog title="Edit Modules" :visible.sync="editCourseUnitModal" :destroy-on-close="true" :before-close="handleClose" style="text-align: left;">
              <div v-loading="submiting_edit">
                <el-form :model="courseUnitForm" :rules="rules" ref="courseUnitForm"  class="demo-courseUnitForm2">
                  <div class="row">
                    <div class="col-md-6">
                      <label>Module Title</label>
                      <el-form-item prop="title">
                        <el-input v-model="courseUnitForm.title"></el-input>
                      </el-form-item>
                    </div>
                    <div class="col-md-6">
                      <label>Module code</label>
                      <el-form-item prop="course_unit_code">
                        <el-input v-model="courseUnitForm.course_unit_code"></el-input>
                      </el-form-item>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <label>Year of study</label>
                      <el-form-item prop="year_of_study">
                        <el-select v-model="courseUnitForm.year_of_study" placeholder="Duration type" style="width: 100%;">
                          <el-option label="Year 1" value="1"></el-option>
                          <el-option label="Year 2" value="2"></el-option>
                          <el-option label="Year 3" value="3"></el-option>
                          <el-option label="Year 4" value="4"></el-option>
                          <el-option label="Year 5" value="5"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>

                    <div class="col-md-6">
                      <label>Semester</label>
                      <el-form-item prop="semester_of_study">
                        <el-select v-model="courseUnitForm.semester_of_study" placeholder="Duration type" style="width: 100%;">
                          <el-option label="Semester 1" value="1"></el-option>
                          <el-option label="Semester 2" value="2"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
                                        
                  <hr/>
                  <el-form-item class="text-center">
                      <el-button type="primary" @click="editCourseUnit_final('courseUnitForm')">Edit Module</el-button>
                  </el-form-item>
                </el-form>

                <div class="text-center">
                  <div class="text-center">
                    <small>© 2021 Pesamoni, All rights reserved.</small>
                  </div>
                  <div class="text-center">
                    <!--v-model="courseUnitForm.Course" <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                  </div>
                </div>
              </div>
            </el-dialog>
          </div>

        </div>
      </section>
    </article>
  </div>
</template>


<script>
  import { validationMixin } from "vuelidate";
  import { ScaleOut } from "vue-loading-spinner";
  import BreadCrumbComponent from "../../components/bread-crumb-component";
  // import BreadCrumbComponent from "../components/bread-crumb-component";

export default {

  components: { ScaleOut, BreadCrumbComponent },
  mixins: [validationMixin],

  data() {
    return {
      addCourseUnitModal: false,
      editCourseUnitModal: false,

      years: "",
      year_numbers: [],

      semesters: "",
      periods: [],

      loading: false,
      loadingError: false,
      submiting: false,
      submiting_edit: false,
      deleting: false,
      responseData: "",
      course: "",
      courses: [],
      cId: this.$route.params.cId, //this is the department id from the browser
      courseName: this.$route.params.title,
      titles: [
        { prop: "department_name", label: "Department Name" },
        { prop: "createdBy", label: "Createdby" },
      ],

      tableProps: {
        border: true,
        stripe: true,
      },

      filters: [
        {
          prop: "title",
          value: "",
        },
      ],

      course_units: [],

      id_edit: "",
            courseUnitForm: {
              title: "",
              course_unit_code: "",
              year_of_study: "",
              semester_of_study: "",
              course_id: this.$route.params.cId
            },

            rules: {
                title: [
                    { required: true, message: 'Please input Title', trigger: 'blur' }
                ],
                course_unit_code: [
                    { required: true, message: 'Please input Module Code', trigger: 'blur' }
                ],
                year_of_study: [
                    { required: true, message: 'year of study is requred', trigger: 'change' }
                ],
                semester_of_study: [
                    { required: true, message: 'Semester is required', trigger: 'change' }
                ]
            },

            faculties_cUnits_crud: ['AR', 'VC', 'SUPER_ADMIN'],

    };
  },

  mounted() {
    this.getCourseUnits();
    this.getOnecourse();
  },
  methods: {

      // Iterate through each element in the
      // first array and if some of them 
      // include the elements in the second
      // array then return true.
      findCommonElements3(arr1, arr2) {
        return arr1.some(item => arr2.includes(item))
      },

      handleClose(done) {
          done();
      },

      showAddCourseUnitModal() {
        this.addCourseUnitModal = true;  
        this.courseUnitForm.title = "";
        this.courseUnitForm.course_unit_code = "";
        this.courseUnitForm.year_of_study = "";
        this.courseUnitForm.semester_of_study = "";
        this.courseUnitForm.course_id = this.$route.params.cId;

      },

    
    async addCourseUnit(formName) {
                this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.submiting = true;
                    this.$http.post(`course-units/add`, this.courseUnitForm ).then(
                    (response) => {
                        if(response.data.success && response.data.message == "COURSE_UNIT_CREATED_SUCCESSFULLY"){
                          this.$refs[formName].resetFields();
                          this.submiting = false;
                          this.getCourseUnits();
                          this.showSuccessMessage("Success", "Course added successfully");
                          this.addCourseUnitModal = false;
                        } else if(response.data.message == "TITLE_MUST_BE_UNIQUE"){
                          this.submiting = false;
                          this.showWarningMessage("Duplicate Entry","Course Title aleady in use");
                        } else if(response.data.message == "COURSE_UNIT_CODE_MUST_BE_UNIQUE"){

                          this.submiting = false;
                          this.showWarningMessage("Duplicate Entry","Module Code aleady in use");
                          
                        } else if (response.data.message == "TITLE_PATTERN_VALIDATION_FAILED") {

                          this.submiting_edit = false;
                          this.showWarningMessage("Unaccepted characters","The title includes unaccepted characters");

                        } else if (response.data.message == "COURSE_CODE_PATTERN_VALIDATION_FAILED") {

                          this.submiting_edit = false;
                          this.showWarningMessage("Unaccepted characters","The code includes unaccepted characters");

                        }
                    },
                    error => { // eslint-disable-line no-unused-vars
                        this.submiting = false;
                        if (error.data == "Network Error") {
                            return this.showFailedMessage("Connection failed","A network error occured, please try again.");
                        }
                        this.showFailedMessage("Unable to add Module","An unexpected Error occured, please try again");
                        
                    });

                } else {
                    return false;
                }
                });
    },

    async getCourseUnits() {
      try {
        this.loading = true;
        this.loadingError = false;
        let request = await this.$http.get(`course-units/fetch-by-course/${this.cId}`);
        if ( request.data.success && request.data.message == "COURSE_UNITS_FETCHED_SUCCESSFULLY" ) { 
          this.course_units = request.data.course_units;
        } 
        else { throw "ERROR OCCURED"; }
      } catch (error) {
        if (error.data == "Network Error") {
          this.loadingError = true;
          return this.showFailedMessage("Connection failed","Unable to load Modules");
        }
        this.showFailedMessage("error","Unable to load Modules, please try again");
      } finally {
        this.loading = false;
      }
    },

    getOnecourse() {
      this.$http.get(`courses/fetch-one/${this.cId}`).then((response) => {
        this.course = response.data.course;
        this.courseName = response.data.course.title;
        this.years = this.course.duration_of_study;
        this.year_numbers = new Array(this.years);
        this.semesters = this.course.maximum_number_of_study_period_per_year;
        this.periods = new Array(this.semesters); 
      });
    },

    courseUnitEditData(id, title, course_unit_code, year_of_study, semester_of_study) {
      this.editCourseUnitModal = true;
      this.id_edit = id;
      this.title_edit = title;
      this.course_unit_code_edit = course_unit_code;
      this.year_of_study_edit = year_of_study;
      this.semester_of_study_edit = semester_of_study;

      this.courseUnitForm.title = title;
      this.courseUnitForm.course_unit_code = course_unit_code;
      this.courseUnitForm.year_of_study = year_of_study;
      this.courseUnitForm.semester_of_study = semester_of_study;
    },

    editCourseUnit_final(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submiting_edit = true;
          this.$http.patch(`course-units/update-one/${this.id_edit}`, this.courseUnitForm ).then(
          (response) => {
            if(response.data.success && response.data.message == "COURSE_UNIT_EDITED_SUCCESSFULLY"){
              this.$refs[formName].resetFields();
              this.submiting_edit = false;
              this.getCourseUnits();
              this.showSuccessMessage("Success", "Course edited successfully");
              this.editCourseUnitModal = false;
            }
            else if(response.data.message == "TITLE_MUST_BE_UNIQUE"){
              this.submiting_edit = false;
              this.showWarningMessage("Warning","Course Title aleady in use");
            }
            else if(response.data.message == "COURSE_UNIT_CODE_MUST_BE_UNIQUE"){
              this.submiting_edit = false;
              this.showWarningMessage("Warning","Course Code aleady in use");
            }
            else if(response.data.message == "COURSE_UNIT_NOT_FOUND"){
              this.submiting_edit = false;
              this.$refs[formName].resetFields();
              this.getCourses();
              this.editCourseUnitModal = false;
              this.showWarningMessage("Warning","Course you are trying to edit might have been deleted");
            } 
            else if (response.data.message == "NOTHING_TO_CHANGE_EVERY_THING_IS_UP_TO_DATE") {

              this.showWarningMessage("Already upto date","There is nothing to change.");

            }
                         else if (response.data.message == "TITLE_PATTERN_VALIDATION_FAILED") {

                          this.submiting_edit = false;
                          this.showWarningMessage("Unaccepted characters","The title includes unaccepted characters");

                        } else if (response.data.message == "COURSE_CODE_PATTERN_VALIDATION_FAILED") {

                          this.submiting_edit = false;
                          this.showWarningMessage("Unaccepted characters","The code includes unaccepted characters");

                        }
          },
          error => { // eslint-disable-line no-unused-vars
            this.submiting_edit = false;
            if (error.data == "Network Error") {
                return this.showFailedMessage("Connection failed","A network error occured, please try again.");
            }
            this.showFailedMessage("Unable to edit Module","An unexpected Error occured, please try again");
            
          });

        } else {
          return false;
        }
      });
    },

    deleteCourseUnit(id) {
      return; // eslint-disable-line no-unreachable
      this.$confirm('This will permanently delete this Module. Do you want to Continue?', 'Confirm delete', { // eslint-disable-line no-unreachable
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.deleting = true;
        this.$http.delete(`course-units/delete-one/${id}`).then((response) => {
          if (response.data.success  && response.data.message == "COURSE_UNIT_DELETED_SUCCESSFULLY") {
            this.getCourseUnits();
            this.showSuccessMessage("Success","Module deleted successfully!");
            this.deleting = false;
          }
          else {
            this.showWarningMessage("Warning", "Unable to delete Module");
            this.deleting = false;
          }
        }, 
        error => { // eslint-disable-line no-unused-vars
          this.showFailedMessage("Error", "Unable to delete Module");
          this.deleting = false;
          this.loading = false;
        });
      }).catch(() => {
        
      });
    },
    
    goToCourseUnitResources(cuId) {
      let course_unit_title = this.course_units.find((course_unit) => {
        return course_unit.course_unit_id == cuId
      }).title;
        
      this.$router.push({name: 'CourseUnitResources', params: {
        cuId,
        course_unit_title
      }});
    },

  },
};
</script>

<style scoped>

  input[type=text], select, textarea {
    width: 100%;
    padding: 10px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    background-color: #FFFFFF;
    font-size: .9em;
  }

  .search_add_section {
    display: flex; align-items: center; justify-content: space-between;
  }

  .search_by_input {
    width: 400px !important;
  }

  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
  }

  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    
  }

  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
      .search_add_section {
        display: block;
      }
      .addbtn {
        width: 100%;
        margin-top: 10px;
      }
      .search_by_input {
        width: 100% !important;
      }
  }

  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
      .search_add_section {
        display: block;
      }
      .addbtn {
        width: 100%;
        margin-top: 10px;
      }
      .search_by_input {
        width: 100% !important;
      }
  }
</style>

<style>

  th > .cell {
    color: rgba(0, 0, 0, 0.836) !important;
    font-weight: 600 !important;
    font-size: 13px !important;
  }
  el-table th > .cell {
    color: black !important;
    font-weight: 500;
  }
  .el-table .cell {
    font-size: 0.9em;
    line-height: 23px;
    font-weight: 300;
  }
  .course_request_form .el-input input {
    font-size: 0.8rem !important;
    font-weight: 400 !important;
    color: #495057 !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  }

  .course_request_form .el-input input:focus {
    color: #495057 !important;
    background-color: #fff !important;
    border-color: #80bdff !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
  }

  .courseUnits_dialogs .el-dialog {
    width: 60%;
  }

  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
    .courseUnits_dialogs .el-dialog {
      width: 60%;
    }
  }

  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .courseUnits_dialogs .el-dialog {
      width: 70%;
    }
  }

  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .courseUnits_dialogs .el-dialog {
      width: 90%;
    }
  }

  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .courseUnits_dialogs .el-dialog {
      width: 90%;
    }

  }

</style>
