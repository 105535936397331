<template>
    <section>
        <div class="todaySection">   
            <div style="display: flex; align-items: center; justify-content: space-between;">
                <div><small style="text-align: left; font-weight:600; color: var(--el-app-primary);">Upcoming Lectures</small></div>
            </div>
            <div v-if="our_upcoming_lectures.length == 0" class="text-left mt-2" style="font-size: 14px; font-weight: 400">
              There are no upcoming Lectures
            </div>
            <div v-else-if="our_upcoming_lectures.length != 0">
                <div v-for="lectures in our_upcoming_lectures" :key="lectures.live_lecture_id" style="cursor: pointer; margin-top:10px; padding:10px; background-color: #eeeeee; border-radius:3px;">
                    <div style="display:flex; align-items: center; justify-content:space-between;">
                        <div style="text-align: left;">
                            <small><strong>{{lectures.course_unit.course_unit_code}}</strong></small><br/>
                            <small>{{lectures.title}}</small>
                        </div>
                        <div style="text-align: left;">
                            
                            <div v-if="lectures.date == formatDateToString()">
                                <small>{{lectures.start_time.toLowerCase()}} - {{lectures.end_time.toLowerCase()}}</small>
                            </div>
                            <div v-else>
                                <small>{{lectures.date}}</small>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>
</template>

<script>
    export default {
        
        data() {
            return {
                
            }
        },
        props: {
            our_upcoming_lectures : {
                type: Array,
                required: true
            }
        },
        methods: {
            currentDate() {
                const current = new Date();
                
                const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;
                return date;
            },

            formatDateToString() {

                const current = new Date();
                const dd = (current.getDate() < 10 ? '0' : '')
                        + current.getDate();
                          
                const MM = ((current.getMonth() + 1) < 10 ? '0' : '')
                        + (current.getMonth() + 1);
                const yyyy = current.getFullYear()
                          
                return yyyy +"-" + MM +"-"+ dd;
            }

        }
    }
</script>

<style scoped>
    .todaySection {
        padding:20px;
    }
    .bg-white {
        background-color:white;
    }

    /*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
    @media (min-width: 768px) and (max-width: 1024px) {
        
        
        
    }
    
    /*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        
        

    }
    
    /*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
    @media (min-width: 481px) and (max-width: 767px) {
        
        
        
    }
    
    /*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
    @media (min-width: 320px) and (max-width: 480px) {

        .todaySection {
        padding: 10px;
        }
        .bg-white {
            background-color:white;
        }

    }
</style>
