<template>
  <el-dialog
    title="Live Lecture Details"
    :visible.sync="isVisible"
    width="40%"
    :before-close="beforeClose"
  >
    <div v-loading="lectureDetailsLoading || isBusy">
      <div class="container-fluid">
        <div class="row w-100">
          <div class="col-12">
            <span class="pr-3" style="font-weight: 600">Batch: </span>
            <span
              v-if="liveLectureDetails.course_intake_batch"
              style="text-transform: uppercase"
            >
              {{ liveLectureDetails.course_intake_batch.course.course_code }}
              {{ toMonthName(liveLectureDetails.course_intake_batch.month) }}/
              {{ liveLectureDetails.course_intake_batch.year }}</span
            >
          </div>
          <div class="col-12 pt-2">
            <span class="pr-3" style="font-weight: 600">Students: </span>
            {{ liveLectureDetails.total_students }}
          </div>
          <div class="col-12 pt-2">
            <span class="pr-3" style="font-weight: 600">Course: </span>
            {{
              liveLectureDetails.course_intake_batch
                ? liveLectureDetails.course_intake_batch.course.title
                : "..."
            }}
          </div>
          <div class="col-12 pt-2">
            <span class="pr-3" style="font-weight: 600">Module: </span>
            <span v-if="liveLectureDetails.course_unit">
              {{ liveLectureDetails.course_unit.title }} ({{
                liveLectureDetails.course_unit.course_unit_code
              }})</span
            >
          </div>
          <div class="col-12 pt-2">
            <span class="pr-3" style="font-weight: 600">Title: </span>
            {{ liveLectureDetails ? liveLectureDetails.title : "..." }}
          </div>
          <div class="col-12 pt-2">
            <span class="pr-3" style="font-weight: 600">Time: </span>
            <span v-if="liveLectureDetails"
              >{{
                liveLectureDetails.date
                  ? formattedDate(liveLectureDetails.date)
                  : ""
              }}
              at {{ liveLectureDetails.start_time }} to
              {{ liveLectureDetails.end_time }}</span
            >
          </div>
          <div class="col-12 pt-2">
            <span class="pr-3" style="font-weight: 600">Status: </span>
            <span
              v-if="
                liveLectureDetails.lecture_status === 'IN_THE_PAST' ||
                liveLectureDetails.lecture_status === 'TODAY_AND_ENDED'
              "
            >
              <span v-if="liveLectureDetails.class_status">
                <span
                  v-if="liveLectureDetails.class_status === 'Attended'"
                  class="badge badge-success py-1 px-2"
                  >Attended</span
                >
                <span
                  v-if="liveLectureDetails.class_status === 'Missed'"
                  class="badge badge-danger py-1 px-2"
                  >Missed</span
                >
              </span>
              <span v-else> N/A</span>
            </span>
            <span
              v-else-if="liveLectureDetails.lecture_status === 'TODAY_AND_LIVE'"
            >
              Live / Ongoing</span
            >
            <span
              v-else-if="
                liveLectureDetails.lecture_status === 'TODAY_AND_UPCOMING' ||
                liveLectureDetails.lecture_status === 'UPCOMING'
              "
            >
              Upcoming</span
            >
          </div>

          <div class="col-12 pt-2" v-if="liveLectureDetails.class_status === 'Attended'">
            <span class="pr-3" style="font-weight: 600">Lecturer Check-in Time: </span>
            <span v-if="liveLectureDetails"
              >{{liveLectureDetails.time_in}}</span
            >
          </div>

            <div class="col-12 pt-2" v-if="liveLectureDetails.class_status === 'Attended'">
            <span class="pr-3" style="font-weight: 600">Lecturer Duration Attended: </span>
            <span v-if="liveLectureDetails"
              >{{liveLectureDetails.duration_attended}} ({{liveLectureDetails.percentage_attended}})</span
            >
          </div>

          <div class="col-12 pt-2">
            <hr />
            <span class="pr-3" style="font-weight: 600">Lecturer: </span>
            <span v-if="liveLectureDetails.lecturer">
              {{ liveLectureDetails.lecturer.first_name }}
              {{ liveLectureDetails.lecturer.last_name }}</span
            >
          </div>
          <div class="col-12 pt-2">
            <span class="pr-3" style="font-weight: 600">Scheduled By: </span>
            <span v-if="liveLectureDetails.staff_creator">
              {{ liveLectureDetails.staff_creator.first_name }}
              {{ liveLectureDetails.staff_creator.last_name }}</span
            >
          </div>
          <div class="col-12 pt-2">
            <span class="pr-3" style="font-weight: 600">Scheduled On: </span>
            <span v-if="liveLectureDetails.createdAt">{{
              formattedDateWithTime(liveLectureDetails.createdAt)
            }}</span>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">Cancel</el-button>
      <el-button type="primary" @click="dialogVisible = false"
        >Confirm</el-button
      > -->
        <div class="col-12 pl-2 mt-1 text-right">
          <button
            type="button"
            class="btn z-depth-0"
            v-ripple="'rgba(255, 255, 255, 0.35)'"
            @click="onFetchAttendance"
            :disabled="
              !liveLectureDetails.class_status ||
              liveLectureDetails.class_status == 'Missed'
            "
            style="
              padding-left: 20px;
              padding-right: 20px;
              text-transform: capitalize;
              height: 40px;
              border-radius: 4px;
              background-color: var(--el-app-primary);
            "
          >
            <span style="position: relative; bottom: 4px"
              >View Students Attendance
            </span>
          </button>
          <button
            type="button"
            class="btn z-depth-0"
            disabled
            @click="onCancelLecture"
            v-ripple="'rgba(255, 255, 255, 0.35)'"
            style="
              width: auto;
              padding-left: 20px;
              padding-right: 20px;
              text-transform: capitalize;
              height: 40px;
              border-radius: 4px;
              background-color: #ff8800;
            "
          >
            <span style="position: relative; bottom: 4px">Cancel Lecture </span>
          </button>
        </div>
      </span>
    </div>
  </el-dialog>
</template>

<script>
import { format, parseISO } from "date-fns";
import getMonthName from "../../helpers/getMonthName";
export default {
  data() {
    return {
      isBusy: false,
    };
  },
  computed: {
    formattedDateWithTime() {
      return (dateString) =>
        format(parseISO(dateString), "do MMMM yyyy ' at ' hh:mma");
    },
    formattedDate() {
      return (dateString) => format(parseISO(dateString), "do MMMM yyyy");
    },
    toMonthName() {
      return (monthNum) => {
        return getMonthName(monthNum);
      };
    },
  },

  methods: {
    async onCancelLecture() {
      return;
      // eslint-disable-next-line no-unreachable
      try {
        let logOut = await this.$confirm(
          "This Lecture will be immediately removed and unavailable for attending. Do you want to cancel it?",
          "Cancel Lecture",
          {
            confirmButtonText: "Yes Proceed",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        );

        if (logOut) {
          this.$emit(
            "delete-live-lecture",
            this.liveLectureDetails.live_lecture_id
          );
        }
      } catch {
        //no-empty
      }
    },

    async onFetchAttendance() {
      this.isBusy = true;
      try {
        let request = await this.$http.get(
          `live-lecture/fetch-class-report/${this.liveLectureDetails.live_lecture_id}`
        );
        if (
          request.data.success &&
          request.data.message ===
            "STUDENT_CLASS_ATTENDANCE_RETRIEVED_SUCCESSFULLY"
        ) {
          this.$router.push({
            name: "LectureAttendance",
            params: { attendance: request.data.attendance },
          });
        } else throw "UNEXPECTED_RESPONSE";
      } catch (error) {
        this.showFailedMessage(
          "Fetch Failed",
          "Unable to fetch Lecture attendance right now, Please try again"
        );
      } finally {
        this.isBusy = false;
      }
    },
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean,
    },
    beforeClose: {
      required: true,
      type: Function,
    },
    lectureDetailsLoading: {
      required: true,
      type: Boolean,
    },
    liveLectureDetails: {
      required: true,
      type: Object,
    },
  },
};
</script>

<style>
</style>