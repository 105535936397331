<template>
    <div class="container-fluid px-0 h-100" style="text-align: left;">
        <article>
            <section style="padding-top: 50px;">
                <div class="container">

                    <div class="row w-100 mb-4">
                        <div class="col-12 pl-3 px-0 mb-0 text-left">
                        <BreadCrumbComponent :pageTitles="['Resources']" />
                        </div>
                    </div>

                    <div class="row w-100">
                        <div class="col-12 text-left">
                            <div style="font-size: 20px; font-weight: 400; color: var(--el-app-primary); margin-bottom: 15px; ">
                                <strong>Resource contents</strong><br>
                            </div>
                        </div>
                    </div>

                    <div class="search_add_section">
                        <div class="" style="display: flex;">
                            <input type="text" v-model.trim="filters[0].value" placeholder="Search by Resource Title"  class="search_by_input" spellcheck="off"/>
                        
                            <div style="margin-left: 20px;">
                                <el-select v-model="selectedFilter" placeholder="Select" class="d-block" style="height: 40px !important;">
                                    <el-option v-for="item in filterOptions"
                                        :key="item"
                                        :label="item"
                                        :value="item">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                    <hr/>

                    <div v-loading="deleting" class="table-responsive">
                        <data-tables :data="resourcesData" :table-props="tableProps" :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }" type="expand" :filters="filters">
                            
                            <div slot="empty">
                                <ScaleOut v-if="loading && !loadingError" class="mt-3" :background="'#164B70'"/>
                                <div @click="getAllResources" v-else-if="!loading && loadingError" style="cursor: pointer">
                                Unable to Load Resources Now. Please click here to retry
                                </div>
                                <div v-if="!loading && !loadingError">No Resources</div>
                            </div>

                            <el-table-column label="#" :show-overflow-tooltip="true" label-class-name="text-left" width="30px">
                                <template slot-scope="scope">
                                    <span>{{ scope.$index + 1 }}</span>
                                </template>
                            </el-table-column>

                            <el-table-column label="Resource Title" :show-overflow-tooltip="true" label-class-name="text-left" width="auto">
                                <template slot-scope="scope">
                                    <div style="display:flex; align-items: center;">
                                        <div  style="width:40px; height:40px; border-radius: 50%;">
                                            
                                            <div v-if="scope.row.file_type == 'mp4'" style="display: flex; align-items:center; justify-content: center; background-color: var(--vu-red); color: white; width:40px; height:40px; object-fit:cover; border-radius: 100%;">
                                                <span class="fas fa-video" style="font-size: 20px;"></span>
                                            </div>
                                            <div v-else style="display: flex; align-items:center; justify-content: center; color: white; width:40px; height:40px; object-fit:cover; border-radius: 100%;">
                                                <img :src="require(`@/assets/img/${documentImage(scope.row.path_url)}`)"
                                                alt="" style="height: 40px; width: 40px;"/>
                                            </div>
                                        </div>
                                        <div style="margin-left: 10px;">
                                            <span>{{ scope.row.title }}</span><br/>
                                            <!-- <span showing years on edit style="font-weight: 600;">Email: </span><span>{{ scope.row.email }}</span> -->
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>

                            <el-table-column label="Module">
                                <template slot-scope="scope">
                                    <strong style="font-weight: 500">
                                        {{ scope.row.course_unit.title }}
                                    </strong>
                                </template>
                            </el-table-column>

                            <el-table-column label="Uploaded By" :show-overflow-tooltip="true" label-class-name="text-left" width="auto">
                                <template slot-scope="scope">
                                    <span>
                                        {{ scope.row.creator.first_name }}
                                        {{ scope.row.creator.last_name }}
                                    </span>
                                </template>
                            </el-table-column>

                            <el-table-column label="Upload Date">
                                <template slot-scope="scope">
                                    <span>{{ formattedDate(scope.row.createdAt) }}</span>
                                </template>
                            </el-table-column>

                            <el-table-column label="Status" label-class-name="text-center" 
                                class-name="text-center">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.is_approved == 'APPROVED'" style="color: green; font-size: 20px; font-weight: 500;" > <i class="el-icon-check"></i> </span>
                                    <span v-if="scope.row.is_approved == 'REJECTED'" style="color: red; font-size: 20px; font-weight: 500;" > <i class="el-icon-close"></i> </span>
                                    <span v-if="scope.row.is_approved == 'PENDING'" style="color: orange; font-size: 20px; font-weight: 500;" > <i class="el-icon-alarm-clock"></i> </span>
                                </template>
                            </el-table-column>

                            <el-table-column label="Actions" label-class-name="text-center" 
                                class-name="text-center" style="display: none;">
                                <template slot-scope="scope">
                                    <el-row style="display: flex; justify-content:center;">
                                        <el-button type="primary"
                                        @click="viewContent(scope.row)" round>View Content</el-button>
                                    </el-row>
                                    <!--- :disabled="!findCommonElements3(resource_content, $store.state.theRole)"--->
                                </template>
                            </el-table-column>
                                        
                        </data-tables>
                    </div>
                </div>
            </section>
        </article>
        
        <article>
            <el-dialog  title="Resource Content" :visible.sync="dialogTableVisible">
                <div v-loading="loading">
                    <div>
                        <div style="display:flex; align-items: center;">
                            <div v-if="resourceData.path_url"  style="width:40px; height:40px; border-radius: 50%;">
                                                
                                <div v-if="resourceData.file_type == 'mp4'" style="display: flex; align-items:center; justify-content: center; background-color: var(--vu-red); color: white; width:40px; height:40px; object-fit:cover; border-radius: 100%;">
                                    <span class="fas fa-video" style="font-size: 20px;"></span>
                                </div>
                                <div v-else style="display: flex; align-items:center; justify-content: center; color: white; width:40px; height:40px; object-fit:cover; border-radius: 100%;">
                                    <img :src="require(`@/assets/img/${documentImage(resourceData.path_url)}`)"
                                    alt="" style="height: 40px; width: 40px;"/>
                                </div>

                            </div>
                            <div>
                                <strong style="font-weight: 500; margin-left: 10px;"> Title</strong>: {{ resourceData.title }}
                            </div>
                        </div>
                        <p v-if="resourceData.course_unit">
                            <strong style="font-weight: 500;">Module</strong>: {{ resourceData.course_unit.title }}
                        </p>
                        <p v-if="resourceData.createdAt">
                            <strong style="font-weight: 500;">Uploaded On</strong>: {{ formattedDate(resourceData.createdAt) }}
                        </p>
                        <p v-if="resourceData.creator">
                            <strong style="font-weight: 500;">Uploaded By</strong>: {{ resourceData.creator.first_name }} 
                            {{ resourceData.creator.last_name }}
                        </p>
                        <p v-if="resourceData.maturity_date">
                            <strong style="font-weight: 500;">Publish date</strong>: {{ formattedDate(resourceData.maturity_date) }}
                        </p>
                        <p>
                            <strong style="font-weight: 500;">Status</strong>:
                            <span v-if="resourceData.is_approved == 'APPROVED'" style="color: green; font-weight: 500;" > <i class="el-icon-check"></i> Approved </span>
                            <span v-if="resourceData.is_approved == 'REJECTED'" style="color: red; font-weight: 500;" > <i class="el-icon-close"></i> Rejected </span>
                            <span v-if="resourceData.is_approved == 'PENDING'" style="color: orange; font-weight: 500;" > <i class="el-icon-alarm-clock"></i> Pending </span>
                        </p>
                    </div>
                    <hr/>
                    <div style="display: flex; justify-content: flex-end;">
                        <div>
                            <el-button type="primary" @click="viewResource(resourceData.resource_id)" round><i class="el-icon-view"></i> View</el-button>
                            <el-button type="success" @click="approveResource(resourceData.resource_id)" round><i class="el-icon-check"></i> Approve</el-button>
                            <el-button type="danger" @click="rejectResource(resourceData.resource_id)" round><i class="el-icon-close"></i> Reject</el-button>
                        </div>
                    </div>
                </div>
            </el-dialog>
        </article>
    </div>
</template>

<script>

    import { ScaleOut } from "vue-loading-spinner";
    import BreadCrumbComponent from "../components/bread-crumb-component";
    import { format, parseISO } from "date-fns";
    // const fileSize = require("filesize");

    export default {

        components: { ScaleOut, BreadCrumbComponent },

        data() {
            return {
                dialogTableVisible: false,
                loading: false,
                loadingError: false,
                submiting: false,
                deleting: false,
                resources: [],
                resourceData: {},
                resource_content: ['QA'],

                tableProps: {
                    border: true,
                    stripe: true,
                },

                filters: [
                    {
                    prop: "title",
                    value: "",
                    },
                ],
                is_active: false,

                selectedFilter: "Show All",
                filterOptions: [
                    "Show All",
                    "Show Approved",
                    "Show Rejected",
                    "Show Pending",
                    "Show Notes",
                    "Show Videos",
                    "Show Course works",
                    "Show Past Papers",
                    "Show PDF",
                    "Show word Documents",
                    "Show Power Point"
                ],
            }
        },

        computed: {

            resourcesData() {
                return this.resources.filter((resource) => {
                    if (this.selectedFilter === "Show Approved")
                    return resource.is_approved === "APPROVED";

                    if (this.selectedFilter === "Show Rejected")
                    return resource.is_approved === "REJECTED";

                    if (this.selectedFilter === "Show Pending")
                    return resource.is_approved === "PENDING";

                    if (this.selectedFilter === "Show Notes")
                    return resource.resource_type === "notes";

                    if (this.selectedFilter === "Show Videos")
                    return resource.resource_type === "videos";

                    if (this.selectedFilter === "Show Course works")
                    return resource.resource_type === "course works";

                    if (this.selectedFilter === "Show Past Papers")
                    return resource.resource_type === "past papers";

                    if (this.selectedFilter === "Show PDF")
                    return resource.file_type === "pdf";

                    if (this.selectedFilter === "Show word Documents")
                    return resource.file_type === "docx";

                    if (this.selectedFilter === "Show Power Point")
                    return resource.file_type === "pptx";

                    return true;
                });
            },

            documentImage() {
                return (filePath) => {
                    let last_dot_index = filePath.lastIndexOf(".");
                    const fileType = filePath.substr(last_dot_index + 1);

                    if (fileType == "pdf" || fileType == "wps-office.pdf") return "pdf.png";
                    if (fileType == "xls" || fileType == "xlsx") return "excel.png";
                    if (fileType == "ppt" || fileType == "pptx") return "ppt.png";
                    return "word.png";
                };
            },

            // toFileSize() {
            //     return (size) => {
            //         return fileSize(size);
            //     };
            // },
        },

        mounted() {
            this.getAllResources();
        },

        methods: {

            findCommonElements3(arr1, arr2) {
                return arr1.some(item => arr2.includes(item))
            },

            formattedDate(dateIso) {
                return format(parseISO(dateIso), "do MMM yyyy");
            },

            async getAllResources() {
                try {
                    this.loading = true;
                    this.loadingError = false;
                    let request = await this.$http.get(`resources/fetch-many-for-qa`);
                    if ( request.data.success && request.data.message == "RESOURCES RETRIEVED SUCCESSFULLY" ) { 
                    this.resources = request.data.resources;
                } 
                else { throw "ERROR OCCURED"; }
                } catch (error) {
                    if (error.data == "Network Error") {

                    this.loadingError = true;
                    return this.showFailedMessage("Connection failed","A network error occured, please try again.");
                    
                    }
                    this.loadingError = true;
                    this.showFailedMessage("Unable to load Resources","An unexpected Error occured, please try again");
                } finally {
                    this.loading = false;
                }
            },

            viewContent(rData) {
                this.resourceData = rData;
                this.dialogTableVisible = true
            },

            viewResource(resource_id) {

                this.$router.push( { path: `${resource_id}/resource-content-details` });

            },

            async approveResource(resource_id) {

                this.$confirm('Once you approve, this content will be available to the students and Staff. Do you want to Continue?', 'Confirm delete', { // eslint-disable-line no-unreachable
                confirmButtonText: 'Approve',
                cancelButtonText: 'Cancel',
                type: 'success'
                }).then(() => {
                    this.loading = true;
                    this.$http.patch(`resources/approve/${resource_id}`).then((response) => {
                        if (response.data.success == true) {
                        this.getAllResources();
                        this.showSuccessMessage("Success","Content Approved!");
                        this.loading = false;
                        this.dialogTableVisible = false;
                        }
                    }, 
                    error => { // eslint-disable-line no-unused-vars
                        this.showFailedMessage("Error","Unable to approve content");
                        this.loading = false;
                    });
                }).catch(() => {

                });

            },

            async rejectResource(resource_id) {

                this.$confirm('Once you rejected, this content will not be available to the students and Staff. Do you want to Continue?', 'Confirm delete', { // eslint-disable-line no-unreachable
                confirmButtonText: 'Reject',
                cancelButtonText: 'Cancel',
                type: 'warning'
                }).then(() => {
                    this.loading = true;
                    this.$http.patch(`resources/reject/${resource_id}`).then((response) => {
                        if (response.data.success == true) {
                        this.getAllResources();
                        this.showSuccessMessage("Success","Content Rejected!");
                        this.loading = false;
                        this.dialogTableVisible = false;
                        }
                    }, 
                    error => { // eslint-disable-line no-unused-vars
                        this.showFailedMessage("Error","Unable to reject content");
                        this.loading = false;
                    });
                }).catch(() => {

                });

            }
        }
    }

</script>

<style scoped>
  * {
    box-sizing: border-box;
  }
  
  input[type=text], select, textarea {
    width: 100%;
    padding: 9px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    background-color: #FFFFFF;
    font-size: .9em;
  }

  .search_add_section {
    display: flex; align-items: center; justify-content: space-between;
  }

  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
  }

  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  }

  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .search_add_section {
      display: block; 
    }
    .addbtn {
      width: 100%;
      margin-top: 10px;
    }
      .search_by_input {
        width: 100% !important;
      }
  }

  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .search_add_section {
      display: block;
    }
    .addbtn {
      width: 100%;
      margin-top: 10px;
    }
      .search_by_input {
        width: 100% !important;
      }
  }
</style>

<style>
  
  .students_dialogs .el-dialog {
    width: 60%;
  }

  .search_by_input {
    width: 400px !important;
  }

  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
    .students_dialogs .el-dialog {
      width: 60%;
    }
  }

  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .students_dialogs .el-dialog {
      width: 70%;
    }
  }

  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .students_dialogs .el-dialog {
      width: 90%;
    }
  }

  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .students_dialogs .el-dialog {
      width: 90%;
    }
  }
</style>