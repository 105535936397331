<template>
  <div class="container-fluid">
    <div class="row w-100">
      <div
        v-if="resources.length === 0"
        class="col-12"
        style="margin-top: 80px"
      >
        <img
          src="../../assets/img/no-content.png"
          alt=""
          style="height: 100px"
        />
        <div
          class="pt-3"
          style="font-weight: 500; font-size: 0.9em; color: rgba(0, 0, 0, 0.45)"
        >
          There are no {{ title }} to display
        </div>
      </div>
      <div
        v-for="resource in resources"
        :key="resource.id"
        @click="$emit('on-select-resource', resource)"
        class="col-md-4"
        style="padding-bottom: 30px"
      >
        <div
          class="content-card text-left"
          v-loading="isLoadingID == resource.resource_id"
        >
          <div class="col-12">
            <div
              style="font-size: 1em; margin-right: 45px; font-weight: 600"
              class="text-left text-truncate"
            >
              {{ resource.title }}
            </div>
            <i
              class="fas fa-video"
              style="
                position: absolute;
                right: 5px;
                top: 0px;
                color: red;
                font-size: 29px;
              "
            ></i>
          </div>
          <div class="col-12 pt-3" style="font-size: 0.8em">
            Uploaded on:
            <strong style="font-weight: 500">
              {{ formattedDate(resource.createdAt) }}</strong
            >
          </div>
          <div class="col-12 pt-2 text-truncate" style="font-size: 0.8em">
            Uploaded by:
            <strong style="font-weight: 500"
              >{{ resource.creator.first_name }}
              {{ resource.creator.last_name }}</strong
            >
          </div>
          <div class="col-12 pt-3" style="font-size: 0.8em">
            File Size:
            <strong style="font-weight: 500">
              {{ toFileSize(resource.file_size) }}</strong
            >
          </div>
          <div class="col-12 pt-2 text-right mt-2" style="font-size: 0.8em">
            <i
              @click.stop="onDeleteResource(resource.resource_id)"
              class="el-icon-delete"
              style="
                font-size: 20px;
                color: rgba(0, 0, 0, 0.25);
                position: absolute;
                right: 11px;
                bottom: 16px;
              "
            ></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format, parseISO } from "date-fns";
// import fileSize from "filesize";
const fileSize = require("filesize");
export default {
  data() {
    return {
      isLoadingID: "",
    };
  },
  computed: {
    toFileSize() {
      return (size) => fileSize(size);
    },
  },
  props: {
    resources: {
      required: true,
      type: Array,
    },
    title: {
      required: true,
      type: String,
    },
  },

  methods: {
    formattedDate(dateIso) {
      return format(parseISO(dateIso), "do MMM yyyy");
    },
    async onDeleteResource(resourceID) {
      try {
        let logOut = await this.$confirm(
          "This File will be permanently deleted, are you sure it's what you want to do?",
          "Delete File",
          {
            confirmButtonText: "Yes Delete",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        );

        if (logOut) {
          this.isLoadingID = resourceID;

          try {
            let request = await this.$http.delete(`resources/${resourceID}`);
            if (
              request.data.success &&
              request.data.message == "DELETED_SUCCESSFULLY"
            ) {
              this.showSuccessMessage(
                "File Deleted",
                "The Selected File was successfuly Deleted."
              );

              //Tell the Parent element that an item was deleted such that we just remove it from the list instead of fetching again
              this.$emit("resource-deleted", resourceID);
            } else throw "UNEXPECTED_RESPONSE";
          } catch (error) {
            console.log(error);
            this.showFailedMessage(
              "Delete Failed",
              "Unable to Delete Selected File. Please try again"
            );
          } finally {
            this.isLoadingID = "";
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.content-card {
  border: 1px solid #bebebea1;
  background-color: #faffff63;
  border-radius: 8px;
  padding: 10px 10px;
  cursor: pointer;
  height: 145px;
}

.el-icon-delete {
  cursor: pointer;
  transition: color 1s;
}

.el-icon-delete:hover {
  color: rgba(253, 0, 0, 0.692) !important;
  transition: color 1s;
}
</style>