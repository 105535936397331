<template>
  <section v-loading="loading" class="container">
    <div class="content">
      <div class="">
        <div class="row w-100 mb-4">
          <div class="col-12 pl-3 px-0 mb-0 text-left">
            <!-- <BreadCrumbComponent :pageTitles="['Account Settings']" /> -->
          </div>
        </div>
        <div class="row w-100">
          <div class="col-12 text-left">
            <div style="font-size: 20px; font-weight: 400; color: var(--el-app-primary); margin-bottom: 15px; ">
              <strong>Account Settings</strong>
            </div>
          </div>
        </div>

        <div class="">
          <div class="TheProfileSettings">
            <div class="theProfile">
              <div style="width:100px; height:100px; border-radius: 50%;">
                <img :src="profile_picture" v-if="profile_picture" alt="" class="img-fluid" style="width:100px; height:100px; object-fit:cover; border-radius: 100%;" />
                <div v-if="profile_picture == null" style="display: flex; align-items:center; justify-content: center; background-color: var(--vu-red); color: white; width:100px; height:100px; object-fit:cover; border-radius: 100%;">
                  <strong>{{ getInitials(`${firstName} ${lastName}` ) }}</strong>
                </div>
              </div>
            </div>
            <div class="">
              <div class="theProfileBtns">
                <div>
                <el-button style="width: 150px; background-color: var(--vu-red); color: white;" class="z-depth" v-ripple="'rgba(255, 255, 255, 0.35)'" @click="profilePictureModal = true">Upload</el-button>
                
                <button v-if="!submiting2" type="button" class="btn remove-picture-btn" @click="removePhoto"
                  v-ripple="'rgba(255, 255, 255, 0.35)'"> <span style="position: relative; bottom: 4px">Remove </span>
                </button>
                <ScaleOut v-else :background="'#164B70'"/>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 px-0 pt-2">
            <hr />
          </div>

          <div class="col-12 text-left px-0" style="font-weight: 600">
            Basic Info
          </div>

          <div class="col-12-px-0 pt-2 text-left" style="font-weight: 400; font-size: 0.9em">
            <i class="fas fa-info-circle pr-2" style="color: rgba(0, 0, 0, 0.3)"></i>
            Some user information such as email, name can not be updated. If you wish to update them,
            <strong style="font-weight: 600; color: var(--el-app-primary); cursor: pointer;" @click="userRequestsModal = true">please click here.</strong>
          </div>

          <!-- BASIC USER INFO -->

          <div class="row mt-4 text-left">
            <div class="col-md-4" style="font-weight: 500; color: rgba(0, 0, 0, 0.7); font-size: 0.9em;">
              First Name
              <div class="mt-1">
                <input type="text" placeholder="First Name" class="legacy-input bg-white" style="width: 100%; height: 36px !important" v-model="firstName" disabled />
              </div>
            </div>
            <div class="col-md-4" style="font-weight: 500; color: rgba(0, 0, 0, 0.7); font-size: 0.9em;">
              Last Name
              <div class="mt-1">
                <input type="text" placeholder="Last Name" class="legacy-input bg-white" style="width: 100%; height: 36px !important" disabled v-model="lastName"/>
              </div>
            </div>
          </div>

          <div class="row mt-2 text-left">
            <div class="col-md-4" style="font-weight: 500; color: rgba(0, 0, 0, 0.7); font-size: 0.9em;">
              Email address
              <div class="mt-1">
                <input type="email" placeholder="email" class="legacy-input bg-white" style="width: 100%; height: 36px !important" v-model="email" disabled/>
              </div>
            </div>
            <div class="col-md-4 " style="font-weight: 500; color: rgba(0, 0, 0, 0.7); font-size: 0.9em;">
              Phone Number
              <div class="mt-1">
                <input type="text" :class="{ 'input-error': $v.phoneNumber.$error }" @keyup.enter="savePhoneInfo" v-model="$v.phoneNumber.$model" placeholder="Phone Number" class="legacy-input bg-white" style="width: 100%; height: 36px !important" />
                <br/>
                <span v-if="$v.phoneNumber.$error" style="font-size: 0.7em; color: red">{{ phoneNumberError }}</span>
              </div>
            </div>
          </div>

          <div class="row mt-4 text-right">
            <div class="col-md-8">
              <button v-if="!submiting3" :disabled="$v.phoneNumber.$invalid" type="button" @click="savePhoneInfo" class="btn z-depth-0 changeBtn" v-ripple="'rgba(255, 255, 255, 0.35)'" style="">
                <span style="position: relative; bottom: 1px">Change phone number</span>
              </button>
              <ScaleOut v-else :background="'#164B70'"/>
            </div>
          </div>

          <div class="col-12 px-0 pt-2">
            <hr />
          </div>

          <!-- CHANGE PASSWORD SECTION -->
          <div class="col-12 text-left px-0" style="font-weight: 600">
            Change Password
          </div>

          <div class="row mt-2 text-left">
            <div class="col-md-4" style="font-weight: 500; color: rgba(0, 0, 0, 0.7); font-size: 0.9em;">
              Current Password
              <div class="mt-1">
                <div style="position: relative;">
                  <input type="password" id="password" v-model="$v.oldPassword.$model" @keyup.enter="changePassword" :class="{ 'input-error': $v.oldPassword.$error }"  placeholder="Current Password" class="legacy-input bg-white" style="width: 100%; height: 36px !important"/>
                  <br/>
                  <span v-if="$v.oldPassword.$error" style="font-size: 0.7em; color: red">{{ oldPasswordError }}</span>
                  <div style="position: absolute; right: 5px; top:0px; bottom: 0px; height:100%; display:flex; align-items:center;">
                    <span @click="switchVisibility()"><i class="far fa-eye"></i></span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-2 text-left">
            <div class="col-md-4" style="font-weight: 500; color: rgba(0, 0, 0, 0.7); font-size: 0.9em;">
              New Password
              <div class="mt-1">
                <div style="position: relative;">
                  <input type="password" id="password1" v-model="$v.newPassword.$model" @keyup.enter="changePassword" :class="{ 'input-error': $v.newPassword.$error }" placeholder="New Password" class="legacy-input bg-white" style="width: 100%; height: 36px !important"/>
                  <br/>
                  <span v-if="$v.newPassword.$error" style="font-size: 0.7em; color: red">{{ newPasswordError }}</span>
                  <div style="position: absolute; right: 5px; top:0px; bottom: 0px; height:100%; display:flex; align-items:center;">
                    <span @click="switchVisibility1()"><i class="far fa-eye"></i></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4" style="font-weight: 500; color: rgba(0, 0, 0, 0.7); font-size: 0.9em;">
              Confirm Password
              <div class="mt-1">
                <div style="position: relative;">
                  <input type="password" id="password2" v-model="$v.confirmNewPassword.$model" @keyup.enter="changePassword" :class="{ 'input-error': $v.confirmNewPassword.$error }" placeholder="Confirm Password" class="legacy-input bg-white" style="width: 100%; height: 36px !important"/>
                  <br/>
                  <span v-if="$v.confirmNewPassword.$error" style="font-size: 0.7em; color: red">{{ confirmNewPasswordError }}</span>
                  <div style="position: absolute; right: 5px; top:0px; bottom: 0px; height:100%; display:flex; align-items:center;">
                    <span @click="switchVisibility2()"><i class="far fa-eye"></i></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4 text-right">
            <div class="col-md-8">
              <button v-if="!submiting" :disabled="$v.oldPassword.$invalid || $v.newPassword.$invalid || $v.confirmNewPassword.$invalid" type="button" class="btn z-depth-0 changeBtn" v-ripple="'rgba(255, 255, 255, 0.35)'" @click="changePassword"
                style="">
                <span style="position: relative; bottom: 1px">Change Password</span>
              </button>
              <ScaleOut v-else :background="'#164B70'"/>
            </div>
          </div>

        </div>
      </div>
    </div>

    <section>
      <div class="settings_dialogs">
        <el-dialog title="Change Profile Picture" :visible.sync="profilePictureModal"
        :destroy-on-close="true" :before-close="handleClose">
          <div v-loading="submiting">
            <div style="text-align: left;">

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="file">Upload Profile Picture</label>
                            <input type="file" id="file" @change="onFileChange($event)">
                        </div>
                    </div>
                </div>
                <div class="form-group text-center" style="padding-top:10px;">
                  <el-button style="width: 150px; background-color: var(--vu-red); color: white;" class="z-depth" v-ripple="'rgba(255, 255, 255, 0.35)'" @click="addProfilePicture()">Save Changes</el-button>
                </div>

            <div class="text-center">
                <div class="text-center">
                    <small>© 2021 Pesamoni, All rights reserved.</small>
                </div>
                <div class="text-center">
                    <!-- <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                </div>
            </div>
            </div>
          </div>
        </el-dialog>

        <el-dialog title="This Request will be sent to the Administration to for editing."
         :visible.sync="userRequestsModal"
         :destroy-on-close="true" :before-close="handleClose">
            <div class="row mt-2 w-100 mx-0 text-left px-0">
              <div class="col-md-6" style="font-weight: 500; color: rgba(0, 0, 0, 0.7); font-size: 0.9em;">
                First Name
                <div class="mt-1">
                  <input type="text" v-model="$v.firstName.$model" :class="{ 'input-error': $v.firstName.$error }" @keyup.enter="sendRequest" placeholder="First Name" class="legacy-input bg-white" style="width: 100%; height: 36px !important" />
                  <br/>
                  <span v-if="$v.firstName.$error" style="font-size: 0.7em; color: red">{{ firstNameError }}</span>
                </div>
              </div>
              <div class="col-md-6" style="font-weight: 500; color: rgba(0, 0, 0, 0.7); font-size: 0.9em;">
                Last Name
                <div class="mt-1">
                  <input type="text" v-model="$v.lastName.$model" :class="{ 'input-error': $v.lastName.$error }" @keyup.enter="sendRequest" placeholder="Last Name" class="legacy-input bg-white" style="width: 100%; height: 36px !important"/>
                  <br/>
                  <span v-if="$v.lastName.$error" style="font-size: 0.7em; color: red">{{ lastNameError }}</span>
                </div>
              </div>
            </div>

            <div class="row mt-2 w-100 mx-0 text-left px-0">
              <div class="col-md-12" style="font-weight: 500; color: rgba(0, 0, 0, 0.7); font-size: 0.9em;">
                Email address
                <div class="mt-1">
                  <input type="email" v-model="$v.email.$model" :class="{ 'input-error': $v.email.$error }" @keyup.enter="sendRequest" placeholder="email" class="legacy-input bg-white" style="width: 100%; height: 36px !important"/>
                  <br/>
                  <span v-if="$v.email.$error" style="font-size: 0.7em; color: red">{{ emailError }}</span>
                </div>
              </div>
            </div>

            <div class="row mt-2 w-100 mx-0 text-left px-0">
              <div class="col-md-12" style="font-weight: 500; color: rgba(0, 0, 0, 0.7); font-size: 0.9em;">
                Reason
                <div class="mt-1"> 
                  <el-input type="textarea"  :autosize="{ minRows: 3, maxRows: 3}" placeholder="Please input reason" v-model="$v.reason.$model" :class="{ 'input-error': $v.reason.$error }" @keyup.enter="sendRequest" style="width: 100% !important;">
                  </el-input>
                  <br/>
                  <span v-if="$v.reason.$error" style="font-size: 0.7em; color: red">{{ reasonError }}</span>
                </div>
              </div>
            </div>
            
            <div class="row mt-3 w-100 mx-0 ">
              <div class="col-md-12 text-center">
                <button v-if="!submiting4"  :disabled="$v.firstName.$invalid || $v.lastName.$invalid || $v.email.$invalid || $v.reason.$invalid" type="button" @click="sendRequest" class="btn z-depth-0 changeBtn" v-ripple="'rgba(255, 255, 255, 0.35)'">
                  <span style="position: relative; bottom: 1px">Send Request</span>
                </button>
                <ScaleOut v-else :background="'#164B70'"/>
              </div>
            </div>

            <div class="col-12 px-0 pt-2">
              <hr />
            </div>
        </el-dialog>
      </div>
    </section>
    
  </section>
</template>

<script>
  // import BreadCrumbComponent from "../components/bread-crumb-component";
  import { ScaleOut } from "vue-loading-spinner";
  import { validationMixin } from "vuelidate";
  import { required, maxLength, minLength, sameAs, } from "vuelidate/lib/validators";

  export default {
    components: { ScaleOut },
    mixins: [validationMixin],

    data() {
      return {
        profilePictureModal: false,
        userRequestsModal: false,
        basicInfoModal: false,
        loading: false,
        submiting: false,
        submiting2: false,
        submiting3: false,
        submiting4: false,
        accessCode: "SLAU0X89",

        firstName: "",
        lastName: "",
        email: "",
        reason: "",

        phoneNumber: "",
        profile_picture: "",

        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",

      };
    },

    computed: {
      firstNameError() {
        if (!this.$v.firstName.required) {
          return "*First name is required";
        }
        return "";
      },

      lastNameError() {
        if (!this.$v.lastName.required) {
          return "*Last name is required";
        }
        return "";
      },

      emailError() {
        if (!this.$v.email.required) {
          return "*Email is required";
        }
        return "";
      },

      reasonError() {
        if (!this.$v.reason.required) {
          return "*Reason is required";
        }
        return "";
      },

      phoneNumberError() {
        if (!this.$v.phoneNumber.required) {
          return "*Phone number is required";
        }
        return "";
      },
      oldPasswordError() {
        if (!this.$v.oldPassword.required) {
          return "*Password is required";
        } else if (!this.$v.oldPassword.minLength) {
          return "*Too short";
        }
        return "";
      },
      newPasswordError() {
        if (!this.$v.newPassword.required) {
          return "*Password is required";
        } else if (!this.$v.newPassword.minLength) {
          return "*Too short";
        }
        return "";
      },
      confirmNewPasswordError() {
        if (!this.$v.confirmNewPassword.required) {
          return "*Please confirm Password";
        } else if (!this.$v.confirmNewPassword.match) {
          return "*Password Mismatch";
        }
        return "";
      },
    
      getInitials() {
        return (nameString) => {
          //  format(parseISO(dateString), "do MMMM yyyy");
          var names = nameString.split(" "),
            initials = names[0].substring(0, 1).toUpperCase();
          if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
          }
          return initials;
        };
      },
  },

     validations: {
        firstName: {
          required
        },

        lastName: {
          required
        },

        email: {
          required
        },
        reason: {
          required
        },
        phoneNumber: {
          required
        },
        oldPassword: { 
          required, minLength: minLength(6), maxLength: maxLength(100) 
        },
        newPassword: {
          required, minLength: minLength(6), maxLength: maxLength(100) 
        },
        confirmNewPassword: {
          required, match: sameAs("newPassword")
        },
      },

    mounted() {
      this.getOneStaff();
    },

    methods: {

      handleClose(done) {
        this.$v.$reset();
        done();
      },

          getOneStaff() {
                this.loading = true;
                this.loadingError = false;
                this.$http.get(`staff/fetch-one/${this.$store.state.userId}`).then(
                  (response) => {
                    if (
                      response.data.success && (response.data.message == "STAFF_RETRIEVED_SUCCESSFULLY")
                    ) {
                      this.staff = response.data.staff;
                      this.firstName = this.staff.first_name;
                      this.lastName = this.staff.last_name;
                      this.email = this.staff.email;
                      this.profile_picture = this.staff.profile_picture;
                      this.phoneNumber = this.staff.phone_number;
                    } else {
                      this.loadingError = true;
                      this.showFailedMessage(
                        "Fetch Failed",
                        "An unexpected Error Occured. Please try again"
                      );
                    }
                  },
                () => {
                  this.loadingError = true;
                  this.showFailedMessage(
                    "Fetch Failed",
                    "An unexpected Error Occured. Please try again"
                  );
                }
              )
              .finally(() => (this.loading = false));
          },

      removePhoto() { 
            this.$confirm('This will permanently delete your profile picture. Do you want to continue?', 'Confirm photo removal', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {

                this.submiting2 = true;
                this.$http.put(`staff/remove-profile-picture` ).then((response) => {
                    if ( response.data.success && response.data.message == "PROFILE_DELETED_SUCCESSFULLY" ) {
                      this.showSuccessMessage("Success","Picture removed successfully");
                      this.getOneStaff();
                      this.submiting2 = false;
                    } else if(response.data.message ==  "NO_PROFILE_PICTURE_TO_DELETE") {
                       this.showWarningMessage("No Picture","No profile picture to be removed");
                      this.submiting2 = false;
                    }
                  }, 
                  error => { // eslint-disable-line no-unused-vars
                    this.showFailedMessage("Error", "Unable to remove photo");
                    this.submiting2 = false;
                  });

                  
              }).catch(() => {
                this.showWarningMessage("Warning", "Removing profile picture canceled.");        
              });
      },

      async savePhoneInfo() {
        
        if ( this.$v.phoneNumber.$invalid ) return;
        let phoneData = {
          phone_number: this.phoneNumber,
        }

            this.submiting3 = true;

            try {
              let request = await this.$http.patch(`staff/update-one/${this.$store.state.userId}`, phoneData );
              
              if (
                request.data.success && request.data.message == "STAFF_UPDATE_SUCCESSFUL"
              ) {
                  this.showSuccessMessage("Success","Phone changed successfully");
                  this.profilePictureModal = false;

              } else if (request.data.message == "STAFF_UPDATE_FAILED") {
                this.showWarningMessage("Warning","Unable to update phone number");
              } else {
                throw "ERROR OCCURED";
              }
            } catch (error) {
              if (error.data == "Network Error") {

                return this.showFailedMessage("Connection failed","A network error occured, please try again.");
              
              }
              this.showFailedMessage("Unable to change phone number","An unexpected Error occured, please try again");
            } finally {
              this.submiting3 = false;
            }
        
      },

          onFileChange(e) {
              this.file = e.target.files[0]; 
          },

          addProfilePicture() {
            if(this.file == null){
              this.showWarningMessage("Empty", "Profile picture is empty");
            }else {
              const formData = new FormData();
              formData.append('file', this.file);
              this.submiting = true;
              this.axios.put(`staff/upload-profile-picture/${this.$store.state.userId}`, formData, { headers: {'Content-Type': 'multipart/form-data' }}).then(
              (response) => {
                  if(response.data.success == true){
                      this.submiting = false;
                      this.getOneStaff();
                      this.showSuccessMessage("Success","Profile picture added successfully");
                      this.profilePictureModal = false;
                  }else {
                      this.submiting = false;
                      this.showSuccessMessage("Warning", "Failed to add Profile picture");
                  }
              },
              error => { // eslint-disable-line no-unused-vars

                if (!error.response) {
                  this.submiting = false;
                  this.showFailedMessage("Error", "Unable to upload profile picture");
                } else {
                  
                    if (error.response.data.message == "Only images are allowed to be uploaded") {
                      
                      this.submiting = false;
                      return this.showFailedMessage("Error", "Only images are allowed to be uploaded");
                        
                    } else {
                      this.submiting = false;
                      return this.showFailedMessage("Error", "Unable to upload profile picture");
                    }

                }

              });
            }
          },

        async changePassword() {

            if (this.$v.oldPassword.$invalid || this.$v.newPassword.$invalid || this.$v.confirmNewPassword.$invalid) return;
            
            let passwordData = {
              oldPassword: this.oldPassword,
              newPassword: this.newPassword
            }

            this.submiting = true;

            try {
              let request = await this.$http.put(`staff/changePassword`, passwordData );
              if (
                request.data.success && request.data.message == "PASSWORD_CHANGED_SUCCESSFULLY"
              ) {
                  this.showSuccessMessage("Success","Password changed successfully");
                  this.profilePictureModal = false;
                  this.oldPassword = "";
                  this.newPassword = "";
                  this.confirmNewPassword = "";
                  this.$v.$reset();

              } else if (request.data.message == "INCORRECT_PASSWORD") {
                this.showWarningMessage("Incorrect password","The current password you entered is incorrect");
              } else {
                throw "ERROR OCCURED";
              }
            } catch (error) {
              if (error.data == "Network Error") {

                return this.showFailedMessage("Connection failed","A network error occured, please try again.");
              
              }
              this.showFailedMessage("Unable to change password","An unexpected Error occured, please try again");
            } finally {
              this.submiting = false;
            }
          }, 
    

        

        switchVisibility() {
          const passwordField = document.querySelector('#password')
          if (passwordField.getAttribute('type') === 'password') passwordField.setAttribute('type', 'text')
          else passwordField.setAttribute('type', 'password')
        },

        switchVisibility1() {
          const passwordField = document.querySelector('#password1')
          if (passwordField.getAttribute('type') === 'password') passwordField.setAttribute('type', 'text')
          else passwordField.setAttribute('type', 'password')
        },

        switchVisibility2() {
          const passwordField = document.querySelector('#password2')
          if (passwordField.getAttribute('type') === 'password') passwordField.setAttribute('type', 'text')
          else passwordField.setAttribute('type', 'password')
        },

        async sendRequest() {
            if (this.$v.firstName.$invalid || this.$v.lastName.$invalid || this.$v.email.$invalid || this.$v.reason.$invalid) return;

            let requestData = {
              first_name: this.firstName,
              last_name: this.lastName,
              email: this.email,
              reason: this.reason
            }
            
            this.submiting4 = true;
            try {
              let request = await this.$http.post(`info-update/submit`, requestData );
              if (
                request.data.success && request.data.message == "REQUEST_SUBMITTED_SUCCESSFULLY"
              ) {
                  this.showSuccessMessage("Success","Request submitted successfully");  
                  this.userRequestsModal = false;
                  this.reason = "";
                  this.$v.$reset();
              } else {
                throw "ERROR OCCURED";
              }
            } catch (error) {
              if (error.data == "Network Error") {

                return this.showFailedMessage("Connection failed","A network error occured, please try again.");
              
              }
              this.showFailedMessage("Unable to send message","An unexpected Error occured, please try again");
            } finally {
              this.submiting4 = false;
            }
          },
    }

  };
</script>

<style scoped>
  .content {
    margin-top: 45px !important;
  }

  .TheProfileSettings {
    display: flex;
    align-items: center;
  }

  .TheProfileSettings>div:nth-child(2) {
    margin-left: 30px;
  }

  input[type=text],[type=email],[type=file], select, textarea {
    width: 100%;
    padding: 10px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    background-color: #FFFFFF;
    font-size: .9em;
  }

  .remove-picture-btn {
    width: auto;
    padding: 0.85rem 1.8rem;
    text-transform: capitalize;
    box-shadow: -1px 0px 9px 0px rgb(0 0 0 / 11%), 0 2px 10px 0 rgb(0 0 0 / 0%) !important;
    height: 40px;
    border-radius: 4px;
    color: red !important;
    background-color: transparent;
    font-weight: 500;
  }

  .changeBtn {
    margin: 0px !important; width: 216px; text-transform: capitalize; height: 43px; border-radius: 4px; background-color: var(--vu-red);
  }

  input.legacy-input:focus:not(.input-error) {
    box-shadow: none !important;
  }

  @media (max-width: 768px) {
    .content {
      margin-top: 15px !important;
    }
  }


  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
  }

  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  }

  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .theProfile {
      display: flex; justify-content: center;
    }
    .theProfileBtns {
      display: flex; align-items: center; justify-content: space-between;
    }
    .changeBtn {
      width: 100%;
    }
  }

  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .TheProfileSettings {
      display: block;
    }
    .theProfile {
      display: flex; justify-content: center; margin-bottom: 30px;
    }
    .theProfileBtns {
      display: flex; align-items: center; justify-content: space-between;
    }
    .changeBtn {
      width: 100%;
    }
  } 

</style>


<style>
  .settings_request_form .el-select .el-input__inner {
    cursor: pointer;
    height: 36px !important;
    width: 100% !important;
    padding-left: 10px !important;
    text-transform: capitalize;
  }
  .settings_request_form .el-button--primary {
    color: #fff;
    background-color: #409eff !important;
    border-color: #409eff !important;
  }

  el-table th > .cell {
    color: black !important;
    font-weight: 500;
  }
  .el-table .cell {
    font-size: 0.9em;
    line-height: 23px;
    font-weight: 300;
  }

  .settings_request_form .el-input input {
    font-size: 0.8rem !important;
    font-weight: 400 !important;
    color: #495057 !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  }

  .settings_request_form .el-input input:focus {
    color: #495057 !important;
    background-color: #fff !important;
    border-color: #80bdff !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
  }

  .settings_dialogs .el-dialog {
    width: 50%;
  }
  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
    .settings_dialogs .el-dialog {
      width: 50%;
    }
  }

  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .settings_dialogs .el-dialog {
      width: 70%;
    }
  }

  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .settings_dialogs .el-dialog {
      width: 90%;
    }
  }

  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .settings_dialogs .el-dialog {
      width: 90%;
    }

  }
</style>
