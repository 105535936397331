<template>
  <div
    class="container-fluid live-lectures px-0 h-100"
    style="text-align: left"
  >
    <article>
      <section style="padding-top: 50px">
        <div class="container">
          <div class="row w-100 mb-4">
            <div class="col-12 pl-3 px-0 mb-0 text-left">
              <BreadCrumbComponent
                :pageTitles="['Live Lectures', 'Attendance']"
              />
            </div>
          </div>

          <div class="row w-100">
            <div class="col-12 text-left">
              <div
                style="
                  font-size: 20px;
                  font-weight: 400;
                  color: var(--el-app-primary);
                  text-transform: uppercase;
                  margin-bottom: 12px;
                "
              >
                <strong
                  >{{ attendanceInfo.batch.course.course_code }}
                  {{ toMonthName(attendanceInfo.batch.month) }}/{{
                    attendanceInfo.batch.year
                  }}
                  Student Class Attendance</strong
                >
              </div>
            </div>
            <div
              class="pb-0 text-left col-12"
              style="
                font-weight: 500;
                text-transform: capitalize;
                font-size: 0.85em;
                color: rgba(0, 0, 0, 0.55);
              "
            >
              <b>Title</b>: {{ attendanceInfo.lecture_title }}
            </div>
            <div
              class="pb-0 text-left col-12"
              style="
                font-weight: 500;
                text-transform: capitalize;
                font-size: 0.85em;
                color: rgba(0, 0, 0, 0.55);
              "
            >
              <b>Module</b>: {{ attendanceInfo.course_unit_title }}
            </div>
            <div
              class="pb-0 text-left col-12"
              style="
                font-weight: 500;
                text-transform: capitalize;
                font-size: 0.85em;
                color: rgba(0, 0, 0, 0.55);
              "
            >
              <b>By</b>: {{ attendanceInfo.lecturer }}
            </div>
            <div
              class="pb-4 text-left col-12"
              style="
                font-weight: 500;
                text-transform: capitalize;
                font-size: 0.85em;
                color: rgba(0, 0, 0, 0.55);
              "
            >
              <b>Date</b>: {{ formattedDate(attendanceInfo.date) }} from
              {{ attendanceInfo.start_time }} to {{ attendanceInfo.end_time }}
            </div>
          </div>

          <div
            style="
              margin-bottom: 20px;
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <div class="row w-100 d-flex">
              
              <div class="col-md-4 mb-3">
                <input
                  type="text"
                  class=""
                  v-model="filters[0].value"
                  placeholder="Search Student's Name"
                  style="height: calc(2.4xrem) !important"
                  spellcheck="off"
                />
              </div>
              <div class="col-3">
                <el-select
                  v-model="selectedFilter"
                  placeholder="Select"
                  class="d-block"
                >
                  <el-option
                    v-for="item in filterOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <hr />
          <div class="row mx-auto mb-4 text-right" style="width: 100%">
            <div class="col-xl-3 pl-md-0 px-2 py-2 col-md-6">
              <div class="card" style="max-height: 110px">
                <div class="row mt-3">
                  <div class="col-md-3 col-5 text-left">
                    <a
                      type="button"
                      class="btn-floating btn-md success-color ml-4"
                    >
                      <i class="fas fa-check" aria-hidden="true"></i>
                    </a>
                  </div>
                  <div class="col-md-9 col-7 text-right pr-5">
                    <h5
                      class="ml-4 mt-4 mb-2 font-bold text-truncate"
                      style="font-size: 28px"
                    >
                      {{ totalAttended }}
                    </h5>
                    <p class="font-small grey-text" style="font-size: 12px">
                      Total Attended
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 px-2 py-2 col-md-6">
              <div class="card" style="max-height: 110px">
                <div class="row mt-3">
                  <div class="col-md-3 col-5 text-left">
                    <a
                      type="button"
                      class="btn-floating z-depth-0 btn-md deep-orange darken-3 ml-4"
                    >
                      <i class="fas fa-close" aria-hidden="true"></i>
                    </a>
                  </div>
                  <div class="col-md-9 col-7 text-right pr-5">
                    <h5
                      class="ml-4 mt-4 mb-2 font-bold text-truncate"
                      style="font-size: 28px"
                    >
                      {{ totalMissed }}
                    </h5>
                    <p class="font-small grey-text" style="font-size: 12px">
                      Total Missed
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-loading="fetchingLiveClassId" style="width: 100%">
            <data-tables
              :table-props="tableProps"
              :pagination-props="{ pageSizes: [25, 50, 100] }"
              type="expand"
              :data="attendanceReport"
              :filters="filters"
              ref="lecturesTable"
            >
              <div slot="empty">
                <ScaleOut
                  v-if="loading && !loadingError"
                  class="mt-3"
                  :background="'#164B70'"
                />
                <div
                  @click="getLiveLectures"
                  v-else-if="!loading && loadingError"
                  style="cursor: pointer"
                >
                  Unable to Load Live lectures Now. Please click here to retry
                </div>
                <div v-if="!loading && !loadingError">No Live lectures</div>
              </div>

              <el-table-column
                label="#"
                :show-overflow-tooltip="true"
                label-class-name="text-center"
                class-name="text-center"
                width="60px"
              >
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Student Name"
                :show-overflow-tooltip="true"
                label-class-name="text-left"
                width="auto"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.fullName }} </span>

                  <!-- <span>{{ scope.row.title }}</span> -->
                </template>
              </el-table-column>

              <el-table-column
                label="Attended"
                width="80px"
                class-name="text-center"
                label-class-name="text-center"
              >
                <template slot-scope="scope">
                  <el-tooltip
                    :content="scope.row.attended ? 'Attended' : 'Missed'"
                    placement="top"
                  >
                    <i
                      :class="{
                        'el-icon-close color-red': !scope.row.attended,
                        'el-icon-check color-green': scope.row.attended,
                      }"
                      style="font-size: 20px; cursor: pointer; color: #ffbb33"
                    ></i>
                  </el-tooltip>
                </template>
              </el-table-column>

              <el-table-column
                label="Check-in time"
                :show-overflow-tooltip="true"
                label-class-name="text-left"
                width="140px"
                prop="course_unit_code"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.checkInTime }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Total Duration"
                :show-overflow-tooltip="true"
                label-class-name="text-left"
                width="130px"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.duration }} </span>
                </template>
              </el-table-column>

              <el-table-column label="% Attendance" width="120px">
                <template slot-scope="scope">
                  <span>{{ scope.row.percentage }}</span>
                </template>
              </el-table-column>
            </data-tables>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
import { ScaleOut } from "vue-loading-spinner";
import { format, parseISO } from "date-fns";
import BreadCrumbComponent from "../components/bread-crumb-component";
import getMonthName from "../helpers/getMonthName";

export default {
  components: { ScaleOut, BreadCrumbComponent },

  data() {
    return {
      selectedFilter: "Show All",
      searchBy: "Search by Lecturer",
      fetchingLiveClassId: null,
      isLectureDetailsModelVisible: false,
      lectureDetailsLoading: true,
      filterOptions: ["Show All", "Show Only Missed", "Show Only Attended"],

      loading: false,
      loadingError: false,
      attendanceInfo: null,
      tableProps: {
        border: true,
        stripe: true,
        // defaultSort: {
        //   prop: "date",
        //   order: "ascending",
        // },
      },

      filters: [
        {
          prop: "fullName",
          value: "",
          filterFn: (row, filterItem) => {
            return row.fullName
              .toLowerCase()
              .includes(filterItem.value.toLowerCase().trim());
          },
        },
        {
          prop: "title",
          value: "",
        },
        {
          prop: "title",
          value: "",
        },
      ],
    };
  },
  watch: {
    searchBy() {
      this.filters[0].value = "";
      // this.$refs.lecturesTable.clearFilter();
    },
  },
  computed: {
    toMonthName() {
      return (monthNum) => {
        return getMonthName(monthNum);
      };
    },
    totalAttended() {
      return this.attendanceInfo.report.filter((report) => report.attended)
        .length;
    },
    totalMissed() {
      return this.attendanceInfo.report.filter((report) => !report.attended)
        .length;
    },
    attendanceReport() {
      return this.attendanceInfo.report.filter((report) => {
        if (this.selectedFilter === "Show Only Missed") {
          return !report.attended;
        }
        if (this.selectedFilter === "Show Only Attended") {
          return report.attended;
        }

        return true;
      });
    },

    formattedDate() {
      return (dateString) => format(parseISO(dateString), "do MMM yyyy");
    },
  },
  created() {
    if (this.$route.params.attendance) {
      this.attendanceInfo = this.$route.params.attendance;
    } else return this.$router.replace({ name: "LiveLectures" });
  },
  methods: {
    beforeDialogClose() {
      this.isLectureDetailsModelVisible = false;
    },
  },
};
</script>

<style>
.live-lectures .row .el-select .el-input__inner {
  cursor: pointer;
  height: 36px !important;
  width: 100% !important;
  padding-left: 10px !important;
  text-transform: capitalize;
}

input[type="text"] {
    width: 100%;
    height: 36px !important;
    padding: 10px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    background-color: #ffffff;
    font-size: 0.9em;
}
.live-lectures .el-button--primary {
  color: #fff;
  background-color: #409eff !important;
  border-color: #409eff !important;
}
</style>

<style scoped>
.color-red {
  color: red !important;
}
.color-green {
  color: rgb(7, 206, 7) !important;
}
</style>
