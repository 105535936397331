<template>
  <section>
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
      "
    >
      <div>
        <small
          style="
            text-align: left;
            font-weight: 600;
            color: var(--el-app-primary);
          "
          >{{ monthName }} {{ year }}</small
        >
      </div>
      <div style="display: flex">
        <div @click="onChangeCalendarMonth(-1)" class="direction_arrows">
          <i class="fas fa-long-arrow-alt-left"></i>
        </div>
        <div @click="onChangeCalendarMonth(1)" class="direction_arrows">
          <i class="fas fa-long-arrow-alt-right"></i>
        </div>
      </div>
    </div>
    <div>
      <ul class="weekdays">
        <li><strong>Mon</strong></li>
        <li><strong>Tue</strong></li>
        <li><strong>Wed</strong></li>
        <li><strong>Thu</strong></li>
        <li><strong>Fri</strong></li>
        <li class="weekends"><strong>Sat</strong></li>
        <li class="weekends"><strong>Sun</strong></li>
      </ul>

      <ul v-for="(week, index) in monthlyWeeks" :key="index" class="days">
        <li
          
          v-for="(day, index) in week"
          :key="index"
          :class="{ weekends: index >= 5 }"
        >
          <span :class="{ date_today: isToday(day) }">
            {{ day ? day : "" }}</span
          >
          <div v-if="hasEvent(day)" class="specialDays">
            <div></div>
          </div>
        </li>
      </ul>
    </div>
    <br />
  </section>
</template>

<script>
import cal from "calendar";
import getMonthName from "../helpers/getMonthName";
export default {
  data() {
    return {};
  },

  props: {
    month: {
      type: Number,
      required: true,
    },
    dateToday: {
      type: String,
    },
    year: {
      type: Number,
      required: true,
    },
    eventsDays: {
      type: Array,
      required: true,
    },
  },

  computed: {
    monthlyWeeks() {
      const calendar = new cal.Calendar(1);
      return calendar.monthDays(this.year, this.month - 1);
    },

    hasEvent() {
      return (day) => this.eventsDays.indexOf(day) >= 0;
    },

    isToday() {
      return (day) => {
        if(!this.dateToday) return false;
        
        const today = this.dateToday.split("-");
        const calendarYear = +today[0];
        const calendarMonth = +today[1];
        const dayToday = +today[2];

        return (
          this.year == calendarYear &&
          this.month == calendarMonth &&
          day == dayToday
        );
      };
    },

    monthName() {
      return getMonthName(this.month, true);
    },
  },

  mounted() {},
  methods: {
    onChangeCalendarMonth(num) {
      this.$emit("on-change-month", num);
    }
  },
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer !important;
}

.date_today {
  border: 1px solid var(--el-app-primary);
  padding-left: 6px;
  padding-right: 6px;
}
.direction_arrows {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: rgb(214, 226, 238);
  color: var(--el-app-primary);
  cursor: pointer;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.direction_arrows i {
  font-size: 10px;
}

.bg-white {
  background-color: white;
}

* {
  box-sizing: border-box;
}
ul {
  list-style-type: none;
}

.weekends {
  color: grey !important;
  font-weight: 500;
}
.weekdays {
  margin: 0;
  padding: 5px 0;
}

.weekdays li {
  display: inline-block;
  width: 13.6%;
  color: black;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
}

.days {
  padding: 5px 0;
  background: white;
  margin: 0;
}

.days li {
  list-style-type: none;
  display: inline-block;
  width: 13.6%;
  text-align: center;
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 500;
  color: black;
  position: relative;
}

.specialDays {
  position: absolute;
  bottom: -3px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
}

.specialDays div {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--el-app-primary);
}

/* Add media queries for smaller screens */
/* @media screen and (max-width:720px) {
        .weekdays li, .days li {width: 13.1%;}
    }

    @media screen and (max-width: 420px) {
        .weekdays li, .days li {width: 12.5%;}
        .days li .active {padding: 2px;}
    }

    @media screen and (max-width: 290px) {
        .weekdays li, .days li {width: 12.2%;}
    } */
</style>
