<template>
  <div class="container-fluid px-0 h-100" style="text-align: left">
    <article>
      <section style="padding-top: 50px">
        <div class="container">

          <div class="row w-100 mb-4">
            <div class="col-12 pl-3 px-0 mb-0 text-left">
              <BreadCrumbComponent :pageTitles="['Faculties','Departments','Programmes' ]" />
            </div>
          </div>

          <div class="row w-100">
            <div class="col-12 text-left">
              <div style="font-size: 20px; font-weight: 400; color: var(--el-app-primary); margin-bottom: 15px;">
                <strong>Programmes in "<span style="">{{ departmentName ? departmentName : '...' }}".</span></strong><br/>
              </div>
            </div>
          </div>

          <div class="search_add_section">
            <div class="">
              <input type="text" v-model.trim="filters[0].value" placeholder="Search by Title"  class="search_by_input" spellcheck="off"/>
            </div>
            <el-button style="" class="z-depth-0 redBtn" v-ripple="'rgba(255, 255, 255, 0.35)'" @click="showAddCourseModal">Add A Programme</el-button>
          </div>
          <hr/>

          <div class="table-responsive">
            <data-tables v-loading="deleting" :data="courses"
              :table-props="tableProps" :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }"
              type="expand" :filters="filters">

              <div slot="empty">
                <ScaleOut v-if="loading && !loadingError" class="mt-3" :background="'#164B70'"/>
                <div @click="getCourses" v-else-if="!loading && loadingError" style="cursor: pointer">
                  Unable to Load Programmes Now. Please click here to retry
                </div>
                <div v-if="!loading && !loadingError">No Programmes</div>
              </div>

              <el-table-column label="#" :show-overflow-tooltip="true" label-class-name="text-center" class-name="text-center" width="80px">
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <el-table-column label="Programme Code" :show-overflow-tooltip="true" label-class-name="text-left" width="auto">
                <template slot-scope="scope">
                  <span>{{ scope.row.course_code }}</span> 
                </template>
              </el-table-column>

              <el-table-column label="Programme Title" :show-overflow-tooltip="true" label-class-name="text-left" width="auto">
                <template slot-scope="scope">
                  <span>{{ scope.row.title }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Duration"
                :show-overflow-tooltip="true"
                label-class-name="text-left"
              >
                <template slot-scope="scope">
                  <span>
                    {{ scope.row.duration_of_study }} 
                    <span v-if="scope.row.total_duration_type == 'YEAR'">years</span>
                    <span v-if="scope.row.total_duration_type == 'MONTH'">Months</span>
                    <span v-if="scope.row.total_duration_type == 'WEEK'">Weeks</span>
                  </span>
                </template>
              </el-table-column>

              <el-table-column label="Modules" :show-overflow-tooltip="true" label-class-name="text-left">
                <template slot-scope="scope">
                  <el-button type="primary" @click="goToCourseUnits(scope.row.course_id)" round>
                    {{scope.row.course_units}} {{scope.row.course_units == 1 ? "Module" : "Modules"}}
                    </el-button>
                </template>
              </el-table-column>

              <el-table-column label="Actions" :show-overflow-tooltip="true" label-class-name="text-left">
                <template slot-scope="scope">
                  <el-row>
                    <el-tooltip content="Edit" placement="top">
                      <el-button type="primary" :disabled="!findCommonElements3(faculties_cUnits_crud, $store.state.theRole)" @click="coursesEditData( scope.row.course_id, scope.row.title, scope.row.course_code, scope.row.duration_of_study, scope.row.total_duration_type, scope.row.study_period_type, scope.row.maximum_number_of_study_period )" icon="el-icon-edit" circle></el-button>
                    </el-tooltip>
                    <el-tooltip content="Delete" placement="top">
                      <el-button type="danger" :disabled= "true" @click="deleteCourse(scope.row.course_id)" icon="el-icon-delete" circle></el-button>
                    </el-tooltip>
                  </el-row>
                </template>
              </el-table-column>

            </data-tables>
          </div>
        </div>
      </section>

      <section>
        <div class="container">
          <div class="course_dialogs">
            <el-dialog title="Add Programme" :visible.sync="addCourseModal" :destroy-on-close="true" :before-close="handleClose">
              <div v-loading="submiting" class="course_request_form">
                <el-form :model="courseForm" :rules="rules" ref="courseForm"  class="demo-courseForm2">
                  <div class="row">
                    <div class="col-md-6">
                      <label>Title</label>
                      <el-form-item prop="title">
                        <el-input v-model="courseForm.title" style="height: 100% !important;"></el-input>
                      </el-form-item>
                    </div>
                    <div class="col-md-6">
                      <label>Programme code</label>
                      <el-form-item prop="course_code">
                        <el-input v-model="courseForm.course_code"></el-input>
                      </el-form-item>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <label>Duration in (<small><i>years, months, or weeks</i></small>)</label>
                      <el-form-item prop="duration_of_study">
                        <el-input-number v-model="courseForm.duration_of_study" controls-position="right" @change="handleChange" :min="1" :max="10" style="width: 100%;"></el-input-number>
                      </el-form-item>
                    </div>
                    <div class="col-md-6">
                      <label>Duration type</label>
                      <el-form-item prop="total_duration_type">
                        <el-select v-model="courseForm.total_duration_type" placeholder="Duration type" style="width: 100%;">
                          <el-option label="Years" value="YEAR"></el-option>
                          <el-option label="Months" value="MONTH"></el-option>
                          <el-option label="Weeks" value="WEEK"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>

                  <div class="row">
                      <div class="col-md-6">
                          <label>Number of Semesters per year</label>
                          <el-form-item prop="maximum_number_of_study_period">
                            <el-input-number v-model="courseForm.maximum_number_of_study_period" controls-position="right" @change="handleChange" :min="1" :max="10" style="width: 100%;"></el-input-number>
                          </el-form-item>
                      </div>
                  </div>
                                        
                  <hr/>
                  <el-form-item class="text-center">
                      <el-button type="primary" @click="addCourse('courseForm')">Create A Programme</el-button>
                  </el-form-item>
                </el-form>
                <div class="text-center">
                  <div class="text-center">
                    <small>© 2021 Pesamoni, All rights reserved.</small>
                  </div>
                  <div class="text-center">
                    <!--<small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                  </div>
                </div>
              </div>
            </el-dialog>
            
            <el-dialog title="Edit Programme" :visible.sync="editCourseModal" :destroy-on-close="true" :before-close="handleClose">
              <div v-loading="submiting_edit" class="course_request_form">
                <el-form :model="courseForm" :rules="rules" ref="courseForm"  class="demo-courseForm2">
                  <div class="row">
                    <div class="col-md-6">
                      <label>Title</label>
                      <el-form-item prop="title">
                        <el-input v-model="courseForm.title"></el-input>
                      </el-form-item>
                    </div>
                    <div class="col-md-6">
                      <label>Programme Code</label>
                      <el-form-item prop="course_code">
                        <el-input v-model="courseForm.course_code"></el-input>
                      </el-form-item>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <label>Duration in (<small><i>years, months, or weeks</i></small>)</label>
                      <el-form-item prop="duration_of_study">
                        <el-input-number v-model="courseForm.duration_of_study" controls-position="right" @change="handleChange" :min="1" :max="10" style="width: 100%;"></el-input-number>
                      </el-form-item>
                    </div>
                    <div class="col-md-6">
                      <label>Duration type</label>
                      <el-form-item prop="total_duration_type">
                        <el-select v-model="courseForm.total_duration_type" placeholder="Duration type" style="width: 100%;">
                          <el-option label="Years" value="YEAR"></el-option>
                          <el-option label="Months" value="MONTH"></el-option>
                          <el-option label="Weeks" value="WEEK"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
                                        
                  <hr/>
                  <el-form-item class="text-center">
                      <el-button type="primary" @click="editCourse_final('courseForm')">Edit Programme</el-button>
                  </el-form-item>
                </el-form>

                <div class="text-center">
                  <div class="text-center">
                    <small>© 2021 Pesamoni, All rights reserved.</small>
                  </div>
                  <div class="text-center">
                    <!-- <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                  </div>
                </div>
              </div>
            </el-dialog>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>

<script>

  import { ScaleOut } from "vue-loading-spinner";
  import BreadCrumbComponent from "../components/bread-crumb-component";

  export default {

    components: { ScaleOut, BreadCrumbComponent },
    // mixins: [validationMixin],

    data() {
      return {
        addCourseModal: false,
        editCourseModal: false,
        loading: false,
        loadingError: false,
        submiting: false,
        deleting: false,
        submiting_edit: false,
        responseData: "",
        department: "",
        faculties: [],
        courses: [],
        dId: this.$route.params.dId, //this is the department id from the browser
        departmentName: this.$route.params.departmentName,
        tableProps: {
          border: true,
          stripe: true,
        },

        filters: [
          {
            prop: "title",
            value: "",
          },
        ],

          course_id: "",
            courseForm: {
              title: "",
              course_code: "",
              duration_of_study: 1,
              total_duration_type: "",
              maximum_number_of_study_period: 1,
              study_period_type: "SEMESTER",
              total_number_of_study_period: 6,
              department_id: this.$route.params.dId
            },

            rules: {
                title: [
                    { required: true, message: 'Please input Title', trigger: 'blur' }
                ],
                course_code: [
                    { required: true, message: 'Please input Programme code', trigger: 'blur' }
                ],
                duration_of_study: [
                    { required: true, message: 'Please add duration of study', trigger: 'blur' }
                ],
                total_duration_type: [
                    { required: true, message: 'Please add duration type', trigger: 'blur' }
                ],
                maximum_number_of_study_period: [
                    { required: true, message: 'Add number of semesters in one year', trigger: 'change' }
                ]
            },

            faculties_cUnits_crud: ['AR', 'VC', 'SUPER_ADMIN'],

      };
    },
    mounted() {
      this.getCourses();
    },
    computed: {
          createdBy() {
          return (createdBy)=> {
            if(!createdBy) return "";
            return `${createdBy.first_name} ${createdBy.last_name}`;
          }
        }
      },

    methods: {

      // Iterate through each element in the
      // first array and if some of them 
      // include the elements in the second
      // array then return true.
      findCommonElements3(arr1, arr2) {
        return arr1.some(item => arr2.includes(item))
      },

      handleChange(value) {
          value();
      },

      handleClose(done) {
          done();
      },

      showAddCourseModal() {
        this.addCourseModal = true;  
        this.courseForm.title = "";
        this.courseForm.course_code = "";
        this.courseForm.duration_of_study = 1;
        this.courseForm.total_duration_type = "";
        this.courseForm.maximum_number_of_study_period = 1;
      },

            async addCourse(formName) {
                this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.submiting = true;
                    this.$http.post(`courses/add`, this.courseForm ).then(
                    (response) => {
                        if(response.data.success && response.data.message == "COURSE_ADDED_SUCCESSFULLY"){
                          this.$refs[formName].resetFields();
                          this.submiting = false;
                          this.getCourses();
                          this.showSuccessMessage("Success", "Programme added successfully");
                          this.addCourseModal = false;
                        }
                        else if(response.data.message == "TITLE_MUST_BE_UNIQUE"){
                          this.submiting = false;
                          this.showWarningMessage("Duplicate Entry","Title aleady in use");
                        }
                        else if(response.data.message == "COURSE_CODE_EXISTS"){
                          this.submiting = false;
                          this.showWarningMessage("Duplicate Entry","Code aleady in use");
                        }
                         else if (response.data.message == "TITLE_PATTERN_VALIDATION_FAILED") {

                          this.submiting = false;
                          this.showWarningMessage("Unaccepted characters","The title includes unaccepted characters");

                        } else if (response.data.message == "COURSE_CODE_PATTERN_VALIDATION_FAILED") {

                          this.submiting = false;
                          this.showWarningMessage("Unaccepted characters","The code includes unaccepted characters");

                        }
                    },
                    error => { // eslint-disable-line no-unused-vars
                        this.submiting = false;
                        if (error.data == "Network Error") {
                            return this.showFailedMessage("Connection failed","A network error occured, please try again.");
                        }
                        this.showFailedMessage("Unable to add Programme","An unexpected Error occured, please try again");
                        
                    });

                } else {
                    return false;
                }
                });
            },


      getCourses() {

        this.loading = true;
                this.loadingError = false;
                this.axios.get(`courses/fetch-many/department/${this.dId}`).then(
                  (response) => {
                    if ( response.data.success == true && "DEPARTMENT_COURSES_FETCHED") {
                      this.courses = response.data.courses;
                      this.departmentName = response.data.department_name;
                    } else {
                      this.loadingError = true;
                      this.showFailedMessage(
                        "Fetch Failed",
                        "An unexpected Error Occured. Please try again"
                      );
                    }
                  },
                () => {
                  this.loadingError = true;
                  //TODO: REPORT THIS ERROR TO A SERVICE
                  this.showFailedMessage(
                    "Fetch Failed",
                    "An unexpected Error Occured. Please try again"
                  );
                }
              )
              .finally(() => (this.loading = false));
      },

      coursesEditData(id, title, course_code, duration_of_study, duration_type, study_period_type, maximum_number_of_study_period ) {
        this.editCourseModal = true;
        this.course_id = id;
        this.courseForm.title = title;
        this.courseForm.course_code = course_code;
        this.courseForm.duration_of_study = duration_of_study;
        this.courseForm.total_duration_type = duration_type;
        this.courseForm.maximum_number_of_study_period = maximum_number_of_study_period;
      },

            async editCourse_final(formName) {
                this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.submiting_edit = true;
                    this.$http.patch(`courses/update-one/${this.course_id}`, this.courseForm ).then(
                    (response) => {
                        if(response.data.success && response.data.message == "COURSE_INFO_UPDATED"){
                          this.$refs[formName].resetFields();
                          this.submiting_edit = false;
                          this.getCourses();
                          this.showSuccessMessage("Success", "Programme edited successfully");
                          this.editCourseModal = false;
                        }
                        else if(response.data.message == "TITLE_MUST_BE_UNIQUE"){
                          this.submiting_edit = false;
                          this.showWarningMessage("Duplicate Entry","Title aleady in use");
                        }
                        else if(response.data.message == "COURSE_CODE_EXISTS"){
                          this.submiting_edit = false;
                          this.showWarningMessage("Duplicate Entry","Code aleady in use");
                        }
                        else if(response.data.message == "COURSE_NOT_FOUND"){
                          this.submiting_edit = false;
                          this.$refs[formName].resetFields();
                          this.getCourses();
                          this.editCourseModal = false;
                          this.showWarningMessage("Oooops!","The Programme  you are trying to edit might have been deleted");
                        } 
                        else if (response.data.message == "NOTHING_TO_CHANGE_EVERY_THING_IS_UP_TO_DATE") {

                          this.showWarningMessage("Already upto date","There is nothing to change.");

                        }
                         else if (response.data.message == "TITLE_PATTERN_VALIDATION_FAILED") {

                          this.submiting_edit = false;
                          this.showWarningMessage("Unaccepted characters","The title includes unaccepted characters");

                        } else if (response.data.message == "COURSE_CODE_PATTERN_VALIDATION_FAILED") {

                          this.submiting_edit = false;
                          this.showWarningMessage("Unaccepted characters","The code includes unaccepted characters");

                        }
                    },
                    error => { // eslint-disable-line no-unused-vars
                        this.submiting_edit = false;
                        if (error.data == "Network Error") {
                            return this.showFailedMessage("Connection failed","A network error occured, please try again.");
                        }
                        this.showFailedMessage("Unable to edit Programme","An unexpected Error occured, please try again");
                        
                    });

                } else {
                    return false;
                }
                });
            },

      deleteCourse(id) {
        return; // eslint-disable-line no-unreachable
        this.$confirm( // eslint-disable-line no-unreachable
          "This will permanently delete this Programme. Do you want to Continue?",
          "Confirm delete",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(() => {
            this.deleting = true;
            this.$http.delete(`courses/delete-one/${id}`).then(
              (response) => {
                if (response.data.success && response.data.message == "COURSE_DELETED_SUCCESFULLY") {
                  this.getCourses();
                  this.showSuccessMessage( "Success", "Programme deleted successfully!" );
                  this.deleting = false;
                } else {
                  this.showFailedMessage( "Warning", "Unable to delete Programme!" );
                  this.deleting = false;
                }
              },
              (error) => { // eslint-disable-line no-unused-vars
                this.showFailedMessage("Error", "Unable to delete Programme!");
                this.loading = false;
                this.deleting = false;
              }
            );
          })
          .catch(() => {
            
          });
      },

      goToCourseUnits(cId) {
        
        let title = this.courses.find((course) => {
              return course.course_id == cId
            }).title;
        
            this.$router.push({name: 'MyCourseUnits', params: {
              cId,
              title
            }});
      },
    },
  };
</script>

<style scoped>
  * {
    box-sizing: border-box;
  }

  input[type=text], select, textarea {
    width: 100%;
    padding: 10px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    background-color: #FFFFFF;
    font-size: .9em;
  }

  .search_add_section {
    display: flex; align-items: center; justify-content: space-between;
  }

  .search_by_input {
    width: 400px !important;
  }
  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
  }

  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    
  }

  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
      .search_add_section {
        display: block;
      }
      .addbtn {
        width: 100%;
        margin-top: 10px;
      }
      .search_by_input {
        width: 100% !important;
      }
  }

  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
      .search_add_section {
        display: block;
      }
      .addbtn {
        width: 100%;
        margin-top: 10px;
      }
      .search_by_input {
        width: 100% !important;
      }
  }
</style>

<style>

  th > .cell {
    color: rgba(0, 0, 0, 0.836) !important;
    font-weight: 600 !important;
    font-size: 13px !important;
  }
  el-table th > .cell {
    color: black !important;
    font-weight: 500;
  }
  .el-table .cell {
    font-size: 0.9em;
    line-height: 23px;
    font-weight: 300;
  }
  .course_request_form .el-input input {
    font-size: 0.8rem !important;
    font-weight: 400 !important;
    color: #495057 !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  }

  .course_request_form .el-input input:focus {
    color: #495057 !important;
    background-color: #fff !important;
    border-color: #80bdff !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
  }

  .course_dialogs .el-dialog {
    width: 60%;
  }

  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
    .course_dialogs .el-dialog {
      width: 60%;
    }
  }

  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .course_dialogs .el-dialog {
      width: 70%;
    }
  }

  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .course_dialogs .el-dialog {
      width: 90%;
    }
  }

  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .course_dialogs .el-dialog {
      width: 90%;
    }

  }

</style>
