var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.lectures.length != 0)?_c('div',{staticClass:"row w-100 mt-3 mx-0",staticStyle:{"border-top":"1px solid rgba(0, 0, 0, 0.185)","border-right":"1px solid rgba(0, 0, 0, 0.185)","border-left":"1px solid rgba(0, 0, 0, 0.185)"}},[_c('div',{staticClass:"calendar-item-column",staticStyle:{"border-right":"none !important"}},[_vm._m(0),_vm._l((_vm.lecturesTime),function(i){return _c('div',{key:i,staticClass:"w-100 pl-3",staticStyle:{"height":"70px","position":"relative","top":"-10px","font-size":"0.8em","font-weight":"500","color":"rgba(0, 0, 0, 0.4)"}},[_vm._v(" "+_vm._s(i)+" ")])})],2),_c('div',{staticClass:"calendar-item-column"},[_c('div',{staticClass:"caledar-header",class:{ 'is-active-header': _vm.dateToday == _vm.daysBetween[0] }},[_c('div',{class:{ 'is-active': _vm.dateToday == _vm.daysBetween[0] }},[_vm._v(" Sun "),_c('div',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.daysBetween[0].substr(8, 2)))])])]),_vm._l((_vm.lecturesTime),function(time){return _c('div',{key:time,staticClass:"w-100 content"},_vm._l((_vm.groupedLectures[_vm.daysBetween[0]]),function(lecture){return _c('div',{key:lecture.live_lecture_id},[(_vm.shouldDisplayEvent(time, lecture))?_c('EventItemComponent',{attrs:{"dateToday":_vm.dateToday,"event":{
            type: 'Lecture',
            dateToday: _vm.dateToday,
            data: lecture,
          }}}):_vm._e()],1)}),0)})],2),_c('div',{staticClass:"calendar-item-column"},[_c('div',{staticClass:"caledar-header",class:{ 'is-active-header': _vm.dateToday == _vm.daysBetween[1] }},[_c('div',{class:{ 'is-active': _vm.dateToday == _vm.daysBetween[1] }},[_vm._v(" Mon "),_c('div',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.daysBetween[1].substr(8, 2)))])])]),_vm._l((_vm.lecturesTime),function(time){return _c('div',{key:time,staticClass:"w-100 content"},_vm._l((_vm.groupedLectures[_vm.daysBetween[1]]),function(lecture){return _c('div',{key:lecture.live_lecture_id},[(_vm.shouldDisplayEvent(time, lecture))?_c('EventItemComponent',{attrs:{"dateToday":_vm.dateToday,"event":{
            type: 'Lecture',
            dateToday: _vm.dateToday,
            data: lecture,
          }}}):_vm._e()],1)}),0)})],2),_c('div',{staticClass:"calendar-item-column"},[_c('div',{staticClass:"caledar-header",class:{ 'is-active-header': _vm.dateToday == _vm.daysBetween[2] }},[_c('div',{class:{ 'is-active': _vm.dateToday == _vm.daysBetween[2] }},[_vm._v(" Tue "),_c('div',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.daysBetween[2].substr(8, 2)))])])]),_vm._l((_vm.lecturesTime),function(time){return _c('div',{key:time,staticClass:"w-100 content"},_vm._l((_vm.groupedLectures[_vm.daysBetween[2]]),function(lecture){return _c('div',{key:lecture.live_lecture_id},[(_vm.shouldDisplayEvent(time, lecture))?_c('EventItemComponent',{attrs:{"dateToday":_vm.dateToday,"event":{
            type: 'Lecture',
            dateToday: _vm.dateToday,
            data: lecture,
          }}}):_vm._e()],1)}),0)})],2),_c('div',{staticClass:"calendar-item-column"},[_c('div',{staticClass:"caledar-header",class:{ 'is-active-header': _vm.dateToday == _vm.daysBetween[3] }},[_c('div',{class:{ 'is-active': _vm.dateToday == _vm.daysBetween[3] }},[_vm._v(" Wed "),_c('div',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.daysBetween[3].substr(8, 2)))])])]),_vm._l((_vm.lecturesTime),function(time){return _c('div',{key:time,staticClass:"w-100 content"},_vm._l((_vm.groupedLectures[_vm.daysBetween[3]]),function(lecture){return _c('div',{key:lecture.live_lecture_id},[(_vm.shouldDisplayEvent(time, lecture))?_c('EventItemComponent',{attrs:{"dateToday":_vm.dateToday,"event":{
            type: 'Lecture',
            dateToday: _vm.dateToday,
            eventData: lecture.course_unit_code,
            data: lecture,
          }}}):_vm._e()],1)}),0)})],2),_c('div',{staticClass:"calendar-item-column"},[_c('div',{staticClass:"caledar-header",class:{ 'is-active-header': _vm.dateToday == _vm.daysBetween[4] }},[_c('div',{class:{ 'is-active': _vm.dateToday == _vm.daysBetween[4] }},[_vm._v(" Thu "),_c('div',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.daysBetween[4].substr(8, 2)))])])]),_vm._l((_vm.lecturesTime),function(time){return _c('div',{key:time,staticClass:"w-100 content"},_vm._l((_vm.groupedLectures[_vm.daysBetween[4]]),function(lecture){return _c('div',{key:lecture.live_lecture_id},[(_vm.shouldDisplayEvent(time, lecture))?_c('EventItemComponent',{attrs:{"dateToday":_vm.dateToday,"event":{
            type: 'Lecture',
            dateToday: _vm.dateToday,
            data: lecture,
          }}}):_vm._e()],1)}),0)})],2),_c('div',{staticClass:"calendar-item-column"},[_c('div',{staticClass:"caledar-header",class:{ 'is-active-header': _vm.dateToday == _vm.daysBetween[5] }},[_c('div',{class:{ 'is-active': _vm.dateToday == _vm.daysBetween[5] }},[_vm._v(" Fri "),_c('div',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.daysBetween[5].substr(8, 2)))])])]),_vm._l((_vm.lecturesTime),function(time){return _c('div',{key:time,staticClass:"w-100 content"},_vm._l((_vm.groupedLectures[_vm.daysBetween[5]]),function(lecture){return _c('div',{key:lecture.live_lecture_id},[(_vm.shouldDisplayEvent(time, lecture))?_c('EventItemComponent',{attrs:{"dateToday":_vm.dateToday,"event":{
            type: 'Lecture',
            data: lecture,
          }}}):_vm._e()],1)}),0)})],2),_c('div',{staticClass:"calendar-item-column",staticStyle:{"border-right":"none !important"}},[_c('div',{staticClass:"caledar-header",class:{ 'is-active-header': _vm.dateToday == _vm.daysBetween[6] }},[_c('div',{class:{ 'is-active': _vm.dateToday == _vm.daysBetween[6] }},[_vm._v(" Sat "),_c('div',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.daysBetween[6].substr(8, 2)))])])]),_vm._l((_vm.lecturesTime),function(time){return _c('div',{key:time,staticClass:"w-100 content"},_vm._l((_vm.groupedLectures[_vm.daysBetween[6]]),function(lecture){return _c('div',{key:lecture.live_lecture_id},[(_vm.shouldDisplayEvent(time, lecture))?_c('EventItemComponent',{attrs:{"dateToday":_vm.dateToday,"event":{
            type: 'Lecture',
            dateToday: _vm.dateToday,
            data: lecture,
          }}}):_vm._e()],1)}),0)})],2)]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"caledar-header",staticStyle:{"border-bottom":"none !important"}},[_vm._v(" Time "),_c('div',{staticClass:"mt-2",staticStyle:{"color":"transparent"}},[_vm._v("?")])])}]

export { render, staticRenderFns }