<template>
  <div class="container-fluid px-0 h-100" style="text-align: left;">
    <article>
      <section style="padding-top: 50px;">
        <div class="container">

          <div class="row w-100 mb-4">
            <div class="col-12 pl-3 px-0 mb-0 text-left">
              <BreadCrumbComponent :pageTitles="['Faculties']" />
            </div>
          </div>

          <div class="row w-100">
            <div class="col-12 text-left">
              <div style="font-size: 20px; font-weight: 400; color: var(--el-app-primary); margin-bottom: 15px; ">
                <strong>Faculties</strong>
              </div>
            </div>
          </div>

          <div class="search_add_section">
            <div class="">
              <input type="text" v-model.trim="filters[0].value" placeholder="Search by Faculty Title"  class="search_by_input" spellcheck="off"/>
            </div>
            <el-button class="z-depth-0 redBtn" v-ripple="'rgba(255, 255, 255, 0.35)'" @click="addFacultyModal = true">Add A Faculty</el-button>
          </div>
          <hr/>

              <div v-loading="deleting" class="table-responsive">
                <data-tables :data="faculties" :table-props="tableProps" :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }" type="expand" :filters="filters">
                  
                  <div slot="empty">
                    <ScaleOut v-if="loading && !loadingError" class="mt-3" :background="'#164B70'"/>
                    <div @click="getFaculties" v-else-if="!loading && loadingError" style="cursor: pointer">
                      Unable to Load Faculties Now. Please click here to retry
                    </div>
                    <div v-if="!loading && !loadingError">No Faculties</div>
                  </div>

                  <el-table-column label="#" :show-overflow-tooltip="true" label-class-name="text-center" class-name="text-center" width="80px">
                    <template slot-scope="scope">
                      <span>{{ scope.$index + 1 }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column label="Title" :show-overflow-tooltip="true" label-class-name="text-left" width="auto">
                    <template slot-scope="scope">
                      <span>{{ scope.row.title }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column label="Created by" :show-overflow-tooltip="true" label-class-name="text-left" class-name="text-left">
                    <template slot-scope="scope">
                      <span>{{ createdBy(scope.row.creator)}}</span>
                    </template>
                  </el-table-column>

                  <el-table-column label="View Departments" :show-overflow-tooltip="true" label-class-name="text-center">
                    <template slot-scope="scope">
                      <div style="display:flex; justify-content: center;">
                        <!-- <el-button type="success" @click="departments(scope.row.faculty_id)" round> {{ scope.row.departments.length }} Department</el-button> -->
                      
                        <el-button type="primary" @click="departments(scope.row.faculty_id)" round>
                          <span v-if="scope.row.departments == 1" >{{ scope.row.departments }} Department</span>
                          <span v-else>{{ scope.row.departments }} Departments</span>
                        </el-button>

                      </div>
                    </template>
                  </el-table-column> 

                  <el-table-column label="Actions" :show-overflow-tooltip="true" label-class-name="text-center" class-name="text-center">
                    <template slot-scope="scope">
                      <el-row>
                        <el-tooltip content="Edit" placement="top">
                          <el-button type="primary" :disabled="!findCommonElements3(faculties_cUnits_crud, $store.state.theRole)" @click="facultyEditData(scope.row.faculty_id, scope.row.title )" icon="el-icon-edit" circle></el-button>
                        </el-tooltip>
                        <el-tooltip content="Delete" placement="top">
                          <el-button type="danger" :disabled= "true" @click="deleteFaculty(scope.row.faculty_id)" icon="el-icon-delete" circle></el-button>
                        </el-tooltip>
                      </el-row>
                    </template>
                  </el-table-column>

                </data-tables>
              </div>
          
        </div>
      </section>

      <section>
            <div class="container">
              <div class="faculty_dialogs">
                <el-dialog title="Add A Faculty" 
                :visible.sync="addFacultyModal" :destroy-on-close="true" :before-close="handleClose">
                  <div v-loading="submiting">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="title">Faculty Title</label><br/>
                                    <input type="text" id="title" :class="{ 'input-error': $v.title.$error }" @keyup.enter="addFaculty" v-model.trim="$v.title.$model" placeholder="Title" class="">
                                    <span v-if="$v.title.$error" style="font-size: 0.7em; color: red">{{ titleError }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group text-center" style="padding-top:10px;">
                            <el-button style="background-color: var(--el-app-primary); position: relative; bottom: 9px; color: white;" class="z-depth-0" v-ripple="'rgba(255, 255, 255, 0.35)'" @click="addFaculty" :disabled="!$v.title.required">Add Faculty</el-button>
                        </div>

                    <div class="text-center">
                        <div class="text-center">
                            <small>© 2021 Pesamoni, All rights reserved.</small>
                        </div>
                        <div class="text-center">
                            <!-- <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                        </div>
                    </div>
                  </div>
                </el-dialog>

                <el-dialog title="Edit A Faculty" :visible.sync="editFacultyModal">
                  <div v-loading="submiting">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="title">Faculty Title</label>
                          <input type="text" :class="{ 'input-error': $v.title_edit.$error }" @keyup.enter="editFaculity_final" v-model.trim="$v.title_edit.$model" placeholder="Faculty Title" class=""><br/>
                          <span v-if="$v.title_edit.$error" style="font-size: 0.7em; color: red">{{ titleEditError }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group text-center" style="padding-top:10px;">
                      <el-button style="background-color: var(--el-app-primary); position: relative; bottom: 9px; color: white;" class="z-depth-0" v-ripple="'rgba(255, 255, 255, 0.35)'" @click="editFaculity_final" :disabled="!$v.title_edit.required">Edit Faculty</el-button>
                    </div>

                    <div class="text-center">
                        <div class="text-center">
                            <small>© 2021 Pesamoni, All rights reserved.</small>
                        </div>
                        <div class="text-center">
                            <!-- <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong></strong></a></small> -->
                        </div>
                    </div>
                  </div>
                </el-dialog>
              </div>
            </div>
      </section>
    </article>
  </div>
</template>

<script>

  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  import { ScaleOut } from "vue-loading-spinner";
  import BreadCrumbComponent from "../components/bread-crumb-component";

  export default {

    components: { ScaleOut, BreadCrumbComponent },
    mixins: [validationMixin],

      data() {
          return {
              addFacultyModal: false,
              editFacultyModal: false,
              loading: false,
              loadingError: false,
              submiting: false,
              deleting: false,
              responseData: "",
              title: "",
              title_edit: "",
              faculties: [],

              tableData: [],
              tableProps: {
                border: true,
                stripe: true,
              },

              filters: [
                {
                  prop: "title",
                  value: "",
                },
              ],

              faculties_cUnits_crud: ['AR', 'VC', 'SUPER_ADMIN'],

          };
      },

      computed: {
        titleError() {
          if (!this.$v.title.required) {
            return "*Title is required";
          }
          return "";
        },

        titleEditError() {
          if (!this.$v.title_edit.required) {
            return "*Title is required";
          }
          return "";
        },

        createdBy() {
          return (creator)=> {
            if(!creator) return "N/A";
            return `${creator.first_name} ${creator.last_name}`;
          }
        }
      },

      validations: {
        title: {
          required
        },
        title_edit: {
          required
        },
      },

      mounted() {
        this.getFaculties();
      },

      methods: {

        // Iterate through each element in the
        // first array and if some of them 
        // include the elements in the second
        // array then return true.
        findCommonElements3(arr1, arr2) {
          return arr1.some(item => arr2.includes(item))
        },

        handleClose(done) {
          this.clearInputs();
          this.$v.$reset();
          done();
        },

        clearInputs() {
          this.title = "";
          this.title_edit = "";
        },

        async addFaculty() {
          //If the function was triggered elsewhere e.g from pressing enter
          if (!this.$v.title.required) return;
          let facultyData = {
              title: this.title
          }
          
          this.submiting = true;

          try {
            let request = await this.$http.post(`faculties/add`, facultyData );
            if (
              request.data.success && request.data.message == "Faculty created successfully"
            ) {
                this.getFaculties();
                this.showSuccessMessage("Success","Faculty added successfully");
                this.title = "";
                this.addFacultyModal = false;
                this.clearInputs();
                this.$v.$reset();

            } else if (request.data.message == "TITLE_IS_ALREADY_IN_USE") {

              this.showWarningMessage("Warning","Faculty Title aleady exists");

            } else if (request.data.message == "TITLE_PATTERN_VALIDATION_FAILED") {

              this.showWarningMessage("Unaccepted characters","The title includes unaccepted characters");

            } else {
              throw "ERROR OCCURED";
            }
          } catch (error) {
            if (error.data == "Network Error") {

              return this.showFailedMessage("Connection failed","A network error occured, please try again.");
            
            }
            this.showFailedMessage("Unable to add Faculty","An unexpected Error occured, please try again");
          } finally {
            this.submiting = false;
          }
        },

        async getFaculties() {
          try {
            this.loading = true;
            this.loadingError = false;
            let request = await this.$http.get(`faculties/fetch-many`);
            if ( request.data.success && request.data.message == "FACULTIES_FETCHED_SUCCESSFULLY" ) { 
              this.faculties = request.data.faculties;
            } 
            else {throw "ERROR OCCURED"; }  
          } catch (error) {
            if (error.data == "Network Error") {

              this.loadingError = true;
              return this.showFailedMessage("Connection failed","A network error occured, please try again.");
            
            }

            this.loadingError = true; 
            this.showFailedMessage("Unable to load Faculties", "An unexpected Error occured, please try again");
          } finally {
            this.loading = false; 
          }
        },

        facultyEditData(id, title) {
          this.editFacultyModal = true;
          this.id_edit = id;
          this.title_edit = title;
        },
        
        async editFaculity_final() {
          //If the function was triggered elsewhere e.g from pressing enter
          if (!this.$v.title_edit.required) return;
          let facultyEditData = {
            title: this.title_edit,
          }
          this.submiting = true;

          try {
            let request = await this.$http.patch(`faculties/update-one/${this.id_edit}?projections=title`, facultyEditData );
            
            if ( request.data.success && request.data.message == "FACULTY_UPDATED_SUCCESSFULLY" ) {

              this.getFaculties();
              this.editFacultyModal = false;
              this.showSuccessMessage("Success", "Faculty edited successfully!");

              this.clearInputs();
              this.$v.$reset();

            } else if (request.data.message == "TITLE_IS_ALREADY_IN_USE") {

              this.showWarningMessage("Duplicate Entry","Faculty Title aleady in use");

            } else if (request.data.message == "NOTHING_TO_CHANGE_EVERY_THING_IS_UP_TO_DATE") {

              this.showWarningMessage("Already upto date","There is nothing to change.");

            }else if (request.data.message == "TITLE_PATTERN_VALIDATION_FAILED") {

              this.showWarningMessage("Unaccepted characters","The title includes unaccepted characters");

            } else {
              throw "ERROR OCCURED";
            }
          } 
          catch (error) {
            if (error.data == "Network Error") {

              return this.showFailedMessage("Connection failed","A network error occured, please try again.");
            
            }
            this.showFailedMessage("Unable to edit Faculty","An unexpected Error occured, please try again");
          } 
          finally {
            this.submiting = false;
          }
        },

        deleteFaculty(id) {
          return;
          // eslint-disable-line no-unreachable
          this.$confirm('This will permanently delete this Faculty. Do you want to Continue?', 'Confirm delete', { // eslint-disable-line no-unreachable
              confirmButtonText: 'OK',
              cancelButtonText: 'Cancel',
              type: 'warning'
          }).then(() => {
            this.deleting = true;
            this.$http.delete(`faculties/delete-one/${id}`).then((response) => {
              if ( response.data.success && response.data.message == "Deleted successfully") {
                this.getFaculties();
                this.showSuccessMessage("Success","Faculty deleted successfully!");
                this.deleting = false;
              }
            }, 
            error => { // eslint-disable-line no-unused-vars
              this.showFailedMessage("Error", "Unable to delete Faculty");
              this.deleting = false;
            });
                
            }).catch(() => {
                  
            }).finally(() => {
            this.deleting = false;
          });
          },

          departments(fId) {
            let facultyName = this.faculties.find((faculty) => {
              return faculty.faculty_id == fId
            }).title;
        
            this.$router.push({name: 'FacultyDetail', params: {
              fId,
              facultyName
            }});
          },
      }

  }

</script>

<style scoped>
  * {
    box-sizing: border-box;
  }
  
  input[type=text], select, textarea {
    width: 100%;
    padding: 10px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    background-color: #FFFFFF;
    font-size: .9em;
  }

  input:focus {
    border: 0.01em solid #409EFF !important;
  }

  .search_add_section {
    display: flex; align-items: center; justify-content: space-between;
  }

  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
  }

  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  }

  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .search_add_section {
      display: block; 
    }
    .addbtn {
      width: 100%;
      margin-top: 10px;
    }
      .search_by_input {
        width: 100% !important;
      }
  }

  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .search_add_section {
      display: block;
    }
    .addbtn {
      width: 100%;
      margin-top: 10px;
    }
      .search_by_input {
        width: 100% !important;
      }
  }
</style>

<style>
  
  .faculty_dialogs .el-dialog {
    width: 40%;
  }

  .search_by_input {
    width: 400px !important;
  }

  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
    .faculty_dialogs .el-dialog {
      width: 50%;
    }
  }

  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .faculty_dialogs .el-dialog {
      width: 70%;
    }
  }

  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .faculty_dialogs .el-dialog {
      width: 90%;
    }
  }

  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .faculty_dialogs .el-dialog {
      width: 90%;
    }

  }
</style>
