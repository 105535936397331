<template>
  <div
    class="container-fluid live-lectures px-0 h-100"
    style="text-align: left"
  >
    <article>
      <section style="padding-top: 50px">
        <div class="container">
          <div class="row w-100 mb-4">
            <div class="col-12 pl-3 px-0 mb-0 text-left">
              <BreadCrumbComponent :pageTitles="['Live Lectures']" />
            </div>
          </div>

          <div class="row w-100">
            <div class="col-12 text-left">
              <div
                style="
                  font-size: 20px;
                  font-weight: 400;
                  color: var(--el-app-primary);
                  margin-bottom: 15px;
                "
              >
                <strong>Live Lectures</strong>
              </div>
            </div>
          </div>

          <div
            style="
              margin-bottom: 20px;
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <div class="row w-100 d-flex">
              <div class="col-md-4 mb-3">
                <input
                  type="text"
                  v-model="filters[0].value"
                  :placeholder="searchInputFieldPlaceHolder"
                  spellcheck="off"
                />
              </div>
              <div class="col-3">
                <el-select
                  v-model="searchBy"
                  placeholder="Select"
                  class="d-block"
                >
                  <el-option
                    v-for="item in searchByOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="col-3">
                <el-select
                  v-model="selectedFilter"
                  placeholder="Select"
                  class="d-block"
                >
                  <el-option
                    v-for="item in filterOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <hr />

          <div v-loading="fetchingLiveClassId" style="width: 100%">
            <data-tables
              :data="liveLecturesData"
              :table-props="tableProps"
              :pagination-props="{ pageSizes: [25, 50, 100] }"
              type="expand"
              :filters="filters"
              ref="lecturesTable"
            >
              <div slot="empty">
                <ScaleOut
                  v-if="loading && !loadingError"
                  class="mt-3"
                  :background="'#164B70'"
                />
                <div
                  @click="getLiveLectures"
                  v-else-if="!loading && loadingError"
                  style="cursor: pointer"
                >
                  Unable to Load Live lectures Now. Please click here to retry
                </div>
                <div v-if="!loading && !loadingError">No Live lectures</div>
              </div>

              <el-table-column
                label="#"
                :show-overflow-tooltip="true"
                label-class-name="text-center"
                class-name="text-center"
                width="60px"
              >
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Batch"
                :show-overflow-tooltip="true"
                label-class-name="text-left"
                width="auto"
              >
                <template slot-scope="scope">
                  <span
                    >{{ scope.row.course_intake_batch.course.course_code }}
                    {{
                      toMonthName(
                        scope.row.course_intake_batch.month
                      ).toUpperCase()
                    }}/{{ scope.row.course_intake_batch.year }}</span
                  >

                  <!-- <span>{{ scope.row.title }}</span> -->
                </template>
              </el-table-column>

              <el-table-column
                label="Module"
                :show-overflow-tooltip="true"
                label-class-name="text-left"
                width="140px"
                prop="course_unit_code"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.course_unit.course_unit_code }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Lecturer"
                :show-overflow-tooltip="true"
                label-class-name="text-left"
                width="auto"
              >
                <template slot-scope="scope">
                  <span
                    >{{ scope.row.lecturer.first_name }}
                    {{ scope.row.lecturer.last_name }}
                  </span>
                </template>
              </el-table-column>

              <el-table-column label="Date" width="120px">
                <template slot-scope="scope">
                  <span>{{ formattedDate(scope.row.date) }}</span>
                </template>
              </el-table-column>

              <el-table-column label="Start time" width="90px">
                <template slot-scope="scope">
                  <span>{{ scope.row.start_time }}</span>
                </template>
              </el-table-column>

              <el-table-column label="End time" width="90px">
                <template slot-scope="scope">
                  <span>{{ scope.row.end_time }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Status"
                width="80px"
                class-name="text-center"
                label-class-name="text-center"
              >
                <template slot-scope="scope">
                  <el-tooltip
                    v-if="scope.row.ongoing_status === 'TODAY_AND_LIVE'"
                    content="Lecture is Live"
                    placement="top"
                  >
                    <i
                      class="fas fa-play color-red"
                      style="font-size: 20px"
                    ></i>
                  </el-tooltip>
                  <el-tooltip
                    v-if="
                      scope.row.date_relative == 1 ||
                      scope.row.ongoing_status === 'TODAY_AND_UPCOMING'
                    "
                    content="Upcoming"
                    placement="top"
                  >
                    <i class="el-icon-alarm-clock" style="font-size: 20px"></i>
                  </el-tooltip>
                  <el-tooltip
                    v-else-if="
                      scope.row.date_relative == -1 ||
                      scope.row.ongoing_status === 'TODAY_AND_ENDED'
                    "
                    :content="toolTipContent(scope.row)"
                    placement="top"
                  >
                    <i
                      @click="loadAttendanceInfo(scope.row.live_lecture_id)"
                      :class="{
                        'el-icon-warning-outline': !scope.row.class_status,
                        'el-icon-loading': scope.row.class_status == 'Loading',
                        'el-icon-close color-red':
                          scope.row.class_status == 'Missed',
                        'el-icon-check color-green':
                          scope.row.class_status == 'Attended',
                      }"
                      style="font-size: 20px; cursor: pointer; color: #ffbb33"
                    ></i>
                  </el-tooltip>
                </template>
              </el-table-column>

              <el-table-column
                label="Actions"
                width="120px"
                class-name="text-center"
                label-class-name="text-center"
              >
                <template slot-scope="scope">
                  <div>
                    <el-tooltip content="Details" placement="top">
                      <el-button
                        @click="getLectureDetails(scope.row.live_lecture_id)"
                        type="primary"
                        icon="el-icon-edit"
                        circle
                      ></el-button>
                    </el-tooltip>
                    <el-tooltip content="Attend" placement="top">
                      <el-button
                        @click="onAttendLecture(scope.row)"
                        type="success"
                        icon="el-icon-position"
                        circle
                      ></el-button>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
            </data-tables>
          </div>
        </div>
      </section>
    </article>

    <LectureDetailsDialog
      :lectureDetailsLoading="lectureDetailsLoading"
      :isVisible="isLectureDetailsModelVisible"
      :beforeClose="beforeDialogClose"
      :liveLectureDetails="liveLectureDetails"
      @delete-live-lecture="onDeleteLiveLecture"
    />
  </div>
</template>

<script>
import { ScaleOut } from "vue-loading-spinner";
import { format, parseISO } from "date-fns";
import BreadCrumbComponent from "../components/bread-crumb-component";
import getMonthName from "../helpers/getMonthName";
import LectureDetailsDialog from "../components/dialogs/lecture-details-dialog";

export default {
  components: { ScaleOut, BreadCrumbComponent, LectureDetailsDialog },

  data() {
    return {
      selectedFilter: "Show All",
      searchBy: "Search by Lecturer",
      fetchingLiveClassId: null,
      isLectureDetailsModelVisible: false,
      lectureDetailsLoading: true,
      filterOptions: [
        "Show All",
        "Show Only Ongoing",
        "Show Only Today",
        "Show Only Upcoming Lectures",
      ],
      searchByOptions: [
        "Search by Lecturer",
        "Search By Batch",
        "Search by Module Code",
      ],
      loading: false,
      loadingError: false,
      live_lectures: [],
      liveLectureDetails: {},
      tableProps: {
        border: true,
        stripe: true,
        // defaultSort: {
        //   prop: "date",
        //   order: "ascending",
        // },
      },

      filters: [
        {
          prop: "course_unit_code",
          value: "",
          filterFn: (row, filterItem) => {
            // if (!row.course_intake_batch.department) return false;
            if (this.searchBy == "Search By Batch") {
              return `${
                row.course_intake_batch.course.course_code
              } ${this.toMonthName(row.course_intake_batch.month)}/${
                row.course_intake_batch.year
              }`
                .toLowerCase()
                .includes(filterItem.value.toLowerCase().trim());
            } else if (this.searchBy == "Search by Lecturer") {
              return `${row.lecturer.first_name} ${row.lecturer.last_name}`
                .toLowerCase()
                .includes(filterItem.value.toLowerCase().trim());
            } else if (this.searchBy == "Search by Module Code") {
              return row.course_unit.course_unit_code
                .toLowerCase()
                .includes(filterItem.value.toLowerCase().trim());
            }

            return false;
          },
        },
        {
          prop: "title",
          value: "",
        },
        {
          prop: "title",
          value: "",
        },
      ],
    };
  },
  watch: {
    searchBy() {
      this.filters[0].value = "";
      // this.$refs.lecturesTable.clearFilter();
    },
  },
  computed: {
    toolTipContent() {
      return (lecture) => {
        if (lecture.class_status === "Loading") {
          return "Please Wait...";
        } else if (lecture.class_status === "Attended") {
          return "Lecture was attended";
        } else if (lecture.class_status === "Missed") {
          return "Lecture was missed";
        } else return "Click to view status";
      };
    },
    toMonthName() {
      return (monthNum) => {
        return getMonthName(monthNum);
      };
    },
    liveLecturesData() {
      return this.live_lectures.filter((lecture) => {
        // if (!lecture.course_intake_batch.department) return false;
        if (this.selectedFilter === "Show Only Today")
          return lecture.date_relative === 0;
        if (this.selectedFilter === "Show Only Upcoming Lectures")
          return (
            lecture.date_relative >= 0 &&
            lecture.ongoing_status !== "TODAY_AND_ENDED"
          );
        if (this.selectedFilter === "Show Only Ongoing")
          return lecture.ongoing_status === "TODAY_AND_LIVE";

        return true;
      });
    },
    searchInputFieldPlaceHolder() {
      if (this.searchBy === "Search By Batch") {
        return "Search By Batch e.g CIA1 JAN/2021";
      } else if (this.searchBy === "Search by Lecturer") {
        return "Search By Lecturer's Name";
      } else if (this.searchBy === "Search by Module Code") {
        return "Search by Module Code";
      } else return "";
    },
    formattedDate() {
      return (dateString) => format(parseISO(dateString), "do MMM yyyy");
    },
  },
  mounted() {
    this.getLiveLectures();
  },
  methods: {
    onAttendLecture(lecture) {
      if (
        lecture.date_relative == -1 ||
        lecture.ongoing_status === "TODAY_AND_ENDED"
      ) {
        //If Lecture already ended, then  just fetch any url as we shall get a recording back if it exists
        return this.fetchLiveLectureUrl(lecture.live_lecture_id);
      }

      //Otherwise means the lecture is either ongoing or is in the future.
      this.$alert(
        "Please Choose How you want to attend this Lecture, either as a Student or as an Administrator with ability to provide assistance.",
        "Attend Lecture",
        {
          confirmButtonText: "Attend as Student",
          cancelButtonText: "Attend as Admin",
          showCancelButton: true,
          distinguishCancelAndClose: true,
          closeOnClickModal: true,
          closeOnPressEscape: true,

          // type: "warning",
          callback: (action) => {
            if (action == "confirm") {
              return this.fetchLiveLectureUrl(lecture.live_lecture_id);
            } else if (action == "cancel") {
              return this.fetchLiveLectureUrl(lecture.live_lecture_id, true);
            }
          },
        }
      );
    },
    async fetchLiveLectureUrl(lectureId, isLecturer) {
      if (this.fetchingLiveClassId != null) return;

      const user = isLecturer ? "lecturer" : "student";
      try {
        this.fetchingLiveClassId = lectureId;
        let request = await this.$http.get(
          `live-lecture/get-${user}-class-url/by-live-lecture-id/${lectureId}`
        );
        //  let request = await this.$http.get(
        //   `live-lecture/get-lecturer-class-url/by-live-lecture-id/${lectureId}`
        // );
        if (
          request.data.success &&
          !!request.data.classUrl &&
          request.data.message === "CLASS_URL_RETRIEVED"
        ) {
          this.fetchingLiveClassId = null;
          this.$router.push({
            name: "LiveLecture",
            params: {
              classUrl: request.data.classUrl,
            },
          });
        } else if (request.data.message == "CLASS_ALREADY_ENDED") {
          this.fetchingLiveClassId = null;

          this.showWarningMessage(
            "Class Ended",
            "The Lecture you are trying to take ended already and Class Recording is not available now."
          );
        } else if (request.data.message == "CLASS_DOES_NOT_EXIST") {
          this.fetchingLiveClassId = null;
          this.showWarningMessage(
            "Class Unavailable",
            "The Lecture you are trying to take isn't available anymore"
          );
        } else if (request.data.message == "LECTURER_HAS_NOT_BEGAN_YET") {
          this.fetchingLiveClassId = null;
          this.showWarningMessage(
            "Lecturer Not Available",
            "In order to Attend as an admin, the Lecturer should already be teaching "
          );
        }
        //Try to check if instead a class Recording URL was sent
        else if (request.data.message == "CLASS_RECORDING_URL_RETRIEVED") {
          this.fetchingLiveClassId = null;
          this.$notify({
            title: "Recorded Lecture",
            type: "info",
            showClose: false,
            message: "A recording for this Lecture was found.",
          });

          this.$router.push({
            name: "RecordedLecture",
            params: { recordedLectureInfo: request.data.recorded_class_info },
          });
        } else throw "UNEXPECTED_RESPONSE";
      } catch (error) {
        this.fetchingLiveClassId = null;

        this.showFailedMessage(
          "Fetching failed",
          "Unable to fetch Live Class Now, please try again"
        );
      }
    },

    async onDeleteLiveLecture(lectureId) {
      this.lectureDetailsLoading = true;
      try {
        let request = await this.$http.delete(`live-lecture/${lectureId}`);
        if (
          request.data.success &&
          request.data.message === "DELETED_LIVE_LECTURE_SUCCESSFULLY"
        ) {
          this.showSuccessMessage(
            "Lecture Cancelled",
            "The Selected Lecture was successfully cancelled."
          );
          this.isLectureDetailsModelVisible = false;

          //Remove the deleted Lecture from our array instead of makin a network request
          this.liveLectureDetails = {};
          this.getLiveLectures();
        }
        //If Lecture was attended it cant be deleted or cancelled
        else if (request.data.message === "LECTURE_WAS_ALREADY_ATTENDED") {
          this.showFailedMessage(
            "Lecture already attended",
            "An attended Lecture can not be cancelled, unfortunately."
          );
        }
        //Means Live Lecture was deleted elswhere
        else if (
          request.data.message === "NO_LIVE_LECTURE_WITH_MATCHING_ID_FOUND"
        ) {
          this.showFailedMessage(
            "Unable to Cancel",
            "Unable to Cancel the Lecture, Please try again"
          );

          //Close the Dialog
          this.isLectureDetailsModelVisible = false;

          //Refetch All Lectures
          this.getLiveLectures();
        } else throw "UNEXPECTED_RESPONSE";
      } catch (error) {
        this.showFailedMessage(
          "Unable to Cancel",
          "Unable to Cancel the Lecture, Please try again"
        );
      } finally {
        this.lectureDetailsLoading = false;
      }
    },

    beforeDialogClose() {
      this.isLectureDetailsModelVisible = false;
    },

    async getLectureDetails(lectureId) {
      this.isLectureDetailsModelVisible = true;
      this.lectureDetailsLoading = true;
      try {
        let request = await this.$http.get(
          `live-lecture/fetch-one/${lectureId}`
        );
        if (
          request.data.success &&
          request.data.message === "LIVE_LECTURE_DETAILS_RETRIEVED_SUCCESSFULLY"
        ) {
          this.liveLectureDetails = request.data.live_lecture;
        } else if (
          request.data.message === "NO_LECTURE_WITH_MATCHING_ID_FOUND"
        ) {
          //If we cant get that lecture, it means probably it was deleted elsewhere, so we close the Dialog and refresh the table
          this.getLiveLectures();
          this.isLectureDetailsModelVisible = false;
          this.showFailedMessage(
            "Fetch Failed",
            "Unable to retrieve Lecture Details"
          );
        } else throw "UNEXPECTED_RESPONSE";
      } catch (error) {
        this.isLectureDetailsModelVisible = false;

        this.showFailedMessage(
          "Fetch Failed",
          "Unable to retrieve Lecture Details"
        );
      } finally {
        this.lectureDetailsLoading = false;
      }
    },

    async loadAttendanceInfo(lectureId) {
      let lecture = this.live_lectures.find(
        (lecture) => lecture.live_lecture_id == lectureId
      );
      //If the attendance state of the live lecture was already loaded, dont check again
      if (
        lecture.class_status === "Attended" ||
        lecture.class_status === "Missed"
      )
        return;

      lecture.class_status = "Loading";

      //Make a request to get Class Attendance Info
      try {
        let request = await this.$http.get(
          `live-lecture/fetch-user-attendance?class_id=${lecture.class_id}&user_id=${lecture.lecturer.staff_id}&user_type=Staff`
        );
        if (
          request.data.success &&
          request.data.message === "ATTENDANCE_RETRIEVED_SUCCESSFULLY" &&
          request.data.attendance
        ) {
          lecture.class_status =
            request.data.attendance.attended == true ? "Attended" : "Missed";
        }
        //If Class was deleted, then refresh our table cos it means this class was deleted elsewhere so we reload the table
        else if (request.data.message === "CLASS_DOES_NOT_EXIST") {
          this.showFailedMessage(
            "Lecture Does not exist",
            "Unable to fetch Attendance for the specified Lecture"
          );
          this.getLiveLectures();
        } else throw "UNEXPECTED_RESPONSE";
      } catch (error) {
        lecture.class_status = "";
        this.showFailedMessage(
          "Failed",
          "Unable to fetch Attendance for the specified Lecture"
        );
      }
    },
    async getLiveLectures() {
      try {
        this.loading = true;
        this.loadingError = false;
        let request = await this.$http.get("live-lecture/fetch-all");
        if (
          request.data.success &&
          request.data.message == "LECTURES_RETRIEVED_SUCCESSFULLY"
        ) {
          this.live_lectures = request.data.lectures;
        } else {
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        // eslint-disable-line no-unused-vars
        if (error.data == "Network Error") {
          this.loadingError = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occured, please try again."
          );
        }

        this.loadingError = true;
        this.showFailedMessage(
          "Unable to load Live Lectures",
          "An unexpected Error occured, please try again"
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
.live-lectures .row .el-select .el-input__inner {
  cursor: pointer;
  height: 40px !important;
  width: 100% !important;
  padding-left: 10px !important;
  text-transform: capitalize;
}
.live-lectures .el-button--primary {
  color: #fff;
  background-color: #409eff !important;
  border-color: #409eff !important;
}
</style>

<style scoped>
input[type="text"],
select,
textarea {
  width: 100%;
  height: 36px !important;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}

.color-red {
  color: red !important;
}
.color-green {
  color: rgb(7, 206, 7) !important;
}
</style>
