<template>
  <div class="container-fluid px-0 h-100" style="text-align: left;">
    <article>
      <section style="padding-top: 50px;">
        <div class="container">

          <div class="row w-100 mb-4">
            <div class="col-12 pl-3 px-0 mb-0 text-left">
              <BreadCrumbComponent :pageTitles="['Intakes', 'Assign Lecturers to Modules in this Intake']" />
            </div>
          </div>

          <div class="row w-100">
            <div class="col-12 text-left">
              <div style="font-size: 20px; font-weight: 400; color: var(--el-app-primary); margin-bottom: 15px; ">
                <strong>{{course_code}} {{batch.year}} {{ toMonthName(batch.month) }} Intake</strong>
              </div>
            </div>
          </div>

          <div style="margin-bottom:20px; display: flex; align-items: center; justify-content: space-between;">
            <div class="row w-100 d-flex">
              <div class="col-md-5 mb-3">
                <input type="text" v-model="filters[0].value" placeholder="Search by name"  class="search_by_input" spellcheck="off"/>
              </div>
            </div>
          </div>
          <hr/>
          <!-- {{ course_units | json }} -->
        <div v-loading="deleting" style="width: 100%">
          <data-tables :data="course_units" :table-props="tableProps" :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }" type="expand" :filters="filters">
            
            <div slot="empty">
              <ScaleOut v-if="loading && !loadingError" class="mt-3" :background="'#164B70'"/>
              <div @click="getOneBatchById" v-else-if="!loading && loadingError" style="cursor: pointer;">
                Unable to Load Modules in this Intake Now. Please click here to retry
              </div>
               <div v-if="!loading && !loadingError">No Modules in this Intake</div>
            </div>

            <el-table-column label="#" :show-overflow-tooltip="true" label-class-name="text-left" width="30px">
              <template slot-scope="scope">
                <span>{{ scope.$index + 1 }}</span>
              </template>
            </el-table-column>

            <el-table-column label="Modules" :show-overflow-tooltip="true" label-class-name="text-left">
              <template slot-scope="scope">
                <span>{{ scope.row.title }} ({{ scope.row.course_unit_code }})</span><br/>
                <!-- <span>Year: {{ scope.row.year_of_study }}, Semester: {{ scope.row.period_of_study }}</span> -->
              </template>
            </el-table-column>

            <el-table-column label="Assigned Lecturers" :show-overflow-tooltip="true" label-class-name="text-left">
              <template slot-scope="scope">
                <div>
                  <ul style="display: flex;  flex-wrap: wrap; margin:0px; list-style:none; padding:0px">
                    <li class="lecturer" v-for="lecturer in scope.row.course_unit_intake_staff" :key="lecturer.staff_id" style="margin:5px; border-radius: 50px; list-style:none; background-color: #eeeeee; position: relative;">
                      <div v-if="lecturer.first_name" style="display: flex; border-radius: 50px;">
                        <div class="profile_img">
                          <img :src="lecturer.profile_picture" v-if="lecturer.profile_picture"  alt="" class="img-fluid" />
                          <div v-if="lecturer.profile_picture == null" style="display: flex; align-items:center; justify-content: center; margin-right:5px; background-color: var(--vu-red); color: white; width:30px; height:30px; object-fit:cover; border-radius: 100%;">
                            <strong>{{ getInitials(`${lecturer.first_name} ${lecturer.last_name}`) }}</strong>
                          </div>
                        </div>
                        <div style="display: flex; align-items: center;margin-right:5px;">
                          <small>{{lecturer.first_name}}</small> 
                        </div>
                      </div>
                      <div 
                        class="deleteLecturer" 
                        @click="deleteLecturer(lecturer.staff_id, scope.row.course_unit_id)" 
                        style="position:absolute; top:0px; right:0px; color: red;"
                      >
                        <i 
                        :class="[findCommonElements3(assign_staff, $store.state.theRole)?'show_list':'hide_list', 'far fa-times-circle']"
                        ></i>
                      </div>
                    </li>
                    <li :class="findCommonElements3(assign_staff, $store.state.theRole)?'show_list':'hide_list'">
                      <el-tooltip content="Click to assign Lecturer to this Modules." placement="top">
                        <el-button @click="batchEditData(scope.row.course_unit_id, batch.course_intake_batch_id)" type="success" class="z-depth-0" v-ripple="'rgba(255, 255, 255, 0.35)'" icon="el-icon-plus" circle></el-button>
                      </el-tooltip>
                    </li>
                  </ul>
                </div>
              </template>
            </el-table-column>
            
          </data-tables>
        </div>
          </div>
      </section>

      <section>
          <div class="container batch_dialogs">
            <el-dialog title="Assign Staff Member" :visible.sync="assignLecturerModal">
              <div v-loading="loading">

                <div class="row">
                  <div class="col-md-12">
                    <el-select v-model="assign_lecturers" filterable placeholder="Choose Lecturer" style="width: 100%; height: 100px;">
                      <el-option v-for="lr in lecturers" :key="lr.staff_id" :label="`${lr.first_name} ${lr.last_name}`" :value="lr.staff_id">

                      </el-option>
                    </el-select>
                  </div>
                </div>

                <div class="form-group text-center" style="padding-top:10px;">
                    <el-button style="background-color: var(--vu-red); color: white;" class="z-depth-0" v-ripple="'rgba(255, 255, 255, 0.35)'"
                       v-on:click="assign_final()" >Save Changes to Assign
                    </el-button>
                </div>

                <div class="text-center">
                    <div class="text-center">
                        <small>© 2021 Pesamoni, All rights reserved.</small>
                    </div>
                    <div class="text-center">
                        <!-- <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong></strong></a></small> -->
                    </div>
                </div>
              </div>
            </el-dialog>
          </div>
      </section>

    </article>
  </div>
</template>

<script>

  import { ScaleOut } from "vue-loading-spinner";
  import BreadCrumbComponent from "../components/bread-crumb-component";
  import getMonthName from "../helpers/getMonthName";

  export default {

    components: { ScaleOut, BreadCrumbComponent  },

    data() {
      return {
        assignLecturerModal: false,
        loading: false,
        loadingError: false,
        submiting: false,
        deleting: false,
        lecturers: [],
        cbId: this.$route.params.cbId, //this is the faculty id from the browserresource: '',
        batch: "",
        course_units: [],
        tableProps: {
          border: true,
          stripe: true,
        },
        filters: [
          {
            prop: "year",
            value: "",
          },
        ],
        year: "",
        month: "",
        created_by: "",
        course_id: "",
        course_intake_batch_id: "",
        course_unit_id: "",
        staff_id: [],
        assign_lecturers: [],
        courseName: "",
        course_code: "",

        assign_staff: ['DEAN', 'HOD', 'VC', 'SUPER_ADMIN'],

      }
    },

    computed: {

      getInitials() {
        return (nameString) => {
          //  format(parseISO(dateString), "do MMMM yyyy");
          var names = nameString.split(" "),
            initials = names[0].substring(0, 1).toUpperCase();
          if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
          }
          return initials;
        };
      },

    },

    mounted() {
      this.getOneBatchById();
    },

    methods: {

      // Iterate through each element in the
      // first array and if some of them 
      // include the elements in the second
      // array then return true.
      findCommonElements3(arr1, arr2) {
        return arr1.some(item => arr2.includes(item))
      },

      toMonthName(monthNum){
        return getMonthName(monthNum, true);
      },

      async getOneBatchById() {
          try {
            this.loading = true;
            this.loadingError = false;
            let request = await this.$http.get(`course-intake-batches/fetch-one/${this.cbId}`);
            if ( request.data.success && request.data.message == "COURSE_INTAKE_BATCH_FETCHED_SUCCESSFULLY" ) { 
              this.batch = request.data.CourseIntakeBatch;
              this.course_units = this.batch.course.course_units;
              this.courseName = this.batch.course.title;
              this.course_code = this.batch.course.course_code;
            } 
            else { throw "ERROR OCCURED"; }
          } catch (error) {
            if (error.data == "Network Error") {

              this.loadingError = true;
              return this.showFailedMessage("Connection failed","A network error occured, please try again.");
            
            }
            this.showFailedMessage("Unable to load Course Intake data","An unexpected Error occured, please try again");
          } finally {
            this.loading = false;
          }
      },

       
      async getAllLecturers() {
          try {
            this.loading = true;
            this.loadingError = false;
            let request = await this.$http.get(`staff/fetch-many?only_names=true`);
            if ( request.data.success && request.data.message == "STAFF_MEMBERS_FETCHED" ) { 
              this.lecturers = request.data.staffs;
            } 
            else { throw "ERROR OCCURED"; } 
          } catch (error) {
            if (error.data == "Network Error") {

              this.loadingError = true;
              return this.showFailedMessage("Connection failed","A network error occured, please try again.");
            
            }
            this.showFailedMessage("Unable to load Lecturers","An unexpected Error occured, please try again");
          } finally {
            this.loading = false;
          }
      },

      batchEditData(cuId, bId) {
        this.getAllLecturers();
        this.assignLecturerModal = true;
        this.course_intake_batch_id = bId;
        this.course_unit_id = cuId;
      },
            
      assign_final() {
        let assignData = {
          course_intake_batch_id: this.course_intake_batch_id ,
          course_unit_id: this.course_unit_id,
          staffs: [this.assign_lecturers]
        }
        
        this.loading = true;
        this.axios.post(`staff/batches`, assignData).then((response) =>{
                  
        if (response.data.success && response.data.message == "saved sucessfully") {
          this.getAllLecturers();
          this.getOneBatchById();
          this.assignLecturerModal = false;
          this.showSuccessMessage("Success", "Staff assigned successfully!");
          this.loading = false;
        } else if ( response.data.message == "STAFF_ALREADY_ASSIGNED_TO_THIS_BATCH" ) {
          this.showFailedMessage("Duplicate entry", "Staff already assigned to this Intake");
          this.loading = false;
        }
        }, 
        error => { // eslint-disable-line no-unused-vars
          this.showFailedMessage("Warning", "Unable to assign staff");
          this.loading = false;
        });
      },

      deleteLecturer(staff_id, course_unit_id) {
        this.$confirm('Do you want to unassign this lecturer from this Intake?', 'Comfirm removal', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          this.deleting = true;
          this.axios.delete(`course-intake-batches/remove-lecturer-from-batch-course-unit/${staff_id}/${course_unit_id}/${this.cbId}`).then((response) => {
            if (response.data.success && response.data.message == "REMOVED_SUCCESS_FULLY") {
              this.getOneBatchById();
              this.showSuccessMessage("Success","Staff removed successfully!");
              this.deleting = false;
            }
          }, 
          error => { // eslint-disable-line no-unused-vars
            this.showFailedMessage("Error", "Unable to delete Staff");
          });
          }).catch(() => {
             
          }).finally(() => {
            this.deleting = false;
          });
        }
      },
      
  }
</script>

<style scoped>

    * {
      box-sizing: border-box;
    }

    
    .deleteLecturer { display: none }
    .lecturer:hover .deleteLecturer { display: block; cursor: pointer; }
    

    /* .profile_img {
    } */
    .profile_img img{
        border-radius:50%; width:30px; height:30px; object-fit:cover;
        margin-right:5px;
    }
    .to-an-array{
      display: flex;
    }

    .to-an-array>div{
      margin-right: 20px;
      background-color: #eeeeee;
      padding: 10px 10px 0px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    #myInput {
      background-image: url('/css/searchicon.png');
      background-position: 10px 10px;
      background-repeat: no-repeat;
      width: 100%;
      font-size: 14px;
      padding: 5px 20px 5px 40px;
      border: 1px solid #ddd;
    }
    
    #myTable {
      border-collapse: collapse;
      width: 100%;
      border: 1px solid #ddd;
      font-size: 16px;
    }
    
    #myTable th, #myTable td {
      text-align: left;
      padding: 12px;
    }
    
    h2 {
      text-align: center;
      padding: 20px 0;
    }
    
    .table-bordered {
      border: 1px solid #ddd !important;
    }
    
    table caption {
      padding: .5em 0;
    }
    
    @media screen and (max-width: 767px) {
      table caption {
        display: none;
      }
    }
    
    .p {
      text-align: center;
      padding-top: 140px;
      font-size: 14px;
    }

    th > .cell {
      color: rgba(0, 0, 0, 0.836) !important;
      font-weight: 600 !important;
      font-size: 13px !important;
    }
    el-table th > .cell {
      color: black !important;
      font-weight: 500;
    }
    .el-table .cell {
      font-size: 0.9em;
      line-height: 23px;
      font-weight: 300;
    }
  .el-input input {
    height: calc(1.55rem) !important;
    padding: 0.075rem 0.05rem !important;
    font-size: 0.8rem !important;
    font-weight: 400 !important;
    color: #495057 !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  }

  .el-input input:focus {
    color: #495057 !important;
    background-color: #fff !important;
    border-color: #80bdff !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
  }
  
  input[type=text], select, textarea {
    width: 100%;
    padding: 9px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    background-color: #FFFFFF;
    font-size: .9em;
  }

  .search_by_input {
    width: 400px !important;
  }


/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  
}


/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .search_by_input {
    width: 100% !important;
  }
}


/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .search_by_input {
    width: 100% !important;
  }
}


/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .search_by_input {
    width: 100% !important;
  }
}
</style>

<style>
  .batch_request_form .el-select .el-input__inner {
    cursor: pointer;
    height: 36px !important;
    width: 100% !important;
    padding-left: 10px !important;
    text-transform: capitalize;
  }
  .batch_request_form .el-button--primary {
    color: #fff;
    background-color: #409eff !important;
    border-color: #409eff !important;
  }

  el-table th > .cell {
    color: black !important;
    font-weight: 500;
  }
  .el-table .cell {
    font-size: 0.9em;
    line-height: 23px;
    font-weight: 300;
  }

  .batch_request_form .el-input input {
    font-size: 0.8rem !important;
    font-weight: 400 !important;
    color: #495057 !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  }

  .batch_request_form .el-input input:focus {
    color: #495057 !important;
    background-color: #fff !important;
    border-color: #80bdff !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
  }

  .batch_dialogs .el-dialog {
    width: 50%;
  }
  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
    .batch_dialogs .el-dialog {
      width: 50%;
    }
  }

  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .batch_dialogs .el-dialog {
      width: 70%;
    }
  }

  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .batch_dialogs .el-dialog {
      width: 90%;
    }
  }

  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .batch_dialogs .el-dialog {
      width: 90%;
    }
  }
</style>