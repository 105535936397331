<template>
  <div class="container-fluid px-0 h-100" style="text-align: left">
    
    <article>
      <section style="padding-top: 50px">
        <div class="container">
          <div class="row w-100">
            <div class="col-12 text-left">
              <div
                style="
                  font-size: 20px;
                  font-weight: 400;
                  color: var(--el-app-primary);
                  margin-bottom: 15px;
                "
              >
                <strong>University Staff</strong><br />
              </div>
            </div>
          </div>

          <div class="search_add_section">
            <div class="">
              <input type="text" v-model.trim="filters[0].value" placeholder="Search by Name"  class="search_by_input" spellcheck="off"/>
            </div>
            <div style="display: flex;" class="addBtn">
              <el-button 
              :class="[findCommonElements3(staff_crud, $store.state.theRole)?'show_list':'hide_list', 'redBtn']"
              style="width: 150px; position: relative; bottom: 0px;" 
              class="z-depth-0" @click="openStaffModal">Add Staff</el-button>
              <el-button 
              :class="[findCommonElements3(staff_crud, $store.state.theRole)?'show_list':'hide_list', 'redBtn']"
              style="width: 150px;"
              class="z-depth-0" @click="addMultipleStaffModal = true">Import File</el-button>
            </div>
          </div>
          <hr/>

          <div v-loading="deleting" class="table-responsive">
            <data-tables :data="lecturers" :table-props="tableProps" :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }" type="expand" :filters="filters">
              
              <div slot="empty">
                <ScaleOut v-if="loading && !loadingError" class="mt-3" :background="'#164B70'"/>
                <div @click="getStaff" v-else-if="!loading && loadingError" style="cursor: pointer">
                  Unable to Load Staff Member Now. Please click here to retry
                </div>
                <div v-if="!loading && !loadingError">No Staff Members</div>
              </div>
              
              <el-table-column label="#" :show-overflow-tooltip="true" label-class-name="text-center" class-name="text-center" width="50px">
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <el-table-column label="Staff name" :show-overflow-tooltip="true" abel-class-name="text-left" width="auto">
                <template slot-scope="scope">
                  <div style="display:flex; align-items: center;">
                    <div style="width:40px; height:40px; border-radius: 50%;">
                      <div v-if="scope.row.profile_picture" style="display: flex; align-items:center; justify-content: center; background-color: var(--vu-red); color: white; width:40px; height:40px; object-fit:cover; border-radius: 100%;">
                        <img :src="scope.row.profile_picture" class="img-fluid" style="width:40px; height:40px; object-fit:cover; border-radius: 100%;" />
                      </div>
                      <div v-if="scope.row.profile_picture == null" style="display: flex; align-items:center; justify-content: center; background-color: var(--vu-red); color: white; width:40px; height:40px; object-fit:cover; border-radius: 100%;">
                        <strong>{{ getInitials(`${scope.row.first_name} ${scope.row.last_name}` ) }}</strong>
                      </div>
                    </div>
                    <div style="margin-left: 10px;">
                        <span>{{ scope.row.first_name }} {{ scope.row.last_name }}</span><br/>
                    </div>
                  </div>
                </template>
              </el-table-column>

              <el-table-column label="Contact info" :show-overflow-tooltip="true" label-class-name="text-left" width="auto">
                <template slot-scope="scope">
                  <span style="font-weight: 600;">Email: </span><span>{{ scope.row.email }}</span><br/>
                  <span style="font-weight: 600;">Phone: </span><span>{{ scope.row.phone_number }}</span>
                </template>
              </el-table-column>

              <el-table-column label="Roles">
                <template slot-scope="scope">
                  <ul style="margin:0px;">
                    <li v-for="role in scope.row.roles" :key="role" style="">
                      <span v-if="role == 'LECTURER'">Lecturer</span>
                      <span v-else-if="role == 'AR'">Academic Registrar </span>
                      <span v-else-if="role == 'HR'">Human Resource</span>
                      <span v-else-if="role == 'DEAN'">Dean</span>
                      <span v-else-if="role == 'HOD'">Head of Department</span>
                      <span v-else-if="role == 'QA'">Quality Assurance</span>
                      <span v-else-if="role == 'IT'">IT</span>
                      <span v-else-if="role == 'SUPER_ADMIN'">Super Admin</span>
                      <span v-else-if="role == 'BURSAR'">Bursar</span>
                      <span v-else-if="role == 'VC'">Vice Chancellor</span>
                    </li>
                  </ul>
                </template>
              </el-table-column>

              <el-table-column label="Status" width="100px">
                <template slot-scope="scope">
                  <!-- <span>{{ scope.row.is_active ? "Active" : "Deactivate" }}</span> -->
                  <el-row style="display: flex;">
                    <el-tooltip
                      :content="scope.row.is_active ? 'Active' : 'Deactivated'" placement="top">
                      <el-switch v-model="scope.row.is_active" :disabled="!findCommonElements3(staff_crud, $store.state.theRole)" @change="swicthStatus(scope.row.is_active, scope.row.staff_id)" active-color="#13ce66" inactive-color="">
                      </el-switch>
                      <!-- <span><small>{{ scope.row.is_active == true ? "Active" : "Deactivated" }}</small></span> -->
                    </el-tooltip>
                  </el-row>
                </template>
              </el-table-column>

              <el-table-column label="Actions" label-class-name="text-center" class-name="text-center">
                <template slot-scope="scope">
                  <el-row>
                    <el-button type="primary" :disabled="!findCommonElements3(staff_crud, $store.state.theRole)" v-if="scope.row.role_faculty != null" @click="staffEditData( scope.row.staff_id, scope.row.first_name, scope.row.last_name, scope.row.email, scope.row.phone_number, scope.row.gender, scope.row.roles, scope.row.role_faculty.faculty_id)" icon="el-icon-edit" circle></el-button>
                    <el-button type="primary" :disabled="!findCommonElements3(staff_crud, $store.state.theRole)" v-if="scope.row.role_department != null" @click="staffEditData( scope.row.staff_id, scope.row.first_name, scope.row.last_name, scope.row.email, scope.row.phone_number, scope.row.gender, scope.row.roles, scope.row.role_department.department_id )" icon="el-icon-edit" circle></el-button>
                    <el-button type="primary" :disabled="!findCommonElements3(staff_crud, $store.state.theRole)" v-if="scope.row.role_department == null && scope.row.role_faculty == null" @click="staffEditData( scope.row.staff_id, scope.row.first_name, scope.row.last_name, scope.row.email, scope.row.phone_number, scope.row.gender, scope.row.roles)" icon="el-icon-edit" circle></el-button>
                    <el-button type="danger" :disabled= "true" @click="deleteStaff(scope.row.staff_id)" icon="el-icon-delete" circle></el-button>
                  </el-row>
                </template>
              </el-table-column>
              
            </data-tables>
          </div>

        </div>
      </section>

      <section>
        <div class="container">
          <div class="staff_dialogs">

            <el-dialog title="Add staff members" :visible.sync="addStaffModal" :destroy-on-close="true" :before-close="handleClose">
              <div v-loading="staff_submiting">

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="first_name">First Name</label>
                      <input type="text" id="first_name" :class="{ 'input-error': $v.first_name.$error }" @keyup.enter="addStaff" v-model.trim="$v.first_name.$model"/>
                      <span v-if="$v.first_name.$error" style="font-size: 0.7em; color: red">{{ firstNameError }}</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="last_name">Last Name</label>
                      <input type="text" id="last_name" :class="{ 'input-error': $v.last_name.$error }" @keyup.enter="addStaff" v-model.trim="$v.last_name.$model"/>
                      <span v-if="$v.last_name.$error" style="font-size: 0.7em; color: red">{{ lastNameError }}</span>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="email">Email</label>
                      <input type="email" id="email"  :class="{ 'input-error': $v.email.$error }" @keyup.enter="addStaff" v-model.trim="$v.email.$model"/>
                      <span v-if="$v.email.$error" style="font-size: 0.7em; color: red">{{ emailError }}</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="phone_number">Phone number</label>
                      <input type="text"
                        id="phone_number" :class="{ 'input-error': $v.phone_number.$error }" @keyup.enter="addStaff" v-model.trim="$v.phone_number.$model"/>
                        <span v-if="$v.phone_number.$error" style="font-size: 0.7em; color: red">{{ phoneNumberError }}</span>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Assign Staff Roles</label>
                      <el-select :class="{ 'input-error': $v.checkedRoles.$error }" @keyup.enter="addStaff" @change="onRoleChange()" v-model.trim="$v.checkedRoles.$model" multiple style="width: 100%;">
                        <el-option label="Academic Registrar (AR)" value="AR"></el-option>
                        <el-option label="Human Resource (HR)" value="HR"></el-option>
                        <el-option label="Dean" value="DEAN"></el-option>
                        <el-option label="Head of Department (HOD)" value="HOD"></el-option>
                        <el-option label="Quality Assurance" value="QA"></el-option>
                        <el-option label="Lecturer" value="LECTURER"></el-option>
                        <el-option label="Super Admin" value="SUPER_ADMIN"></el-option>
                        <el-option label="Vice Chancellor" value="VC"></el-option>
                        <el-option label="Bursar" value="BURSAR"></el-option>
                        <el-option label="IT" value="IT"></el-option>
                      </el-select>
                      <span v-if="$v.checkedRoles.$error" style="font-size: 0.7em; color: red">{{ checkedRolesError }}</span>
                    </div>
                  </div>

                  <div class="col-md-6" :class="[selectedRoles ? 'fd_chosen' : 'fd_Notchosen']">
                    <div class="form-group" :class="[selectedFaculty ? 'fd_faculty' : 'fd_Notfaculty']">
                      <label>Choose Dean's Faculty</label>
                      <el-select v-model.trim="$v.faculty.$model" filterable style="width: 100%;">
                        <el-option 
                        v-for="faculty in faculties"
                        :key="faculty.faculty_id"
                        :label="faculty.title"
                        :value="faculty.faculty_id">
                        </el-option>
                      </el-select>
                    </div>

                    <div class="form-group" :class="[selectedDepartment ? 'fd_dept' : 'fd_Notdept']">
                      <label>Choose Department for this (HOD)</label>
                      <el-select v-model.trim="$v.department.$model" filterable style="width: 100%;">
                        <el-option 
                        v-for="department in departments"
                        :key="department.department_id"
                        :label="department.department_name"
                        :value="department.department_id">
                        </el-option>
                      </el-select>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="gender">Gender</label>
                      <div class="to-an-array">
                        <div>
                          <input type="radio" id="male" class="with-gap" value="MALE" :class="{ 'input-error': $v.gender.$error }" @keyup.enter="addStaff" v-model.trim="$v.gender.$model"/>
                          <label for="male">Male</label>
                        </div>

                        <div>
                          <input type="radio" id="female" class="with-gap" value="FEMALE" :class="{ 'input-error': $v.gender.$error }" @keyup.enter="addStaff" v-model.trim="$v.gender.$model"/>
                          <label for="female">Female</label>
                        </div>
                      </div>
                      <span v-if="$v.gender.$error" style="font-size: 0.7em; color: red">{{ genderError }}</span>
                    </div>
                  </div>
                </div>

                <div class="text-center">
                  <el-button @click="addStaff" style="width: 150px; background-color: var(--el-app-primary); position: relative; bottom: 0px; color: white;" class="z-depth-0" :disabled="!$v.first_name.required || !$v.last_name.required || !$v.phone_number.required || !$v.email.required || !$v.gender.required || !$v.checkedRoles.required">
                    Add Staff Member
                  </el-button>
                </div>
                  
                <div class="text-center">
                  <div class="text-center">
                    <small>© 2021 Pesamoni, All rights reserved.</small>
                  </div>
                  <div class="text-center">
                    <!-- <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                  </div>
                </div>

              </div>
            </el-dialog>

            <el-dialog title="Upload Staff Excel file" :visible.sync="addMultipleStaffModal" :destroy-on-close="true" :before-close="handleClose">
              <div v-loading="submiting">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <input type="file" id="file" ref="file" name="file" class="form-control">
                        </div>
                    </div>
                </div>
                <div class="form-group" style="padding-top:10px; display: flex; justify-content:flex-end; ">
                    <el-button style="width: 150px; background-color:  var(--el-app-primary); color:white;" class="z-depth-0"  @click="uploadStaff" >
                        Upload Staff
                    </el-button>
                </div>

                <div class="text-center" style="display: flex; align-items: center; justify-content: space-between;">
                    <div class="text-center">
                      <small>© 2021 Pesamoni, All rights reserved.</small>
                    </div>
                    <div class="text-center" @click="downloadFile" style="font-weight: 600; cursor: pointer;">
                      <span>Download sample excel file <i class="fas fa-cloud-download-alt"></i></span>
                    </div>
                </div>
              </div>
            </el-dialog>
        
            <el-dialog title="Edit staff member" :visible.sync="showEditModal" :destroy-on-close="true" :before-close="handleClose">
              <div v-loading="staff_edit_submiting">

                <div v-if="!staffEditStatus">
                  <div>
                      <p><span style="font-weight: 700;">Name:</span> {{ first_name_edit }} {{ last_name_edit }}</p>
                      <p><span style="font-weight: 700;">Email:</span> {{ email_edit }}</p>
                      <p><span style="font-weight: 700;">Phone Number:</span> {{ phone_number_edit }}</p>
                      <p><span style="font-weight: 700;">Gender:</span> {{ gender_edit }}</p>
                      <p><span style="font-weight: 700;">Roles:</span>
                      
                      <ul style="margin:0px;">
                        <li v-for="role in roles_edit" :key="role" style="">
                          <span v-if="role == 'LECTURER'">Lecturer</span>
                          <span v-else-if="role == 'AR'">Academic Registrar </span>
                          <span v-else-if="role == 'HR'">Human Resource</span>
                          <span v-else-if="role == 'DEAN'">Dean</span>
                          <span v-else-if="role == 'HOD'">Head of Department</span>
                          <span v-else-if="role == 'QA'">Quality Assurance</span>
                          <span v-else-if="role == 'IT'">IT</span>
                          <span v-else-if="role == 'SUPER_ADMIN'">Super Admin</span>
                          <span v-else-if="role == 'BURSAR'">Bursar</span>
                          <span v-else-if="role == 'VC'">Vice Chancellor</span>
                        </li>
                      </ul>

                      </p>
                      <hr/>
                      <div 
                      :class="findCommonElements3(reset_password, $store.state.theRole)?'show_list':'hide_list'"
                      style="color: var(--el-app-primary); 
                      display: flex; align-items: center; justify-content: space-between;">
                        <div style="cursor: pointer;">
                          <div @click="showPassword()" v-if="!showPasswordBool">
                            <span style="font-weight: 700;">Show Password<i class="el-icon-key"></i></span>
                          </div>
                          <div v-if="showPasswordBool">
                            <span style="font-weight: 700;">Password: </span>{{StaffPassword}}
                          </div>
                        </div>

                        <div @click="resetPassword" class="btn-success" style="cursor: pointer; font-weight: 600; border-radius: 5px; padding:3px 10px;">
                          Reset Password
                        </div>
                      </div>
                      <!--reset_password-->
                      <hr/>
                  </div>
                </div>

                <div v-if="staffEditStatus">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="first_name_edit">First Name</label>
                        <input type="text" id="first_name_edit" :class="{ 'input-error': $v.first_name_edit.$error }" @keyup.enter="editStaff_final" v-model.trim="$v.first_name_edit.$model"/>
                        <span v-if="$v.first_name_edit.$error" style="font-size: 0.7em; color: red">{{ firstNameEditError }}</span>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="last_name_edit">Last Name</label>
                        <input type="text" id="last_name_edit" :class="{ 'input-error': $v.last_name_edit.$error }" @keyup.enter="editStaff_final" v-model.trim="$v.last_name_edit.$model"/>
                        <span v-if="$v.last_name_edit.$error" style="font-size: 0.7em; color: red">{{ lastNameEditError }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="email_edit">Email</label>
                        <input type="email" id="email_edit"  :class="{ 'input-error': $v.email_edit.$error }" @keyup.enter="editStaff_final" v-model.trim="$v.email_edit.$model"/>
                        <span v-if="$v.email_edit.$error" style="font-size: 0.7em; color: red">{{ emailEditError }}</span>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="phone_number_edit">Phone Number</label>
                        <input type="text" id="phone_number_edit" :class="{ 'input-error': $v.phone_number_edit.$error }" @keyup.enter="editStaff_final" v-model.trim="$v.phone_number_edit.$model"/>
                          <span v-if="$v.phone_number_edit.$error" style="font-size: 0.7em; color: red">{{ phoneNumberEditError }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="Roles">Assign Staff Roles</label>
                        <el-select :class="{ 'input-error': $v.roles_edit.$error }" @change="onEditRoleChange()" @keyup.enter="editStaff_final" v-model.trim="$v.roles_edit.$model" multiple style="width: 100%;">
                          <el-option label="Academic Registrar (AR)" value="AR"></el-option>
                          <el-option label="Human Resource (HR)" value="HR"></el-option>
                          <el-option label="Dean" value="DEAN"></el-option>
                          <el-option label="Head of Department (HOD)" value="HOD"></el-option>
                          <el-option label="Quality Assurance" value="QA"></el-option>
                          <el-option label="Lecturer" value="LECTURER"></el-option>
                          <el-option label="Super Admin" value="SUPER_ADMIN"></el-option>
                          <el-option label="Vice Chancellor" value="VC"></el-option>
                          <el-option label="Bursar" value="BURSAR"></el-option>
                          <el-option label="IT" value="IT"></el-option>
                        </el-select> 
                        <span v-if="$v.roles_edit.$error" style="font-size: 0.7em; color: red">{{ RolesEditError }}</span>
                      </div>
                    </div>

                    <div class="col-md-6" :class="[selectedRoles ? 'fd_chosen' : 'fd_Notchosen']">
                      <div class="form-group" :class="[selectedFaculty ? 'fd_faculty' : 'fd_Notfaculty']">
                        <label>Choose Dean's Faculty</label>
                        <el-select v-model="$v.faculty_edit.$model" :class="{ 'input-error': $v.faculty_edit.$error }" filterable style="width: 100%;">
                          <el-option 
                          v-for="faculty in faculties"
                          :key="faculty.faculty_id"
                          :label="faculty.title"
                          :value="faculty.faculty_id">
                          </el-option>
                        </el-select>
                        <span v-if="$v.faculty_edit.$error" style="font-size: 0.7em; color: red">{{ facultyEditError }}</span>
                      </div>

                      <div class="form-group" :class="[selectedDepartment ? 'fd_dept' : 'fd_Notdept']">
                        <label>Choose Department for this (HOD)</label>
                        <el-select v-model="$v.department_edit.$model" filterable style="width: 100%;">
                          <el-option 
                          v-for="department in departments"
                          :key="department.department_id"
                          :label="department.department_name"
                          :value="department.department_id">
                          </el-option>
                        </el-select>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="gender">Gender</label>
                        <div class="to-an-array"> 
                          <div>
                            <input type="radio" id="male" class="with-gap" value="MALE" :class="{ 'input-error': $v.gender_edit.$error }" @keyup.enter="editStaff_final" v-model.trim="$v.gender_edit.$model"/>
                            <label for="male">Male</label>
                          </div>
                          <div>
                            <input type="radio" id="female" class="with-gap" value="FEMALE" :class="{ 'input-error': $v.gender_edit.$error }" @keyup.enter="editStaff_final" v-model.trim="$v.gender_edit.$model"/>
                            <label for="female">Female</label>
                          </div>
                        </div>
                        <span v-if="$v.gender_edit.$error" style="font-size: 0.7em; color: red">{{ genderEditError }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="text-center">
                    <el-button @click="editStaff_final" style="width: 150px; background-color: var(--el-app-primary); position: relative; bottom: 0px; color: white;" class="z-depth-0" :disabled="!$v.first_name_edit.required || !$v.last_name_edit.required || !$v.phone_number_edit.required || !$v.email_edit.required || !$v.gender_edit.required || !$v.roles_edit.required">
                      Edit Staff Member
                    </el-button>
                  </div>

                  <div class="text-center">
                    <div class="text-center">
                      <small>© 2021 Pesamoni, All rights reserved.</small>
                    </div>
                    <div class="text-center">
                      <!-- <small>Design by <a href="https://ovalspace.co" style="color: black"><strong>Ovalspace</strong></a></small> -->
                    </div>
                  </div>
                </div>
                
                <div>
                    <el-button v-if="!staffEditStatus" type="primary" @click="showEditSection" icon="el-icon-edit">Edit</el-button>
                    <el-button v-if="staffEditStatus" type="success" @click="showStatusSection" icon="el-icon-warning-outline">Show staff info</el-button>
                </div>

              </div>
            </el-dialog>

            <el-dialog title="Error message" :visible.sync="dialogVisible">
              <span>{{fileErrorMessage}}</span>
              <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogVisible = false">Cancel</el-button>
              </span>
            </el-dialog>

          </div>
        </div>
      </section>

    </article>
  </div>
</template>

<script>

  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  import { ScaleOut } from "vue-loading-spinner";
  // import BreadCrumbComponent from "../components/bread-crumb-component";
    
  export default {

    components: { ScaleOut },
    mixins: [validationMixin],

    data() {
      return {
        addStaffModal: false,
        innerVisible: false,
        showEditModal: false,
        assignCourseBatchVisible: false,
        addMultipleStaffModal: false,
        dialogVisible: false,
        fileErrorMessage: '',
        staffEditStatus: false,
        StaffPassword: "",
        showPasswordBool: false,

        loading: false,
        submiting: false,
        loadingError: false,
        deleting: false,
        staff_submiting: false,
        staff_edit_submiting: false,
        responseData: "",
        file: "",
        is_active: false,
        faculties: [],
        departments: [],
        selectedRoles: false,
        selectedRoleSection: false,
        selectedFaculty: false,
        selectedDepartment: false,
        staffData: {},
        staffEditingData: {},

        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        gender: "",
        checkedRoles: [],
        faculty: "",
        department: "",

        id_edit: "",
        first_name_edit: "",
        last_name_edit: "",
        email_edit: "",
        phone_number_edit: "",
        gender_edit: "",
        roles_edit: [],
        faculty_edit: "",
        department_edit: "",

        lecturers: [],
        tableProps: {
          border: true,
          stripe: true,
        },

        filters: [
          {
            prop: "first_name",
            value: "",
          },
        ],

        staff_crud: ['HR', 'VC', 'SUPER_ADMIN'],
        reset_password: ['IT']

      };
    },
    
    computed: {

      firstNameError() { 
        if (!this.$v.first_name.required) { return "*First Name is required"; }
        return "";
      },

      lastNameError() { 
        if (!this.$v.last_name.required) { return "*Last Name is required"; }
        return "";
      },

      emailError() { 
        if (!this.$v.email.required) { return "*Email is required"; }
        return "";
      },

      phoneNumberError() { 
        if (!this.$v.phone_number.required) { return "*Phone Number is required"; }
        return "";
      },

      genderError() { 
        if (!this.$v.gender.required) { return "*Gender is required"; }
        return "";
      },

      checkedRolesError() { 
        if (!this.$v.checkedRoles.required) { return "*Roles are required"; }
        return "";
      },

      facultyError() { 
        if (!this.$v.faculty.required) { return "*Faculty is required"; }
        return "";
      },

      departmentError() { 
        if (!this.$v.department.required) { return "*Department is required"; }
        return "";
       },

      firstNameEditError() { 
        if (!this.$v.first_name_edit.required) { return "*First Name is required"; }
          return "";
      },

      lastNameEditError() { 
        if (!this.$v.last_name_edit.required) { return "*Last Name is required"; }
        return "";
       },

      emailEditError() { 
        if (!this.$v.email_edit.required) { return "*Email is required"; }
        return "";
      },

      phoneNumberEditError() { 
        if (!this.$v.phone_number_edit.required) { return "*Phone Number is required"; }
        return "";
      },

      genderEditError() { 
        if (!this.$v.gender_edit.required) { return "*Gender is required"; }
        return "";
      },

      RolesEditError() { 
        if (!this.$v.roles_edit.required) { return "*Roles are required"; }
        return "";
      },

      facultyEditError() { 
        if (!this.$v.faculty_edit.required) { return "*Faculty is required"; }
        return "";
      },

      departmentEditError() { 
        if (!this.$v.department_edit.required) { return "*Department is required"; }
        return "";
      },

      createdBy() {
        return (creator)=> {
          if(!creator) return "N/A";
          return `${creator.first_name} ${creator.last_name}`;
        }
      },

      getInitials() {
        return (nameString) => {
          var names = nameString.split(" "),
              initials = names[0].substring(0, 1).toUpperCase();
          if (names.length > 1) {
              initials += names[names.length - 1].substring(0, 1).toUpperCase();
          }
          return initials;
          };
      }

    },

    validations: {

      first_name: { required },
      last_name: { required },
      email: { required },
      phone_number: { required },
      gender: { required },
      checkedRoles: { required },
      faculty: { required },
      department: { required },

      first_name_edit: { required },
      last_name_edit: { required },
      email_edit: { required },
      phone_number_edit: { required },
      gender_edit: { required },
      roles_edit: { required },
      faculty_edit: { required },
      department_edit: { required },

    },

    mounted() {
      this.getStaff();
    },

    methods: {

      // Iterate through each element in the
      // first array and if some of them
      // include the elements in the second
      // array then return true.
      findCommonElements3(arr1, arr2) {
        return arr1.some(item => arr2.includes(item));
      },

      openStaffModal() {
        this.addStaffModal = true;
        this.getFaculties();
        this.getDepartments();
        this.faculty = "";
        this.department = "";
      },

      handleClose(done) {
        this.clearInputs();
        this.$v.$reset();
        done();
      },

      clearInputs() {
        this.first_name = "";
        this.last_name = "";
        this.email = "";
        this.phone_number = "";
        this.gender = "";
        this.checkedRoles = [];

        this.first_name_edit = "";
        this.last_name_edit = "";
        this.email_edit = "";
        this.phone_number_edit = "";
        this.gender_edit = "";
        this.roles_edit = [];
      },

      onRoleChange() {
        this.faculty = "";
        this.department = "";
        console.log(this.checkedRoles);
        if(this.checkedRoles.includes("DEAN")){
          this.selectedRoles = true;
          this.selectedFaculty = true;
          this.selectedDepartment = false;
        }else if(this.checkedRoles.includes("HOD")){
          this.selectedRoles = true;
          this.selectedDepartment = true;
          this.selectedFaculty = false;
        } else if(this.checkedRoles.includes("DEAN") && this.checkedRoles.includes("HOD")) {
          this.selectedRoles = true;
          this.selectedFaculty = true;
          this.selectedDepartment = true;
        }
          else {
          this.selectedRoles = false;
        }
      },

      async getFaculties() {
          try {
            this.staff_submiting = true;
            let request = await this.$http.get(`faculties/fetch-many`);
            if ( request.data.success && request.data.message == "FACULTIES_FETCHED_SUCCESSFULLY" ) { 
              this.faculties = request.data.faculties;
            } 
            else {throw "ERROR OCCURED"; }  
          } catch (error) {
            if (error.data == "Network Error") {
              return this.showFailedMessage("Connection failed","A network error occured, please try again.");
            
            }
            this.showFailedMessage("Unable to load Faculties", "An unexpected Error occured, please try again");
          } finally {
            this.staff_submiting = false; 
          }
      },

      async getDepartments() {
          try {
            this.staff_submiting = true;
            let request = await this.$http.get(`departments/fetch-many`);
            if ( request.data.success && request.data.message == "DEPARTMENTS_RETRIEVED_SUCCESSFULLY" ) { 
              this.departments = request.data.departments;
            } 
            else {throw "ERROR OCCURED"; }  
          } catch (error) {
            if (error.data == "Network Error") {
              return this.showFailedMessage("Connection failed","A network error occured, please try again.");
            
            }
            this.showFailedMessage("Unable to load Departments", "An unexpected Error occured, please try again");
          } finally {
            this.staff_submiting = false;
          }
      },

      //$v.checkedRoles
      addStaff() {

        if(!this.$v.first_name.required || !this.$v.last_name.required || !this.$v.phone_number.required || !this.$v.email.required || !this.$v.gender.required || !this.$v.checkedRoles.required ){ 
          return; 
        } else {
          if(this.checkedRoles.includes("HOD")){

            this.staffData = {
              first_name: this.first_name.trim(),
              last_name: this.last_name.trim(),
              email: this.email.trim(),
              phone_number: this.phone_number.trim(),
              gender: this.gender,
              roles: this.checkedRoles,
              department_id: this.department,
            };
            
            if(this.department == "") {
              this.showWarningMessage( "Required", "Department field is required" );
              return;
            }
             
          }else if(this.checkedRoles.includes("DEAN")) {

            this.staffData = {
              first_name: this.first_name.trim(),
              last_name: this.last_name.trim(),
              email: this.email.trim(),
              phone_number: this.phone_number.trim(),
              gender: this.gender,
              roles: this.checkedRoles,
              faculty_id: this.faculty,
            };
            
            if(this.faculty == "") {
              this.showWarningMessage( "Required", "Faculty field is required" );
              return;
            }

          }else {
            this.staffData = {
              first_name: this.first_name.trim(),
              last_name: this.last_name.trim(),
              email: this.email.trim(),
              phone_number: this.phone_number.trim(),
              gender: this.gender,
              roles: this.checkedRoles
            };
          }

          this.loading = true;
          this.staff_submiting = true;

          this.axios
            .post(`staff/add`, this.staffData)
            .then(
              (response) => {
                if ( response.data.success == true && response.data.message == "STAFF_CREATED_SUCCESSFULLY") {
                  this.showSuccessMessage( "Success", "Staff member added successfully" );
                  this.getStaff();
                  this.addStaffModal = false;
                  this.staff_submiting= false;
                          
                  this.clearInputs();
                  this.$v.$reset();

                } else if (response.data.message == "EMAIL_ALREADY_IN_USE" ) {
                  this.showWarningMessage(
                    "Duplicate Email",
                    "The email address you entered is already in use by another account. Please choose another one."
                  );
                  this.staff_submiting= false;
                } else if (response.data.message == "INVALID_EMAIL" ) {
                  this.showWarningMessage(
                    "Invalid Email",
                    "The email address you entered is invalid. Please choose another one."
                  );
                  this.staff_submiting= false;
                } else if (response.data.message == "PHONE_NUMBER_ALREADY_IN_USE") {
                  this.showWarningMessage(
                    "Duplicate Phone Number",
                    "The Phone Number you entered is already in use by another account. Please choose another one."
                  );
                  this.staff_submiting= false;
                } else if (response.data.message == "INVALID PHONE NUMBER") {
                  this.showWarningMessage(
                    "Invalid Phone Number",
                    "The Phone Number you entered is Invalid. Please choose another one."
                  );
                  this.staff_submiting= false;
                } else {
                  this.showFailedMessage(
                    "Unexpected Error",
                    "An unexpected Error Occured. Please try again"
                  );
                  this.staff_submiting= false;
                }
              },
              () => {
                this.showFailedMessage(
                  "Unexpected Error",
                  "An unexpected Error Occured. Please try again"
                );
                this.staff_submiting= false;
              }
            )
            .finally(() => {
              this.loading = false;
              this.staff_submiting= false;
            });
        }
      },
     
      async uploadStaff() {
        const formData = new FormData();
        formData.append('staffFile', this.$refs.file.files[0]);
        this.submiting = true;

        try {
          let request = await this.$http.post(`staff/upload-by-file`, formData, { headers: {'Content-Type': 'multipart/form-data' } } );
          if ( request.data.success && request.data.message == "STAFF_MEMBERS_UPLOAD_SUCCESS" ) {

            this.getStaff();
            this.showSuccessMessage("Success","Staff members added successfully");
            this.addMultipleStaffModal = false;
            this.$v.$reset();

          } else if( request.data.success == false && request.data.message == "EMPTY_FILE_SENT" ) {

            this.showWarningMessage("Empty File","You sent an empty file. Please confirm it has content");

          } else if( request.data.success == false && request.data.message == "UNSUPPORTED_FILE_TYPE" ) {

            this.showWarningMessage("Success","Unsupported file");

          } else if( request.data.success == false && request.data.message == "CONSTRAINT_VIOLATION" ) {

            this.fileErrorMessage = request.data.errorMessage;
            this.dialogVisible = true;
            this.addMultipleStaffModal = false;

          } else if( request.data.success == false && request.data.message == "INVALID_EMAIL" ) {

            this.fileErrorMessage = request.data.errorMessage;
            this.dialogVisible = true;
            this.addMultipleStaffModal = false;

          } else if( request.data.success == false && request.data.message == "INVALID PHONE NUMBER" ) {

            this.fileErrorMessage = request.data.errorMessage;
            this.dialogVisible = true;            
            this.addMultipleStaffModal = false;

          }
          else {
              throw "ERROR OCCURED";
          }
        } catch (error) { // eslint-disable-line no-unused-vars

          if (!error.response) {
            this.submiting = false;
            this.showFailedMessage("Error", "Unable to upload staff excel file");
          } else {
                  
            if (error.response.data.message == "UNSUPPORTED_FILE_TYPE") {
              this.submiting = false;
              return this.showWarningMessage("Unsuppoted", "The file you uploaded is unsupported.");
            } else {
              this.submiting = false;
              return this.showFailedMessage("Error", "Unable to upload staff excel file");
            }

          }

        } finally {
          this.submiting = false;
        }
      },

      downloadFile() {
        this.submiting = true;
        this.$http.get(`staff/sample-file`, { responseType: 'blob', }).then((response) => {
          const url = URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `Staff-sample-${new Date().toLocaleDateString()}.xlsx`
          )
          document.body.appendChild(link)
          link.click()
        })
        .finally(() => {
          this.submiting = false;
        });

      },  

      getStaff() {
        this.loading = true;
        this.loadingError = false;
        this.$http.get(`staff/fetch-many`)
          .then((response) => {
              if (
                response.data.success == true &&
                response.data.message == "STAFF_MEMBERS_FETCHED"
              ) {
                this.lecturers = response.data.staffs;
              } else {
                this.loadingError = true;
                this.showFailedMessage(
                  "Fetch Failed",
                  "An unexpected Error Occured. Please try again"
                );
              }
            },
            () => {
              this.loadingError = true;
              this.showFailedMessage(
                "Fetch Failed",
                "An unexpected Error Occured. Please try again"
              );
            }
          )
          .finally(() => (this.loading = false));
      },

      onEditRoleChange() {
        this.faculty_edit = "";
        this.department_edit = "";
        // this.roles_edit = this.$store.state.userRoles;
        console.log(this.roles_edit);
        if(this.roles_edit.includes("DEAN")){
          this.selectedRoles = true;
          this.selectedFaculty = true;
          this.selectedDepartment = false;
        }else if(this.roles_edit.includes("HOD")){
          this.selectedRoles = true;
          this.selectedDepartment = true;
          this.selectedFaculty = false;
        } else if(this.roles_edit.includes("DEAN") && this.roles_edit.includes("HOD")) {
          this.selectedRoles = true;
          this.selectedFaculty = true;
          this.selectedDepartment = true;
        }
          else {
          this.selectedRoles = false;
        }
      },

      staffEditData( id, first_name, last_name, email, phone_number, gender, roles, nid ) {
        this.showEditModal = true;
        this.getFaculties();
        this.getDepartments();

        this.id_edit = id;
        this.first_name_edit = first_name;
        this.last_name_edit = last_name;
        this.email_edit = email;
        this.phone_number_edit = phone_number;
        this.gender_edit = gender;
        this.roles_edit = roles;

        this.faculty_edit = nid;
        this.department_edit = nid;
        if(this.roles_edit.includes("DEAN")){
          this.selectedRoles = true;
          this.selectedFaculty = true;
          this.selectedDepartment = false;
        }else if(this.roles_edit.includes("HOD")){
          this.selectedRoles = true;
          this.selectedDepartment = true;
          this.selectedFaculty = false;
        } else if(this.roles_edit.includes("DEAN") && this.roles_edit.includes("HOD")) {
          this.selectedRoles = true;
          this.selectedFaculty = true;
          this.selectedDepartment = true;
        } else {
          this.selectedRoles = false;
        }

        this.staffEditStatus = false;
        this.showPasswordBool = false;
      },

      editStaff_final() {
        if(!this.$v.first_name_edit.required && !this.$v.last_name_edit.required && !this.$v.phone_number_edit.required && !this.$v.email_edit.required && !this.$v.gender_edit.required && !this.$v.roles_edit.required ){ return; }
         
         if(this.roles_edit.includes("HOD")){

            this.staffEditingData = {
              first_name: this.first_name_edit,
              last_name: this.last_name_edit,
              email: this.email_edit,
              phone_number: this.phone_number_edit,
              gender: this.gender_edit,
              roles: this.roles_edit,
              department_id: this.department_edit,
            };
            
            if(this.department_edit == "") {
              this.showWarningMessage( "Required", "Department field is required" );
              return;
            }
             
          }else if(this.roles_edit.includes("DEAN")) {

            this.staffEditingData = {
              first_name: this.first_name_edit,
              last_name: this.last_name_edit,
              email: this.email_edit,
              phone_number: this.phone_number_edit,
              gender: this.gender_edit,
              roles: this.roles_edit,
              faculty_id: this.faculty_edit,
            };
            
            if(this.faculty_edit == "") {
              this.showWarningMessage( "Required", "Faculty field is required" );
              return;
            }

          }else {

            this.staffEditingData = {
              first_name: this.first_name_edit,
              last_name: this.last_name_edit,
              email: this.email_edit,
              phone_number: this.phone_number_edit,
              gender: this.gender_edit,
              roles: this.roles_edit,
            };

          }

        this.loading = true;
        this.staff_edit_submiting = true;
        this.$http.patch(`staff/update-one/${this.id_edit}`, this.staffEditingData).then(
          (response) => {
            if (response.data.success && response.data.message == "STAFF_UPDATED_SUCCESS_FULLY") {
              this.getStaff();
              this.showSuccessMessage("Success","Staff edited successfully!");
              this.loading = false;
              this.staff_edit_submiting = false;
              this.showEditModal = false;
                    
              this.clearInputs();
              this.$v.$reset(); // "FACULTY_ID_IS_REQUIRED_FOR_A_DEAN_AND_MUST_BE_AN_INTEGER"
            } else if(response.data.message == "FACULTY_ID_IS_REQUIRED_FOR_EACH_DEAN") {
                this.showWarningMessage(
                  "Faculty Required",
                  "The Dean is required to have a faculty. Please choose one."
                );
              this.staff_edit_submiting = false;
            }else if(response.data.message == "DEPARTMENT_ID_IS_REQUIRED_FOR_EACH_HEAD_OF_DEPARTMENT") {
                this.showWarningMessage(
                  "Department Required",
                  "The Head of Department is required to have a department. Please choose one."
                );
              this.staff_edit_submiting = false;
            } else if (response.data.message == "EMAIL_ALREADY_IN_USE" ) {
                this.showWarningMessage(
                  "Duplicate Email",
                  "The email address you entered is already in use by another account. Please choose another one."
                );
              this.staff_edit_submiting = false;
            } else if (response.data.message == "PHONE_NUMBER_ALREADY_IN_USE") {
                this.showWarningMessage(
                  "Duplicate Phone Number",
                  "The Phone Number you entered is already in use by another account. Please choose another one."
                );
              this.staff_edit_submiting = false;
                this.staff_submiting= false;
            } else if (response.data.message == "INVALID PHONE NUMBER") {
                this.showWarningMessage(
                  "Invalid",
                  "The Phone Number you entered is Invalid. Please choose another one."
                );
              this.staff_edit_submiting = false;
            }
          },
          (error) => { // eslint-disable-line no-unused-vars
            this.showFailedMessage("Failed","Unable to edit staff");
            this.loading = false;
            this.staff_edit_submiting = false;
          }
        );
      },

      deleteStaff(id) {
        // return; // eslint-disable-line no-unreachable
          this.$confirm(
            "This will permanently delete this staff member. Do you want to Continue?",
            "Confirm delete",
            {
              confirmButtonText: "OK",
              cancelButtonText: "Cancel",
              type: "warning",
            }
          )
            .then(() => {
              this.deleting = true;
              this.axios.delete(`staff/delete-one/${id}`).then(
                (response) => {
                  if (response.data.success == true) {
                    this.getStaff();
                    this.showSuccessMessage( "Success", "Staff deleted successfully!");
                    this.showEditModal = false;
                    this.deleting = false;
                  }
                },
                (error) => { // eslint-disable-line no-unused-vars
                  this.showFailedMessage("Error", "Unable to delete Staff");
                  this.loading = false;
                  this.deleting = false;
                }
              );
            })
            .catch(() => {
              
            });
      },

            async swicthStatus(activation_status, staffId) {
              let staffStatus = {
                is_active: activation_status
              }
                try {
                    this.deleting = true;
                    let request = await this.$http.patch(`staff/change_active/${staffId}`, staffStatus );
                    if ( request.data.success && request.data.message == "STAFF_ACTIVE_STATUS_CHANGED_SUCCESSFULLY" ) { 
                      this.getStaff();
                      this.showSuccessMessage("Success","Staff status changed");
                      this.deleting = false;
                    } else if (request.data.message == "STAFF_ACTIVE_STATUS_COULD_NOT_BE_CHANGED") {
                      this.getStaff();
                      this.showSuccessMessage("Failed","Staff status unable to change");
                      this.deleting = false;
                    } else if (request.data.message == "STAFF_ACTIVE_STATUS_IS_REQUIRED") {
                      this.getStaff();
                      this.showSuccessMessage("Failed","Staff status required");
                      this.deleting = false;
                    }
                    else { throw "ERROR OCCURED"; }
                } catch (error) {
                    if (error.data == "Network Error") {

                        this.deleting = false;
                        return this.showFailedMessage("Connection failed","A network error occured, please try again.");
                        
                    }
                    this.loadingError = true;
                    this.showFailedMessage("Unable to change staff status","An unexpected Error occured, please try again");
                } finally {
                   this.deleting = false;
                }
            },

            showEditSection() {
                this.staffEditStatus = true;
            },

            showStatusSection() {
                this.staffEditStatus = false;
            },

            async showPassword() {
              this.StaffPassword = "";
                try {
                    this.staff_edit_submiting = true;
                    let request = await this.$http.get(`/staff/default_password/${this.id_edit} `);
                    if ( request.data.message == "STAFF_PASSWORD_RETRIEVED_SUCCESFULLY" ) { 
                      this.showPasswordBool = true;
                      this.StaffPassword = request.data.default_pass;
                    } else if(request.data.message == "STAFF_HAS_NO_DEFAULT_PASSWORD") {
                        this.showWarningMessage("No default password","Staff has already changed password.");
                    }
                    else { throw "ERROR OCCURED"; }
                } 
                catch (error) {
                    if (error.data == "Network Error") {
                        return this.showFailedMessage("Connection failed","A network error occured, please try again.");
                    }
                    this.showFailedMessage("Unable to load Password","An unexpected Error occured, please try again");
                } finally {
                    this.staff_edit_submiting = false;
                }
                
            },

      async resetPassword() {
        
        this.StaffPassword = "";

        try {
            this.staff_edit_submiting = true;
            let request = await this.$http.patch(`/staff/default_password/${this.id_edit} `);
            if ( request.data.message == "STAFF_DEFAULT_PASSWORD_CHANGED_SUCCESSFULLY" ) { 
            this.showPasswordBool = true;
            this.StaffPassword = request.data.default_password;
            return this.showSuccessMessage("Success","Password reset successfully");
            }
            else { throw "ERROR OCCURED"; }
        } 
                catch (error) {
            if (error.data == "Network Error") {
                return this.showFailedMessage("Connection failed","A network error occured, please try again.");
            }
            this.showFailedMessage("Unable to load Password","An unexpected Error occured, please try again");
        } finally {
            this.staff_edit_submiting = false;
        }
                
      }      
    
    },
  };
</script>

<style scoped>

  * {
    box-sizing: border-box;
  }
  
  input[type=text],input[type=email], select, textarea {
    width: 100%;
    padding: 10px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    background-color: #FFFFFF;
    font-size: .9em;
  }

  .search_add_section {
    display: flex; align-items: center; justify-content: space-between;
  }

  .to-an-array {
    display: flex; align-items: center;
  }

  .to-an-array >div {
    margin-right: 20px;
  }

  .fd_chosen {
    display: block;
  }

  .fd_Notchosen {
    display: none;
  }

  .fd_faculty, .fd_dept {
    display: block;
  }

  .fd_Notfaculty, .fd_Notdept {
    display: none;
  }

  

  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
  }

  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  }

  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .search_add_section {
      display: block; 
    }
    .addBtn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top: 10px;
    }
      .search_by_input {
        width: 100% !important;
      }
  }

  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .search_add_section {
      display: block;
    }
    .addBtn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top: 10px;
    }
      .search_by_input {
        width: 100% !important;
      }
  }
</style>

<style>
  
  .staff_dialogs .el-dialog {
    width: 60%;
  }

  .search_by_input {
    width: 400px !important;
  }

  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
    .staff_dialogs .el-dialog {
      width: 50%;
    }
  }

  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .staff_dialogs .el-dialog {
      width: 70%;
    }
  }

  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .staff_dialogs .el-dialog {
      width: 90%;
    }
  }

  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .staff_dialogs .el-dialog {
      width: 90%;
    }

  }
</style>
