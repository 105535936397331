/* eslint-disable  no-unused-vars*/
import Vue from 'vue';
import store from "../store";

/* eslint-disable no-unused-vars */
import VueRouter, { RouteConfig } from 'vue-router';
import Dashboard from '../views/dashboard.vue';
// import ForgotPassword from "../views/forgot-password.vue";
import EnterVerificationCode from "../views/enter-verification-code.vue";
// import ResetYourPassword from "../views/reset-your-password.vue";
// import PasswordUpdated from "../views/password-updated.vue";
// import SignUp from "../views/sign-up.vue";
import Home from "../views/home.vue";
import MyCourseUnits from "../views/course-units/my-course-units.vue";
import Settings from "../views/settings.vue";
import Help from "../views/help.vue";
import UserProfile from "../views/user-profile.vue";
import StudentProfile from "../views/profiles/student-profile.vue";
import StaffProfile from "../views/profiles/staff-profile.vue";
import HelpMessages from "../views/help-messages.vue";
import UserRequests from "../views/user-requests.vue";
import SearchResults from "../views/search-results.vue";
import ResourceDetails from "../views/resource-details.vue";
import Faculties from "../views/faculties.vue";
import FacultyDetail from "../views/faculty-detail.vue";
import DepartmentCourses from "../views/department-courses.vue";
import Staff from "../views/staff.vue";
import CourseBatches from "../views/course-batches.vue";
import CourseBatchDetail from "../views/course-batch-detail.vue";
import Students from "../views/students.vue";
import LiveLectures from "../views/live-lectures.vue";
import LiveLecture from "../views/live-lecture.vue";
import IntakeBatchCalendar from "../views/intake-batch-calendar.vue"
import CourseUnitResources from "../views/course-unit-resources.vue"
import RecordedLecture from "../views/recorded-lecture.vue";
import LectureAttendance from "../views/lecture-attendance.vue";
import NoticeBoard from "../views/notice-board.vue";
import ResourceContents from "../views/resource-content.vue";
import ResourceContentDetails from "../views/resource-content-details.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/dashboard/home',
    meta: { requiresLogin: true }
  },
  // {
  //   path: '/forgot-password',
  //   name: 'ForgotPassword',
  //   component: ForgotPassword
  // },
  // {
  //   path: '/enter-verification-code',
  //   name: 'EnterVerificationCode',
  //   component: EnterVerificationCode
  // },
  // {
  //   path: '/reset-your-password',
  //   name: 'ResetYourPassword',
  //   component: ResetYourPassword
  // },

  // {
  //   path: '/password-updated',
  //   name: 'PasswordUpdated',
  //   component: PasswordUpdated
  // },
  // {
  //   path: '/sign-up',
  //   name: 'SignUp',
  //   component: SignUp
  // },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requiresLogin: true },
    children: [
      {
        path: 'home',
        name: 'Home',
        component: Home,
      },
      {
        path: 'staff',
        name: 'Staff',
        component: Staff,
      },
      {
        path: 'course-batches',
        name: 'CourseBatches',
        component: CourseBatches,
      },

      {
        path: ':cbId/course-batch-detail',
        name: 'CourseBatchDetail',
        component: CourseBatchDetail,
      },
      {
        path: 'students',
        name: 'Students',
        component: Students,
      },
      {
        path: 'live-lectures',
        name: 'LiveLectures',
        component: LiveLectures,
      },
      {
        path: 'live-lecture',
        name: 'LiveLecture',
        component: LiveLecture,
      },
      {
        path: 'course/:cId/course-units',
        name: 'MyCourseUnits',
        component: MyCourseUnits,
      },
      {
        path: 'course-unit-resources/:cuId',
        name: 'CourseUnitResources',
        component: CourseUnitResources,
        meta: { requiresLogin: true },
      },
      {
        path: 'resource-details',
        name: 'ResourceDetails',
        component: ResourceDetails,
        meta: { requiresLogin: true },
      },

      {
        path: 'batch-calendar/:batchId',
        name: 'IntakeBatchCalendar',
        component: IntakeBatchCalendar,
      },
      {
        path: 'settings',
        name: 'Settings',
        component: Settings,
      },
      {
        path: 'help',
        name: 'Help',
        component: Help,
      },
      {
        path: 'user-profile',
        name: 'UserProfile',
        component: UserProfile,
      },
      {
        path: 'student-profile/:sId',
        name: 'StudentProfile',
        component: StudentProfile,
      },
      {
        path: 'staff-profile/:sId',
        name: 'StaffProfile',
        component: StaffProfile,
      },
      {
        path: 'help-messages',
        name: 'HelpMessages',
        component: HelpMessages,
      },
      {
        path: 'user-requests',
        name: 'UserRequests',
        component: UserRequests
      },
      {
        path: 'search-results',
        name: 'SearchResults',
        component: SearchResults
      },
      {
        path: 'faculties',
        name: 'Faculties',
        component: Faculties,
      },
      {
        path: 'faculty/:fId/departments',
        name: 'FacultyDetail',
        component: FacultyDetail,
      },
      {
        path: 'department/:dId/courses',
        name: 'DepartmentCourses',
        component: DepartmentCourses,
      },
      {
        path: 'recorded-lecture',
        name: 'RecordedLecture',
        component: RecordedLecture,
      },
      {
        path: 'lecture-attendance',
        name: 'LectureAttendance',
        component: LectureAttendance,
      },
      {
        path: 'notice-board',
        name: 'NoticeBoard',
        component: NoticeBoard,
      },
      {
        path: 'resource-contents',
        name: 'ResourceContents',
        component: ResourceContents,
      },
      {
        path: ':rId/resource-content-details',
        name: 'ResourceContentDetails',
        component: ResourceContentDetails,
      },

      //In case the route is anything else
      {
        path: '*',
        redirect: '/dashboard/home'
      },

    ],

  },

  //In case the route is anything else
  {
    path: '*',
    redirect: '/dashboard/home'
  },
  // {
  //    path: '/signup',
  //    name: 'Signup',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  //    component: () => import(/* webpackChunkName: "about" */ '../views/signup.vue')
  //  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0, behavior: 'smooth', }
  }
})

router.beforeResolve((to, from, next) => {

  if (((to.name === 'Login') || (to.name === 'SignUp')) && store.getters.isLoggedIn) {
    next({ path: '/dashboard/home' })
  }
  //Check if the route is a restricted one to some users
  // if (to.name === 'Analysis' || to.name === 'Documentation' || to.name === 'Settings') {
  //   if (store.state.accessLevel != 'Super') {
  //     next({ path: '/pcms' });
  //   }
  // }

  //Check if login is required for Routes with meta data loginRequired!
  if (to.matched.some(record => record.meta.requiresLogin)) {
    if (!store.getters.isLoggedIn) {
      //Redirect to Login Page
      next({ path: '/login' })
    } else next();
  } else next();
})


export default router
