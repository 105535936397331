<template>
  <div class="container-fluid px-0 h-100" style="text-align: left;">
    <article>
      <section style="padding-top: 50px;">
        <div class="container">

          <div class="row w-100 mb-4">
            <div class="col-12 pl-3 px-0 mb-0 text-left">
              <BreadCrumbComponent :pageTitles="['messages']" />
            </div>
          </div>

              <div class="row w-100">
                <div class="col-12 text-left">
                  <div style="font-size: 20px; font-weight: 400; color: var(--el-app-primary); margin-bottom: 15px; ">
                    <strong>User help messages</strong>
                  </div>
                </div>
              </div>

              <div style="margin-bottom:20px; display: flex; align-items: center; justify-content: space-between;">
                  <div class="row w-100 d-flex">
                      <div class="col-md-5 mb-3">
                        <input type="text" class="search_by_input" v-model.trim="filters[0].value" placeholder="Search" spellcheck="off"/>
                      </div>
                  </div>
              </div>
              <hr/>

          <div v-loading="deleting" class="table-responsive">
            <data-tables :data="messages" :table-props="tableProps" :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }" type="expand" :filters="filters">
              
              <div slot="empty">
                <ScaleOut v-if="loading && !loadingError" class="mt-3" :background="'#164B70'"/>
                <div @click="getMessages" v-else-if="!loading && loadingError" style="cursor: pointer">
                  Unable to Load messages Now. Please click here to retry
                </div>
                <div v-if="!loading && !loadingError">No messages</div>
              </div>

              <el-table-column label="#" :show-overflow-tooltip="true" label-class-name="text-center" class-name="text-center" width="80px">
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <el-table-column label="Message" :show-overflow-tooltip="true" label-class-name="text-left" width="auto">
                <template slot-scope="scope">
                  <span @click="viewMessage( scope.row.full_name, scope.row.email, scope.row.subject, scope.row.message, formattedDate(scope.row.createdAt) )">
                    <strong><span style="font-weight: 700; font-size: 15px;">{{ scope.row.subject }}</span></strong><br/>
                    <span>{{ scope.row.message }}</span>
                  </span>
                </template>
              </el-table-column>

              <el-table-column label="Sender" :show-overflow-tooltip="true" label-class-name="text-left" class-name="text-left">
                <template slot-scope="scope">
                  <span>{{scope.row.full_name}}</span>
                </template>
              </el-table-column>

              <el-table-column label="Date sent" :show-overflow-tooltip="true" label-class-name="text-left" class-name="text-left">
                <template slot-scope="scope">
                  <span>{{ formattedDate(scope.row.createdAt) }}</span>
                </template>
              </el-table-column>

              <el-table-column label="Actions" :show-overflow-tooltip="true" label-class-name="text-center" class-name="text-center">
                <template slot-scope="scope">
                  <el-row>
                    <el-button type="success" icon="el-icon-message" circle @click="viewMessage( scope.row.full_name, scope.row.email, scope.row.subject, scope.row.message, formattedDate(scope.row.createdAt) )"></el-button>
                    <el-button type="danger" @click="deleteMessage(scope.row.id)" icon="el-icon-delete" circle></el-button>
                  </el-row>
                </template>
              </el-table-column>

            </data-tables>
          </div>
          
            </div>

            <section>
              <el-dialog :title="subject" :visible.sync="outerVisible">
                <p><span style="color: black;">{{ message }}</span></p>
                <p>
                  <span>From: <strong>{{ full_name }}</strong></span><br/>
                  <span>Email: {{ email }}</span>
                </p>
                
                
                <div slot="footer" class="dialog-footer">
                  <small>Sent on: <span>{{ createdAt }}</span></small>
                </div>
              </el-dialog>
            </section>
      </section>

    </article>
  </div>
</template>

<script>

  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  import { ScaleOut } from "vue-loading-spinner";
  import BreadCrumbComponent from "../components/bread-crumb-component";
  import { format, parseISO } from "date-fns";

  export default {

    components: { ScaleOut, BreadCrumbComponent },
    mixins: [validationMixin],

      data() {
          return {
              addFacultyModal: false,
              editFacultyModal: false,
              outerVisible: false,

              loading: false,
              loadingError: false,
              submiting: false,
              deleting: false,
              responseData: "",
              messages: [],

              full_name: "",
              email: "",
              subject:"",
              message: "",
              createdAt: "",

              tableData: [],
              tableProps: {
                border: true,
                stripe: true,
              },

              filters: [
                {
                  prop: "full_name",
                  value: "",
                },
              ],

          };
      },

      computed: {
        createdBy() {
          return (createdBy)=> {
            if(!createdBy) return "N/A";
            return `${createdBy.first_name} ${createdBy.last_name}`;
          }
        },

        formattedDate() {
          return (dateString) => format(parseISO(dateString), "do MMMM yyyy");
        },

      },

      validations: {
        title: {
          required
        },
        title_edit: {
          required
        },
      },

      mounted() {
          this.getMessages();
      },
      methods: {

        async getMessages() {
          try {
            this.loading = true;
            this.loadingError = false;
            let request = await this.$http.get(`contact/get`);
            if ( request.data.success && request.data.message == "MESSAGES_RETRIEVED_SUCEESSFULLY" ) { 
              this.messages = request.data.messages;
            } 
            else {throw "ERROR OCCURED"; }  
          } catch (error) {
            if (error.data == "Network Error") {

              this.loadingError = true;
              return this.showFailedMessage("Connection failed","A network error occured, please try again.");
            
            }

            this.loadingError = true; 
            this.showFailedMessage("Unable to load messages", "An unexpected Error occured, please try again");
          } finally {
            this.loading = false; 
          }
        },


        deleteMessage(id) {
          
          this.$confirm('This will permanently delete this message. Do you want to Continue?', 'Confirm delete', {
              confirmButtonText: 'OK',
              cancelButtonText: 'Cancel',
              type: 'warning'
          }).then(() => {
            this.deleting = true;
            this.$http.delete(`contact/delete/${id}`).then((response) => {
              if ( response.data.success && response.data.message == "MESSAGE_DELETED_SUCCESSFULLY") {
                this.getMessages();
                this.showSuccessMessage("Success","Message deleted successfully!");
                this.deleting = false;
              }
            }, 
            error => { // eslint-disable-line no-unused-vars
              this.showFailedMessage("Error", "Unable to delete Massage");
              this.deleting = false;
            });

            }).catch(() => {
                
            });
        },

        viewMessage( full_name, email, subject, message, createdAt ) {
          this.outerVisible = true;
          this.full_name = full_name;
          this.email = email;
          this.subject = subject;
          this.message = message;
          this.createdAt = createdAt;
        }

      }

  }


</script>

<style scoped>
  * {
    box-sizing: border-box;
  }

  .search_by_input {
    width: 400px !important;
  }

  input[type=text], select, textarea {
    width: 100%;
    padding: 10px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    background-color: #FFFFFF;
    font-size: .9em;
  }

  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
  }

  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  }

  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
      .search_by_input {
        width: 100% !important;
      }
  }

  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
      .search_by_input {
        width: 100% !important;
      }
  }
</style>
