<template>
  <div class="text-left schedule-live-lesson">
    <el-dialog
      title="Schedule a Live Class"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
    >
      <div v-loading="isSchedulingLecture">
        <div class="row" style="margin-bottom: 30px">
          <div
            class="col-md-4"
            style="font-weight: 500; color: rgba(0, 0, 0, 0.8)"
          >
            Choose Lecturer
            <div class="mt-1">
              <el-select
                v-model="$v.selectedLecturerId.$model"
                placeholder="Select"
                class="d-block"
              >
                <el-option
                  v-for="lecturer in lecturers"
                  :key="lecturer.staff_id"
                  :label="`${lecturer.first_name} ${lecturer.last_name}`"
                  :value="lecturer.staff_id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div
            class="col-md-4"
            style="font-weight: 500; color: rgba(0, 0, 0, 0.8)"
          >
            Module
            <div class="mt-1">
              <el-select
                :disabled="selectedLecturerId === ''"
                v-model="$v.selectedCourseUnitCodeId.$model"
                placeholder="Select"
                class="d-block"
              >
                <el-option
                  v-for="courseUnit in courseUnitCodeOptions"
                  :key="courseUnit.course_unit_id"
                  :label="courseUnit.title"
                  :value="courseUnit.course_unit_id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div
            class="col-md-4"
            style="font-weight: 500; color: rgba(0, 0, 0, 0.8)"
          >
            Topic
            <span
              v-show="$v.topic.$error && !$v.topic.required"
              class="pl-2"
              style="color: red; font-size: 0.8em"
              >Required</span
            >
            <span
              v-show="$v.topic.$error && !$v.topic.maxLength"
              class="pl-2"
              style="color: red; font-size: 0.8em"
              >Too Long</span
            >
            <div class="mt-1">
              <input
                type="text"
                placeholder="Topic "
                :class="{ 'input-error': $v.topic.$error }"
                v-model.trim="$v.topic.$model"
                class="bg-white"
                style="width: 100%"
              />
            </div>
          </div>
        </div>
        <div class="row mt-1">
          <div
            class="col-md-4"
            style="font-weight: 500; color: rgba(0, 0, 0, 0.8)"
          >
            <div class="block">
              <span class="demonstration">Lecture Date</span>
              <el-date-picker
                v-model="$v.lectureDate.$model"
                :format="'dd MMMM yyyy'"
                type="date"
                placeholder="Select date and time"
                style="width: 100%"
              >
              </el-date-picker>
            </div>
          </div>
          <div
            class="col-md-4"
            style="font-weight: 500; color: rgba(0, 0, 0, 0.8)"
          >
            <div class="block">
              <span class="demonstration">Start & End Time</span>
              <el-time-picker
                is-range
                :editable="false"
                v-model="startAndEndTime"
                @focus="isDurationError = false"
                :format="'hh:mm A'"
                range-separator="To"
                start-placeholder="Start time"
                :class="{ 'input-error': isDurationError }"
                end-placeholder="End time"
                style="height: 40px !important"
              >
              </el-time-picker>
            </div>
          </div>
          <div class="col-md-4"></div>
        </div>
        <div v-show="isDurationError" class="row w-100">
          <div
            class="col-12"
            style="font-weight: 400; color: red; font-size: 0.95em"
          >
            <i class="fas fa-close pr-2"></i> The duration of the Lecture should
            be between 2 Hours and 3 Hours
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 offset-md-8 px-3">
            <br />
            <button
              type="button"
              :disabled="$v.$invalid"
              :class="{ 'not-allowed': $v.$invalid }"
              @click="onScheduleLecture"
              class="btn z-depth-0 w-100 mx-0"
              v-ripple="'rgba(255, 255, 255, 0.35)'"
              style="
                text-transform: capitalize;
                height: 45px;
                border-radius: 4px;
                background-color: var(--el-app-primary);
              "
            >
              <span style="position: relative; bottom: 1px"
                >Schedule Class</span
              >
            </button>
          </div>
        </div>
      </div>
    </el-dialog>

    <div class="text-left mt-5">
      <div style="padding-right: 80px">
        <ScaleOut v-show="isLoading" :background="'red'" />
      </div>
      <button
        v-show="!isLoading"
        type="button"
        @click="fetchBatchLecturers"
        class="btn z-depth-0"
        v-ripple="'rgba(255, 255, 255, 0.35)'"
        style="
          width: auto;
          text-transform: capitalize;
          height: 40px;
          border-radius: 4px;
          background-color: red;
          position: relative;
          bottom: 10px;
        "
      >
        <span style="position: relative; bottom: 4px">
          <i class="fas fa-video pr-2"></i> Schedule Lecture
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import ScaleOut from "vue-loading-spinner/src/components/ScaleOut";
import getMonthName from "../helpers/getMonthName";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import { format } from "date-fns";
export default {
  mixins: [validationMixin],
  components: { ScaleOut },
  data() {
    return {
      isSchedulingLecture: false,
      dialogVisible: false,
      isLoading: false,
      lectureDate: "",
      startAndEndTime: "",
      selectedCourseUnitCodeId: "",
      isDurationError: false,
      selectedLecturerId: "",
      topic: "",
      lecturers: [],
      courseUnitCodeOptions: [{}],
    };
  },
  props: {
    batchInfo: {
      required: true,
      type: Object,
    },
  },
  validations: {
    lectureDate: { required },
    startAndEndTime: { required },
    selectedCourseUnitCodeId: { required },
    selectedLecturerId: { required },
    topic: { required, maxLength: maxLength(100) },
  },
  watch: {
    //When selected Batch changes, Lecturer can now select Paper
    selectedLecturerId() {
      this.selectedCourseUnitCodeId = "";
      // if (this.selectedLecturerId == "") return;

      let lecturer = this.lecturers.find(
        (lecturer) => this.selectedLecturerId == lecturer.staff_id
      );
      //If no batch was found, stop there
      if (!lecturer) return;
      this.courseUnitCodeOptions = lecturer.staff_intake_course_units;

      //If it's only one Module in this batch, select it already otherwsise let them choose
      // this.selectedCourseUnitCodeId =
      //   this.courseUnitCodeOptions.length == 1
      //     ? this.courseUnitCodeOptions[0].course_unit_id
      //     : "";
    },
  },
  methods: {
    handleClose(done) {
      done();
    },

    toBatchIntake(month, year) {
      return `${getMonthName(month)}/${year}`.toUpperCase();
      // return toBatchIntakeString(12, 2019);
    },

    async onScheduleLecture() {
      //If a user enabled the button in the DOM, then it could stl be invalid, so make another check
      if (this.$v.$invalid) {
        return this.showFailedMessage(
          "Validation Error",
          "A validation error occured"
        );
      }

      //Get the Length of the lecture
      // let lectureLength =
      //   this.startAndEndTime[1].getTime() - this.startAndEndTime[0].getTime();

      // //If the lecture length is more than  3 Hours, reject
      // // eslint-disable-next-line no-unreachable
      // if (lectureLength > 3600000 * 3) {
      //   return (this.isDurationError = true);
      // }

      // //If the lecture length is less than 2 Hours, reject
      // if (lectureLength < 3600000 * 2) {
      //   return (this.isDurationError = true);
      // }

      try {
        this.isSchedulingLecture = true;

        /* eslint-disable no-unreachable  */
        let request = await this.$http.post("live-lecture/add", {
          title: this.topic,
          date: format(this.lectureDate, "yyyy-LL-dd"),
          start_time: format(this.startAndEndTime[0], "hh:mma"),
          end_time: format(this.startAndEndTime[1], "hh:mma"),
          course_unit_id: `${this.selectedCourseUnitCodeId}`,
          course_intake_batch_id: this.batchInfo.course_intake_batch_id,
          lecturer_id: this.selectedLecturerId,
          created_by: this.$store.state.userId,
        });

        if (
          request.data.success &&
          request.data.message == "LIVE_LECTURE_SCHEDULED_SUCCESFULLY"
        ) {
          this.dialogVisible = false;
          //Clear the Data Inputs Now
          this.clearInputs();

          //Tell other event listners to refresh their data as a new  lecture has been scheduled
          this.$emit("on-refresh");
          return this.showSuccessMessage(
            "Lecture Scheduled",
            "The Lecture has been successfully scheduled."
          );
        } else if (request.data.message == "ENTER_DATE_AFTER_CURRENT_DATE") {
          return this.showFailedMessage(
            "Lecture Time Incorrect",
            "The Lecture Time should not be in the past"
          );
        } else if (
          request.data.message == "LECTURE_LENGTH_MUST_BE_ATLEAST_2_HOURS"
        ) {
          return this.showFailedMessage(
            "Lecture Length Incorrect",
            "The Lecture length should not be less than 2 Hours"
          );
        } else if (
          request.data.message == "LECTURE_LENGTH_MUST_NOT_BE_MORE_THAN_3_HOURS"
        ) {
          return this.showFailedMessage(
            "Lecture Length Incorrect",
            "The Lecture length should not be more than 3 Hours"
          );
        } else if (request.data.message == "CONLFICTING_LECTURE_FOUND") {
          const { title, lecturer, course_unit, start_time, end_time } =
            request.data.conflicting_lecture;
          return this.$alert(
            `There's already a Lecture entitled '${title}' scheduled on the same day from ${start_time} to ${end_time} for ${lecturer.first_name} ${lecturer.last_name} who teaches '${course_unit.title}' `,
            "Conflicting Lecture",
            {
              confirmButtonText: "OK",
              type: "error",
              callback: () => {},
            }
          );
        } else if (
          request.data.message ==
          "NO_BATCH_FOR_THE_COURSE_UNIT_AVAILABLE_FOR_SUPPLIED_LECTURER"
        ) {
          this.dialogVisible = false;
          //Clear the Data Inputs Now
          this.clearInputs();

          //Tell other event listners to refresh their data.
          this.$emit("on-refresh");
          return this.showFailedMessage(
            "Scheduling Failed",
            "Unable to Schedule Lecture, ensure the Lecturer teaches that Module and try again"
          );
        }

        throw "UNEXPECTED_ERROR";
      } catch (error) {
        //If it's a server side error
        if (error.response && error.response.status == 500) {
          return this.showFailedMessage(
            "Server Side Error Occured",
            "A Server Side Error occured"
          );
        } else
          return this.showFailedMessage(
            "Error Occured",
            "An unexpected error occured, Please try again"
          );
      } finally {
        this.isSchedulingLecture = false;
      }
    },

    clearInputs() {
      this.lectureDate = "";
      this.startAndEndTime = "";
      this.selectedCourseUnitCodeId = "";
      this.selectedLecturerId = "";
      this.topic = " ";
      this.batches = [];
      this.courseUnitCodeOptions = [];
    },

    async fetchBatchLecturers() {
      //If lectures were already fetched, then dont fetch again
      if (this.lecturers.length > 0) return (this.dialogVisible = true);
      //Start Loading
      this.isLoading = true;
      try {
        let request = await this.$http.get(
          `course-intake-batches/lecturers/${this.batchInfo.course_intake_batch_id}`
        );

        this.isLoading = false;

        //If request was successful, get Lecturer batches,
        if (
          request.data.success &&
          request.data.message === "BATCH_LECTURERS_FETCHED_SUCCESSFULLY"
        ) {
          this.lecturers = request.data.lecturers;

          //SHow Schedule Live Lecture Modal
          this.dialogVisible = true;
        } else if (request.data.message == "lecturer not found")
          throw "LECTURER_NOT_FOUND";
        else throw "UNKNOWN_ERROR_OCCURED";
      } catch {
        this.isLoading = false;
        this.showFailedMessage(
          "Fetching failed",
          "Unable to process the scheduling of the lecture, Please try again."
        );
      }
    },
  },
};
</script>

<style scoped>
input[type="text"],
select,
textarea {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}
</style>

<style>
.input-error {
  border: 1px solid red !important;
  box-shadow: none !important;
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;
  /*  animation-iteration-count:  infinite;  */
}
.not-allowed {
  cursor: not-allowed !important;
  pointer-events: all !important;
}
select.browser-default {
  display: block !important;
  height: 30px !important;
  width: 105%;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  cursor: pointer;
}
.schedule-live-lesson .el-dialog {
  width: 60%;
}

.schedule-live-lesson .el-dialog__title {
  font-weight: 500;
}

.schedule-live-lesson .el-select .el-input__inner {
  cursor: pointer;
  height: 40px !important;
  width: 100% !important;
  padding-left: 10px !important;
}

.el-date-editor--date .el-input__inner,
.el-range-editor .el-input__inner {
  cursor: pointer;
  height: 40px !important;
  width: 100% !important;
  padding-left: 45px !important;
}
.el-date-editor .el-range-separator {
  display: contents;
  font-size: 0.8em;
  width: 20px !important;
  white-space: nowrap;
}

.el-date-editor.el-range-editor.el-input__inner.el-date-editor--timerange {
  height: 45px !important;
  width: 100%;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .schedule-live-lesson .el-dialog {
    width: 80%;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .schedule-live-lesson .el-dialog {
    width: 80%;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .schedule-live-lesson .el-dialog {
    width: 90%;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .schedule-live-lesson .el-dialog {
    width: 90%;
  }
}
</style>