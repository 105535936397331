<template>
  <div class="container-fluid px-0 h-100" style="text-align: left;">
    <article>
      <section style="padding-top: 50px;">
        <div class="container">

          <div class="row w-100 mb-4">
            <div class="col-12 pl-3 px-0 mb-0 text-left">
              <BreadCrumbComponent :pageTitles="['Faculties','Departments']" />
            </div>
          </div>

            <div class="row w-100">
              <div class="col-12 text-left">
                <div style="font-size: 20px; font-weight: 400; color: var(--el-app-primary); margin-bottom: 15px; ">
                  <strong>Departments in "<span style="text-transform: capitalize;">{{facultyName ? facultyName : '...'}}</span>"</strong><br>
                </div>
              </div>
            </div>

          <div class="search_add_section">
            <div class="">
              <input type="text" v-model.trim="filters[0].value" placeholder="Search by Title"  class="search_by_input" spellcheck="off"/>
            </div>
            <el-button class="z-depth-0 redBtn" v-ripple="'rgba(255, 255, 255, 0.35)'" @click="addDepartmentModal = true">Add A Department</el-button>
          </div>

            <hr/>

          <div v-loading="deleting" class="table-responsive">
            <data-tables :data="departments" :table-props="tableProps" :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }" type="expand" :filters="filters">
              
              <div slot="empty">
                <ScaleOut v-if="loading && !loadingError" class="mt-3" :background="'#164B70'"/>
                <div @click="getDepartments" v-else-if="!loading && loadingError" style="cursor: pointer">
                  Unable to Load Departments Now. Please click here to retry
                </div>
                <div v-if="!loading && !loadingError">No Departments</div>
              </div>

              <el-table-column label="#" :show-overflow-tooltip="true" label-class-name="text-center" class-name="text-center" width="80px">
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <el-table-column label="Department Title" :show-overflow-tooltip="true" label-class-name="text-left" width="auto">
                <template slot-scope="scope">
                  <span>{{ scope.row.department_name }}</span>
                </template>
              </el-table-column>

              <el-table-column label="Created by" :show-overflow-tooltip="true" label-class-name="text-left">
                <template slot-scope="scope">
                  <span>{{ createdBy(scope.row.creator)}}</span>
                  <!-- <span>{{ scope.row.created_by.first_name }} {{ scope.row.created_by.last_name }}</span> -->
                </template>
              </el-table-column>

              <el-table-column label="View Programmes" :show-overflow-tooltip="true" label-class-name="text-left">
                <template slot-scope="scope">
                  <el-button type="primary" @click="goToCourses(scope.row.department_id)" round>
                    {{ scope.row.courses }} <span>{{ scope.row.courses == 1 ? "Programme" : "Programmes" }}</span>
                  </el-button>
                </template>
              </el-table-column>

              <el-table-column label="Actions" :show-overflow-tooltip="true" label-class-name="text-left">
                <template slot-scope="scope">
                  <div style="display:flex;">
                    <el-row>
                      <el-tooltip content="Edit" placement="top">
                        <el-button type="primary" :disabled="!findCommonElements3(faculties_cUnits_crud, $store.state.theRole)" @click="departmentEditData(scope.row.department_id, scope.row.department_name, scope.row.short_name )" icon="el-icon-edit" circle></el-button>
                      </el-tooltip>
                      <el-tooltip content="Delete" placement="top">   
                        <el-button type="danger" :disabled= "true" @click="deleteDepartment(scope.row.department_id)" icon="el-icon-delete" circle></el-button>
                      </el-tooltip>
                    </el-row>
                  </div>
                </template>
              </el-table-column>
            </data-tables>
          </div>
        </div>
      </section>

      <section>
          <div class="container">
            <div class="department_dialogs">
              <el-dialog title="Add A Department" :visible.sync="addDepartmentModal" :destroy-on-close="true" :before-close="handleClose">
                <div v-loading="submiting">
                      <div class="row">
                          <div class="col-md-12">
                              <div class="form-group">
                                  <label for="department_name">Department Title</label>
                                  <input type="text" id="department_name" :class="{ 'input-error': $v.department_name.$error }" @keyup.enter="addDepartment" v-model.trim="$v.department_name.$model" placeholder="Department Title">
                                  <br/><span v-if="$v.department_name.$error" style="font-size: 0.7em; color: red">{{ departmentNameError }}</span>
                              </div>
                          </div>
                          <!-- <div class="col-md-6">
                              <div class="form-group">
                                  <label for="Department_code">Department Code</label>
                                  <input type="text" id="Department_code" :class="{ 'input-error': $v.short_name.$error }" @keyup.enter="addDepartment" v-model.trim="$v.short_name.$model" placeholder="Department Code">
                                  <br/><span v-if="$v.short_name.$error" style="font-size: 0.7em; color: red">{{ shortNameError }}</span>
                              </div>
                          </div> -->
                      </div>
                      <div class="form-group text-center" style="padding-top:10px;">
                          <el-button style="width: 150px; background-color: var(--el-app-primary); position: relative; bottom: 9px; color: white;" class="z-depth-0" v-ripple="'rgba(255, 255, 255, 0.35)'" @click="addDepartment" :disabled="!$v.department_name.required">Add Department</el-button>
                      </div>

                  <div class="text-center">
                      <div class="text-center">
                          <small>© 2021 Pesamoni, All rights reserved.</small>
                      </div>
                      <div class="text-center">
                          <!-- <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                      </div>
                  </div>
                </div>
              </el-dialog>
              
              <el-dialog title="Edit Department" :visible.sync="editDepartmentModal" :destroy-on-close="true" :before-close="handleClose">
                <div v-loading="submiting">
                      <div class="row">
                          <div class="col-md-12">
                              <div class="form-group">
                                  <label for="department_name_edit">Department Title</label>
                                  <input type="text" id="department_name_edit" :class="{ 'input-error': $v.department_name_edit.$error }" @keyup.enter="editDepartment_final" v-model.trim="$v.department_name_edit.$model" placeholder="Department Title">
                                  <br/><span v-if="$v.department_name_edit.$error" style="font-size: 0.7em; color: red">{{ departmentNameEditError }}</span>
                              </div>
                          </div>
                          <!-- <div class="col-md-6">
                              <div class="form-group">
                                  <label for="Department_code">Department Code</label>
                                  <input type="text" id="Department_code" :class="{ 'input-error': $v.short_name_edit.$error }" @keyup.enter="addDepartment" v-model.trim="$v.short_name_edit.$model" placeholder="Department Code">
                                  <br/><span v-if="$v.short_name_edit.$error" style="font-size: 0.7em; color: red">{{ shortNameEditError }}</span>
                              </div>
                          </div> -->
                      </div>

                      <div class="form-group text-center" style="padding-top:10px;">
                        <el-button style="width: 150px; background-color: var(--el-app-primary); position: relative; bottom: 9px; color: white;" class="z-depth-0" v-ripple="'rgba(255, 255, 255, 0.35)'" @click="editDepartment_final" :disabled="!$v.department_name_edit.required">Edit Department</el-button>
                      </div>

                  <div class="text-center">
                      <div class="text-center">
                          <small>© 2021 Pesamoni, All rights reserved.</small>
                      </div>
                      <!-- <div class="text-center">
                          <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small>
                      </div> -->
                  </div>
                </div>
              </el-dialog>
            </div>
          </div>
      </section>
    </article>
  </div>
</template>

<script>

  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  import { ScaleOut } from "vue-loading-spinner";
  import BreadCrumbComponent from "../components/bread-crumb-component";

  export default {

      components: { ScaleOut, BreadCrumbComponent },
      mixins: [validationMixin],

      data() {
          return {
              addDepartmentModal: false,
              editDepartmentModal: false,
              loading: false,
              loadingError: false,
              submiting: false,
              deleting: false,
              responseData: "",

              department_name: "",
              // short_name: "",
              // short_name_edit: "",
              department_name_edit: "",
              facultyName: this.$route.params.facultyName,
              departments: [],
              fid: this.$route.params.fId, //this is the faculty id from the browser
              
              tableProps: {
                border: true,
                stripe: true,
              },
              filters: [
                {
                  prop: "department_name",
                  value: "",
                },
              ],

              faculties_cUnits_crud: ['AR', 'VC', 'SUPER_ADMIN'],
              
          }
      },

      computed: {
        departmentNameError() {
          if (!this.$v.department_name.required) {
            return "*Department title is required";
          }
          return "";
        },

        // shortNameError() {
        //   if (!this.$v.short_name.required) {
        //     return "*Department code is required";
        //   }
        //   return "";
        // },

        departmentNameEditError() {
          if (!this.$v.department_name_edit.required) {
            return "*Department title is required";
          }
          return "";
        },

        // shortNameEditError() {
        //   if (!this.$v.short_name_edit.required) {
        //     return "*Department code is required";
        //   }
        //   return "";
        // },

        createdBy() {
          return (creator)=> {
            if(!creator) return "N/A";
            return `${creator.first_name} ${creator.last_name}`;
          }
        }
      },

      validations: {
          department_name: {
            required
          },
          // short_name: {
          //   required
          // },
          department_name_edit: {
            required
          },
          // short_name_edit: {
          //   required
          // }
      },

      mounted() {
          this.getDepartments();
      },

      methods: {

        // Iterate through each element in the
        // first array and if some of them 
        // include the elements in the second
        // array then return true.
        findCommonElements3(arr1, arr2) {
          return arr1.some(item => arr2.includes(item))
        },

        handleClose(done) {
          this.clearInputs();
          this.$v.$reset();
          done();
        },

        clearInputs() {
          this.department_name = "";
          // this.short_name = "";
          // this.short_name_edit = "";
          this.department_name_edit = "";
        },

        async addDepartment() {
          //If the function was triggered elsewhere e.g from pressing enter
          if (!this.$v.department_name.required) return;
          let departmentData = {
            department_name: this.department_name,
            // short_name: this.short_name,
            faculty_id: this.fid
          }
          this.submiting = true;

          try {
            let request = await this.$http.post(`departments/add`, departmentData );
            if ( request.data.success && request.data.message == "Department was created successfully" ) 
            {
                
                this.department_name = "";
                // this.short_name = "";
                this.getDepartments(); 
                this.showSuccessMessage("Success","Department title added successfully");
                this.addDepartmentModal = false;
                this.clearInputs();
                this.$v.$reset();

            } else if (request.data.message == "FACULTY_ID_DEPARTMENT_NAME_COMBINATION_MUST_BE_UNIQUE") {

              this.showWarningMessage("Duplicate Entry","Department title aleady in use");

            } else if (request.data.message == "DEPARTMENT_NAME_PATTERN_VALIDATION_FAILED") {

              this.showWarningMessage("Unaccepted characters","The title includes unaccepted characters");

            } else {
              throw "ERROR OCCURED";
            }
          } catch (error) {
            if (error.data == "Network Error") {

              return this.showFailedMessage("Connection failed","A network error occured, please try again.");
            
            }
            this.showFailedMessage("Unable to add Department title","An unexpected Error occured, please try again");
          } finally {
            this.submiting = false;
          }
        },

        getDepartments() {
              this.loading = true;
              this.loadingError = false;
              this.$http.get(`departments/fetch-many/${this.fid}`).then(
                (response) => {
                  if (
                    response.data.success && response.data.message == "FACULTY_DEPARTMENTS_FETCHED"
                  ) {
                    this.departments = response.data.departments;
                    this.facultyName = response.data.faculty_name;
                  } else {
                    this.loadingError = true;
                    this.showFailedMessage(
                      "Fetch Failed",
                      "An unexpected Error Occured. Please try again"
                    );
                  }
                },
              () => {
                this.loadingError = true;
                this.showFailedMessage(
                  "Fetch Failed",
                  "An unexpected Error Occured. Please try again"
                );
              }
            )
            .finally(() => (this.loading = false));
        },

          departmentEditData(id, dname, shortName) {
              this.editDepartmentModal = true;
              this.id_edit = id;
              this.department_name_edit = dname;
              this.short_name_edit = shortName;
          },
          
        async editDepartment_final() {
          //If the function was triggered elsewhere e.g from pressing enter
          if (!this.$v.department_name_edit.required) return;
          let departmentEditData = {
            department_name: this.department_name_edit,
            short_name: this.short_name_edit,
          }
          this.submiting = true;
          try {
            let request = await this.$http.patch(`departments/update-one/${this.id_edit}`, departmentEditData );
            if ( request.data.success ) 
            {
              this.editDepartmentModal = false;
              this.getDepartments();
              this.showSuccessMessage("Success","Department title edited successfully");
              this.clearInputs();
              this.$v.$reset();

            } else if (request.data.message == "faculty_id must be unique") {

              this.showWarningMessage("Warning","Department title aleady exists");

            } else if (request.data.message == "DEPARTMENT_SHORT_ALREADY_EXISTS") {

              this.showWarningMessage("Duplicate Entry","Department code aleady in use");

            } else if (request.data.message == "NOTHING_TO_CHANGE_EVERY_THING_IS_UP_TO_DATE") {

              this.showWarningMessage("Already upto date","There is nothing to change.");

            } else if (request.data.message == "DEPARTMENT_NAME_PATTERN_VALIDATION_FAILED") {

              this.showWarningMessage("Unaccepted characters","The title includes unaccepted characters");

            } else if (request.data.message == "DEPARTMENT_SHORT_PATTERN_VALIDATION_FAILED") {

              this.showWarningMessage("Unaccepted characters","The code includes unaccepted characters");

            } else {
              throw "ERROR OCCURED";
            }
          } catch (error) {
            if (error.data == "Network Error") {

              return this.showFailedMessage("Connection failed","A network error occured, please try again.");
              
            }
            this.showFailedMessage("Unable to edit Department","An unexpected Error occured, please try again");
          } finally {
            this.submiting = false;
          }
        },

          deleteDepartment(id) {
          return;
          // eslint-disable-line no-unreachable
            this.$confirm('This will permanently delete this Department title. Do you want to Continue?', 'Confirm delete', { // eslint-disable-line no-unreachable
              confirmButtonText: 'OK',
              cancelButtonText: 'Cancel',
              type: 'warning'
            }).then(() => {
              this.deleting = true;
              this.axios.delete(`departments/delete-one/${id}`).then((response) => {
                if (response.data.success == true) {
                  this.getDepartments();
                  this.showSuccessMessage("Success","Department title deleted successfully!");
                  this.deleting = false;
                }
              }, 
              error => { // eslint-disable-line no-unused-vars
                  this.showFailedMessage("Error","Cound not delete this Department title!");
                  this.deleting = false;
              });
            }).catch(() => {

            });
          },

          goToCourses(dId) {
            let departmentName = this.departments.find((department) => {
              return department.department_id == dId
            }).department_name;
        
            this.$router.push({name: 'DepartmentCourses', params: {
              dId,
              departmentName
            }});
          }
      }
  }
</script>

<style scoped>

  input[type=text], select, textarea {
    width: 100%;
    padding: 10px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    background-color: #FFFFFF;
    font-size: .9em;
  }

  .search_add_section {
    display: flex; align-items: center; justify-content: space-between;
  }

  .search_by_input {
    width: 400px !important;
  }
/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
    .search_add_section {
      display: block;
    }
    .addbtn {
      width: 100%;
      margin-top: 10px;
    }
    .search_by_input {
      width: 100% !important;
    }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
    .search_add_section {
      display: block;
    }
    .addbtn {
      width: 100%;
      margin-top: 10px;
    }
    .search_by_input {
      width: 100% !important;
    }
}
</style>

<style>
  .department_dialogs .el-dialog {
    width: 60%;
  }

  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
    .department_dialogs .el-dialog {
      width: 60%;
    }
  }

  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .department_dialogs .el-dialog {
      width: 70%;
    }
  }

  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .department_dialogs .el-dialog {
      width: 90%;
    }
  }

  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .department_dialogs .el-dialog {
      width: 90%;
    }

  }
</style>
