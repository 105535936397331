<template>
  <div>
    <section class="container">
      <header>
        <div class="in-the-center" style="padding-top: 20px; padding-bottom: 10px">
          <div class="in-the-center">
            <div id="menucontainer" v-on:click="openNav()" style="margin-right: 10px;">
              <div>
                <div class="bar1"></div>
                <div class="bar2"></div>
                <div class="bar3"></div>
              </div>
            </div>
            <div>
              <div class="input-group input-group-lg bg-white search-area">
                <div id="search-icon" class="input-group-prepend">
                  <span class="input-group-text" id="inputGroup-sizing-lg"
                    ><i class="fas fa-search"></i></span>
                </div>
                <input type="text" v-model.trim="keyWords" @keyup.enter="getSearchResults" id="search-input" placeholder="Search ..."/>
                <ScaleOut v-if="loading" :background="'#164B70'"/>
              </div>
            </div>
          </div>

          <div class="in-the-center">
            <div @click="getNotifications" class="notification" v-popover:popover style="cursor: pointer;">
              <div class="bell">
                <i class="far fa-bell"></i>
              </div>
              <div v-if="notifications_count != 0" class="notify">
                <small>{{ notifications_count }}</small>
              </div>
            </div>

            <div class="nav_fname">
              <div>
                <span>{{ $store.state.firstName }}</span>
              </div>
            </div>
            <router-link to="/dashboard/settings">
              <div class="profile_img">
                <img
                  :src="this.$store.state.profilePicture"
                  v-if="this.$store.state.profilePicture"
                  alt=""
                  class="img-fluid"
                />
                <div
                  v-if="this.$store.state.profilePicture == null"
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: var(--el-app-primary);
                    color: white;
                    width: 35px;
                    height: 35px;
                    object-fit: cover;
                    border-radius: 100%;
                  "
                >
                  <strong>{{ $store.state.userInitials }}</strong>
                </div>
              </div>
            </router-link>

            <el-popover
              ref="popover"
              placement="bottom"
              trigger="click">
              <div v-loading="Noteloading" class="notification_section">

                  <div v-if="notifications.length == 0" class="nonote">
                    <p style="color: grey; font-weight: 600;">Notifications not found</p>
                  </div>

                  <div v-else>
                    <div v-for="notification in notifications" :key="notification.system_notification_id" style="cursor: pointer; margin-top:10px; padding:10px; background-color: #eeeeee; color: var(--el-app-primary); border-radius:3px;">
                      <div style="display:flex; justify-content: space-between;">
                        <div style="text-align: left;">
                          <small><strong>{{ notification.subject }}</strong></small><br/>
                          <small>{{ notification.content }}</small>
                        </div>
                        <div style="font-size: 20px; padding-left: 10px;">
                          <el-popover
                            placement="left-start"
                            width="200"
                            trigger="hover">
                            <div>
                              <div @click="deleteNotification(notification.system_notification_id)" style="display: flex; align-items: center; cursor: pointer;">
                                <i class="el-icon-delete" style="font-size: 20px; font-weight: 600;"></i>
                                <div style="padding-left: 10px;">
                                  <span><strong>Delete</strong></span><br/>
                                  <small>Delete this notification</small>
                                </div>
                              </div>
                            </div>

                            <i class="fas fa-ellipsis-v" slot="reference"></i>
                          </el-popover>
                        </div>
                      </div>
                      <div style="text-align: right;">
                        <small>{{ notification.createdAt }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="text-center">
                    <br/>
                    <!-- <p style="color: var(--el-app-primary); cursor: pointer;">
                      <strong>See All</strong>
                    </p> -->
                  </div>

              </div>
            </el-popover>

            <el-popover
              ref="popover22"
              placement="bottom"
              trigger="click">
              <div style="cursor: pointer; margin-top:10px; padding:10px; background-color: #eeeeee; color: var(--el-app-primary); border-radius:3px;">
                  <div style="text-align: right;">
                    <small>29th Jan 2021</small>
                  </div>
                </div>
            </el-popover>

          </div>
        </div>
      </header>
    </section>

    <section>
      <div>
        <div id="mySidenav" class="sidenav">
          <div
            style="
              width: 70%;
              height: 100%;
              float: left;
              padding-top: 50px;
              background-color: white;
            "
          >
            <SideNavbarComponent></SideNavbarComponent>
          </div>
          <div
            v-on:click="closeNav()"
            style="
              width: 30%;
              height: 100%;
              float: left;
              background-color: rgba(0, 0, 0, 0.8);
            "
          ></div>
          <div style="clear: both"></div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import SideNavbarComponent from "../components/side-navbar-component.vue";
  import { ScaleOut } from "vue-loading-spinner";
  
  export default {
    components: { SideNavbarComponent, ScaleOut },
    data() {
      return {
        visible: false,
        profilePictureModal: false,
        submiting: false,
        loading: false,
        Noteloading: false,
        accessCode: "SLAU0X89",
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        faculty: "Faculty Of Social Sciences",
        profile_picture: "",
        events: [],
        keyWords: "",
        notifications: [],
        notifications_count: "",

        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",

        gridData: [{
          date: '2016-05-02',
          name: 'Jack',
          address: 'New York City'
        }, {
          date: '2016-05-04',
          name: 'Jack',
          address: 'New York City'
        }, {
          date: '2016-05-01',
          name: 'Jack',
          address: 'New York City'
        }, {
          date: '2016-05-03',
          name: 'Jack',
          address: 'New York City'
        }]


      };
    },

    computed: {
        getInitials() {
          return (nameString) => {
            //  format(parseISO(dateString), "do MMMM yyyy");
            var names = nameString.split(" "),
              initials = names[0].substring(0, 1).toUpperCase();
            if (names.length > 1) {
              initials += names[names.length - 1].substring(0, 1).toUpperCase();
            }
            return initials;
          };
        },
    },

    mounted() {
    
      this.unreadNotificationsCount();

    },

    methods: {
      openNav() {
        document.getElementById("mySidenav").style.width = "100%";
      },

      closeNav() {
        document.getElementById("mySidenav").style.width = "0";
      },

      show_hide() {
        if (
          document.getElementById("notifications_display").style.display !=
          "block"
        ) {
          document.getElementById("notifications_display").style.display =
            "block";
        } else {
          document.getElementById("notifications_display").style.display = "none";
        }
      },

      searchResults() {
        // this.$router.push({name: 'SearchResults', params: { keyword: this.keyWords } });
      },

      async getSearchResults() {
        if(this.keyWords.length < 3) return;
              try {
                this.loading = true;
                this.loadingError = false;
                let request = await this.$http.get(`search?search_string=${this.keyWords}`);
                if ( request.data.success && request.data.message == "SEARCH_RESULTS_FETCHED" ) { 
                  this.$store.commit("SAVE_SEARCH_RESULTS", request.data.results)

                  if(this.$route.name != "SearchResults"){
                  return this.$router.push({name: 'SearchResults', params: { keyword: this.keyWords } });
                  }
                } else if(request.data.message == "NO_SEARCH_RESULTS_FOUND") {
                  return this.showFailedMessage("Failed","Search results not found");
                }
                else {throw "ERROR OCCURED"; }
              } catch (error) {
                if (error.data == "Network Error") {

                  this.loadingError = true;
                  return this.showFailedMessage("Connection failed","A network error occured, please try again.");
                
                }

                this.loadingError = true; 
                this.showFailedMessage("Unable to load Search Results", "An unexpected Error occured, please try again");
              } finally {
                this.loading = false; 
              }
      },

      async getNotifications() {
              try {
                this.Noteloading = true;
                let request = await this.$http.get(`system-notifications/fetch-all-notifications-for-user`);
                if ( request.data.success && request.data.message == "NOTIFICATIONS_RETRIEVED_SUCCESSFULLY" ) { 
                  this.notifications = request.data.notifications;
                  this.notifications_count = 0;
                }
                else { throw "ERROR OCCURED"; }
              } catch (error) {
                if (error.data == "Network Error") {

                  return this.showFailedMessage("Connection failed","A network error occured, please try again.");
                
                }
                
                this.showFailedMessage("Unable to load Notifications", "An unexpected Error occured, please try again");
              } finally {
                this.Noteloading = false;
              }
      },

      async unreadNotificationsCount() {
              try {
                this.Noteloading = true;
                let request = await this.$http.get(`system-notifications/count-all-unread-notifications/staff/${this.$store.state.userId}`);
                if ( request.data.success && request.data.message == "NOTIFICATIONS_COUNTED_SUCCESS_FULLY" ) { 
                  this.notifications_count = request.data.count;
                }
                else { throw "ERROR OCCURED"; }
              } catch (error) {
                if (error.data == "Network Error") {

                  return this.showFailedMessage("Connection failed","A network error occured, please try again.");
                
                }
                
                this.showFailedMessage("Unable to count Notifications", "An unexpected Error occured, please try again");
              } finally {
                this.Noteloading = false;
              }
      },

      async deleteNotification(notificationId) {
        try {
                this.Noteloading = true;
                let request = await this.$http.delete(`system-notifications/delete-one/${notificationId}`);
                if ( request.data.success && request.data.message == "NOTIFICATION_DELETED_SUCCESS_FULLY" ) { 
                  this.unreadNotificationsCount();
                  return this.showSuccessMessage("success","Notification deleted succesfully.");
                }
                else if ( request.data.success && request.data.message == "NOTIFICATIONS_NOT_FOUND" ) { 
                  return this.showWarningMessage("Not found","Notification not found.");
                }
                else { throw "ERROR OCCURED"; }
              } catch (error) {
                if (error.data == "Network Error") {

                  return this.showFailedMessage("Connection failed","A network error occured, please try again.");
                
                }
                
                this.showFailedMessage("Unable to delete Notification", "An unexpected Error occured, please try again");
              } finally {
                this.Noteloading = false;
              }
      }

    },
  };
</script>

<style scoped>

  .nav_fname {
    margin: 0px 0px 0px 20px; display: flex; align-items:center;
  }

  input[type=text] {
    width: 100%;
    padding: 8px 10px;
    border-radius: 4px;
    resize: vertical;
    background-color: #FFFFFF;
    font-size: .8em;
  }
  input[type=text]:focus {
    border: 0.01em solid #409EFF !important;
  }
  .notification {
    position: relative;
    border-radius: 50%;
    background-color: rgb(250, 248, 248);
  }
  .notification:hover {
    background-color: white;
  }
  .bell {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-radius: 50%;     border-color: */
    /* background-color: white; */
  }

  .bell i {
    color: grey;
    font-size: 25px;
  }

  .notify {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    top: -3px;
    right: -3px;
    background-color: red;
    color: white;
    font-size: 15px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #notifications_display {
    display: none;
    position: absolute;
    right: 0px;
    width: 300px;
    background-color: white;

    -webkit-box-shadow: 4px 4px 17px -5px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 4px 4px 17px -5px rgba(0, 0, 0, 0.75);
    box-shadow: 4px 4px 17px -5px rgba(0, 0, 0, 0.75);
  }

  #notifications_display ul {
    margin: 30px 0px;
    padding: 0px;
  }

  #notifications_display ul p {
    padding: 10px 20px 0px 20px;
  }

  #notifications_display ul li {
    list-style: none;
    text-align: left;
    color: var(--el-app-primary);
    padding: 10px 20px;
    /* border-radius: 10px; */
    cursor: pointer;
    transition: 0.5s;
  }

  #notifications_display ul li:hover {
    background-color: #eeeeee;
    -webkit-box-shadow: 10px 10px 21px -16px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 21px -16px rgba(0, 0, 0, 0.75);
    box-shadow: 10px 10px 21px -16px rgba(0, 0, 0, 0.75);
    transition: 0.5s;
  }

  .notification_section {
    min-height: 25vh;
    max-height: 90vh; overflow-y: scroll;
    width: 400px;
    /* display: flex;
    align-items: center;
    justify-content: center;    */
  }

  .notification_section .nonote {
    min-height: 25vh;
    max-height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;   
  }

  /* width */
  .notification_section::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  .notification_section::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
  
  /* Handle */
  .notification_section::-webkit-scrollbar-thumb {
    background: var(--el-app-primary);; 
    border-radius: 10px;
  }

  /* Handle on hover */
  .notification_section::-webkit-scrollbar-thumb:hover {
    background: grey; 
  }

  #menucontainer {
    display: none;
  }

  .bar1,
  .bar2,
  .bar3 {
    width: 25px;
    height: 2px;
    background-color: var(--el-app-primary);
    margin: 5px 0;
    border-radius: 3px;
    transition: 0.4s;
  }

  .change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
  }

  .change .bar2 {
    opacity: 0;
  }

  .change .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
  }

  .search-area {
    border-radius: 5px;
    border: 1px solid #ccc;
  }

  #search-input {
    width: 400px;
    border: none !important;
  }

  #search-icon {
    display: flex;
    align-items: center;
    padding: 0px 10px 0px 30px;
    z-index: 0;
  }

  .in-the-center {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .round-ball {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    background-color: black;
  }
  .round-ball-lg {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    background-color: black;
  }
  .profile_img {
    margin-left: 10px;
  }
  .profile_img img {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    object-fit: cover;
  }
  .bg-white {
    background-color: white;
  }

  /***THE SIDENAV***/

  .sidenav {
    height: 100vh;
    width: 0px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.2s;
  }

  .sidenav a {
    /* padding: 8px 8px 8px 32px; */
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    /* display: block; */
    transition: 0.3s;
    width: 100%;
    background-color: #221b06;
    padding-left: 15px;
  }

  .sidenav a:hover {
    color: #f1f1f1;
  }

  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 0px;
    font-size: 36px;
    margin-left: 10px;
  }

  @media screen and (max-height: 450px) {
    .sidenav {
      padding-top: 15px;
    }
    .sidenav a {
      font-size: 18px;
    }
  }
  /***THE SIDENAV***/

  /*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
  @media (min-width: 768px) and (max-width: 1024px) {
    #menucontainer {
      display: block;
      cursor: pointer;
      padding: 0px;
      margin: 0px;
      display: flex;
      align-items: center;
    }
    #search-input {
      width: 300px;
      height: 25px;
    }
    #search-icon {
      display: flex;
      align-items: center;
      padding: 0px 10px 0px 10px;
      z-index: 0;
    }
    .round-ball {
      border-radius: 50%;
      width: 30px;
      height: 30px;
      background-color: black;
    }
    .profile_img {
      margin-left: 10px;
    }
    .profile_img img {
      border-radius: 50%;
      width: 30px;
      height: 30px;
      object-fit: cover;
    }
    .notification_section {
      width: 80%;
    }
  }

  /*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    #menucontainer {
      display: block;
      cursor: pointer;
      padding: 0px;
      margin: 0px;
      display: flex;
      align-items: center;
    }
    #search-input {
      width: 300px;
      height: 25px !important;
      border: none !important;
    }
    #search-icon {
      display: flex;
      align-items: center;
      padding: 0px 10px 0px 10px;
      z-index: 0;
    }
    .round-ball {
      border-radius: 50%;
      width: 30px;
      height: 30px;
      background-color: black;
    }
    .profile_img {
      margin-left: 10px;
    }
    .profile_img img {
      border-radius: 50%;
      width: 30px;
      height: 30px;
      object-fit: cover;
    }

    .notification_section {
      width: 80%;
    }
  }

  /*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
  @media (min-width: 481px) and (max-width: 767px) {
    .nav_fname {
      display: none;
    }
    #menucontainer {
      display: block;
      cursor: pointer;
      padding: 0px;
      margin: 0px;
      display: flex;
      align-items: center;
    }
    #search-input {
      width: 200px;
      height: 25px !important;
      border: none !important;
    }
    #search-icon {
      display: flex;
      align-items: center;
      padding: 0px 10px 0px 10px;
      z-index: 0;
    }
    .round-ball {
      border-radius: 50%;
      width: 30px;
      height: 30px;
      background-color: black;
    }
    .profile_img {
      margin-left: 10px;
    }
    .profile_img img {
      border-radius: 50%;
      width: 30px;
      height: 30px;
      object-fit: cover;
    }
    .notification_section {
      width: 100%;
    }
  }

  /*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
  @media (min-width: 320px) and (max-width: 480px) {
    .nav_fname {
      display: none;
    }

    #menucontainer {
      display: block;
      cursor: pointer;
      padding: 0px;
      margin: 0px;
      display: flex;
      align-items: center;
    }
    #search-input {
      width: 150px;
      height: 25px !important;
      border: none !important;
    }
    #search-icon {
      display: flex;
      align-items: center;
      padding: 0px 10px 0px 10px;
      z-index: 0;
    }
    .round-ball {
      border-radius: 50%;
      width: 30px;
      height: 30px;
      background-color: black;
    }
    .profile_img {
      margin-left: 10px;
    }
    .profile_img img {
      border-radius: 50%;
      width: 30px;
      height: 30px;
      object-fit: cover;
    }

    .notification {
      position: relative;
      border-radius: 50%;
      background-color: rgb(250, 248, 248);
    }
    .notification:hover {
      background-color: white;
    }
    .bell {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      /* border-radius: 50%; */
      /* background-color: white; */
    }

    .bell i {
      color: grey;
      font-size: 20px;
    }

    .notify {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      position: absolute;
      top: -3px;
      right: -3px;
      background-color: red;
      color: white;
      font-size: 12px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .notification_section {
      width: 100%;
    }
  }
</style>
