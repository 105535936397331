<template>
  <section class="container my-calendar">
    <div class="content">
      <div v-if="!loading && !loadingError" class="row w-100 mx-0">
        <div class="col-12 px-0">
          <div class="row w-100 mb-4">
            <div class="col-12 pl-3 px-0 mb-0 text-left">
              <BreadCrumbComponent :pageTitles="['Batches', 'Calendar']" />
            </div>
          </div>
          <div class="row w-100">
            <div class="col-12 text-left">
              <div
                style="
                  font-size: 20px;
                  font-weight: 400;
                  color: var(--el-app-primary);
                  margin-bottom: 15px;
                "
              >
                <strong>Batch Intake Calendar</strong>
              </div>
            </div>
          </div>
          <div class="row w-100 mx-1">
            <div class="col-12 intro-card">
              <div class="row w-100 py-4 px-3">
                <div class="col-md-9 text-left">
                  <div class="intro-heading">
                    {{ batchInfo.course.course_code }}
                    {{ toMonthName(batchInfo.month).toUpperCase() }}/{{
                      batchInfo.year
                    }}
                    INTAKE
                  </div>
                  <div class="intro-content">
                    <span>Course: </span> {{ batchInfo.course.title }}
                    <div>
                      <span>Students: </span> {{ totalStudents }} Students
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row w-100 mx-0">
              <div class="col-md-9 mt-5 pl-0 pr-4 text-left">
                <span style="font-weight: 600"
                  >{{ formattedDate(weekStartDate) }} -
                  {{ formattedDate(weekEndDate) }}</span
                >
                <span
                  @click="onChangeWeekIntent"
                  class="float-right"
                  style="
                    font-size: 0.8em;
                    font-weight: 500;
                    color: rgba(0, 0, 0, 0.6);
                    border: 1px solid rgba(0, 0, 0, 0.2);
                    margin-left: 8px;
                    padding: 2px 8px;
                    position: relative;
                    bottom: 4px;
                    border-radius: 3px;
                    cursor: pointer;
                  "
                >
                  <i class="fas fa-calendar pr-1" style="color: purple"></i>
                  Change Week
                </span>

                <span
                  class="float-right"
                  style="
                    font-size: 0.8em;
                    color: rgba(0, 0, 0, 0.6);
                    position: relative;
                    bottom: 4px;
                  "
                >
                  <div
                    @click="onWeekForwardBackward(+1)"
                    class="text-center chevron-right-box"
                  >
                    <i class="fas fa-chevron-right text-center"></i>
                  </div>
                </span>
                <span
                  class="float-right"
                  style="
                    font-size: 0.8em;
                    color: rgba(0, 0, 0, 0.6);
                    position: relative;
                    bottom: 4px;
                  "
                >
                  <div
                    @click="onWeekForwardBackward(-1)"
                    class="text-center chevron-left-box"
                    style=""
                  >
                    <i class="fas fa-chevron-left text-center"></i>
                  </div>
                </span>
                <div class="row w-100">
                  <div class="col-12">
                    <ScheduleLiveLessonComponent
                      @on-refresh="fetchBatchCalendar"
                      :batchInfo="batchInfo"
                    />
                  </div>
                </div>
                <DetailedCalendarComponent
                  class="d-none d-md-flex"
                  :lectures="lectures"
                  :weekStartDate="weekStartDate"
                  :weekEndDate="weekEndDate"
                  :dateToday="dateToday"
                />
              </div>
              <div class="col-md-3 px-0">
                <div class="row w-100 mt-5 mx-0" style="">
                  <div
                    v-show="!loading && !loadingError && lectures.length !== 0"
                    class="col-12 pb-3"
                    style="font-weight: 600"
                  >
                    Events this Week
                  </div>
                  <EventDetailsCardComponent
                    v-for="(lecture, index) in lectures"
                    :key="index"
                    :event="{ type: 'Lecture', data: lecture }"
                    :dateToday="dateToday"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="!loading && !loadingError && lectures.length === 0"
              class="row w-100"
            >
              <div class="col-12" style="margin-top: 80px">
                <img
                  src="../assets/img/no-content.png"
                  alt=""
                  style="height: 100px"
                />
                <div
                  class="pt-3"
                  style="
                    font-weight: 500;
                    font-size: 0.9em;
                    color: rgba(0, 0, 0, 0.45);
                  "
                >
                  There are No Events to display
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="loading" class="row w-100">
        <div class="col-12">
          <ScaleOut style="margin-top: 150px" :background="'#1F4EA1'" />
          <div class="mt-3" style="font-weight: 500; font-size: 0.9em">
            Fetching Intake Calendar
          </div>
          <div style="font-weight: 500; font-size: 0.9em">Please Wait...</div>
        </div>
      </div>

      <div
        v-if="!loading && loadingError"
        class="col-12"
        style="margin-top: 120px"
      >
        <span style="font-weight: 600">Unable to get Calendar</span>
        <div class="pt-2" style="font-size: 0.9em">
          Failed to get Calendar Information right now. Click below to retry
        </div>
        <div class="col-12 mt-1">
          <button
            type="button"
            class="btn z-depth-0"
            @click="fetchBatchCalendar"
            v-ripple="'rgba(255, 255, 255, 0.35)'"
            style="
              width: 250px;
              text-transform: capitalize;
              height: 45px;
              border-radius: 4px;
              background-color: red;
              font-weight: 500;
            "
          >
            <span style="position: relative; bottom: 1px">Try Again</span>
          </button>
        </div>
      </div>
    </div>
    <div class="change_week_dialog">
      <el-dialog
        title="Change Week"
        :visible.sync="showChangeMonthModal"
        :before-close="onBeforeClose"
      >
        <div class="row">
          <div class="col-md-12">
            <el-date-picker
              ref="datePicker"
              v-model="value1"
              type="week"
              format="Week WW"
              placeholder="Pick a week"
              class="text-center"
              :clearable="false"
              @change="onChangeWeek"
            >
            </el-date-picker>
          </div>
        </div>
      </el-dialog>
    </div>
  </section>
</template>

<script>
import BreadCrumbComponent from "../components/bread-crumb-component";
import DetailedCalendarComponent from "../components/detailed-calendar-component";
import EventDetailsCardComponent from "../components/event-details-card-component";
import ScheduleLiveLessonComponent from "../components/schedule-live-lesson-component";
import { ScaleOut } from "vue-loading-spinner";
import getMonthName from "../helpers/getMonthName";
import { format, parseISO, startOfWeek, addWeeks } from "date-fns";
export default {
  components: {
    BreadCrumbComponent,
    DetailedCalendarComponent,
    EventDetailsCardComponent,
    ScaleOut,
    ScheduleLiveLessonComponent,
  },
  data() {
    return {
      value1: "",
      lectures: [],
      week: "",
      weekStartDate: "",
      weekEndDate: "",
      dateToday: "2019-03-16",
      loading: true,
      showChangeMonthModal: false,
      loadingError: false,
      totalStudents: "",
      batchId: "",
      batchInfo: {},
    };
  },
  computed: {
    formattedDate() {
      return (dateString) => format(parseISO(dateString), "do MMMM yyyy");
    },
    toMonthName() {
      return (monthNum) => {
        return getMonthName(monthNum);
      };
    },
  },
  mounted() {
    if (this.$route.params.batchId) {
      this.batchId = this.$route.params.batchId;
    } else return this.$router.replace({ name: "CourseBatches" });
    this.fetchBatchCalendar();
  },
  methods: {
    onChangeWeek() {
      this.weekStartDate = format(startOfWeek(this.value1), "yyyy-MM-dd");
      this.showChangeMonthModal = false;
      this.fetchBatchCalendar();
    },

    onWeekForwardBackward(direction) {
      this.weekStartDate = format(
        addWeeks(parseISO(this.weekStartDate), direction),
        "yyyy-MM-dd"
      );
      this.fetchBatchCalendar();
    },

    onChangeWeekIntent() {
      this.showChangeMonthModal = true;
    },
    onBeforeClose(done) {
      done();
    },
    async fetchBatchCalendar() {
      window.scrollTo(0, 0);
      this.loading = true;
      this.loadingError = false;
      try {
        let request = await this.$http.get(
          `/calendar/by-batch/${this.batchId}?start_date=${this.weekStartDate}`
        );
        if (
          request.data.success &&
          request.data.message == "LIVE_LECTURES_RETRIEVED"
        ) {
          this.lectures = request.data.lectures;
          this.weekStartDate = request.data.week_start_date;
          this.weekEndDate = request.data.week_end_date;
          this.dateToday = request.data.date_today;
          this.batchInfo = request.data.batch;
          this.totalStudents = request.data.total_students;
        } else if (request.data.message == "STUDENT_NOT_FOUND") {
          this.showFailedMessage(
            "Account Not Found",
            "Student Account Not found. Redirecting to login now"
          );
          this.$store.commit("LOGOUT_USER");
          this.$router.replace({ path: "/login" });
        } else if (request.data.message == "STUDENT_HAS_NO_BATCH") {
          this.showFailedMessage(
            "No Student Batch Found",
            "No Student Batch found. Redirecting to login now"
          );
          this.$store.commit("LOGOUT_USER");
          this.$router.replace({ path: "/login" });
        } else throw "UNEXPECTED_RESPONSE";
      } catch (error) {
        this.showFailedMessage(
          "Fetching Failed",
          "Unable to fetch Calendar Now, Please try again"
        );
        this.loadingError = true;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.content {
  margin-top: 45px !important;
}

.intro-card {
  background-color: var(--el-app-primary);
  border-radius: 19px 19px 0px 19px;
}

.intro-heading {
  color: rgba(255, 255, 255, 0.918);
  /* padding-top: 20px; */
  font-weight: 500;
  font-size: 1.3em;
}

.intro-content {
  color: rgba(255, 255, 255, 0.74);
  font-size: 0.9em;
  padding-top: 10px;
}
.fa-chevron-left,
.fa-chevron-right {
  color: rgba(0, 0, 0, 0.4);
  position: relative;
  top: 3px;
}

.chevron-right-box {
  width: 35px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0px 3px 3px 0px;
  height: 26px;
  cursor: pointer;
}

.chevron-left-box {
  width: 35px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px 0px 0px 3px;
  height: 26px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .content {
    margin-top: 15px !important;
  }
}
</style>


<style>
  .change_week_dialog .el-input__icon {
    height: 100%;
    width: 25px;
    text-align: center;
    transition: all 0.3s;
    line-height: 40px;
  }

  .change_week_dialog .el-input input {
    height: calc(1.55rem) !important;
    padding: 0.075rem 0.05rem !important;
    font-size: 0.8rem !important;
    font-weight: 400 !important;
    color: #495057 !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  }

  .change_week_dialog .el-input input:focus {
    color: #495057 !important;
    background-color: #fff !important;
    border-color: #80bdff !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
  }

  .change_week_dialog .el-date-editor--week .el-input__inner,
  .change_week_dialog  .el-range-editor .el-input__inner {
    cursor: pointer;
    height: 40px !important;
    width: 100% !important;
    padding-left: 45px !important;
  }

  .change_week_dialog  .my-calendar .el-dialog__title {
    font-size: 1em;
    font-weight: 500;
  }

  .change_week_dialog .el-dialog {
      width: 30%;
    }
  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
    .change_week_dialog .el-dialog {
      width: 50%;
    }
  }

  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .change_week_dialog .el-dialog {
      width: 60%;
    }
  }

  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .change_week_dialog .el-dialog {
      width: 90%;
    }
  }

  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .change_week_dialog .el-dialog {
      width: 90%;
    }

  }
</style>