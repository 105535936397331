export default function getMonthName(monthInt, isFullName) {
    switch (monthInt) {
        case 1:
            return isFullName ? "January" : "Jan";
        case 2:
            return isFullName ? "February" : "Feb";
        case 3:
            return isFullName ? "March" : "Mar";
        case 4:
            return isFullName ? "April" : "Apr";
        case 5:
            return isFullName ? "May" : "May";
        case 6:
            return isFullName ? "June" : "Jun";
        case 7:
            return isFullName ? "July" : "Jul";
        case 8:
            return isFullName ? "August" : "Aug";
        case 9:
            return isFullName ? "September" : "Sep";
        case 10:
            return isFullName ? "October" : "Oct";
        case 11:
            return isFullName ? "November" : "Nov";
        case 12:
            return isFullName ? "December" : "Dec";

        default:
            return isFullName ? "N/A" : "N/A";

    }
}

// module.exports = getMonthName;